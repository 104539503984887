import { Accordion } from '@/modules/app/components/accordion';

import { useLocationsListQuery } from '@/modules/locations';

import styles from './location-accordion.module.scss';


type LocationAccordionProps = {
  city: number[];
  district: number[];
  onChangeCity: (value: number[]) => void;
  onChangeDistrict: (value: number[]) => void;
};

export const LocationAccordion = ({
  onChangeCity, onChangeDistrict, city, district,
}: LocationAccordionProps) => {
  const { data: { data } } = useLocationsListQuery({
    refetchOnMount: false,
  });

  return (
    <div className={styles.wrapper}>
      {data?.map((item) => (
        <Accordion
          key={item.id}
          isChecked={city.includes(item.id)}
          onChangeChecked={(checked) => {
            if (checked) {
              onChangeCity([...city, item.id]);
            } else {
              onChangeCity([...city.filter((c) => c !== item.id)]);
            }
          }}
          onChangeDistrict={(ids) => {
            /**
             * Удаляем все id районов для того чтобы добавить актуальное состоение по текущему городу
             */
            const addDistrictsExcludeThisCity = district
              .filter((id) => !item.districts.map((d) => d.id).includes(id));

            onChangeDistrict([...addDistrictsExcludeThisCity, ...ids]);
          }}
          city={item.codename}
          district={district}
          options={item.districts}
        />
      ))}
    </div>
  );
};
