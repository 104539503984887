import React, { useLayoutEffect, useState } from 'react';
import {
  SubCategory, Category, UnderSubCategory,
} from '@/modules/categories';
import { useRouter } from 'next/router';

import { CategoryItem } from './category-item.component';
import styles from './category-selection.module.scss';



type CategorySelectionProps = {
  category?: Category, subCategory?: SubCategory, underSubCategory?: UnderSubCategory
};

export const CategorySelection = ({
  category, subCategory, underSubCategory,
}: CategorySelectionProps) => {
  const router = useRouter();
  const [openIds, setOpenIds] = useState<Record<number, boolean>>({});

  useLayoutEffect(() => {
    if (subCategory) {
      setOpenIds({ [subCategory.id]: true });
    }
  }, [subCategory, underSubCategory]);

  return (
    <div className={styles.category_selection}>
      {category && <h2 className={styles.title}>{category.title}</h2>}
      {category?.sub_categories.map((item) => (
        <CategoryItem
          key={item.id}
          isActive={!underSubCategory && item.id === subCategory?.id}
          title={item.title}
          onClickItem={({ pathname }) => router.push(pathname, undefined, { shallow: true })}
          onClickIcon={() => {
            setOpenIds((prev) => ({ ...prev, [item.id]: !prev[item.id] }));
          }}
          activeItemId={underSubCategory?.id}
          items={item.under_sub_categories}
          isOpen={!!openIds[item.id]}
          onClickCategory={() => router.push(item.pathname, undefined, { shallow: true })}
        />
      ))}
    </div>
  );
};
