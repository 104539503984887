import { useCallback, useState } from 'react';

export const useSetFocus = () => {
  const [isFocus, setIsFocus] = useState(false);

  const setFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  return { isFocus, setFocus };
};
