import React from 'react';
import Link from 'next/link';
import { Scrollbar, SvgIcon } from '@/modules/shared/components';
import { useCategoryName } from '@/modules/categories/hooks';
import { Category, SubCategory } from '@/modules/categories';
import styles from '../category-list.module.scss';

interface Props {
  activeCategory: Category | null;
  activeSubcategory: SubCategory | null;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}
export const CategoryListStepThree = ({ activeSubcategory, activeCategory, onClose }: Props) => {
  const [getNameByCode] = useCategoryName();
  const title = getNameByCode(activeCategory!.codename, activeSubcategory!.codename);

  return (
    <>
      <Link
        href={activeSubcategory!.pathname}
        className={styles.subcategory}
        onClick={() => onClose(false)}
      >
        <h4>{title}</h4>
        <SvgIcon name="arrow-right" />
      </Link>
      <Scrollbar>
        {activeSubcategory && activeSubcategory.under_sub_categories.map(
          (underSubcategory, index) => (
            <Link
              key={index}
              href={underSubcategory.pathname}
              className={styles.subcategory}
              onClick={() => onClose(false)}
              data-testid={`category_${underSubcategory.codename}`}
            >
              <p>
                {getNameByCode(activeCategory!.codename, activeSubcategory.codename, underSubcategory.codename)}
              </p>
              <SvgIcon name="arrow-right" />
            </Link>
          ),
        )}
      </Scrollbar>
    </>
  );
};
