import React from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import { Category } from '@/modules/categories';
import styles from './category-card.module.scss';

type CategoryCardProps = {
  data: Category;
};

export const CategoryCard = ({ data } : CategoryCardProps) => {
  const {
    icon, title, pathname, codename,
  } = data;

  return (
    <Link
      href={pathname}
      data-testid={`tile_${codename}`}
    >
      <div
        className={clsx(styles.container, {
          [styles.transport]: codename === 'transport',
          [styles.personal_items]: codename === 'personal-items',
          [styles.real_estate]: codename === 'real-estate',
          [styles.electronics]: codename === 'electronics',
          [styles.services]: codename === 'services',
          [styles.home]: codename === 'home',
          [styles.animals]: codename === 'animals',
        })}
      >
        <p className={styles.text}>{title}</p>
        <div className={styles.icon}>
          <img
            src={icon}
            alt={title}
          />
        </div>
      </div>
    </Link>
  );
};
