import { useCallback, RefObject, useEffect } from 'react';

export type useAdjustTextareaHeightProps = {
  textareaRef: RefObject<HTMLTextAreaElement>;
  minHeight: number;
};

export const useAdjustTextareaHeight = ({ textareaRef, minHeight } : useAdjustTextareaHeightProps) => {
  const adjustTextareaHeight = useCallback(() => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      const computedStyle = getComputedStyle(textarea);
      const { clientWidth } = textarea;

      // Создаем временный элемент для вычисления высоты
      const tempDiv = document.createElement('div');
      tempDiv.style.visibility = 'hidden';
      tempDiv.style.position = 'absolute';
      tempDiv.style.whiteSpace = 'pre-wrap';
      tempDiv.style.width = `${clientWidth}px`;
      tempDiv.style.padding = computedStyle.padding;
      tempDiv.style.margin = computedStyle.margin;
      tempDiv.style.border = computedStyle.border;
      tempDiv.style.fontFamily = computedStyle.fontFamily;
      tempDiv.style.fontSize = computedStyle.fontSize;
      tempDiv.style.lineHeight = computedStyle.lineHeight;
      tempDiv.style.letterSpacing = computedStyle.letterSpacing;
      tempDiv.style.boxSizing = 'border-box';
      tempDiv.style.overflow = 'hidden';

      // Используем innerHTML для учета HTML-тегов
      tempDiv.innerHTML = textarea.value || textarea.placeholder;
      document.body.appendChild(tempDiv);
      const newHeight = Math.max(tempDiv.scrollHeight, minHeight);
      document.body.removeChild(tempDiv);

      // Устанавливаем новую высоту
      textarea.style.height = `${newHeight}px`;
    }
  }, [minHeight]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [textareaRef.current?.value, adjustTextareaHeight]);

  return adjustTextareaHeight;
};
