import { BoldString } from './bold-string.component';
import s from './creation-location.module.scss';
import { AutocompleteSuggestion } from './types';

type SearchListProps = {
  isOpen: boolean;
  onClick: (item: AutocompleteSuggestion) => void;
  autocompleteSuggestions: AutocompleteSuggestion[];
};

export const SearchList = ({ isOpen, autocompleteSuggestions, onClick }: SearchListProps) => (
  <ul
    className={s.list}
    style={!autocompleteSuggestions.length || !isOpen ? { display: 'none' } : {}}
  >
    {autocompleteSuggestions.length > 0 && autocompleteSuggestions.map((item) => (
      <li
        key={item.id}
        onClick={() => onClick(item)}
      >
        <p>
          <BoldString {...item.name} />
          {', '}
          <BoldString {...item.address} />
        </p>
      </li>
    ))}
  </ul>
);
