import React, { useRef, ChangeEvent, useCallback } from 'react';
import { SvgIcon } from '@/modules/shared/components';
import { useImageUploader } from '@/modules/shared/hooks';
import s from './chat-choose-photo.module.scss';

interface ChatChoosePhotoProps {
  sendMessage: (img?: File) => Promise<void>;
}

export const ChatChoosePhoto: React.FC<ChatChoosePhotoProps> = ({ sendMessage }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { handleImageUpload } = useImageUploader({
    maxSizeInMB: 10,
    compress: 5,
  });

  const handleFileChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    const newImages = await handleImageUpload(e);
    sendMessage(newImages[0] as File);
  }, [handleImageUpload]);

  return (
    <>
      <SvgIcon
        name="add-photo-32"
        className={s.photo}
        onClick={() => fileInputRef.current?.click()}
      />
      <input
        ref={fileInputRef}
        onChange={handleFileChange}
        type="file"
        accept=".jpg, .jpeg, .png, .heic, .webp"
        hidden
        multiple={false}
      />
    </>
  );
};
