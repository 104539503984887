export const formatMessageCount = (count: number, limit: number | null = 99) => {
  if (limit === null) { // Нет лимита
    return count.toString();
  }

  if (count > limit) {
    return `${limit}+`;
  }

  return count.toString();
};
