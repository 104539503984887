interface DeclensionParams {
  count: number;
  t: (key: string, opts?: Record<string, unknown>) => string;
  singular: string;
  few: string;
  many: string;
}

export const getDeclension = ({
  count, t, singular, few, many,
}: DeclensionParams) => {
  if (!count || typeof count !== 'number' || count === 0) {
    const noneString = few.split('.').slice(0, -1).join('.');
    const none = t(`${noneString}.none`);
    return none;
  } // готово для использования, ничего подставлять не нужно дополнительно

  const singularTranslate = t(singular, { count });
  const fewTranslate = t(few, { count });
  const manyTranslate = t(many, { count });

  const mod10 = count % 10;
  const mod100 = count % 100;

  if (mod10 === 1 && mod100 !== 11) {
    return singularTranslate;
  }
  if (mod10 >= 2 && mod10 <= 4 && (mod100 < 10 || mod100 >= 20)) {
    return fewTranslate;
  }
  return manyTranslate;
};
