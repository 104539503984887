import React, { useMemo } from 'react';
import { useMatchMedia } from '@/modules/shared/hooks';
import useTranslation from 'next-translate/useTranslation';
import { Button, ModalDesktop } from '@/modules/shared/components';
import styles from './modal-confirm-ad.module.scss';

interface ConfirmAdProps {
  isOpen: boolean;
  isSelected: boolean;
  adType: string;
  onClose: () => void;
  toggleAdVisibility: () => void;
}

export const ModalConfirmAd = ({
  isOpen, isSelected, onClose, adType, toggleAdVisibility,
}: ConfirmAdProps) => {
  const { t } = useTranslation('userProfile');
  const { isMobile } = useMatchMedia();

  const actionTexts = useMemo(() => ({
    active: {
      title: isSelected
        ? t('myAds.hideAd', { title: t('myAds.ads.few') })
        : t('myAds.hideAd', { title: t('myAds.ads.singular') }),
      description: isSelected
        ? t('myAds.adHiddenWarn', { title: t('myAds.theAds') })
        : t('myAds.adHiddenWarn', { title: t('myAds.ad') }),
      confirm: t('myAds.confirmHide'),
      cancel: t('myAds.cancel'),
    },
    hidden: {
      title: isSelected
        ? t('myAds.openAd', { title: t('myAds.ads.few') })
        : t('myAds.openAd', { title: t('myAds.ads.singular') }),
      description: isSelected
        ? t('myAds.adWarn', { title: t('common:tabBar.ads') })
        : t('myAds.adWarn', { title: t('myAds.ad') }),
      confirm: t('myAds.confirmOpen'),
      cancel: t('myAds.cancel'),
    },
    drafts: {
      title: isSelected
        ? t('myAds.deleteDraft', { title: t('myAds.drafts') })
        : t('myAds.deleteDraft', { title: t('myAds.optionDrafts.singular') }),
      description: t('myAds.draftWarn'),
      confirm: t('myAds.confirmDelete'),
      cancel: t('myAds.cancel'),
    },
  }), [t]);

  const {
    title, description, confirm, cancel,
  } = actionTexts[adType];

  const onSubmit = () => {
    toggleAdVisibility();
    onClose();
  };

  return (
    <ModalDesktop open={isOpen} onClose={onClose} showCloseButton={!isMobile} className={styles.modal_confirm}>
      <div className={styles.confirm_ad}>
        <h2>{t(title)}</h2>
        <p>{t(description)}</p>
        <div className={styles.buttons}>
          <Button
            fullWidth
            appearance="primary"
            buttonColor="blue"
            buttonSize={isMobile ? 'xs' : 's'}
            onClick={onSubmit}
          >
            {t(confirm)}
          </Button>
          <Button
            appearance="primary"
            buttonColor="grey"
            buttonSize={isMobile ? 'xs' : 's'}
            onClick={onClose}
            fullWidth
          >
            {t(cancel)}
          </Button>
        </div>
      </div>
    </ModalDesktop>
  );
};
