import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import useTranslation from 'next-translate/useTranslation';
import Cookies from 'js-cookie';
import getConfig from 'next/config';

import { SvgIcon } from '@/modules/shared/components';

import { getTokenExpiration } from '../../../../providers/auth.helpers';
import { useAuthContext } from '../../../../contexts';
import { AuthApi } from '../../../../api';

const { publicRuntimeConfig: { telegramOAuthBotId } } = getConfig();

export const TelegramOauth = () => {
  const { locale } = useRouter();
  const { setAccessToken, setRefreshToken } = useAuthContext();
  const { t } = useTranslation();

  const openTelegramAuth = () => {
    const authUrl = `https://oauth.telegram.org/auth?bot_id=${telegramOAuthBotId}&return_to=${window.location.origin}&origin=${window.location.origin}&lang=${locale}`;
    const width = 600;
    const height = 500;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    window.open(authUrl, '_blank', `width=${width},height=${height},top=${top},left=${left}`);
  };

  useEffect(() => {
    const onMessage = async (event: MessageEvent) => {
      if (event.origin !== 'https://oauth.telegram.org') return;
      const message = JSON.parse(event.data);
      if (message.origin !== window.location.origin || message.event !== 'auth_result') return;

      try {
        const response = await AuthApi.telegramLogin(message.result);

        Cookies.set('access_token', response.access, { expires: getTokenExpiration(response.access) });
        Cookies.set('refresh_token', response.refresh, { expires: getTokenExpiration(response.refresh) });
        Cookies.set('user_id', response.id, { expires: getTokenExpiration(response.access) });

        setRefreshToken(response.refresh);
        setAccessToken(response.access);
        toast.success(t('auth:success'));
      } catch (e) {
        toast.error(t('auth:error'));
      }
    };

    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [setAccessToken, setRefreshToken, t]);

  return (
    <button type="button" onClick={openTelegramAuth}>
      <SvgIcon
        style={{
          fontSize: '44px',
        }}
        name="telegram"
      />
    </button>
  );
};
