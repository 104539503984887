import { useQuery } from '@tanstack/react-query';
import { IReviews } from '@/modules/shared/types';
import { ReviewApi } from '../api';

const queryKey = ['reviews'];

export const useReviewsListQuery = () => useQuery<IReviews[]>({
  queryKey,
  queryFn: () => ReviewApi.reviewsList(),
});
