import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { useGlobalData } from '@/context/GlobalContext';
import styles from './category-button.module.scss';

export const CategoryButton = () => {
  const { t } = useTranslation('');
  const { isShowCategoriesModal, setIsShowCategoriesModal } = useGlobalData();

  return (
    <button
      type="button"
      data-testid="category_button"
      className={clsx(styles.category_button, {
        [styles.category_button_open]: isShowCategoriesModal,
      })}
      onClick={() => setIsShowCategoriesModal(!isShowCategoriesModal)}
    >
      <span
        className={clsx(styles.lineContainer, {
          [styles.lineContainer_active]: isShowCategoriesModal,
        })}
      >
        <span className={styles.line} />
      </span>
      <span className={styles.hideText}>{t('common:categories')}</span>
    </button>
  );
};
