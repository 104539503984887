import { FieldError } from 'react-hook-form';
import s from '../modal.module.scss';

export const ModalError = ({ error }: { error: FieldError | undefined }) => (
  error ? (
    <p className={s.error}>
      {error['0'] as unknown as string || error.message as string}
    </p>
  ) : null
);
