import { ChangeEvent, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { useFormContext } from 'react-hook-form';
import { InputPrice, Switcher } from '@/modules/shared/components';
import { AddEventType } from '../../types';
import s from './add-event-page.module.scss';

export const Price = () => {
  const {
    register, watch, resetField, setError, formState: { errors }, clearErrors, setValue,
  } = useFormContext<AddEventType>();
  const { t } = useTranslation();
  const min = watch('price');
  const max = watch('max_price');
  const free = watch('free');
  const priceDisabled = useMemo(() => !!free, [free]);

  const handleBlur = useCallback(() => {
    if (max || min) {
      setValue('free', false);
      if (max && min && +max < +min) {
        setError('max_price', {
          type: 'minmax',
          message: t('forms:validationErrors.minmax_price', { min }),
        });
      } else if (max && !min) {
        setError('price', {
          type: 'required',
          message: t('forms:validationErrors.required'),
        });
      } else {
        clearErrors('price');
        clearErrors('max_price');
        clearErrors('free');
      }
    }
  }, [max, min]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue('free', e.target.checked);
    if (e.target.checked) {
      resetField('price');
      resetField('max_price');
    }
    if (!max && !min && !e.target.checked) {
      setError('free', {
        type: 'required',
        message: t('forms:validationErrors.required'),
      });
    } else {
      clearErrors('free');
    }
  }, [max, min]);

  return (
    <div className={s.field}>
      <h2>{t('events:createEvents.price._')}</h2>
      <div className={s.field_inner}>
        <p>
          {t('events:createEvents.price._')}
          <span> *</span>
        </p>
        <div className={clsx(s.values, s.price)}>
          <div className={s.from_to}>
            <InputPrice
              min="0"
              placeholder={t('events:createEvents.price.from')}
              disabled={priceDisabled}
              {...register('price', { onBlur: handleBlur })}
            />
            <InputPrice
              min="0"
              placeholder={t('events:createEvents.price.to')}
              disabled={priceDisabled}
              {...register('max_price', { onBlur: handleBlur })}
            />
          </div>
          {errors.max_price && (
            <p className={s.error}>{errors.max_price['0'] || errors.max_price.message}</p>
          )}
          {errors.free && (
            <p className={s.error}>{errors.free['0'] || errors.free.message}</p>
          )}
          {errors.price && (
            <p className={s.error}>{errors.price['0'] || errors.price.message}</p>
          )}
          <div className={s.for_free}>
            <Switcher {...register('free', { onChange: handleChange })} />
            <span>{t('events:createEvents.price.free')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
