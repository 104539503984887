import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { Button } from '@/modules/shared/components';
import s from './event-description.module.scss';

export type EventDescriptionProps = {
  description: string;
  showMore?: boolean;
  className?: string;
  classNameWrapper?: string;
};

const createClickableLinks = (text) => {
  const pattern = /((https?:\/\/)(([\da-z]([\da-z-]*[\da-z])*)\.)+[a-z]{2,}(\/[\w%+.~-]*)*(\?[\w%&+.;=~-]*)?)/gim;
  return text.replace(pattern, (match, ...args) => {
    // the first n args are the catched groups
    const [, , , , , , , offset] = args;
    const maxOffset = Math.max(offset - 6, 0);
    if (text.substr(maxOffset, 6) === 'href="' || text.substr(maxOffset, 6) === 'href=\'') {
      return match;
    }
    return `<a target="_blank" style="color: blue" href="${match}">${match}</a>`;
  });
};

export const EventDescription = ({
  description, showMore = false, className, classNameWrapper,
}: EventDescriptionProps) => {
  const { t } = useTranslation('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const descriptionRef = useRef<HTMLParagraphElement>(null);

  const linkedDescription = createClickableLinks(description);
  const linkedDescriptionWithBreaks = useMemo(
    () => linkedDescription.replace(/\n/g, '<br/>'),
    [linkedDescription],
  );

  const toggleExpand = () => setIsExpanded((prev) => !prev);

  const checkButtonVisibility = () => {
    if (descriptionRef.current) {
      const element = descriptionRef.current;
      const isOverflowing = element.scrollHeight > element.clientHeight;
      if (!isExpanded) {
        setIsButtonVisible(isOverflowing);
      }
    }
  };

  useEffect(() => {
    checkButtonVisibility();
  }, [description, isExpanded]);

  useEffect(() => {
    const handleResize = () => checkButtonVisibility();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={clsx(s.wrapper, classNameWrapper)}>
      <div
        ref={descriptionRef}
        className={clsx(s.description, className, { [s.expanded]: isExpanded })}
        dangerouslySetInnerHTML={{
          __html: linkedDescriptionWithBreaks,
        }}
      />
      {showMore && isButtonVisible && (
        <Button
          appearance="secondary"
          buttonColor="blue"
          buttonSize="s"
          onClick={toggleExpand}
          className={s.action}
        >
          {isExpanded ? t('common:showLess') : t('common:more')}
        </Button>
      )}
    </div>
  );
};
