import React, { FC } from 'react';
import { IAd } from '../../types';

interface IAdImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  ad: IAd;
}
export const AdImage: FC<IAdImageProps> = ({ ad, ...props }) => {
  const mainImage = ad?.main_picture;
  const imageSrc = ad?.images?.find((img) => img.id === mainImage);

  return <img {...props} src={imageSrc?.thumbnail || imageSrc?.image} alt={ad.title} loading="lazy" />;
};
