import React, { useEffect, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { Checkbox, Button, SvgIcon } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { SearchBar } from '@/modules/app/components/search-bar';
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form';
import { getDeclension } from '@/modules/shared/helpers';
import { ButtonBack } from '../button-back';
import { FilterSelectByMessage } from '../filter-select-by-message';
import styles from './chat-list-sorting.module.scss';


type ChatListSortingProps<T extends FieldValues> = {
  isInputFocused: boolean;
  setIsInputFocused: (focused: boolean) => void;
  selectAll: boolean;
  selectedChat: number[];
  handleSelectAll: () => void;
  setIsOpenModalDeleteChat: (isOpen: boolean) => void;
  messageFilterOptions: { value: string; label: string }[];
  adFilterOptions: { value: string; label: string }[];
  control: Control;
  onSearchChat: (value: string) => void;
  setValue: UseFormSetValue<T>;
  isOptionsMobile: boolean;
};

export const ChatListSorting: React.FC<ChatListSortingProps<FieldValues>> = ({
  isInputFocused,
  setIsInputFocused,
  selectAll,
  selectedChat,
  handleSelectAll,
  setIsOpenModalDeleteChat,
  messageFilterOptions,
  adFilterOptions,
  control,
  onSearchChat,
  setValue,
  isOptionsMobile,
}) => {
  const { t } = useTranslation('');
  const { isMobile, isTablet, isDesktop } = useMatchMedia();

  const countDeleteChat = useMemo(
    () => getDeclension({
      count: selectedChat.length,
      t,
      singular: 'chat:chatDelete.one',
      few: 'chat:chatDelete.few',
      many: 'chat:chatDelete.many',
    }),
    [selectedChat.length, t],
  );

  useEffect(() => {
    const navBar = document.getElementById('mobile-navigation-bar');
    const navDelete = document.getElementById('mobile-nav-delete');

    const checkNavDelete = () => {
      if (navDelete && navBar) {
        navBar.style.display = 'none';
      } else if (navBar) {
        navBar.style.display = '';
      }
    };

    checkNavDelete();

    return () => {
      if (navBar) {
        navBar.style.display = '';
      }
    };
  }, [isOptionsMobile]);



  return (
    <div className={styles.sorting}>
      {(!isInputFocused || isMobile) && (
        <div className={styles.sorting_buttons}>
          {/* Удалить */}
          {isDesktop && (
            <>
              <Checkbox
                checkboxSize="24"
                checked={selectAll && selectedChat.length > 0}
                onChange={handleSelectAll}
              />
              <Button
                as="button"
                buttonSize="xs"
                buttonColor="grey"
                appearance="primary"
                className={styles.sorting_buttons_delete}
                disabled={selectedChat.length === 0}
                onClick={() => setIsOpenModalDeleteChat(true)}
              >
                <SvgIcon name="delete" />
                {countDeleteChat}
              </Button>
            </>
          )}
          {(isMobile || isTablet) && isOptionsMobile && (
            <div className={styles.nav_delete} id="mobile-nav-delete">
              <Button
                as="button"
                buttonSize="s"
                buttonColor="blue"
                appearance="secondary"
                className={styles.nav_delete_button}
                onClick={handleSelectAll}
              >
                {t('chat:selectAllChats')}
              </Button>

              <Button
                as="button"
                buttonSize="s"
                buttonColor="blue"
                appearance="secondary"
                className={styles.nav_delete_button}
                disabled={selectedChat.length === 0}
                onClick={() => setIsOpenModalDeleteChat(true)}
              >
                {t('chat:delete')}
              </Button>
            </div>
          )}

          {/* Все сообщения */}
          <FilterSelectByMessage
            setValue={setValue}
            control={control}
            name="select-filter-message"
            options={messageFilterOptions}
          />

          {/* Все объявления */}
          <FilterSelectByMessage
            setValue={setValue}
            control={control}
            name="select-filter-ads"
            options={adFilterOptions}
          />
        </div>
      )}

      {/* Поиск */}
      <div className={clsx(styles.search, { [styles.search_not_background]: isMobile && isInputFocused })}>
        {isInputFocused && !(isMobile && isInputFocused) && (
        <ButtonBack
          setIsInputFocused={setIsInputFocused}
          pathRedirect={() => setIsInputFocused(false)}
        />
        )}
        <SearchBar
          isInputFocused={isInputFocused}
          setIsInputFocused={setIsInputFocused}
          hasHistory={false}
          onSearchFilter={onSearchChat}
          hasSearchButton={false}
          iconOnly={(isMobile && !isInputFocused)}
          customStyles={{
            searchBarContainer: styles.search_container,
            searchBar: styles.search_searchbar,
            searchBarField: styles.search_container_filed,
            field: styles.search_field,
            button_delete: styles.search_button_delete,
          }}
          searchOnType
          shouldClearSearchOnBack
          isClickOutside={isTablet || isDesktop}
        />
      </div>
    </div>
  );
};
