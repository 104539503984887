import { ComponentProps, ElementType } from 'react';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import s from './trimmed-text.module.scss';

type TrimmedTextProps<T extends ElementType> = {
  component: T;
  tooltip: string;
  lines?: number;
  className?: string;
} & ComponentProps<T>;

export const TrimmedText = <T extends ElementType>({
  component: Component,
  tooltip,
  lines = 1,
  className = '',
  style = {},
  ...props
}: TrimmedTextProps<T>) => {
  const mergedStyle = { ...style, WebkitLineClamp: lines };

  return (
    <Tooltip title={tooltip}>
      <Component {...props} className={clsx(s.trimmed, className)} style={mergedStyle} />
    </Tooltip>
  );
};
