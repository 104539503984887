/* для бэкенда:
* нет полей год выпуска, пробег, объем двигателя, тип двигателя, коробка передач
*/

import useTranslation from 'next-translate/useTranslation';

export const useTransport = () => {
  const { t } = useTranslation('ad-parameters');

  const mapCondition = (value:string) => ({ value, label: t(`transport.conditions.${value}`) });
  const mapDuration = (value:string) => ({ value, label: t(`transport.duration.${value}`) });

  const mapCarsBrands = (value:string) => ({ value, label: value });
  const mapCarsEngineType = (value:string) => ({ value, label: t(`transport.cars.engineType.${value}`) });
  const mapCarsTransmission = (value:string) => ({ value, label: t(`transport.cars.transmission.${value}`) });
  const mapCarsAdditionalOptions = (value:string) => ({ value, label: t(`transport.cars.additionalOptions.${value}`) });

  const mapMotorcyclesBrands = (value:string) => ({ value, label: value });
  const mapMotorcyclesAdditionalOptions = (value:string) => ({ value, label: t(`transport.motorcycles.additionalOptions.${value}`) });


  const transport = {
    condition: ['new', 'used'].map(mapCondition),
    duration: ['daily', 'long_term'].map(mapDuration),
    cars: {
      brands: [
        'AITO', 'Alfa Romeo', 'Arcfox', 'Audi', 'Avatr', 'BAIC', 'Baojun', 'Bentley', 'BMW', 'Buick', 'BYD',
        'Cadillac', 'Changan', 'Chery', 'CheryExeed', 'Chevrolet', 'Citroen', 'Daihatsu', 'Denza', 'Dodge',
        'Dongfeng', 'DS', 'EXEED', 'FAW', 'FIAT', 'Ford', 'Forthing', 'Foton', 'GAC', 'Geely', 'Genesis', 'GMC',
        'Golden Dragon', 'Great Wall', 'Haval', 'HiPhi', 'Honda', 'Hongqi', 'Hyundai', 'Infiniti', 'Isuzu', 'JAC',
        'Jaguar', 'Jeep', 'Jetour', 'Jetta', 'JMC', 'Kaiyi', 'Kia', 'Lamborghini', 'Lancia', 'Land Rover', 'Lexus',
        'Lincoln', 'LiXiang', 'Lotus', 'Lynk & Co', 'Maserati', 'Maxus', 'Mazda', 'Mercedes-Benz', 'MG', 'MINI',
        'Mitsubishi', 'Neta', 'NIO', 'Nissan', 'OMODA', 'Opel', 'Ora', 'Oshan', 'Peugeot', 'Polestar', 'Porsche',
        'RAM', 'Renault', 'Renault Samsung', 'Roewe', 'Rolls-Royce', 'Rover', 'Saic', 'Scion', 'Seres', 'Skoda',
        'SsangYong', 'Subaru', 'Suzuki', 'Tank', 'Tesla', 'Toyota', 'Trumpchi', 'Venucia', 'VGV', 'Volkswagen',
        'Volvo', 'Voyah', 'Weltmeister', 'Wey', 'Wuling', 'XPeng', 'Zeekr', 'Zhiji',
      ].map(mapCarsBrands),
      engineType: ['electric', 'gasoline', 'diesel'].map(mapCarsEngineType),
      transmission: ['mechanical', 'automatic'].map(mapCarsTransmission),
      additionalOptions: ['child_seat', 'audio_system', 'deposit', 'delivery', 'phone_charger'].map(mapCarsAdditionalOptions),
    },
    motorcycles: {
      brands: [
        'ABM (X-moto)', 'Aermacchi', 'Agiax', 'Ajerra', 'AJP', 'AJS', 'Alfer', 'Alpha', 'Alphamoto', 'Alrendo',
        'Amazonas', 'American Eagle', 'American IronHorse', 'Antrike', 'Apache', 'Apaq', 'Apollo', 'Aprilia',
        'Arch Motorcycle', 'Arctic Leopard', 'Ardie', 'Ariel', 'Arlen Ness', 'ArmadA', 'Asiawing', 'Asia wing',
        'Ataki', 'ATK', 'ATV-TY', 'Avantis', 'AVM', 'Azel', 'Bajaj', 'Baltmotors', 'BamX', 'Baodiao', 'Bars', 'Benda',
        'Benelli', 'Beta', 'BHR', 'Bianchi', 'Bifei', 'Big Bear Choppers', 'Big Dog Motorcycles', 'Bimota', 'Bison',
        'Blata', 'BMC Choppers', 'BMW', 'Boom Trikes', 'Borile', 'Boss Hoss', 'Bourgets Bike Works', 'Brabus', 'Brammo',
        'Briar streetfire', 'Brough Superior', 'BRP', 'BRZ', 'BSA', 'BSE', 'BucciMoto', 'Buell', 'Bull', 'Bultaco',
        'BUTCH', 'BYD', 'Cagiva', 'Campagna', 'Caofen', 'Carver', 'CCM', 'Centurion', 'CFMOTO', 'Champ', 'Chang-Jiang',
        'Charger bike', 'China Motors', 'CHITUMA', 'CH Racing', 'Cobra', 'Confederate', 'Coolster', 'CPI', 'Cronus',
        'Cross', 'CR&S', 'CRZ', 'CSR', 'Curtiss', 'Custom-Wolf', 'Cyclone', 'CZ', 'Daelim', 'Darex', 'Defant', 'Defiant',
        'Deller', 'Denzel', 'Derbi', 'Desert Raven', 'Dirtmax', 'DKW', 'DM Telai', 'Dodge', 'D-RAD', 'DraXter', 'Ducati',
        'EBR', 'ECO', 'EcoGet', 'Ecosse', 'Ekonik', 'Electric Motion', 'Electron', 'El Moto', 'EMAX', 'eMaxis', 'Energica',
        'Eurotex', 'E.V.A.', 'Excelsior-Henderson', 'ExDrive', 'Expert-Moto', 'Factory Bike', 'Falcon', 'Fantic',
        'Fekon', 'Fidelis Et Fortis', 'Fighter', 'Fine Custom Mechanics', 'FIREGUARD', 'Fischer', 'Flyrite Choppers',
        'FMC', 'Forsage', 'Forte', 'Fosti', 'Frateli', 'FST', 'Fuego', 'Full Crew', 'Futong', 'FXMOTO', 'Gaokin', 'GAS GAS',
        'Generic', 'Geon', 'G&G', 'Ghezzi-Brian', 'Gilera', 'Gixxer', 'G-max', 'Golden Tomorrow', 'GR', 'GR2', 'GRCmoto',
        'GreenCamel', 'Groza', 'GR-X', 'Gryphon', 'GS Motors', 'Guowei', 'GX Moto', 'Gyurza', 'Hanway', 'Harley-Davidson',
        'Hartford', 'Hasky', 'Hb Co', 'Headbanger', 'Hengjian', 'Hercules', 'Honda', 'Honling', 'Horex', 'Horwin', 'HUAIHAI',
        'Huanpeng', 'Husaberg', 'Husqvarna', 'Hyosung', 'Independence', 'Indian', 'IRBIS', 'Iride', 'Italian Volt', 'James',
        'Jawa', 'JHL', 'Jialing', 'Jianshe', 'Jinyi', 'JMC', 'JMX', 'Johnny Pag', 'JUMPER', 'K2R', 'Kaitong Motors', 'KAMAX',
        'Kangda', 'Kanuni', 'KATAR', 'Kawasaki', 'Kaxa Motos', 'KAYO', 'KC Creations', 'Keeway', 'Kews', 'Kington',
        'Kinlon Motors', 'Koshine', 'Kove', 'Kramit', 'Kreidler', 'KSM', 'KTM', 'Kuberg', 'KXD', 'Kymco', 'Laverda',
        'Legend', 'Lem', 'Lifan', 'LITUS', 'Loncin', 'Loncin Voge', 'Longzu Star', 'LUQI', 'Magni', 'Maico', 'Malaguti',
        'Malanca', 'Matchless', 'MBK', 'MBS', 'Megelli', 'Meiduo', 'Metrakit', 'MGMoto', 'Midual', 'Mikilon',
        'Millennium', 'Minsk', 'MM', 'Mondial', 'Montesa', 'Motax', 'MoTesla', 'MOTO73', 'Motobi', 'Moto Guzzi', 'Motoland',
        'Moto Morini', 'Motorhispania', 'Mowgli', 'MTM Moto', 'MTT', 'MV Agusta', 'MZ', 'Nanfang', 'NCR', 'Neander',
        'Neighborhood home', 'Nexus', 'Nicot', 'Nipponia', 'Nitro', 'Nort', 'Norton', 'NSU', 'Ohvale', 'Omaks',
        'Orange County Choppers', 'Orion', 'OSET', 'OSSA', 'Pannonia', 'Patron', 'Paxus', 'PCW', 'Pegas', 'Peugeot',
        'Phoenix Trike Works', 'Pioneer', 'PITONMOTO', 'Pitrace', 'Pitster Pro', 'Polaris', 'Polini', 'Pony Motors',
        'Praga', 'PROCIDA', 'Progasi', 'Promax', 'PUCH', 'PWR Racing', 'Qingqi', 'QJiang', 'Yamaha',
      ].map(mapMotorcyclesBrands),
      additionalOptions: ['deposit', 'delivery', 'phone_mount'].map(mapMotorcyclesAdditionalOptions),
      engineType: [] as { value: string; label: string }[],
      transmission: [] as { value: string; label: string }[],
    },
    motorbikes: {
      brands: [] as { value: string; label: string }[],
      engineType: [] as { value: string; label: string }[],
      transmission: [] as { value: string; label: string }[],
      additionalOptions: [] as { value: string; label: string }[],
    },
  };

  transport.motorcycles.engineType = transport.cars.engineType;
  transport.motorcycles.transmission = transport.cars.transmission;

  transport.motorbikes.brands = transport.motorcycles.brands; // на бэке пока нет значений для скутеров, оставил значения мотоциклов
  transport.motorbikes.transmission = transport.cars.transmission;
  transport.motorbikes.engineType = transport.cars.engineType;
  transport.motorbikes.additionalOptions = transport.motorcycles.additionalOptions;


  return transport;
};
