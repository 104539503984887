import { api } from '@/modules/shared/api';
import { endpoints } from './endpoints';

type Location = {
  city: string;
};

export const LocationsApi = {
  getLocation: async ({ latitude, longitude }: { latitude: number; longitude: number }) => {
    const { data } = await api.get<Location>(endpoints.myLocation({ latitude, longitude }));
    return data;
  },
};
