import React, { useCallback, useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { InputText, SvgIcon, Switcher } from '@/modules/shared/components';
import { CreateAdTypes } from '../../types';
import styles from '../part-of-form.module.scss';
import priceStyle from './price.module.scss';

const regex = /^[\d\b]+$/;

export const Price = () => {
  const { t } = useTranslation('createAd');
  const {
    control, setValue, formState, clearErrors,
  } = useFormContext<CreateAdTypes>();
  const price = useWatch({ name: 'price' });

  useEffect(() => {
    if (price && Number(price) === 0) {
      setValue('price_type', 'for_free');
    } else if (price && Number(price) > 0) {
      setValue('price_type', 'for_money');
    }
  }, [price, setValue]);

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!regex.test(event.key) && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }, []);

  const handleSwitchChange = useCallback((newPriceType: CreateAdTypes['price_type']) => {
    setValue('price_type', newPriceType);
    setValue('price', '');
    clearErrors('price');
    clearErrors('price_type');
  }, [clearErrors, setValue]);

  return (
    <div className={styles.field}>
      <p className={styles.secondary_title}>
        {t('descriptionAndPhotos.price')}
        <span> *</span>
      </p>
      <div className={priceStyle.price_wrapper}>
        <div className={priceStyle.input_wrapper}>
          <Controller
            name="price"
            control={control}
            render={({ field }) => (
              <InputText
                {...field}
                type="number"
                className={priceStyle.input}
                onChange={(e) => {
                  field.onChange(e);
                  setValue('price_type', 'for_money');
                }}
                onKeyDown={handleKeyDown}
              />
            )}
          />
          <SvgIcon
            className={priceStyle.icon}
            name="THB-14-medium"
          />
          {(formState.errors.price && <p className={priceStyle.error}>{(formState.errors.price?.message)}</p>)
           || (formState.errors.price_type && <p className={priceStyle.error}>{formState.errors.price_type?.message}</p>)}
        </div>
        <div className={priceStyle.switcher_wrapper}>
          <Controller
            name="price_type"
            control={control}
            render={({ field }) => (
              <Switcher
                {...field}
                checked={field.value === 'for_free'}
                onChange={() => handleSwitchChange('for_free')}
              />
            )}
          />
          <p>{t('descriptionAndPhotos.free')}</p>
        </div>
        <div className={priceStyle.switcher_wrapper}>
          <Controller
            name="price_type"
            control={control}
            render={({ field }) => (
              <Switcher
                {...field}
                checked={field.value === 'by_agreement'}
                onChange={() => handleSwitchChange('by_agreement')}
              />
            )}
          />
          <p>{t('descriptionAndPhotos.byAgreement')}</p>
        </div>
      </div>
    </div>
  );
};
