import { useCallback, useRef } from 'react';

export const useInfiniteListRef = (hasMore, fetchMore, isLoading) => {
  const observerRef = useRef();
  return useCallback(
    (node) => {
      if (isLoading && !hasMore) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          fetchMore();
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [isLoading, fetchMore, hasMore],
  );
};
