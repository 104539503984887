import React, {
  KeyboardEvent,
  ClipboardEvent,
  useRef,
  useLayoutEffect,
  useState,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import type { OverlayScrollbarsComponentRef } from 'overlayscrollbars-react';
import { useAdjustTextareaHeight } from '../../hooks';
import { useMatchMedia } from '../../hooks/use-match-media.hook';
import styles from './textarea.module.scss';

export interface TextareaProps {
  name?: string;
  value?: string;
  placeholder?: string;
  onChange?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
  onPaste?: (e: ClipboardEvent<HTMLTextAreaElement>) => void;
  className?: string;
  maxChars?: number;
  countChars?: boolean;
  hasErrors?: boolean;
  heightScroll?: number;
  size?: 'width_12';
}

export const Textarea = ({
  name,
  value,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  onPaste,
  className,
  maxChars = 10000,
  countChars = false,
  hasErrors = false,
  heightScroll = 220,
  size = 'width_12',
  ...props
}: TextareaProps) => {
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const { t } = useTranslation('');
  const osRef = useRef<OverlayScrollbarsComponentRef>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [charCount, setCharCount] = useState(0);

  const handleWrapperClick = () => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const minHeight = heightScroll - 40;
  const adjustTextareaHeight = useAdjustTextareaHeight({
    textareaRef,
    minHeight,
  });

  useLayoutEffect(() => {
    // Инициализация высоты при монтировании
    adjustTextareaHeight();

    if (countChars) {
      setCharCount(value?.length || 0);
    }

    if (isDesktop && osRef.current) {
      const osInstance = osRef.current.osInstance();
      if (osInstance) {
        const { scrollOffsetElement } = osInstance.elements();
        const cursorPosition = textareaRef.current?.selectionStart ?? 0;

        // Прокрутка вниз только если курсор находится в нижней части текста
        if (cursorPosition === value?.length) {
          scrollOffsetElement.scrollTo({
            behavior: 'smooth',
            top: scrollOffsetElement.scrollHeight - scrollOffsetElement.clientHeight,
          });

          if (countChars) {
            textareaRef.current?.classList.add(styles.textarea_padding);
          }
        }
      }
    }
  }, [value, maxChars, countChars, isDesktop]);

  const renderTextarea = () => (
    <textarea
      ref={textareaRef}
      name={name}
      value={value}
      onFocus={() => onFocus?.()}
      onChange={onChange}
      onBlur={() => onBlur?.()}
      onKeyDown={(e) => onKeyDown?.(e)}
      onPaste={(e) => onPaste?.(e)}
      onInput={adjustTextareaHeight}
      placeholder={placeholder || t('common:enter')}
      maxLength={maxChars}
      spellCheck
      style={{
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        resize: 'none',
        overflow: isDesktop ? 'hidden' : 'auto',
      }}
      className={clsx(
        {
          [styles.textarea_desktop]: isDesktop,
          [styles.textarea_adaptive]: isMobile || isTablet,
        },
        className,
      )}
      wrap="soft"
      {...props}
    />
  );

  return (
    <div
      onClick={handleWrapperClick}
      style={{ height: `${heightScroll}px` }}
      className={clsx(styles.container, { [styles.error]: hasErrors })}
    >
      {isDesktop && (
        <OverlayScrollbarsComponent
          ref={osRef}
          className={clsx(styles.scroll_textarea, {
            [styles.scroll_textarea_width_12]: size === 'width_12',
          })}
        >
          {renderTextarea()}
        </OverlayScrollbarsComponent>
      )}

      {(isMobile || isTablet) && renderTextarea()}

      {countChars && (
        <div className={styles.charCounter}>
          {`${charCount.toLocaleString()}/${maxChars.toLocaleString('ru-RU')}`}
          {/* Без ru-RU не проходит гидрация. Поймал на странице создания мероприятий */}
        </div>
      )}
    </div>
  );
};
