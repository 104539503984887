import { ModalMobile } from '@/modules/shared/components';
import s from './modal.module.scss';

type MobileConfirmedModalProps = {
  header: string;
  text: string;
  isOpen: boolean;
  onClose: () => void;
};

export const MobileConfirmedModal = ({
  header, text, isOpen, onClose,
}: MobileConfirmedModalProps) => (
  <ModalMobile
    open={isOpen}
    fullHeight
    headerBordered
    showCloseButton
    onClose={onClose}
    showBackButton={false}
    contentStyles={s.content}
  >
    <h2 className={s.header}>{header}</h2>
    <p className={s.text}>{text}</p>
  </ModalMobile>
);
