import React, { ReactNode } from 'react';
import { StarNotification } from '@/modules/shared/components';
import { useProgressbar } from '../hooks';
import { ProgressBar } from './progress-bar';
import { Buttons } from './buttons.component';
import styles from './form-wrapper.module.scss';

export const FormWrapper = ({ children }: { children: ReactNode }) => {
  const progressbar = useProgressbar();

  return (
    <section className={styles.form_wrapper}>
      <ProgressBar progressbar={progressbar} />
      <StarNotification />
      <section className={styles.form}>{children}</section>
      <Buttons progressbar={progressbar} />
    </section>
  );
};
