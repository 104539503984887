import React, { useMemo } from 'react';
import { useMatchMedia } from '@/modules/shared/hooks';
import { Sorting } from '@/modules/shared/components';
import useTranslation from 'next-translate/useTranslation';
import { Controller, useFormContext } from 'react-hook-form';
import { SortingDesktop } from '../categories/sorting';
import styles from './sorting-ads.module.scss';

interface SortingAdsProps {
  label: string;
}

export const SortingAds = ({ label }: SortingAdsProps) => {
  const { t } = useTranslation();
  const { isTablet } = useMatchMedia();
  const { control, setValue } = useFormContext<{ sort: string }>();

  const options = useMemo(() => [
    { value: '-created_at', label: t('adv:filters.sortingModal.newest') },
    { value: 'created_at', label: t('adv:filters.sortingModal.oldest') },
    { value: '-price', label: t('adv:filters.sortingModal.decreasing') },
    { value: 'price', label: t('adv:filters.sortingModal.increasing') },
  ], [t]);

  return (
    <div className={styles.sorting_ads}>
      <span>{label}</span>
      {isTablet ? (
        <Controller
          control={control}
          name="sort"
          render={({ field }) => (
            <Sorting
              value={field.value}
              setValue={(value) => {
                field.onChange(value);
                setValue('sort', value);
              }}
              options={options}
            />
          )}
        />
      ) : (
        <SortingDesktop
          name="sort"
          control={control}
          setValue={setValue}
          options={options}
        />
      )}
    </div>
  );
};
