import React from 'react';
import { useMatchMedia } from '@/modules/shared/hooks';
import useTranslation from 'next-translate/useTranslation';
import { Button, SvgIcon } from '@/modules/shared/components';
import styles from './not-any-ads.module.scss';

export const NotAnyAds = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchMedia();

  return (
    <div className={styles.not_ads}>
      <div className={styles.titles}>
        <h2>
          {t(isDesktop ? 'userProfile:myAds.notAds' : 'userProfile:myAds.noAdsTitle')}
        </h2>
        {!isDesktop && <p>{t('userProfile:myAds.noAdsSubtitle')}</p>}
      </div>

      <div className={styles.button_container}>
        <Button
          appearance="primary"
          buttonColor={isDesktop ? 'blue' : 'green'}
          buttonSize="s"
          as="link"
          fullWidth={!isDesktop}
          href="/product/create"
        >
          {!isDesktop && <SvgIcon name="plus" />}
          {t('common:header.placeAd')}
        </Button>
      </div>
    </div>
  );
};
