import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useTranslateLocation } from '@/modules/locations';
import { useFormatDataPrice } from '@/modules/shared/hooks';
import { AppTooltip, SvgIcon } from '@/modules/shared/components';
import { FavoriteButton } from '@/modules/favorites';
import { AdProfileBlock } from '@/modules/user-profile/components/ad-profile-block';
import { IAd } from '../../../types';
import { AdImage } from '../../ad-image';
import { AdLink } from '../../ad-link';
import styles from './desktop-ad-card.module.scss';

interface DesktopAdCardProps {
  ad: IAd;
  // moreImages?: boolean;
}

export const DesktopAdCard = ({ ad }: DesktopAdCardProps) => {
  const priceDisplayFormat = useFormatDataPrice();

  const translatedLocation = useTranslateLocation(
    ad.city?.codename,
    ad.district?.codename,
  );
  const mainImage = ad.main_picture;
  const { images } = ad;

  const mainImageSrc = useMemo(
    () => images.find((img) => img.id === mainImage),
    [images, mainImage],
  );
  const additionalImages = useMemo(() => {
    const limit = images.length < 2 ? images.length : 2;
    return images.filter((img) => img.id !== mainImage).slice(0, limit);
  }, [images, mainImage]);

  const hasMoreImages = images.length >= 3;

  return (
    <div className={styles.desktop_card}>
      <AdLink ad={ad}>
        <div className={styles.desktop_card_images}>
          {mainImageSrc && (
            <AdImage ad={ad} src={mainImageSrc.image} alt={ad.title} />
          )}
          {hasMoreImages && (
            <div className={styles.additional_images}>
              {additionalImages.map((img) => (
                <img key={img.id} src={img.image} alt={ad.title} />
              ))}
            </div>
          )}
        </div>
      </AdLink>
      <div className={styles.desktop_card_content}>
        <div className={clsx(styles.wrapper, { [styles.more_images]: hasMoreImages })}>
          <div className={styles.information}>
            <div className={styles.headline}>
              <div className={styles.title}>
                <AdLink ad={ad} className={styles.title}>
                  <AppTooltip title={ad.title}>
                    <p>{ad.title}</p>
                  </AppTooltip>
                </AdLink>
                <div className={styles.favorite_button_wrapper}>
                  <FavoriteButton id={ad.id} type="ads" />
                </div>
              </div>
              <div className={styles.price}>
                <p>
                  {priceDisplayFormat({ priceType: ad?.price_type, price: ad?.price })}
                </p>
                {ad?.price_type === 'for_money' && (<SvgIcon name="THB" />)}
              </div>
            </div>
            <div className={clsx(styles.description, { [styles.more]: hasMoreImages })}>
              <p>{ad.description}</p>
            </div>
          </div>
          <div className={styles.location}>
            <div className={styles.city}>
              <SvgIcon name="geo-point" />
              <span>{translatedLocation}</span>
            </div>
            <p>{ad.created_at}</p>
          </div>
        </div>
        <AdProfileBlock data={ad.user_data} />
      </div>
    </div>
  );
};
