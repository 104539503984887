export const scrollElementIntoViewCenter = (element: HTMLElement | null) => {
  if (!element || !element.parentElement) return;
  const parentCenter = (element.parentElement?.clientWidth || 0) / 2;
  const elementPosition = element.offsetLeft + (element.clientWidth / 2);

  element.parentElement.scroll({
    left: elementPosition - parentCenter,
    behavior: 'smooth',
  });
};
