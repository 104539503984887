import i18n from '@/lib/i18n';
import { useRouter } from 'next/router';
import React from 'react';


export default function Loading() {
  const { locale } = useRouter();

  return <div>{i18n.loading[locale]}</div>;
}
