import React, { useMemo, useRef } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useGlobalData } from '@/context/GlobalContext';
import { useHomeCity } from '@/modules/locations/hooks';
import { useCustomWindowWidth, useInfiniteListRef } from '@/modules/shared/hooks';
import { InfiniteListOfAdsLayout } from '@/modules/shared/components';
import { IAd } from '@/modules/ads/types';
import { useRecommendedAdsInfinityList, useAdsInfiniteListQuery } from '@/modules/ads/queries';
import { AdCard } from '../ad-card';
import styles from './ads-block.module.scss';

export const AdsBlock = () => {
  const { t } = useTranslation();
  const { homeCity = 'allcities' } = useGlobalData();
  const adsContainerRef = useRef<HTMLDivElement>(null);
  const { windowWidth } = useCustomWindowWidth();

  const lastAdsQuantity = useMemo(() => {
    if (windowWidth && windowWidth <= 740) return 2;
    if (windowWidth && windowWidth <= 1200) return 3;
    return 5;
  }, [windowWidth]);

  const recommendedAdsQuantity = useMemo(() => {
    if (windowWidth && windowWidth <= 1200) return 12;
    return 10;
  }, [windowWidth]);

  const { cityId, error: citiesError } = useHomeCity();
  const filters = useMemo(
    () => ({
      city: cityId,
    }),
    [cityId],
  );
  const { pages: lastAdsPages, isLoading: lastAdsLoading } = useAdsInfiniteListQuery({
    pageSize: lastAdsQuantity,
    filters,
    page: 1,
    disabled: homeCity !== 'allcities' && !filters.city,
    refetchOnMount: false,
  });

  const {
    pages: recommendedPages,
    error,
    fetchMore,
    hasMore,
    isLoading: recommendedLoading,
  } = useRecommendedAdsInfinityList({
    pageSize: recommendedAdsQuantity,
    filters,
    disabled: homeCity !== 'allcities' && !filters.city,
    refetchOnMount: false,
  });

  const infiniteListRef = useInfiniteListRef(hasMore, fetchMore, recommendedLoading);
  if (error || citiesError) return <div>Error loading data</div>;

  return (
    <div className={styles.ads_wrapper}>
      <InfiniteListOfAdsLayout
        title={t('index-page:adsBlock.recent')}
        isLoading={lastAdsLoading}
        isResent
      >
        {lastAdsPages?.map((page) => page?.data?.map((ad: IAd) => (
          <AdCard key={ad.id} ad={ad} extendedCard={false} />
        )))}
      </InfiniteListOfAdsLayout>
      <InfiniteListOfAdsLayout
        title={t('index-page:adsBlock.recommendations')}
        ref={adsContainerRef}
        infiniteListRef={infiniteListRef}
      >
        {recommendedPages?.map((page) => page?.data?.map((ad: IAd) => (
          <AdCard key={ad.id} ad={ad} extendedCard={false} />
        )))}
      </InfiniteListOfAdsLayout>
    </div>
  );
};
