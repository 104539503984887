export const validateInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (['e', '-', '+', '.', ','].includes(event.key)) {
    event.preventDefault();
  }
};

export const validateInputKeyPressFractional = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (['e', '-', '+'].includes(event.key)) {
    event.preventDefault();
  }
};
