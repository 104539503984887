import { useCityPrefix } from '@/context/CityPrefixContext';
import { useCallback } from 'react';

export const useCategoryPath = () => {
  const { cityPrefix } = useCityPrefix();

  const getPathByCode = useCallback(
    (...codenames: string[]) => `/${cityPrefix}/catalog/${codenames.join('/')}`,
    [cityPrefix],
  );

  return [getPathByCode] as const;
};
