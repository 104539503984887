import React, { ReactNode } from 'react';
import clsx from 'clsx';
import styles from '../../part-of-form.module.scss';

interface FieldLayoutProps {
  title: string;
  children: ReactNode;
  marginBottom?: boolean;
}

// универсальный компонент для блока с̲ ̲з̲а̲г̲о̲л̲о̲в̲к̲о̲м в дополнительных полях в форме создания объявлений
export const FieldsLayout = ({ title, children, marginBottom = false }: FieldLayoutProps) => (
  <div className={clsx(styles.description_and_photos, { [styles.marginBottom]: marginBottom })}>
    <div className={styles.block}>
      <h3 className={styles.block_title}>
        {title}
      </h3>
      {children}
    </div>
  </div>
);
