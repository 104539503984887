import { useCallback } from 'react';
import * as yup from 'yup';
import useTranslation from 'next-translate/useTranslation';
import { CreateAdTypes } from '../types';

export const useSchemaHelpers = () => {
  const { t } = useTranslation();

  /**
   * Функция делает поле обязательным. Нужно передать номер категории
   * Значение поля формы приводится к типу string!
   */
  const allTheCategoryRequiredString = useCallback((categoryNumber: number) => (
    yup.string()
      .when(['category'], {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        is: (category: number) => category === categoryNumber,
        then: (schema) => schema.required(t('forms:validationErrors.FIELD_REQUIRED')),
        otherwise: (schema) => schema.notRequired(),
      })
  ), [t]);

  /**
   * Функция делает поле обязательным. Нужно передать номер категории
   * Значение поля формы приводится к типу number!
   */
  const allTheCategoryRequiredNumber = useCallback((categoryNumber: number) => (
    yup.number()
      .transform((value, originalValue) => ((originalValue) === '' ? undefined : value))
      .when(['category'], {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        is: (category: number) => category === categoryNumber,
        then: (schema) => schema.required(t('forms:validationErrors.FIELD_REQUIRED')),
        otherwise: (schema) => schema.notRequired(),
      })
  ), [t]);

  /**
   * Функция делает поле обязательным. Нужно передать номер подкатегории или массив номеров подкатегорий
   * Важно. Значение поля формы приводится к типу string!
   */
  const allTheSubcategoryRequiredString = useCallback((subcategoryNumber: number | number[]) => (
    yup.string().when(['sub_category'], {
      is: (sub_category: number) => {
        if (Array.isArray(subcategoryNumber)) {
          return subcategoryNumber.some((num) => sub_category === num);
        }
        return sub_category === subcategoryNumber;
      },
      then: (schema) => schema.required(t('forms:validationErrors.FIELD_REQUIRED')),
      otherwise: (schema) => schema.notRequired(),
    })), [t]);

  /**
   * Функция делает поле обязательным. Нужно передать номер подкатегории или массив номеров подкатегорий
   * Важно. Значение поля формы приводится к типу number!
   */
  const allTheSubcategoryRequiredNumber = useCallback((subcategoryNumber: number | number[]) => (
    yup.number()
      .transform((value, originalValue) => ((originalValue) === '' ? undefined : value))
      .when(['sub_category'], {
        is: (sub_category: number) => {
          if (Array.isArray(subcategoryNumber)) {
            return subcategoryNumber.some((num) => sub_category === num);
          }
          return sub_category === subcategoryNumber;
        },
        then: (schema) => schema.required(t('forms:validationErrors.FIELD_REQUIRED')),
        otherwise: (schema) => schema.notRequired(),
      })), [t]);

  const requiredFieldWithOperationType = useCallback(
    (categoryType: 'category' | 'sub_category', id: number, operationType: NonNullable<CreateAdTypes['operation_type']>) => (
      yup.string()
        .when([categoryType, 'operation_type'], {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          is: (categoryType: number, operation_type: string) => categoryType === id && operation_type === operationType,
          then: (schema) => (
            schema.required(t('forms:validationErrors.FIELD_REQUIRED'))
          ),
          otherwise: (schema) => schema.notRequired(),
        })
    ),
    [t],
  );

  return {
    allTheCategoryRequiredString,
    allTheCategoryRequiredNumber,
    allTheSubcategoryRequiredString,
    allTheSubcategoryRequiredNumber,
    requiredFieldWithOperationType,
  };
};
