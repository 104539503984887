import { memo, useMemo } from 'react';

import { FormState } from '@/modules/shared/hooks';
import { ButtonSwitcher } from '@/modules/shared/components';

import { FiltersMap } from '../../types';
import { FilterRow } from '../../components/filter-row';
import { withDynamicFieldFormGuard } from '../../hoc/withDynamicFieldFormGuard';


type OperationTypeFieldProps = {
  form: FormState<'operation_type'>;
  filter: FiltersMap['checkbox_list']
};

export const OperationTypeField = withDynamicFieldFormGuard<OperationTypeFieldProps>(memo(({
  filter: { choices },
  form,
  t,
}) => {
  const buttons = useMemo(() => ['all', ...choices].map((code) => ({
    name: t(`filters:checkbox-list.operation_type.options.${code}`, null, {
      fallback: code,
    }),
    value: code,
  })), [choices, t]);

  const handleChange = (code: (typeof buttons)[number]['value']) => {
    if (code === 'all') {
      form.setter.operation_type?.([]);
    } else {
      form.setter.operation_type?.([code]);
    }
  };

  return (
    <FilterRow>
      <ButtonSwitcher
        fullWidth
        buttons={buttons}
        value={form.value.operation_type?.[0] || buttons[0].value}
        onChange={handleChange}
      />
    </FilterRow>
  );
}, (prev, next) => (
  prev.form.value.operation_type === next.form.value.operation_type
    && prev.filter === next.filter
    && prev.t === next.t
)));
