import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useMatchMedia } from '../../hooks/use-match-media.hook';

import { Checkbox, RadioButton } from '../inputs';
import { Button } from '../button';

import { minShowItem } from './options.const';
import styles from './selection-options.module.scss';

interface Option<T> {
  value: T | null;
  label: string;
}

interface SelectionOptionsProps {
  title: string;
  checked: string | string[];
  onClick: (value: string | null) => void;
  options: Option<string | null>[];
  type: 'radio' | 'checkbox';
}

export const SelectionOptions = ({
  title,
  checked,
  onClick,
  options,
  type,
}: SelectionOptionsProps) => {
  const [showAll, setShowAll] = useState(false);
  const { t } = useTranslation('');
  const { isMobile, isTablet, isDesktop } = useMatchMedia();

  const dataToShow = useMemo(() => (showAll ? options : options.slice(0, 5)), [showAll, options]);

  const isChecked = useMemo(
    () => (value: string | null) => {
      if (value && type === 'checkbox' && Array.isArray(checked)) {
        return checked.includes(value);
      }
      return checked === value;
    },
    [checked, type],
  );


  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      <div className={styles.selection}>
        <ul
          className={clsx(styles.list, {
            [styles.list_show]: showAll && !isMobile,
          })}
        >
          {isMobile
            && dataToShow.map((el, index) => (
              <li key={index} className={styles.listItem}>
                <label className={styles.label}>
                  {type === 'checkbox' ? (
                    <Checkbox
                      checkboxSize="24"
                      checked={isChecked(el.value)}
                      onChange={() => onClick(el.value)}
                    />
                  ) : (
                    <RadioButton
                      radioColor="green"
                      checked={isChecked(el.value)}
                      onChange={() => onClick(el.value)}
                    />
                  )}
                  {el.label}
                </label>
              </li>
            ))}
          {(isTablet || isDesktop) && (
            <OverlayScrollbarsComponent
              className={clsx(styles.scrollbar, { [styles.scrollable]: dataToShow.length > minShowItem })}
            >
              {dataToShow.map((el, index) => (
                <li key={index} className={styles.listItem}>
                  <label className={styles.label}>
                    {type === 'checkbox' ? (
                      <Checkbox
                        checkboxSize="24"
                        checked={isChecked(el.value)}
                        onChange={() => onClick(el.value)}
                      />
                    ) : (
                      <RadioButton
                        radioColor="green"
                        checked={isChecked(el.value)}
                        onChange={() => onClick(el.value)}
                      />
                    )}
                    {el.label}
                  </label>
                </li>
              ))}
            </OverlayScrollbarsComponent>
          )}
        </ul>

        {options.length > 5 && (
          <Button
            appearance="secondary"
            buttonColor="green"
            buttonSize="xs"
            onClick={() => setShowAll(!showAll)}
            className={styles.button}
          >
            {showAll
              ? (isMobile && t('common:hide'))
                || ((isTablet || isDesktop) && t('common:showLess'))
              : t('common:showMore')}
          </Button>
        )}
      </div>
    </div>
  );
};
