import { create } from '@/modules/shared/api';
import { EntityImage, ImageFormat } from '@/modules/shared/types';
import { endpoints } from './endpoints';

interface CreateImagesInput {
  formats: ImageFormat[];
}

export const ChatApi = {
  createMessageImages: async (images: CreateImagesInput) => create<CreateImagesInput, EntityImage[]>(endpoints.createChatImage, images),
};
