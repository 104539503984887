import { Translate } from 'next-translate';
import type { IEvent } from '@/modules/events/types';

export const getEventSeoProps = (t: Translate, onePoster: IEvent, translatedLocation: string) => {
  const defaultText = `${onePoster?.title || ''} ${translatedLocation} ${onePoster?.event_date || ''}`;
  const defaultTitle = t('seo:posterPage.title');
  const defaultDescription = t('seo:posterPage.description');
  const title = defaultText ? `${defaultText} | ${defaultTitle}` : defaultTitle;
  const description = defaultText ? `${defaultText} - ${defaultDescription}` : defaultDescription;
  return { title, description };
};
