import React, { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { SubHeaderItems } from '../../sub-header-items';
import { SubCategoryTypes } from '../../types';
import styles from '../view-list-categories.module.scss';

interface Props {
  sub_categories: SubCategoryTypes[];
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  prevCodename: string;
  operationType: string | undefined;
}

export const DesktopViewList = ({
  sub_categories, onClose, prevCodename, operationType,
}: Props) => {
  const { t } = useTranslation('');
  const [isImageExists, setImageExists] = useState(false);

  const firstColumn = Math.ceil(sub_categories.length / 3);
  const secondColumn = 2 * Math.ceil(sub_categories.length / 3);
  const imagePath = `/assets/images/rabbit-${prevCodename}.svg`;
  const title = t(`categories:categories.${prevCodename}._`);

  // Checks image existence
  useEffect(() => {
    const img = new Image();
    img.onerror = () => {
      setImageExists(false);
    };
    img.onload = () => {
      setImageExists(true);
    };
    img.src = imagePath;
  }, [prevCodename, imagePath]);

  return (
    <>
      <div className={styles.column}>
        {sub_categories.slice(0, firstColumn).map((value, index) => (
          <SubHeaderItems
            key={index}
            data={value}
            onClick={onClose}
            prevCodename={prevCodename}
            operationType={operationType}
          />
        ))}
      </div>
      <div className={styles.column}>
        {sub_categories
          .slice(firstColumn, secondColumn)
          .map((value, index) => (
            <SubHeaderItems
              key={index}
              data={value}
              onClick={onClose}
              prevCodename={prevCodename}
              operationType={operationType}
            />
          ))}
      </div>
      <div className={styles.thirdColumn}>
        <div className={styles.textContainer}>
          {sub_categories
            .slice(secondColumn, sub_categories.length)
            .map((value, index) => (
              <SubHeaderItems
                data={value}
                key={index}
                onClick={onClose}
                prevCodename={prevCodename}
                operationType={operationType}
              />
            ))}
        </div>
        {isImageExists && (
          <div className={styles.imageContainer}>
            <img src={imagePath} alt={title} className={styles.image} />
          </div>
        )}
      </div>
    </>
  );
};
