'use client';

import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import i18n from '@/lib/i18n';
import { useRouter } from 'next/router';

let heic2any;

const loadHeic2any = async () => {
  const lib = await import('heic2any');
  heic2any = lib.default || lib;
};

export const useConvertHeicToPng = () => {
  const { locale } = useRouter();

  useEffect(() => {
    loadHeic2any();
  }, []);

  const convertHeicToPng = useCallback(async (file: File): Promise<File | null> => {
    const arr = new Uint8Array(await file.arrayBuffer()).subarray(0, 4);
    let header = '';
    for (let i = 0; i < arr.length; i++) {
      header += arr[i].toString(16);
    }

    let type = '';
    switch (header) {
      case '89504e47':
        type = 'image/png';
        break;
      case '47494638':
        type = 'image/gif';
        break;
      case 'ffd8ffe0':
      case 'ffd8ffe1':
      case 'ffd8ffe2':
      case 'ffd8ffe3':
      case 'ffd8ffe8':
        type = 'image/jpeg';
        break;
      default:
        type = '';
    }

    const nameArr = file.name.split('.');
    const newNamePng = `${nameArr.slice(0, -1).join('')}${Date.now()}.png`;
    const newNameJpeg = `${nameArr.slice(0, -1).join('')}${Date.now()}.jpeg`;

    try {
      const arrayBuffer = await file.arrayBuffer();
      const uniqueBlob = new Blob([arrayBuffer], { type: file.type });
      let pngBlob = await heic2any({
        blob: uniqueBlob,
        toType: 'image/png',
      });

      if (Array.isArray(pngBlob)) {
        // eslint-disable-next-line prefer-destructuring
        pngBlob = pngBlob[0];
      }

      const pngFile = new File(
        [pngBlob],
        newNamePng,
        { type: 'image/png' },
      );

      URL.revokeObjectURL(pngBlob);

      return pngFile;
    } catch (error) {
      if (error.message === `ERR_USER Image is already browser readable: ${type}`) {
        return new File(
          [await file.arrayBuffer()],
          newNameJpeg,
          { type },
        );
      }
      console.log('Произошла ошибка при конвертации:', error);
      toast.error(i18n.Form.errors.wrongImgFormat[locale]);
      return null;
    }
  }, []);

  return convertHeicToPng;
};
