import React, { ButtonHTMLAttributes, AnchorHTMLAttributes, forwardRef } from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import styles from './button.module.scss';

// Интерфейсы для атрибутов кнопки и ссылки
interface ButtonAttributes extends ButtonHTMLAttributes<HTMLButtonElement> {}
interface LinkAttributes extends AnchorHTMLAttributes<HTMLAnchorElement> {}

type AnchorOnClick = (event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void; // нужно, чтобы сделать preventDefault() при нажатии на ссылку
type ButtonOnClick = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | (() => void);
type OnClickType = AnchorOnClick | ButtonOnClick;

// Общий интерфейс для пропсов компонента
interface CommonButtonProps {
  appearance: 'primary' | 'secondary';
  buttonColor: 'blue' | 'green' | 'white' | 'black' | 'grey';
  buttonSize: 'xs' | 's' | 'm';
  as?: 'button' | 'link';
  className?: string;
  children: React.ReactNode;
  href?: string; // href для ссылки
  onClick?: OnClickType;
  fullWidth?: boolean;
}

// Условный тип для атрибутов компонента
type ButtonProps = CommonButtonProps & (ButtonAttributes | LinkAttributes);

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(({
  appearance,
  buttonSize,
  buttonColor,
  className,
  onClick,
  fullWidth,
  children,
  as = 'button',
  href,
  ...props
}, ref) => {
  const style = clsx(
    styles[appearance],
    styles[buttonColor],
    styles[`size_${buttonSize}`],
    {
      [styles.fullWidth]: fullWidth,
    },
    className,
  );

  if (as === 'link' && href) {
    return (
      <Link
        href={href}
        className={style}
        ref={ref as React.Ref<HTMLAnchorElement>}
        onClick={(event) => (onClick as AnchorOnClick)?.(event)}
        {...(props as LinkAttributes)}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      type="button"
      className={style}
      onClick={() => (onClick as ButtonOnClick)?.()}
      ref={ref as React.Ref<HTMLButtonElement>}
      {...(props as ButtonAttributes)}
    >
      {children}
    </button>
  );
});
