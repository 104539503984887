import { forwardRef } from 'react';
import { validateInputKeyPress } from '../../../helpers';
import s from './input-price.module.scss';
import { SvgIcon } from '../../svg-icon';

type InputPriceProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'className'>;

export const InputPrice = forwardRef<HTMLInputElement, InputPriceProps>(({ ...props }, ref) => (
  <div className={s.wrapper}>
    <input
      type="number"
      ref={ref}
      inputMode="numeric"
      className={s.price_input}
      onWheel={(e) => e.currentTarget.blur()}
      onKeyDown={validateInputKeyPress}
      {...props}
    />
    <SvgIcon name="THB" className={s.icon} />
  </div>
));
