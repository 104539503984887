import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { UniversalSelect } from '@/modules/shared/components/select/select.component';
import { useCreateAdContext } from '../../context';
import { useTransport } from './hooks';
import {
  FieldsLayout,
  FieldLayout,
  ConditionField,
  HiddenRadiobutton,
  CreateAdInput,
  SelectColor,
  Label,
} from './shared';
import {
  AUTO_ENGINE_MAX, AUTO_ENGINE_MIN, MOTO_ENGINE_MAX, MOTO_ENGINE_MIN,
} from '../../const';

// для подкатегорий cars motorcycles motorbikes
const GroundTransport = () => {
  const { setValue, control } = useFormContext();
  const { subCategory, adType } = useCreateAdContext();
  const { t } = useTranslation();
  const transport = useTransport();
  const condition = useWatch({ name: 'condition' });

  let postfix: 'm' | 'cc' | 'liters' | 'km';
  switch (subCategory!.codename) {
    case 'cars':
      postfix = 'liters';
      break;
    case 'motorcycles':
      postfix = 'cc';
      break;
    case 'motorbikes':
      postfix = 'cc';
      break;
    default:
      postfix = 'm';
  }

  useEffect(() => {
    if (condition === 'new') {
      setValue('mileage', 0);
    }
  }, [condition]);

  return (
    <>
      <FieldsLayout title={t('ad-parameters:characteristics')} marginBottom>
        <ConditionField
          name="condition"
          options={transport.condition}
          required={adType !== 'rent'}
        />
        {adType === 'rent' && (
          <FieldLayout
            name="duration"
            title={t('ad-parameters:transport.rental_type')}
          >
            {transport.duration.map(({ value, label }) => (
              <HiddenRadiobutton
                key={value}
                name="duration"
                value={value}
                label={label}
              />
            ))}
          </FieldLayout>
        )}
        <FieldLayout
          name="brand"
          title={t('ad-parameters:transport.brand')}
        >
          <UniversalSelect
            setValue={setValue}
            control={control}
            name="brand"
            options={transport[subCategory!.codename].brands}
            placeholder=""
            isSearchable
          />
        </FieldLayout>
        <FieldLayout
          name="year"
          title={t('ad-parameters:transport.year')}
          required={adType !== 'rent'}
        >
          <CreateAdInput
            name="year"
            max={new Date().getFullYear()}
            required={adType !== 'rent'}
          />
        </FieldLayout>
        <FieldLayout
          name="mileage"
          title={t('ad-parameters:transport.mileage')}
          required={adType !== 'rent'}
        >
          <CreateAdInput
            name="mileage"
            postfix="km"
            disabled={condition === 'new'}
            max={500_000}
            required={adType !== 'rent'}
          />
        </FieldLayout>
        <FieldLayout
          name="engine_type"
          title={t('ad-parameters:transport.engine_type')}
        >
          {transport[subCategory!.codename].engineType.map(({ value, label }) => (
            <HiddenRadiobutton
              key={value}
              name="engine_type"
              value={value}
              label={label}
            />
          ))}
        </FieldLayout>
        <FieldLayout
          name="transmission"
          title={t('ad-parameters:transport.transmission')}
        >
          {transport[subCategory!.codename].transmission.map(({ value, label }) => (
            <HiddenRadiobutton
              key={value}
              name="transmission"
              value={value}
              label={label}
            />
          ))}
        </FieldLayout>
        <FieldLayout
          name="engine_capacity"
          title={t('ad-parameters:transport.engine_power')}
        >
          <CreateAdInput
            name="engine_capacity"
            postfix={postfix}
            min={subCategory!.codename === 'cars' ? AUTO_ENGINE_MIN : MOTO_ENGINE_MIN}
            max={subCategory!.codename === 'cars' ? AUTO_ENGINE_MAX : MOTO_ENGINE_MAX}
          />
        </FieldLayout>
        <FieldLayout
          name="color"
          title={t('ad-parameters:color')}
          required={false}
        >
          <SelectColor />
        </FieldLayout>
      </FieldsLayout>
      {adType === 'rent' && (
        <FieldsLayout title={t('ad-parameters:additionally')}>
          <FieldLayout
            name="additional_options"
            title={t('ad-parameters:included')}
            required={false}
            isVertically
          >
            {transport[subCategory!.codename].additionalOptions.map(({ value, label }) => (
              <Label
                key={value}
                name={value}
                label={label}
              />
            ))}
          </FieldLayout>
        </FieldsLayout>
      )}
    </>
  );
};

const AirWaterTransport = () => {
  const { adType } = useCreateAdContext();
  const { t } = useTranslation();
  const transport = useTransport();

  return (
    <FieldsLayout title={t('ad-parameters:characteristics')}>
      <ConditionField
        options={transport.condition}
        required={adType !== 'rent'}
        name="condition"
      />
      {adType === 'rent' && (
        <FieldLayout
          name="duration"
          title={t('ad-parameters:transport.rental_type')}
        >
          {transport.duration.map(({ value, label }) => (
            <HiddenRadiobutton
              key={value}
              name="duration"
              value={value}
              label={label}
            />
          ))}
        </FieldLayout>
      )}
      <FieldLayout
        name="color"
        title={t('ad-parameters:color')}
        required={false}
      >
        <SelectColor />
      </FieldLayout>
    </FieldsLayout>
  );
};

const Trucks = () => {
  const { adType } = useCreateAdContext();
  const { t } = useTranslation();
  const transport = useTransport();

  return (
    <FieldsLayout title={t('ad-parameters:characteristics')}>
      <ConditionField
        options={transport.condition}
        name="condition"
        required={adType !== 'rent'}
      />
      {adType === 'rent' && (
        <FieldLayout
          name="duration"
          title={t('ad-parameters:transport.rental_type')}
        >
          {transport.duration.map(({ value, label }) => (
            <HiddenRadiobutton
              key={value}
              name="duration"
              value={value}
              label={label}
            />
          ))}
        </FieldLayout>
      )}
      <FieldLayout
        name="color"
        title={t('ad-parameters:color')}
        required={false}
      >
        <SelectColor />
      </FieldLayout>
    </FieldsLayout>
  );
};

const additionalFields = {
  cars: <GroundTransport />,
  motorcycles: <GroundTransport />,
  'air-vehicles': <AirWaterTransport />,
  trucks: <Trucks />,
  'water-vehicles': <AirWaterTransport />,
  motorbikes: <GroundTransport />,
};

// компонент с дополнительными полями для всей категории
export const Transport = () => {
  const { subCategory } = useCreateAdContext();

  return additionalFields[subCategory!.codename];
};
