import { useCallback, useSyncExternalStore } from 'react';
import { isEqual } from 'lodash';
import { getInitialCityInfo } from '../helpers/initial-city-info.helpers';


let lastCityInfo = getInitialCityInfo();

const SERVER_SNAPSHOT = { city: 'allcities', userSet: true };

export const useInitialCityInfoHook = () => {
  const getSnapshot = useCallback((onChange?: () => void) => {
    const newCityInfo = getInitialCityInfo();
    if (!isEqual(newCityInfo, lastCityInfo)) {
      lastCityInfo = newCityInfo;
      onChange?.();
    }

    return lastCityInfo;
  }, []);
  const subscribe = useCallback((onStoreChange) => {
    const intervalId = setInterval(() => {
      getSnapshot(onStoreChange);
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [getSnapshot]);
  const getServerSnapshot = useCallback(() => SERVER_SNAPSHOT, []);

  return useSyncExternalStore(
    subscribe,
    getSnapshot,
    getServerSnapshot,
  );
};
