import { UseFormWatch } from 'react-hook-form';
import { Company } from '@/modules/company/types';
import { CompanyCredentialsForm } from '../types';

type Props = {
  watch: UseFormWatch<CompanyCredentialsForm>;
  data:Company | undefined;
  name: string | undefined;
  avatar: File | undefined;
};

export const getDirty = ({
  watch, data, name, avatar,
}: Props) => {
  const [description, custom_page_url, telegram, instagram, whatsapp, banners, bannersToRemove] = watch(['description', 'custom_page_url', 'telegram', 'instagram', 'whatsapp', 'banners', 'banners_to_remove']);

  // FIXME огромный костыль, который нужно будет отрефакторить и сделать страницы для частного лица и компании абсолютно раздельными
  const dirty = (description && description !== data?.description)
    || ((custom_page_url || custom_page_url === '') && data?.custom_page_url && custom_page_url !== data?.custom_page_url)
    || ((custom_page_url || custom_page_url === '') && !data?.custom_page_url && custom_page_url !== `${data?.id}`)
    || (!telegram && !!data?.social_networks.telegram)
    || (telegram && telegram !== data?.social_networks.telegram)
    || (!instagram && !!data?.social_networks.instagram)
    || (instagram && instagram !== data?.social_networks.instagram)
    || (!whatsapp && !!data?.social_networks.whatsapp)
    || (whatsapp && whatsapp !== data?.social_networks.whatsapp)
    || avatar
    || name
    || (banners && banners.length)
    || (bannersToRemove && bannersToRemove.length);

  return dirty;
};
