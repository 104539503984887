import { toast } from 'react-toastify';

export const onNetworkError = (template) => (err) => {
  toast(template ? template(err) : err, {
    type: 'error',
    autoClose: 5000,
    hideProgressBar: true,
    toastId: 'Network error',
  });
};
