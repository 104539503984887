import React, { useCallback, useContext } from 'react';
import s from '@/styles/layout/AdminSidebar.module.scss';
import { useRouter } from 'next/router';
import useUserAccount from '@/hooks/users/useUserAccount';
import AuthContext from '@/context/AuthContext';
import Loading from '@/components/partials/Loading';

const URL = process.env.NEXT_PUBLIC_API_URL;

const menuItems = [
  { path: '/admin/all-adv', icon: 'my_advs.svg', label: 'Объявления' },
  {
    path: '/admin/categories',
    icon: 'icon_category.svg',
    label: 'Категории и подкатегории',
  },
  {
    path: '/admin/users',
    icon: 'icon_user_notactive.svg',
    label: 'Пользователи',
  },
  {
    path: '/admin/statistics',
    icon: 'icon_statistics.svg',
    label: 'Статистика сайта',
  },
  {
    path: '/admin/moderators',
    icon: 'icon_moderator.svg',
    label: 'Модераторы',
  },
  { path: '/admin/chat', icon: 'my_chat.svg', label: 'Чат' },
  { path: '/admin/posters', icon: 'my_posters.svg', label: 'Афиша' },
  {
    path: '/admin/security',
    icon: 'my_security.svg',
    label: 'Безопасность и вход',
  },
  { path: '/admin/complaints', icon: 'icon_complaint.svg', label: 'Жалобы' },
  {
    path: '/admin/deactivated',
    icon: 'icon_deactivate.svg',
    label: 'Скрытые аккаунты',
  },
  {
    path: '/admin/locations',
    icon: 'city.svg',
    label: 'Города и регионы',
  },
  {
    path: '/admin/words',
    icon: 'words.svg',
    label: 'Словарь запрещенных слов',
  },
  {
    path: '/admin/banner',
    icon: 'banner.svg',
    label: 'Баннер на главной странице',
  },
];

export default function AdminSidebar() {
  const router = useRouter();
  const { userData, loading, error } = useUserAccount();
  const { logout } = useContext(AuthContext);

  const navigate = useCallback(
    (path) => () => {
      router.push(path);
    },
    [router],
  );

  if (loading) return <Loading />;
  if (error) return <div>Error fetching user data!</div>;

  const renderMenuItem = ({ path, icon, label }) => {
    const isActive = router.pathname === path;
    const iconFileName = isActive ? icon.replace('.svg', '_white.svg') : icon;
    const iconPath = `/assets/${
      isActive ? 'white_icons' : 'icons'
    }/${iconFileName}`;

    return (
      <li
        key={path}
        className={isActive ? s.active_link : s.link}
        onClick={navigate(path)}
      >
        <img src={iconPath} alt={`${label}_icon`} />
        <p>{label}</p>
      </li>
    );
  };

  return (
    <aside className={s.sidebar}>
      <div className={s.sidebar_header}>
        <div
          className={
            router.pathname === '/admin/account'
              ? s.active_admin_ava
              : s.admin_ava
          }
          onClick={() => router.push('/admin/account')}
        >
          <img
            src={
              userData.avatar
                ? `${userData.avatar}`
                : '/assets/icons/adv_contact_profile.svg'
            }
            alt="profile_icon"
          />
          <span>
            <h4>{userData.full_name}</h4>
            <p>Администратор</p>
          </span>
        </div>
      </div>
      <nav className={s.sidebar_links}>
        <ul>{menuItems.map(renderMenuItem)}</ul>
        <div className={s.logout}>
          <button onClick={logout}>
            Выйти из аккаунта
            <img src="/assets/icons/personal_logout.svg" alt="logout_icon" />
          </button>
        </div>
      </nav>
    </aside>
  );
}
