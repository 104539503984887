import { useCallback } from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { FieldError, useFormContext } from 'react-hook-form';
import { InputText } from '@/modules/shared/components/inputs/input-text';
import { COMPANY_CONTACTS } from '@/modules/shared/const';
import { SvgIcon } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { SocialNetworks } from '@/modules/company/types';
import s from '../credentials.module.scss';
import styles from './company-credentials.module.scss';
import { CompanyCredentialsForm } from '../types';

export const Media = ({ media }: { media: SocialNetworks }) => {
  const { t } = useTranslation();
  const {
    register, formState: { errors }, clearErrors,
  } = useFormContext<CompanyCredentialsForm>();
  const { isDesktop } = useMatchMedia();

  const handleBlur = useCallback((field: string) => {
    clearErrors('social_networks'[field]);
    clearErrors(field as keyof CompanyCredentialsForm);
  }, [clearErrors]);

  return (
    <section className={clsx(s.media, s.section)}>
      <h2>{t(`userAccount:company.${isDesktop ? 'media' : 'social_media'}`)}</h2>
      <div className={s.field}>
        <p className={s.field_header}>{t('userAccount:company.links')}</p>
        <div className={clsx(s.wrapper)}>
          {COMPANY_CONTACTS.map((el) => (
            <div className={styles.input_wrapper} key={el}>
              <InputText
                fullWidth
                defaultValue={media[el.toLowerCase()] || ''}
                placeholder={`${t('userAccount:company.link_to')}${el}`}
                {...register(`${el.toLowerCase()}` as keyof CompanyCredentialsForm, {
                  onBlur: () => handleBlur(el.toLowerCase()),
                })}
              />
              <SvgIcon
                name={`${el.toLowerCase()}-no-bg-24`}
                className={styles[el.toLowerCase()]}
              />
              {(errors[el.toLowerCase()] || errors.social_networks?.[el.toLowerCase()]) && (
                <p className={s.error}>
                  {(errors.social_networks?.[el.toLowerCase()] as FieldError & { code: string }).code
                    ? t(`forms:validationErrors.${(errors.social_networks?.[el.toLowerCase()] as FieldError & { code: string }).code}`)
                    : errors[el.toLowerCase()].message as string}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
