import React, { useCallback, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useMatchMedia } from '../../hooks/use-match-media.hook';
import { ColorTagMobile } from './color-tag-mobile.component';
import { ColorTagDesktop } from './color-tag-desktop.component';
import styles from './color-picker.module.scss';


type ColorPickerProps = {
  colors?: string[]
  value: string[];
  onChange?: (colors: string[]) => void;
};

const COLOR_LIST = {
  dark_gray: '#808080',
  blue: '#414BB2',
  beige: '#DBCA95',
  black: '#000000',
  birch: '#10A689',
  red: '#F14725',
  ping: '#F2439B',
  purple: '#652CB3',
  sky: '#6AD9E6',
  orange: '#FAC712',
  yellow: '#FEF445',
  gray: '#D2C7C7',
  gold: '#ECC819',
  bard: '#9E3D3D',
  light_gray: '#F2F2F2',
  white: '#FFFF',
};

export const ColorPicker = ({ onChange, colors, value: fieldValue }: ColorPickerProps) => {
  const { t } = useTranslation('');
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const selectedColors = fieldValue?.length > 0 ? fieldValue : ['multicolor'];

  const colorOptions = useMemo(() => [
    { value: '', label: t('common:colors.multicolor'), codename: 'multicolor' },
    ...colors?.reduce((memo, colorCode) => {
      if (COLOR_LIST[colorCode]) {
        memo.push({ value: COLOR_LIST[colorCode], codename: colorCode });
      }
      return memo;
    }, [] as { value: string, codename: string }[]) || [],
  ].map(({ value, codename }) => ({
    value,
    label: t(`common:colors.${codename}`),
    codename,
  })), [colors, t]);

  const handleColorClick = useCallback((color: string) => {
    if (color === 'multicolor') {
      onChange?.([]);
    } else {
      let updatedColors: string[];
      if (selectedColors.includes('multicolor')) {
        updatedColors = [color];
      } else {
        updatedColors = selectedColors.includes(color)
          ? selectedColors.filter((c) => c !== color)
          : [...selectedColors.filter((c) => c !== 'multicolor'), color];
      }
      onChange?.(updatedColors.filter(Boolean));
    }
  }, [onChange, selectedColors]);

  return (
    <div className={styles.colorPicker}>
      {(isTablet || isDesktop) && (
        <ColorTagDesktop
          selectedColors={selectedColors}
          colorOptions={colorOptions}
          handleColorClick={handleColorClick}
        />
      )}
      {isMobile && (
        <ColorTagMobile
          selectedColors={selectedColors}
          colorOptions={colorOptions}
          handleColorClick={handleColorClick}
        />
      )}
    </div>
  );
};
