import { useMemo } from 'react';
import { User, UserTypeEnum } from '@/modules/users/types';
import { useUserCompanyById } from '@/modules/company/queries';
import { CompanyContact, UserContact } from '../types';

export const useUserContact = (userData : User | null): { recipient: UserContact | null } => {
  const { data: companyData, isLoading } = useUserCompanyById(userData?.company_id);
  const type = userData?.company_id ? UserTypeEnum.Company : UserTypeEnum.Individual;

  const userContact: UserContact | null = useMemo(() => {
    if (userData) {
      return {
        id: userData.id,
        company_id: userData.company_id,
        full_name: userData.full_name,
        phone_number: userData.phone_number,
        avatar: userData.avatar as string,
        thumbnail: userData.thumbnail as string,
        is_online: userData.is_online,
        user_type: userData.user_type,
        last_online: userData.last_online,
        company: null,
      };
    }

    return null;
  }, [userData]);

  const companyContact: CompanyContact | undefined = useMemo(() => {
    if (!companyData) return undefined;
    return {
      id: companyData.id,
      custom_page_url: companyData.custom_page_url,
      name: companyData.name,
      verified_at: companyData.verified_at,
      logo: companyData.logo ? {
        id: companyData.logo.id,
        image: companyData.logo.image,
        thumbnail: companyData.logo.thumbnail,
        user: companyData.logo.user,
      } : null,
    };
  }, [companyData]);

  const recipient: UserContact | null = useMemo(() => {
    if (type === UserTypeEnum.Company && userContact) {
      return {
        ...userContact,
        company: companyContact,
      };
    }
    return userContact;
  }, [type, userContact, companyContact]);

  return { recipient: !isLoading ? recipient : null };
};
