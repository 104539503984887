import { NextSeo } from 'next-seo';
import React, { useEffect, useMemo, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { category as categoryList } from './canonical-catalog.const';
// import styles from './seo.module.scss';

export const SeoCatalog = () => {
  const { t: translateSeo } = useTranslation('seo-catalog');
  const { t: translateCity } = useTranslation('seo.common');
  const router = useRouter();

  const [baseUrl, setBaseUrl] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setBaseUrl(window.location.origin);
    }
  }, []);

  const path = router.asPath.split('?')[0]; // на всякий случай убираем query параметры

  // когда добавится поддержка страны, добавить в начало country
  // Name и добавить страну в currentCity
  const [, cityName, , category, subCategory, underSubCategory] = path.split('/');

  const currentCity = useMemo(
    () => translateCity(`countries.th.${cityName}`),
    [cityName, translateCity],
  );

  const categoryTitle = `category.${category}.title`;
  const categoryDescription = `category.${category}.description`;
  const categoryCanonical = `${baseUrl}/${router.locale}/${cityName}/catalog/${categoryList[category]?.title}`;

  const subCategoryTitle = `category.${category}.${subCategory}.title`;
  const subCategoryDescription = `category.${category}.${subCategory}.description`;

  const underSubCategoryTitle = `category.${category}.${subCategory}.${underSubCategory}.title`;
  const underSubCategoryDescription = `category.${category}.${subCategory}.${underSubCategory}.description`;

  const seoConfig = useMemo(() => {
    if (category && !subCategory && !underSubCategory) {
      return {
        title: translateSeo(categoryTitle, { city: currentCity }),
        description: translateSeo(categoryDescription, { city: currentCity }),
        canonical: categoryCanonical,
      };
    }
    if (subCategory && !underSubCategory) {
      const categoryText = categoryList[category]?.title;
      const subCategoryText = categoryList[category]?.[subCategory]?.title;
      return {
        title: translateSeo(subCategoryTitle, { city: currentCity }),
        description: translateSeo(subCategoryDescription, { city: currentCity }),
        canonical: `${baseUrl}/${router.locale}/${cityName}/catalog/${categoryText}/${subCategoryText}`,
      };
    }
    if (category && subCategory && underSubCategory) {
      const categoryText = categoryList[category].title;
      const subCategoryText = categoryList[category][subCategory].title;
      const underSubCategoryText = categoryList[category][subCategory][underSubCategory]?.title;
      return {
        title: translateSeo(underSubCategoryTitle, { city: currentCity }),
        description: translateSeo(underSubCategoryDescription, { city: currentCity }),
        canonical: `${baseUrl}/${router.locale}/${cityName}/catalog/${categoryText}/${subCategoryText}/${underSubCategoryText}`,
      };
    }
  }, [
    translateSeo,
    cityName,
    category,
    subCategory,
    underSubCategory,
    currentCity,
    categoryTitle,
    categoryDescription,
    subCategoryTitle,
    subCategoryDescription,
    underSubCategoryTitle,
    underSubCategoryDescription,
    categoryCanonical,
    router.locale,
  ]);
  return (
    <>
      <NextSeo {...seoConfig} />
      {/* <h1 className={styles.h1}>{seoConfig?.title}</h1> */}
    </>
  );
};
