import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { Category } from '@/modules/categories';
import { Scrollbar } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { CategoryLabel } from '../category-label';
import { ViewListCategories } from '../view-list-categories';
import styles from './category-list.module.scss';

interface Props {
  categories: Category[];
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

// FIXME: возможно типы нужно будет исправить, когда будет готов бэк
export const CategoryList = ({ categories, onClose }: Props) => {
  const [activeCategory, setActiveCategory] = useState<Category | null>(categories[0] || null);
  const [isHovering, setIsHovering] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { isDesktop } = useMatchMedia();

  const handleCategoryEnter = (category: Category) => {
    setIsHovering(true);
    setActiveCategory(category);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleCategoryLeave = () => {
    setIsHovering(false);
    timeoutRef.current = setTimeout(() => {
      if (!isHovering) {
        setActiveCategory(categories[0] || null);
      }
    }, 300);
    // задержка для предотвращения случайного исчезновения
    // списка подкатегорий при перемещении курсора
  };

  const handleSubcategoriesEnter = () => {
    setIsHovering(true);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleSubcategoriesLeave = () => {
    setIsHovering(false);
    timeoutRef.current = setTimeout(() => {
      if (!isHovering) {
        setActiveCategory(categories[0] || null);
      }
    }, 300);
    // задержка для предотвращения случайного исчезновения
    // списка подкатегорий при перемещении курсора
  };
  const handleAnchorClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!isDesktop) {
      event.preventDefault();
    }
  };

  useEffect(() => () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  return (
    <div className={styles.container} onClick={(event) => event.stopPropagation()}>
      <div className={styles.list}>
        <Scrollbar>
          <div className={styles.content}>
            {categories.map((value, index) => (
              <Link
                key={index}
                href={value.pathname}
                data-testid={`section_${value.codename}`}
                onMouseEnter={() => handleCategoryEnter(value)}
                onMouseLeave={handleCategoryLeave}
                onClick={(event) => handleAnchorClick(event)}
              >
                <CategoryLabel codename={value.codename} icon={value.icon} />
              </Link>
            ))}
          </div>
        </Scrollbar>
      </div>
      {activeCategory && (
        <div
          className={styles.subcategoryBlock}
          onMouseEnter={handleSubcategoriesEnter}
          onMouseLeave={handleSubcategoriesLeave}
        >
          <ViewListCategories
            categories={activeCategory as Category & { allow_ad_type: boolean }}
            onClose={onClose}
          />
        </div>
      )}
    </div>
  );
};
