import { baseEndpointV1 } from '@/modules/shared/api';

export const endpoints = {
  me: () => `${baseEndpointV1}/users/profile/`,
  uploadAvatar: () => `${baseEndpointV1}/users/avatar/create/`,
  code: () => `${baseEndpointV1}/users/me/phone_number_totp/`,
  phone_number: () => `${baseEndpointV1}/users/me/phone_number/`,
  email: () => `${baseEndpointV1}/users/profile/email/add`,
  confirm_password: () => `${baseEndpointV1}/users/confirm_password/`,
};
