import React, { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { District } from '@/modules/locations';
import { SvgIcon } from '@/modules/shared/components';
import { Checkbox } from '@/modules/shared/components/inputs';
import { AccordionItem } from './accordion-item.component';
import styles from './accordion.module.scss';

interface AccordionProps {
  city: string;
  district: number[];
  isChecked: boolean
  onChangeChecked: (value: boolean) => void;
  onChangeDistrict: (value: number[]) => void;
  options: District[];
}

export const Accordion = ({
  city,
  district,
  options,
  isChecked,
  onChangeChecked,
  onChangeDistrict,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelectDistrict = (dis: District) => {
    const prevSelected = options.filter((v) => district.includes(v.id));

    const isSelected = prevSelected.some((selected) => selected.id === dis.id);
    const updatedSelected = isSelected
      ? prevSelected.filter((selected) => selected.id !== dis.id)
      : [...prevSelected, dis];

    if (updatedSelected.length === 0) {
      onChangeChecked(false);
    } else if (!isChecked) {
      onChangeChecked(true);
    }

    onChangeDistrict(updatedSelected.map((v) => v.id));
  };

  const onChangeCheckbox = () => {
    if (isChecked) {
      onChangeChecked(false);
      onChangeDistrict([]);
    } else {
      onChangeChecked(true);
    }
  };

  return (
    <div className={styles.accordion}>
      <div className={styles.accordion_headline}>
        <Checkbox checked={isChecked} onChange={onChangeCheckbox} checkboxSize="24" />
        <span className={styles.accordion_headline_text} onClick={toggleDropdown}>
          <p>{t(`locations:${city}.${city}`)}</p>
          <SvgIcon name={isOpen ? 'arrow-up' : 'arrow-down'} />
        </span>
      </div>
      <AccordionItem
        city={city}
        isOpen={isOpen}
        options={options}
        cityChecked={isChecked}
        onSelectCity={onChangeCheckbox}
        onSelectDis={handleSelectDistrict}
        selectedDistricts={options.filter((value) => district.includes(value.id))}
      />
    </div>
  );
};
