import {
  Controller, useFormContext,
} from 'react-hook-form';
import { memo } from 'react';

import { Button, InputText, Checkbox } from '@/modules/shared/components';
import useTranslation from 'next-translate/useTranslation';

import { useOpenAuthModal } from '../../../../hooks';
import { useAuthContext } from '../../../../contexts';

import styles from '../../auth-modal.module.scss';


export const Password = memo(() => {
  const { control, getValues } = useFormContext<{
    password: string,
    rememberMe: boolean,
    authType: 'email' | 'phone',
    phone_number?: string,
    email?: string,
  }>();
  const setModalType = useOpenAuthModal();
  const { t } = useTranslation();
  const authType = getValues('authType');
  const email = getValues('email');
  const phone = getValues('phone_number');

  const {
    isLoading,
  } = useAuthContext();

  return (
    <>
      <div>
        <h2 className={styles.title}>{t('auth:titlePassword')}</h2>
      </div>
      <div className={styles.password}>
        <div
          onClick={(e) => {
            e.preventDefault();
            setModalType('forgot', authType === 'email' ? {
              email,
            } : {
              phone,
            });
          }}
          className={styles.passwordForgot}
        >
          {t('auth:forgotPassword.title')}
        </div>
        <Controller
          control={control}
          name="password"
          render={({ field, formState: { errors } }) => (
            <>
              <InputText
                {...field}
                type="password"
                error={!!errors.password}
                fullWidth
                placeholder={t('auth:label.password')}
                eyeVisible
              />
              {errors.password && <div className={styles.error}>{errors.password.message}</div>}
            </>
          )}
        />
      </div>
      <div className={styles.rememberMe}>
        <label>
          <Controller
            control={control}
            name="rememberMe"
            render={({ field }) => (
              <Checkbox onChange={field.onChange} checked={!!field.value} checkboxSize="24" />
            )}
          />
          {t('auth:label.rememberPassword')}
        </label>
      </div>
      <div>
        <Button
          disabled={isLoading}
          type="submit"
          fullWidth
          appearance="primary"
          buttonColor="green"
          buttonSize="s"
        >
          {t('auth:login.button')}
        </Button>
      </div>
    </>
  );
});
