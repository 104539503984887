import { ChangeEvent } from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { SvgIcon } from '@/modules/shared/components';
import s from './file-input.module.scss';
import type { BecomeCompanyFormType } from './types';

type FileInputProps = {
  isSmall: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
};

export const LogoFileInput = ({ isSmall, handleChange }: FileInputProps) => {
  const { formState: { errors } } = useFormContext<BecomeCompanyFormType>();

  return (
    <label className={isSmall ? clsx(s.label, s.label_small) : s.label}>
      <input
        type="file"
        accept=".jpg, .jpeg, .png, .heic, .webp"
        onChange={handleChange}
        style={errors.logo ? { border: '1px solid var(--bzr-secondary-error)' } : {}}
      />
      <SvgIcon name="add-photo-right-plus" />
    </label>
  );
};
