const districtTranslationsEN = {
  Пхукет: 'Phuket',
  Бангтао: 'Bang Tao',
  Сурин: 'Surin',
  Камала: 'Kamala',
  Патонг: 'Patong',
  Карон: 'Karon',
  Ката: 'Kata',
  'Най Харн': 'Nai Harn',
  Равай: 'Rawai',
  Чалонг: 'Chalong',
  'Пхукет таун': 'Phuket town',
  Кату: 'Kathu',
  'Най Янг': 'Nai Yang',
  'Май Кхао': 'Mai Khao',
  Панва: 'Panwa',
  'Ко Каео': 'Koh Kaew',
  Паклок: 'Pa Klok',

  // Районы города Паттайя
  Паттайя: 'Pattaya',
  Наклуа: 'Naklua',
  'Центральная Паттайя': 'Central Pattaya',
  'Южная Паттайя': 'South Pattaya',
  'Северная Паттайя': 'North Pattaya',
  Джомтьен: 'Jomtien',
  Наджомтьен: 'Na Jomtien',
  Пратумнак: 'Pratumnak Hill',
  'Вонг Амат': 'Wong Amat',
  'Хуай Яй': 'Huai Yai',

  // Районы о. Самуи
  Самуи: 'Samui',
  Маенам: 'Mae Nam District',
  Бопхут: 'Bo Phut District',
  'Банг Рак': 'Bang Rak District',
  'Чонг Мон': 'Choen Mon District',
  Чавенг: 'Chaweng District',
  Ламай: 'Lamai District',
  'Талинг Нгам': 'Taling Ngam District',
  'Липа Ной': 'Lipa Noi District',
  Натон: 'Nathon District',
  'Банг По': 'Bang Por',

  // Районы провинции Краби
  Краби: 'Krabi',
  'Муанг Краби': 'Krabi Town',
  'Ао Нанг': 'Ao Nang',
  'Ко Ланта': 'Ko Lanta',
  'Ко Яо Ноя': 'Ko Yao Noi',
  'Ноппарат Тхара': 'Noppharat Thara',
  Хадйаи: 'Had Yai',
  'Ао Луек': 'Ao Leuk',
  'Ко Пипи': 'Ko Phi Phi',
  Прананг: 'Phranang',
  'Ко Яо Яи': 'Ko Yao Yai',

  // Районы острова Панган
  Панган: 'Pangan',
  'Тонг Сала': 'Thong Sala',
  'Баан Тай': 'Baantai',
  Шритану: 'Sritanu',
  Чалоклам: 'Chaloklum',
  'Хаад Рин': 'Haad Rin',
  'Тонг Най Пан': 'Thong Nai Pan',
  'Хаад Яо': 'Haad Yao',
  'Макха Бэй': 'Mae Kha Bay',
};

export const translateDistrict = (districtName, locale) => {
  if (locale === 'en') {
    return districtTranslationsEN[districtName] || districtName;
  }
  // Для других языков или если перевод не требуется
  return districtName;
};

const cityTranslationsEN = {
  Краби: 'Krabi',
  Паттайя: 'Pattaya',
  Пхукет: 'Phuket',
  Самуи: 'Samui',
  Панган: 'Pangan',
};

export const translateCity = (cityName, locale) => {
  if (locale === 'en') {
    return cityTranslationsEN[cityName] || cityName;
  }
  // Для других языков или если перевод не требуется
  return cityName;
};
