import React from 'react';
import clsx from 'clsx';
import { IAd } from '@/modules/ads/types';
import { AdImage, AdLink } from '@/modules/ads';
import { useTranslateLocation } from '@/modules/locations';
import useTranslation from 'next-translate/useTranslation';
import { useFormatDataPrice } from '@/modules/shared/hooks';
import {
  AppTooltip, Button, Checkbox, SvgIcon,
} from '@/modules/shared/components';
import styles from './my-ad-card.module.scss';

interface MyAdCardProps {
  ad: IAd;
  adType: string;
  isSelected: number[];
  onSelect: (id: React.SetStateAction<number>) => void;
  onClick: (id: React.SetStateAction<number>) => void;
}

export const MyAdCard = ({
  ad, adType, onSelect, isSelected, onClick,
}: MyAdCardProps) => {
  const { t } = useTranslation('userProfile');
  const translateLocations = useTranslateLocation(
    ad?.city.codename,
    ad?.district?.codename,
  );
  const priceDisplayFormat = useFormatDataPrice();

  return (
    <div className={styles.my_ad_card}>
      {adType === 'active' && (
        <Checkbox
          checkboxSize="24"
          checked={!!isSelected?.includes(ad?.id)}
          onChange={() => onSelect(ad?.id)}
        />
      )}
      <div className={styles.card}>
        <div className={styles.image}>
          <AdLink ad={ad}>
            {ad.images[0] ? (
              <AdImage ad={ad} />
            ) : (
              <SvgIcon name="b_no_bg" />
            )}
          </AdLink>
        </div>
        <div className={styles.card_content}>
          <div className={clsx(styles.ad_info, { [styles.active_ads]: adType === 'active' })}>
            <div className={styles.title}>
              <AdLink ad={ad} className={styles.title}>
                <AppTooltip title={ad.title}>
                  <h4>
                    {adType === 'drafts'
                      ? t('myAds.notSpecifiedTitle')
                      : ad?.title}
                  </h4>
                </AppTooltip>
              </AdLink>
              <p>
                {adType === 'drafts' ? (
                  t('myAds.notSpecifiedPrice')
                ) : (
                  <>
                    {priceDisplayFormat({
                      priceType: ad?.price_type,
                      price: ad?.price,
                    })}
                    {ad?.price_type === 'for_money' && <SvgIcon name="THB" />}
                  </>
                )}
              </p>
            </div>
            {adType !== 'drafts' && (
              <div className={styles.description}>
                <p>{ad?.description}</p>
              </div>
            )}
            <div className={styles.location_date}>
              <span className={styles.city}>
                <SvgIcon name="geo-point" />
                <p>{translateLocations}</p>
              </span>
              {adType !== 'drafts' && (
                <p className={styles.date}>{ad?.created_at}</p>
              )}
            </div>
          </div>
          <div className={styles.actions}>
            {adType === 'active' && (
              <Button
                fullWidth
                as="button"
                buttonSize="xs"
                buttonColor="grey"
                appearance="primary"
                className={styles.hide}
                onClick={() => onClick(ad?.id)}
              >
                <SvgIcon name="eye-opened" />
                {t('myAds.hide')}
              </Button>
            )}
            {adType === 'hidden' && (
              <Button
                as="button"
                buttonSize="xs"
                buttonColor="grey"
                appearance="primary"
                className={styles.open}
                onClick={() => onClick(ad?.id)}
                fullWidth
              >
                <SvgIcon name="eye-closed" />
                {t('myAds.open')}
              </Button>
            )}
            {adType === 'drafts' && (
              <div className={styles.drafts_buttons}>
                <Button
                  fullWidth
                  as="button"
                  buttonSize="xs"
                  buttonColor="grey"
                  appearance="primary"
                  className={styles.edit}
                >
                  <SvgIcon name="pencil" />
                  {t('myAds.edit')}
                </Button>
                <Button
                  as="button"
                  buttonSize="xs"
                  buttonColor="grey"
                  appearance="primary"
                  className={styles.delete}
                  onClick={() => onClick(ad?.id)}
                >
                  <SvgIcon name="trash-bin" />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
