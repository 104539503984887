import {
  Button,
} from '@/modules/shared/components';
import React, { memo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import styles from '../../auth-modal.module.scss';

export const Blocked = memo(() => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.title}>
        <h2>{t('auth:blocked.title')}</h2>
        <div className={styles.subTitle}>
          {t('auth:blocked.description')}
        </div>
      </div>
      <div>
        <Button
          type="button"
          fullWidth
          as="link"
          href="/support/form"
          appearance="primary"
          buttonColor="green"
          buttonSize="s"
        >
          {t('auth:blocked.button')}
        </Button>
      </div>
    </>
  );
});
