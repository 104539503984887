import { ChangeEvent, KeyboardEvent, ClipboardEvent } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { validateInput } from '@/modules/shared/helpers';

type TitleInputProps = {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error: string;
};

export const TitleInput = ({ value, onChange, error }: TitleInputProps) => {
  const { t } = useTranslation();

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!validateInput(e.key)) {
      e.preventDefault();
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const clipboardData = e.clipboardData.getData('Text');
    if (!validateInput(clipboardData)) {
      e.preventDefault();
    }
  };

  return (
    <label htmlFor="title">
      {t('events:createEvents.title.label')}
      <input
        type="text"
        id="title"
        name="title"
        placeholder={t('events:createEvents.title.placeholder')}
        maxLength={25}
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {value.length === 25 && (
        <p>{t('events:createEvents.title.maxLength', { maxLength: 25 })}</p>
      )}
    </label>
  );
};
