import React, {
  memo, useEffect, useMemo, useState,
} from 'react';
import { useAdsInfiniteListQuery } from '@/modules/ads';
import { IAd } from '@/modules/ads/types';
import { useInfiniteListRef } from '@/modules/shared/hooks';
import { SimilarAds } from './similar-ads.component';

interface AdsToDisplay {
  data: IAd[];
}
interface Props {
  adId: number;
  category: number;
  subcategory: number;
  underSubcategory: number | undefined;
  cityId: number;
}

export const SimilarAdsWrapper = memo((props: Props) => {
  const {
    category,
    subcategory,
    underSubcategory,
    cityId,
    adId,
  } = props;
  const [showSimilarAds, setShowSimilarAds] = useState(false);
  const [ads, setAds] = useState<AdsToDisplay[]>([]);
  const [adsToDisplay, setAdsToDisplay] = useState<AdsToDisplay[]>([]);

  const filters = useMemo(() => ({
    category,
    sub_category: subcategory,
    under_sub_category: underSubcategory,
  }), [category, subcategory, underSubcategory]);

  const { // похожие объявления в том же городе
    pages: adsInTheSameCity,
    fetchMore: fetchMoreOne,
    hasMore: hasMoreOne,
    isLoading: isLoadingOne,
  } = useAdsInfiniteListQuery({
    pageSize: 12,
    filters: { ...filters, city: cityId },
    refetchOnMount: false,
  });

  // если похожее объявление в том же городе только одно,
  // оно будет то же самое, на странице которого мы находимся,
  // тогда показываем похожие объявления во всех городах
  const [showAllCitiesAds, setShowAllCitiesAds] = useState(false);
  useEffect(() => {
    if (adsInTheSameCity.length > 0 && adsInTheSameCity[0].data[0]?.id === adId) {
      setShowAllCitiesAds(true);
    } else {
      setShowAllCitiesAds(false);
    }
  }, [adId, adsInTheSameCity]);

  const { // похожие объявления во всех городах
    pages: adsInAllCities,
    fetchMore: fetchMoreTwo,
    hasMore: hasMoreTwo,
    isLoading: isLoadingTwo,
  } = useAdsInfiniteListQuery({
    pageSize: 12,
    filters,
    disabled: isLoadingOne || showAllCitiesAds,
    refetchOnMount: false,
  });

  const infiniteListRefOne = useInfiniteListRef(hasMoreOne, fetchMoreOne, isLoadingOne);
  const infiniteListRefTwo = useInfiniteListRef(hasMoreTwo, fetchMoreTwo, isLoadingTwo);
  const infiniteListRef = showAllCitiesAds
    ? infiniteListRefTwo
    : infiniteListRefOne;

  useEffect(() => {
    if (showAllCitiesAds) {
      setAds(adsInAllCities);
    } else {
      setAds(adsInTheSameCity);
    }
  }, [adsInTheSameCity, adsInAllCities, showAllCitiesAds]);

  useEffect(() => {
    const filteredAds = ads
      .map((page) => ({
        ...page,
        data: page.data.filter((ad) => ad.id !== adId),
      }));

    if (filteredAds?.[0]?.data?.length > 0) {
      setAdsToDisplay(filteredAds);
      setShowSimilarAds(true);
    } else {
      setShowSimilarAds(false);
    }
  }, [adId, ads]);

  return (
    <>
      {showSimilarAds && (
        <SimilarAds
          adId={adId}
          adsToDisplay={adsToDisplay}
          infiniteListRef={infiniteListRef}
        />
      )}
    </>
  );
});
