import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import {
  ErrorOption, FormProvider, SubmitHandler, useForm,
} from 'react-hook-form';
import { AxiosError } from 'axios';
import Loading from '@/components/partials/Loading';
import useUserAccount from '@/hooks/users/useUserAccount';
import { useMatchMedia } from '@/modules/shared/hooks';
import { SvgIcon } from '@/modules/shared/components';
import { useMyCompany } from '@/modules/company/queries';
import { IconBack, IconSave } from '@/modules/shared/components/mobile-layout';
import { UpdateCompany } from '@/modules/company/types';
import { useUpdateCompany } from '@/modules/company/mutations';
import { UserTypeEnum } from '@/modules/users';
import s from './credentials.module.scss';
import { UserInfo } from './user-info.component';
import { Contacts } from './contacts.component';
import { ChangeNameModal } from './modals';
import { CompanyCredentials } from './company-credentials';
import { CompanyCredentialsForm } from './types';
import styles from './company-credentials/company-credentials.module.scss';
import { getDirty } from './helpers';

export const Credentials = () => {
  const { t } = useTranslation();
  const {
    userData, loading, error, refetch,
  } = useUserAccount();
  const { isDesktop, isMobile } = useMatchMedia();
  const [isChangeNameModalOpen, setIsChangeNameModalOpen] = useState(false);
  const {
    data, isLoading, error: companyError, refetch: companyRefetch,
  } = useMyCompany(userData?.user_type === UserTypeEnum.Company);
  const [name, setName] = useState<string>();
  const [avatar, setAvatar] = useState<File>();
  const {
    mutate, isSuccess, isPending, error: updateError, isError,
  } = useUpdateCompany();
  const formMethods = useForm<CompanyCredentialsForm>();
  const {
    handleSubmit, setError, reset, watch,
  } = formMethods;

  const type = userData?.user_type === UserTypeEnum.Company && !!userData?.company_id
    ? userData?.user_type
    : UserTypeEnum.Individual;

  const onsubmit: SubmitHandler<CompanyCredentialsForm> = async (submitData) => {
    if (!data) return;

    const formData: UpdateCompany = {
      ...submitData,
      user: data.user,
    };
    if (submitData.custom_page_url === data.custom_page_url
      || submitData.custom_page_url === `${data.id}`) {
      delete formData.custom_page_url;
    }
    if (name) formData.name = name;
    if (avatar) formData.logo = avatar;

    mutate(formData);
  };

  const handleChangeName = useCallback(() => setIsChangeNameModalOpen(true), []);
  const onClose = useCallback(() => setIsChangeNameModalOpen(false), []);

  const dirty = getDirty({
    watch, data, name, avatar,
  });
  const handleSave = useCallback(() => {
    if (dirty) {
      handleSubmit(onsubmit)();
    }
  }, [dirty, handleSubmit, onsubmit]);

  useEffect(() => {
    if (isError && updateError instanceof AxiosError) {
      const responseErrorsArray = Object.entries(updateError.response?.data?.validation_errors);
      responseErrorsArray.forEach((el) => {
        if (el[0] === 'social_networks') {
          Object.entries(el[1] as object).forEach((item) => {
            setError(item[0] as keyof CompanyCredentialsForm, item[1] as ErrorOption);
          });
        }
        if (el[1] instanceof Array && el[1][0] === 'Company with this custom page url already exists.') {
          setError('custom_page_url', {
            type: 'name_taken',
            message: t('forms:validationErrors.name_taken'),
          });
          return;
        }
        setError(el[0] as keyof CompanyCredentialsForm, el[1] as ErrorOption);
      });
      return;
    }
    if (isSuccess) {
      setName(undefined);
      setAvatar(undefined);

      companyRefetch().then(() => {
        refetch();
        reset();
        toast.success(t('userAccount:company.toast_success'), {
          toastId: 'Company patch toast success',
        });
      });
    }
  }, [isSuccess, isError, updateError, refetch, companyRefetch, reset]);

  if (loading || isLoading) return <Loading />;
  if (error || companyError) return toast.error('Error fetching user data!');

  return (
    <>
      {!isDesktop && (
        <div className={s.personal_account_header}>
          <IconBack />
          <p className={s.header_title}>{t('common:userSidebar.credentials')}</p>
          <IconSave onSave={handleSave} />
        </div>
      )}
      {isDesktop && (
        <h1 className={s.title}>{t('common:userSidebar.credentials')}</h1>
      )}
      <div className={s.personal_account_content}>
        <UserInfo
          userData={userData}
          handleChangeName={handleChangeName}
          refetch={type === UserTypeEnum.Company ? companyRefetch : refetch}
          companyData={data}
          type={type}
          setAvatar={setAvatar}
          avatar={avatar}
          name={name}
        />
        {isMobile && (
          <section className={clsx(s.contacts, s.section)}>
            <h2>
              {type === UserTypeEnum.Company
                ? t('userAccount:become_company.company_info.name')
                : t('auth:label.name')}
            </h2>
            <div className={s.contact_item}>
              <div className={clsx(s.input_view, s.name)}>
                <span>
                  {type === UserTypeEnum.Company
                    ? (name || data?.name)
                    : userData?.full_name}
                </span>
                <span className={s.edit_icon} onClick={handleChangeName}>
                  <SvgIcon name="pencil" />
                </span>
              </div>
            </div>
          </section>
        )}
        <Contacts
          email={userData?.email}
          phoneNumber={userData?.phone_number}
          refetch={refetch}
          emailVerified={!!userData?.is_email_verified}
          phoneVerified={!!userData?.is_phone_verified}
          phoneUpdatedAt={userData?.phone_updated_at}
        />
        {type === UserTypeEnum.Company && data && (
          <FormProvider {...formMethods}>
            <form
              onSubmit={handleSubmit(onsubmit)}
              autoComplete="off"
              id="form-company-credentials"
              className={styles.form}
            >
              <CompanyCredentials
                data={data}
                name={name}
                avatar={avatar}
                onsubmit={onsubmit}
                isPending={isPending}
                isError={isError}
              />
            </form>
          </FormProvider>
        )}
      </div>
      <ChangeNameModal
        isOpen={isChangeNameModalOpen}
        onClose={onClose}
        name={type === UserTypeEnum.Company ? data?.name : userData?.full_name}
        refetch={refetch}
        type={type}
        setName={setName}
      />
    </>
  );
};
