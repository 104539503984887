import {
  useRef, useEffect, useState, RefCallback,
} from 'react';

type SwipeDirection = 'top-to-bottom' | 'left-to-right';

export const useSwipeToCloseHook = (
  onClose: () => void,
  direction: SwipeDirection = 'top-to-bottom',
): RefCallback<HTMLDivElement> => {
  const touchPositionRef = useRef({
    startX: 0, startY: 0, currentX: 0, currentY: 0,
  });
  const [element, setElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!element) return;

    const handleTouchStart = (e: TouchEvent) => {
      touchPositionRef.current.startX = e.touches[0].clientX;
      touchPositionRef.current.startY = e.touches[0].clientY;
    };

    const handleTouchMove = (e: TouchEvent) => {
      touchPositionRef.current.currentX = e.touches[0].clientX;
      touchPositionRef.current.currentY = e.touches[0].clientY;
    };

    const handleTouchEnd = () => {
      const {
        startX, startY, currentX, currentY,
      } = touchPositionRef.current;

      if (direction === 'top-to-bottom') {
        if (currentY > startY && currentY - startY > 50) {
          onClose();
        }
      } else if (direction === 'left-to-right') {
        if (currentX > startX && currentX - startX > 50) {
          onClose();
        }
      }

      touchPositionRef.current.startX = 0;
      touchPositionRef.current.startY = 0;
      touchPositionRef.current.currentX = 0;
      touchPositionRef.current.currentY = 0;
    };

    element.addEventListener('touchstart', handleTouchStart);
    element.addEventListener('touchmove', handleTouchMove);
    element.addEventListener('touchend', handleTouchEnd);

    return () => {
      element.removeEventListener('touchstart', handleTouchStart);
      element.removeEventListener('touchmove', handleTouchMove);
      element.removeEventListener('touchend', handleTouchEnd);
    };
  }, [onClose, element, direction]);

  return setElement;
};
