import { infiniteListQueryFactory, buildListQueryKeyFactory } from '@/modules/shared/queries';
import { INFINITE_LIST_QUERY_KEY } from '../consts';
import { AdsApi } from '../api';

const queryMainKeys = [INFINITE_LIST_QUERY_KEY];
export const buildAdsListQueryKey = buildListQueryKeyFactory(queryMainKeys);
export const useAdsInfiniteListQuery = infiniteListQueryFactory(
  AdsApi.list,
  queryMainKeys,
);
