import {
  memo, useMemo,
} from 'react';

import { FormState } from '@/modules/shared/hooks';

import clsx from 'clsx';
import { withErrorBoundary } from '../hoc/withErrorBoundary';
import { FiltersMap } from '../types';
import { withDynamicFieldFormGuard } from '../hoc/withDynamicFieldFormGuard';
import { FilterRow } from '../components/filter-row';

import styles from './buttons-list.module.scss';

type NativeCheckboxListProps<T extends FiltersMap['checkbox_list'] = FiltersMap['checkbox_list']> = {
  form: FormState<T['name']>;
  filter: T
  resetOnWillUnmount?: boolean;
};

export const ButtonListField = withErrorBoundary(withDynamicFieldFormGuard<NativeCheckboxListProps>(
  memo(({
    t,
    form,
    filter: { choices, name },
  }) => {
    const value = form.value[name] || [];
    const onChange = form.setter[name]!;

    const items = useMemo(
      () => choices.map((code) => ({
        label: t(`filters:checkbox-list.${name}.options.${code}`, null, {
          fallback: code,
        }),
        value: code,
      })),
      [choices, name, t],
    );

    return (
      <FilterRow title={t(`filters:checkbox-list.${name}.title`)}>
        <div className={styles.wrapper}>
          <div className={styles.items}>
            {items.map(({ label, value: itemValue }) => {
              const active = value.includes(itemValue);

              return (
                (
                  <button
                    key={itemValue}
                    className={clsx(styles.item, {
                      [styles.item_active]: value.includes(itemValue),
                    })}
                    onClick={() => {
                      if (active) {
                        onChange(value.filter((v) => v !== itemValue));
                      } else {
                        onChange([...value, itemValue]);
                      }
                    }}
                  >
                    {label}
                  </button>
                )
              );
            })}
          </div>
        </div>
      </FilterRow>
    );
  }, (prev, next) => (
    prev.form.value[prev.filter?.name] === next.form.value[next.filter?.name]
      && prev.filter === next.filter
      && prev.t === next.t
  )),
));
