import { RefObject, useEffect } from 'react';

type ClickOutsideHandler = (event: MouseEvent) => void;

export const useClickOutsideHandler = (
  ref: RefObject<HTMLElement>,
  onClose: () => void,
): void => {
  const handleClickOutside: ClickOutsideHandler = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClose]);
};
