// date: ДД.ММ.ГГГГ

export const useTimeMessages = (date:string, t, lang) => {
  const [dayStr, monthStr, yearStr] = date.split('.');
  const day = parseInt(dayStr, 10);
  const month = parseInt(monthStr, 10) - 1;
  const year = parseInt(yearStr, 10);

  const inputDate = new Date(year, month, day);

  const today = new Date();

  if (inputDate.getFullYear() === today.getFullYear()
      && inputDate.getMonth() === today.getMonth()
      && inputDate.getDate() === today.getDate()) {
    return t('common:today');
  }

  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  if (inputDate.getFullYear() === yesterday.getFullYear()
      && inputDate.getMonth() === yesterday.getMonth()
      && inputDate.getDate() === yesterday.getDate()) {
    return t('common:yesterday');
  }

  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
  };

  if (inputDate.getFullYear() !== today.getFullYear()) {
    options.year = 'numeric';
  }

  const formattedDate = inputDate.toLocaleDateString(lang, options);
  return formattedDate;
};
