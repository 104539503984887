import React, {
  FC, ReactNode, createContext, useCallback, useContext, useMemo, useState,
} from 'react';
import { noop } from 'lodash';
import useTranslation from 'next-translate/useTranslation';
import { DeactivationModal } from '@/modules/shared/components';
import { IAd } from '../../types';
import { useMyAds } from '../../hooks';

interface IMyAdsPageContext {
  adToDeactivate?: IAd;
  setAdToDeactivate: (ad?: IAd) => void;
}
export const MyAdsPageContext = createContext<IMyAdsPageContext>({
  setAdToDeactivate: noop,
});
export const useMyAdsPageContext = () => useContext(MyAdsPageContext);

export const MyAdsPageProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { reasons, deactivateMyAd } = useMyAds();
  const [adToDeactivate, setAdToDeactivate] = useState<IAd>();
  const closeModal = useCallback(() => setAdToDeactivate(undefined), [setAdToDeactivate]);
  const value = useMemo<IMyAdsPageContext>(() => ({
    adToDeactivate,
    setAdToDeactivate,
  }), [adToDeactivate]);

  const { t } = useTranslation('userAdvertisements');
  const titleReason = t('userAdvertisements.deactivatedModal.title');

  const deactivateAd = useCallback(async (reason: string) => {
    if (!adToDeactivate) {
      throw new Error('adToDeactivate is null');
    }
    await deactivateMyAd(adToDeactivate.id, { action: 'hide', deactivation_reason: reason });
    closeModal();
  }, [closeModal, adToDeactivate, deactivateMyAd]);

  return (
    <MyAdsPageContext.Provider value={value}>
      {children}
      {!adToDeactivate ? null : (
        <DeactivationModal
          onClose={closeModal}
          title={titleReason}
          onSubmit={deactivateAd}
          reasons={reasons}
        />
      )}
    </MyAdsPageContext.Provider>
  );
};
