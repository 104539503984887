export const YM_TRACKING_ID = 95978195;

export const events = {
  register: {
    meta: 'CompleteRegistration',
    yandex: 'register',
  },
  publishAd: {
    yandex: 'publish_ad',
  },
};

export const providers: {
  name: string;
  track: (eventName: string, payload?: unknown) => void;
}[] = [{
  name: 'meta',
  track: (eventName, payload) => {
    if (window.fbq && typeof window.fbq === 'function') {
      window.fbq('track', eventName, payload);
    }
  },
}, {
  name: 'yandex',
  track: (eventName) => {
    window.ym?.(YM_TRACKING_ID, 'reachGoal', eventName);
  },
}];

