/* для бэкенда:
* право собственности - (классы LandPlotOwnershipRight и CommercialAndResidentialOwnershipRight) разные,
* но по макету должны быть одинаковые.
* Поля "дополнительные" (https://www.figma.com/design/3Sn1LsLpflBNE0ywoEXipV/Bazaar?node-id=664-130054&node-type=frame&t=2Z53CsBciQ6IR3cK-0)
*  и значения для них (https://www.figma.com/design/3Sn1LsLpflBNE0ywoEXipV/Bazaar?node-id=664-130346&node-type=frame&t=2Z53CsBciQ6IR3cK-0) тоже не поддерживаются.
* Причем для Недвижимость/Коммерческая для "сдать" и "продать" разные дополнительные поля
*/

import useTranslation from 'next-translate/useTranslation';

export const useRealEstate = () => {
  const { t } = useTranslation('ad-parameters');

  const mapState = (value:string) => ({ value, label: t(`realEstate.conditions.${value}`) });
  const mapDuration = (value:string) => ({ value, label: t(`realEstate.durations.${value}`) });

  const mapNumberOfRooms = (value:string) => ({ value, label: t(`realEstate.numberOfRooms.${value}`) });
  const mapOwnershipRight = (value:string) => ({ value, label: t(`realEstate.ownershipRight.${value}`) });
  const mapCompletionStatus = (value:string) => ({ value, label: t(`realEstate.completionStatus.${value}`) });
  const mapAllowsPets = (value:string) => ({ value, label: t(`realEstate.allowsPets.${value}`) });

  const mapResidentialAdditionalOptions = (value:string) => ({ value, label: t(`realEstate.residential.additionalOptions.${value}`) });

  const mapCommercialAdditionalOptionsBuy = (value:string) => ({ value, label: t(`realEstate.commercial.additional_options.buy.${value}`) });
  const mapCommercialAdditionalOptionsRent = (value:string) => ({ value, label: t(`realEstate.commercial.additional_options.rent.${value}`) });

  const mapLandPermittedBuilding = (value:string) => ({ value, label: t(`realEstate.land.permittedBuilding.${value}`) });

  const realEstate = {
    state: ['new', 'secondary'].map(mapState),
    duration: ['long_term', 'daily'].map(mapDuration),
    numberOfRooms: ['studio', '1', '2', '3', '4', '5_and_more', 'free_planning'].map(mapNumberOfRooms),
    ownershipRight: ['foreign_quota', 'thai_quota', 'company', 'leasehold'].map(mapOwnershipRight),
    completionStatus: ['completed', 'under_construction', 'not_planned'].map(mapCompletionStatus),
    allowsPets: ['allowed', 'by_agreement', 'not_allowed'].map(mapAllowsPets),
    residential: {
      additional_options: [
        'private_pool', 'furnished_by_developer', 'partially_furnished', 'pool_access',
        'jacuzzi', 'sauna', 'garden', 'gym', 'beach_access', 'several_floors', 'parking_space',
      ].map(mapResidentialAdditionalOptions),
    },
    commercial: {
      additional_options: {
        buy: ['with_fine_finishing', 'several_floors', 'parking_space'].map(mapCommercialAdditionalOptionsBuy),
        rent: [
          'with_fine_finishing', 'several_floors', 'parking_space',
          'fully_furnished', 'partially_furnished', 'air_conditioning_in_every_room',
        ].map(mapCommercialAdditionalOptionsRent),
      },
    },
    land: {
      permittedBuilding: ['residential', 'commercial', 'industrial'].map(mapLandPermittedBuilding),
    },
  };

  return realEstate;
};
