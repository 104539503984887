import { AutocompletePrediction } from './types';

export const getAutocompleteSuggestions = (
  predictions: AutocompletePrediction[],
) => predictions.map((prediction) => {
  const { structured_formatting } = prediction;
  const {
    main_text,
    main_text_matched_substrings,
    secondary_text,
    secondary_text_matched_substrings,
  } = structured_formatting;
  return {
    id: prediction.place_id,
    name: {
      string: main_text,
      length: main_text_matched_substrings?.[0].length,
      offset: main_text_matched_substrings?.[0].offset,
    },
    address: {
      string: secondary_text,
      length: secondary_text_matched_substrings?.[0].length,
      offset: secondary_text_matched_substrings?.[0].offset,
    },
  };
});
