import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CompanyApi } from '../api';
import { COMPANY_KEY } from '../const';

export const useCreateCompany = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: CompanyApi.createCompany,
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: [COMPANY_KEY],
      });
    },
    onError: (e) => console.error(`Creation company error: ${e}`),
  });
};
