import React from 'react';
import s from './item-loader.module.scss';

export function ItemLoader({ isLoading, size }) {
  if (!isLoading) {
    return null;
  }

  const sizeInt = parseInt(`${size}`.replace('px', ''), 10);

  const loaderStyle = {
    width: sizeInt ? `${sizeInt}px` : '50px',
    height: sizeInt ? `${sizeInt}px` : '50px',
  };

  return <div className={s.loader} style={loaderStyle} />;
}
