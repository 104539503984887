import { useState } from 'react';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import s from './expandable-text.module.scss';

type MoreButtonProps = {
  text: string;
  maxLength: number;
  className?: string;
};

export const ExpandableText = ({ text, maxLength, className = '' }: MoreButtonProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => setIsOpen((prev) => !prev);

  return (
    <p className={clsx(s.comment, className)}>
      {
        (isOpen || text.length <= maxLength)
          ? `${text} `
          : `${text.slice(0, maxLength + 1)}...`
      }
      {text.length > maxLength && (
        <button type="button" className={s.more} onClick={handleClick}>
          {isOpen ? t('common:hide') : t('common:more')}
        </button>
      )}
    </p>
  );
};
