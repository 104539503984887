import React, { FC, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Dropdown, useDropdownOnChange, useDropdownValue } from '@/modules/shared/components';
import { Option } from '@/modules/shared/types';
import { useLocationsListQuery } from '../queries';


type Props = {
  value: string;
  onChange: (value?: string) => void;
};
export const CitiesDropdown: FC<Props> = ({ value = null, onChange }) => {
  const { data } = useLocationsListQuery();
  const { t } = useTranslation('locations');

  const options = useMemo<Option[]>(() => {
    const cities = data.data.map(({ id, codename }) => ({
      value: id,
      label: t(`locations:${codename}.${codename}`),
    }));
    cities.unshift({ value: null, label: t('common:firstOption') });

    return cities;
  }, [data, t]);

  return (
    <Dropdown
      options={options}
      value={useDropdownValue(value, options)}
      onChange={useDropdownOnChange(onChange)}
    />
  );
};
