import { authAxios } from '@/modules/auth/api';
import { useState } from 'react';

const API_BASE_URL = process.env.NEXT_PUBLIC_API_URL;

const useForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [code, setCode] = useState(Array(6).fill(''));

  const sendActivationCode = async (emailActivation) => {
    await authAxios.post(
      `${API_BASE_URL}/api/users/forgot_pass_send_activation_code/`,
      { email: emailActivation },
    );
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  const completeForgotPassword = async () => {
    const emailFromStorage = getCookie('forgotPasswordEmail') || email;
    const passwordFromStorage = getCookie('resetPassword') || password;
    const password2FromStorage = getCookie('resetPassword2') || password2;

    const requestData = {
      email: emailFromStorage,
      code: code.join(''),
      password: passwordFromStorage,
      password2: password2FromStorage,
    };

    await authAxios.post(
      `${API_BASE_URL}/api/users/forgot_pass_complete/`,
      requestData,
    );
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    password2,
    setPassword2,
    code,
    setCode,
    sendActivationCode,
    completeForgotPassword,
  };
};

export default useForgotPassword;
