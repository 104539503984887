import { parsePhoneNumberFromString, getExampleNumber, parsePhoneNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/examples.mobile.json';

export const exampleFormatPhoneNumber = (countryCode: string) => {
  const phoneNumber = parsePhoneNumberFromString(countryCode || '');
  if (!phoneNumber || !phoneNumber.country) {
    return '';
  }
  const exampleNumber = getExampleNumber(phoneNumber.country, examples);
  return exampleNumber?.formatInternational() || '';
};

export const formatPhoneNumber = (number: string) => (number ? parsePhoneNumber(number).formatInternational() : '');
