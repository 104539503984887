import { useEffect, useRef, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { createZoomControl } from './create-zoom-control.helper';

type MapProps = {
  address: string;
  loader: Loader;
  getAddress?: (address: string) => void;
  initialLocation?: google.maps.LatLngLiteral | undefined;
  isAdPage?: boolean;
  zoom?: number;
};

export const MapContainer = ({
  address, getAddress, loader, initialLocation, isAdPage = false, zoom,
}: MapProps) => {
  const [mode, setMode] = useState<'address' | 'coordinates'>('coordinates');
  const [location, setLocation] = useState<google.maps.LatLngLiteral | undefined>();
  const mapRef = useRef(null);

  useEffect(() => {
    if (initialLocation) setLocation(initialLocation);
  }, [initialLocation]);

  useEffect(() => {
    if (address !== 'Phuket, Thailand') setMode('address');
  }, [address]);

  useEffect(() => {
    if (!mapRef || !mapRef.current) return;

    let map: google.maps.Map | null;
    const initMap = async (position: google.maps.LatLng | undefined) => {
      // @ts-expect-error: пример с исключением взят из документации google
      const { Map } = await google.maps.importLibrary('maps');
      // @ts-expect-error: пример с исключением взят из документации google
      const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary('marker');

      map = new Map(mapRef.current, {
        zoom: zoom || 15,
        center: position,
        mapId: process.env.NEXT_PUBLIC_GOOGLE_MAP_ID,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        zoomControl: false,
      });

      const zoomControlDiv = document.createElement('div');
      const zoomControl = createZoomControl(map as google.maps.Map);
      zoomControlDiv.appendChild(zoomControl);

      (map as google.maps.Map).controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomControlDiv);

      const pin = new PinElement({
        scale: 0.8,
        background: 'var(--bzr-main-green-default)',
        borderColor: 'var(--bzr-main-green-default)',
        glyphColor: 'var(--bzr-main-green-pressed)',
      });

      /* eslint-disable @typescript-eslint/no-unused-vars */
      const marker = new AdvancedMarkerElement({
        map,
        position,
        content: pin.element,
      });

      if (!isAdPage) {
        (map as google.maps.Map).addListener('click', (mapsMouseEvent) => {
          setMode('coordinates');
          const mousePosition = mapsMouseEvent.latLng;
          setLocation(mousePosition);
        });
      }
    };

    loader.importLibrary('maps').then(() => {
      const geocoder = new google.maps.Geocoder();

      if (mode === 'address') {
        geocoder?.geocode({ address }, (results, status) => {
          if (status === 'OK') {
            initMap(results?.[0].geometry.location);
          } else {
            console.error(`Geocode was not successful: ${status}`);
          }
        });
      } else if (location) {
        geocoder?.geocode({ location }, (results, status) => {
          if (status === 'OK' && results && results[0]) {
            getAddress?.(results[0].formatted_address || '');
            initMap(results?.[0].geometry.location);
          } else {
            console.error(`Reverse geocode was not successful: ${status}`);
          }
        });
      }
    });

    return () => {
      if (map) {
        map = null;
      }
    };
  }, [mapRef, address, mode, location, initialLocation]);

  return <div style={{ borderRadius: '8px', height: '100%' }} ref={mapRef} />;
};
