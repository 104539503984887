import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReviewApi } from '../api';

interface Props {
  id: string;
  answerData: {
    recipient_answer: string;
  };
}

export const useReviewAnswerMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: Props) => ReviewApi.reviewAnswer(params.id, params.answerData),
    onSuccess: async () => queryClient.invalidateQueries({
      queryKey: ['reviews'],
      refetchType: 'all',
    }),
  });
};
