import { MeApi } from '@/modules/me/api';
import { useMutation } from '@tanstack/react-query';

export const useConfirmPassword = () => useMutation({
  mutationFn: MeApi.confirmPassword,
  onError: (error) => {
    console.error('Error password confirmation:', error.message);
    throw error;
  },
});
