import { Path, useFormContext } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { useAuthContext } from '@/modules/auth';
import { useMeQuery } from '@/modules/me';
import { InputText } from '../inputs';
import { SvgIcon } from '../svg-icon';
import s from './creation-contacts.module.scss';
import { MEDIA_CONTACTS } from './const';

type Media = {
  telegram?: string;
  instagram?: string;
  whatsapp?: string;
};

export const MediaContacts = () => {
  const { register, formState: { errors } } = useFormContext<Media>();
  const { t } = useTranslation();
  const { isAuthenticated } = useAuthContext();
  const { data: me } = useMeQuery(isAuthenticated);

  return (
    <div className={s.field_inner}>
      <p>
        {t('events:createEvents.contact.messengers')}
        <br />
        {t('events:createEvents.contact.media')}
      </p>
      <div className={clsx(s.values, s.media)}>
        {MEDIA_CONTACTS.map((el) => (
          <div key={el} style={{ width: '100%' }}>
            <InputText
              fullWidth
              placeholder={`${t('events:createEvents.contact.link_to')} ${el}`}
              defaultValue={me?.[el.toLowerCase()] || ''}
              {...register(`${el.toLowerCase()}` as Path<Media>)}
            />
            <SvgIcon name={`${el.toLowerCase()}-no-bg-24`} className={s[el.toLowerCase()]} />
            {errors[el.toLowerCase()] && (
              <p className={s.error}>
                {errors[el.toLowerCase()]['0'] || errors[el.toLowerCase()].message as string}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
