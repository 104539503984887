import React, {
  useEffect, useRef, useState, useCallback, useMemo,
} from 'react';
import { useRouter } from 'next/router';
import Cookie from 'js-cookie';
import { useMeContext } from '@/modules/me';
import { saveEntityImages } from '@/modules/shared/api';
import { IAd } from '@/modules/ads/types';
import {
  AdDataForFeedback, ChatSupportList, DraftMessage, MessageDetails, UserContact,
} from '../types';
import { ChatTop } from './chat/chat-top';
import { MessagesContainer } from './chat/messages-container';
import { ChatChoosePhoto, ChatTextField } from './chat/chat-input';
import { useNetworkStatus, useCanRequestReviews } from '../hooks';
import styles from './chat-page.module.scss';
import { ChatApi } from '../api';

type ChatPageProps = {
  selectedUserData: UserContact | ChatSupportList;
  ad?: IAd | undefined;
  isSupport?: boolean;
};

export const ChatPage = ({
  selectedUserData, ad, isSupport = false,
}: ChatPageProps) => {
  const router = useRouter();
  const { query, isReady } = router;
  const [value, setValue] = useState<string>('');
  const [messages, setMessages] = useState<MessageDetails[]>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  const [image, setImage] = useState<File>();
  const [isPreloading, setIsPreloading] = useState<boolean>(false);
  const [isAutoScroll, setIsAutoScroll] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean | null>(null);
  const [draftMessages, setDraftMessages] = useState<DraftMessage[]>([]);
  const [errorMessages, setErrorMessages] = useState<DraftMessage[]>([]);

  const createImageApi = useCallback(async (formats) => ChatApi.createMessageImages({ formats }), []);

  const timeString = new Date().toString();

  const ws = useRef<WebSocket | null>(null);
  const refetchTimer = useRef<NodeJS.Timeout | null>(null);
  const { me } = useMeContext();

  const isConnected = useNetworkStatus();
  const meID = me?.id;

  const draftMessage = useMemo(() => (
    {
      message: image ? '' : newMessage,
      timestamp: timeString,
      images: image ? [image] : [],
    }
  ), [image, newMessage, timeString]);

  useEffect(() => {
    if (ws.current) return;

    if (!isReady) return;
    setIsLoading(true);

    const accessToken = Cookie.get('access_token') || '';
    const adID = Array.isArray(query.ad) ? query.ad[0] : query.ad;


    if (selectedUserData && (!ws.current || (ws.current as WebSocket).readyState === WebSocket.CLOSED)) {
      const wsUrl = adID
        ? `${process.env.NEXT_PUBLIC_WS_API_URL}/chat/${selectedUserData.id}/${adID}/?token=${accessToken}`
        : `${process.env.NEXT_PUBLIC_WS_API_URL}/chat/${selectedUserData.id}/?token=${accessToken}`;

      ws.current = new WebSocket(wsUrl);

      ws.current.onopen = () => {
        setTimeout(() => {
          if (
            ws.current
            && ws.current.readyState === WebSocket.OPEN
            && messages.length === 0
          ) {
            setIsLoading(false);
            setIsPreloading(false);
          }
        }, 1000);
      };

      ws.current.onmessage = (e) => {
        const data = JSON.parse(e.data);
        setMessages((prevMessages) => [...prevMessages, data]);
        setIsLoading(false);
        setIsPreloading(false);
        setDraftMessages([]);
        setErrorMessages([]);
      };

      ws.current.onclose = () => {
        setIsLoading(false);
        setIsPreloading(false);
        setDraftMessages([]);
        setErrorMessages([]);
      };

      ws.current.onerror = (err) => {
        console.error('WebSocket Error:', err);
        setIsLoading(false);
        setIsPreloading(false);
        setDraftMessages([]);
        setErrorMessages((prev) => [...prev, draftMessage]);
      };
    }

    return () => {
      if (ws.current) {
        ws.current.onopen = null;
        ws.current.onmessage = null;
        ws.current.onclose = null;
        ws.current.onerror = null;
        ws.current.close();

        ws.current = null;
      }
    };
  }, [selectedUserData?.id]);

  const sendMessage = useCallback(async (img?: File) => {
    const trimmedMessage = newMessage.trim();
    setValue('');
    if (img) setImage(img);

    if (trimmedMessage !== '' || img) {
      setIsPreloading(true);
      setDraftMessages((prev) => [...prev, {
        message: img ? '' : trimmedMessage,
        timestamp: timeString,
        images: img ? [img] : [],
      }]);
    }
    if (
      (trimmedMessage !== '' || img)
      && ws.current
      && ws.current.readyState === WebSocket.OPEN
      && isConnected
    ) {
      try {
        const imageData = img ? await saveEntityImages([img], createImageApi) : [];
        const messageData = img ? {
          images: imageData,
          message: '',
          senderId: meID,
        } : {
          message: trimmedMessage,
          senderId: meID,
        };
        ws.current.send(JSON.stringify(messageData));
        setNewMessage('');
      } catch (error) {
        setErrorMessages((prev) => [...prev, {
          message: img ? '' : trimmedMessage,
          timestamp: timeString,
          images: img ? [img] : [],
        }]);
      }
    }

    if (!isConnected) {
      setTimeout(() => {
        setErrorMessages((prev) => [...prev, {
          message: img ? '' : trimmedMessage,
          timestamp: timeString,
          images: img ? [img] : [],
        }]);
        setIsPreloading(false);
        setDraftMessages([]);
      }, 2000);
    }
  }, [isConnected, newMessage, meID, timeString]);

  const handleErrorClick = () => {
    setDraftMessages(() => [draftMessage]);
    setErrorMessages([]);
    setIsPreloading(true);

    if (refetchTimer.current) {
      clearTimeout(refetchTimer.current);
    }

    // Пытаемся отправить сообщение после небольшой задержки
    refetchTimer.current = setTimeout(() => {
      if (isConnected) {
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          try {
            const messageData = {
              message: newMessage,
              senderId: meID,
            };
            ws.current.send(JSON.stringify(messageData));
            // Очищаем черновики после успешной отправки
            setDraftMessages([]);
            setNewMessage('');
            setIsPreloading(false); // Останавливаем предзагрузку
          } catch (error) {
            // Если отправка не удалась, добавляем сообщение в ошибки
            setErrorMessages((prev) => [...prev, draftMessage]);
            setIsPreloading(false); // Останавливаем предзагрузку
          }
        } else {
          // Если WebSocket не открыт, добавляем сообщение в ошибки
          setErrorMessages((prev) => [...prev, draftMessage]);
          setIsPreloading(false); // Останавливаем предзагрузку
        }
      } else {
        // Если сеть не подключена, добавляем сообщение в ошибки
        setErrorMessages((prev) => [...prev, draftMessage]);
        setIsPreloading(false); // Останавливаем предзагрузку
      }
    }, 2000);
  };

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        sendMessage();
      }
    },
    [sendMessage],
  );

  const handleChange = useCallback((valueInput:string) => {
    setIsAutoScroll(true);
    setValue(valueInput);
    setNewMessage(valueInput);
  }, []);

  const canRequestReviews = useCanRequestReviews(messages);


  const adDataForFeedback = useMemo(() => {
    if (ad) {
      return {
        userId: ad.user,
        adId: ad.id,
        announcementReviewData: {
          id: ad.id,
          title: ad.title,
          price_type: ad.price_type,
          price: ad.price,
          main_picture: {
            thumbnail: ad?.images.find((img) => img.id === ad?.main_picture)?.thumbnail || '',
          },
        },
      };
    }
  }, [ad]) as AdDataForFeedback;

  return (
    <div className={styles.chat}>
      <div className={styles.chat_top}>
        <ChatTop
          recipient={!isSupport ? (selectedUserData as UserContact) : null}
          ad={ad}
          typeChat={isSupport ? 'support' : 'user'}
          canRequestReviews={canRequestReviews()}
        />
      </div>
      <MessagesContainer
        isLoading={isLoading}
        messages={messages}
        userId={meID ?? null}
        adData={adDataForFeedback}
        autoScroll={isAutoScroll}
        isPreloading={isPreloading}
        errorMessages={errorMessages}
        draftMessages={draftMessages}
        onErrorClick={handleErrorClick}
        isSupport={isSupport}
      />

      <div className={styles.send_message}>
        <ChatChoosePhoto sendMessage={sendMessage} />
        <ChatTextField
          handleChange={handleChange}
          handleKeyDown={handleKeyDown}
          sendMessage={sendMessage}
          value={value}
        />
      </div>
    </div>
  );
};
