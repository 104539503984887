import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { SvgIcon } from '@/modules/shared/components';
import { profileBasePath } from '@/modules/shared/const';
import styles from './support-service.module.scss';

export type SupportServiceProps = {
  newMessage?: boolean;
};

export const SupportService = ({ newMessage = false }: SupportServiceProps) => {
  const { t } = useTranslation('');

  return (
    <Link
      className={styles.container}
      href={`/${profileBasePath}/chat/support`}
    >
      <SvgIcon name="support-service" />

      <div className={styles.info}>
        <h4 className={styles.info_title}>{t('chat:supportTitle')}</h4>

        {!newMessage && (
          <p className={styles.info_description}>{t('chat:supportMessage')}</p>
        )}
        {newMessage && (
        <p className={styles.info_message}>
          {t('chat:newMessage')}
          <span className={styles.ellipse} />
        </p>
        )}
      </div>
    </Link>
  );
};
