import React, { useCallback, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Cookie from 'js-cookie';
import { useCityPrefix } from '@/context/CityPrefixContext';
import { SvgIcon } from '../svg-icon';
import { Modal } from '../modal';
import { CitySelectionProps } from './types';
import s from './city-selection.module.scss';

interface CityType {
  id: number;
  codename: string;
  label: string;
}

export const CitySelection = ({
  city, onSelect, onClose, isOpen, data,
}: CitySelectionProps) => {
  const { t } = useTranslation();
  const { setCityId } = useCityPrefix();

  const cities = useMemo(() => {
    const allCities = { id: 1, codename: 'allcities', label: t('locations:allcities.allcities') };

    const filteredCities = [allCities, ...data]
      .filter(({ codename }) => codename !== city)
      .map(({ id, codename }) => ({
        id,
        codename,
        label: t(`locations:${codename}.${codename}`),
      }));

    return filteredCities.filter((item) => !!item);
  }, [data, t, city]);

  const saveCityIdToCookie = useCallback((selectedCity: CityType) => {
    if (selectedCity.codename === 'allcities') {
      Cookie.set('selectedCity', 'allcities');
      Cookie.set('selectedCityName', 'allcities');
      setCityId(undefined);
      return;
    }
    setCityId(String(selectedCity.id));
    Cookie.set('selectedCity', String(selectedCity.id));
    Cookie.set('selectedCityName', selectedCity.codename);
  }, []);

  const handleCityClick = useCallback((item: CityType) => {
    saveCityIdToCookie(item);
    onSelect(item.codename);
  }, [onSelect, saveCityIdToCookie]);

  return (
    <Modal title={t('common:chooseCity')} open={isOpen} onClose={onClose} titleJustifyContent="start" showBackButton={false}>
      <div className={s.city_selection}>
        <div className={s.current_location}>
          <SvgIcon name="geo-point" style={{ fontSize: '24px' }} />
          {t(`locations:${city}.${city}`)}
        </div>
        <ul className={s.list_locations}>
          {cities.map((item) => (!item ? null : (
            <li
              key={item.id}
              onClick={() => handleCityClick(item)}
              data-testid={`city_selector_${item.codename}`}
              className={s.city}
            >
              {item?.label}
            </li>
          )))}
        </ul>
      </div>
    </Modal>
  );
};
