import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useMatchMedia } from '@/modules/shared/hooks';
import { SvgIcon } from '@/modules/shared/components';
import { useCreateAdActionsContext, useCreateAdContext } from '../../context';
import { ReturnedType } from '../../types';
import styles from './progress-bar.module.scss';

export const ProgressBarDesktop = ({ progressbar }: { progressbar: ReturnedType }) => {
  const { 2: contactInformation, 3: description, 4: details } = progressbar;
  const { isDesktop } = useMatchMedia();
  const { mainStep, isAdditionalSpecifications } = useCreateAdContext();
  const { setMainStep } = useCreateAdActionsContext();

  const handleBack = useCallback(() => {
    if (mainStep === 3) {
      setMainStep(2);
      return;
    }
    if (mainStep === 4 && isAdditionalSpecifications) {
      setMainStep(3);
      return;
    }
    if (mainStep === 4 && !isAdditionalSpecifications) {
      setMainStep(2);
    }
  }, [isAdditionalSpecifications, mainStep]);

  return (
    <div className={styles.progress_bar}>
      {isDesktop && mainStep > 2 && (
        <button
          className={styles.button_back}
          type="button"
          onClick={handleBack}
        >
          <SvgIcon name="arrow-forward-left" />
        </button>
      )}

      <div className={styles.step}>
        <div
          className={clsx(styles.border, {
            [styles.active]: mainStep === 2,
            [styles.completed]: mainStep > 2,
          })}
        >
          {mainStep > 2 ? (
            <SvgIcon name="check" />
          ) : (
            <span
              className={clsx(styles.step_number, {
                [styles.step_number_active]: mainStep === 2,
              })}
            >
              {contactInformation.id - 1}
            </span>
          )}
        </div>
        <p className={styles.title}>{contactInformation.title}</p>
      </div>

      <div
        className={clsx(styles.line, {
          [styles.line_active]: mainStep === 3 && isAdditionalSpecifications,
          [styles.line_completed]: mainStep > 3,
        })}
      />
      <div className={styles.step}>
        <div
          className={clsx(styles.border, {
            [styles.pending]: mainStep === 2,
            [styles.active]: mainStep === 3 && isAdditionalSpecifications,
            [styles.completed]: mainStep > 3,
          })}
        >
          {mainStep > 3 ? (
            <SvgIcon name="check" />
          ) : (
            <span
              className={clsx(styles.step_number, {
                [styles.step_number_active]: mainStep === 3 && isAdditionalSpecifications,
              })}
            >
              {description.id - 1}
            </span>
          )}
        </div>
        <p className={styles.title}>{description.title}</p>
      </div>

      <div
        className={clsx(styles.line, {
          [styles.pending]: mainStep < 4,
          [styles.line_active]: mainStep === 4,
        })}
      />
      <div className={styles.step}>
        <div
          className={clsx(styles.border, {
            [styles.pending]: mainStep < 4,
            [styles.active]: mainStep === 4,
          })}
        >
          {mainStep > 4 ? (
            <SvgIcon name="check" />
          ) : (
            <span
              className={clsx(styles.step_number, {
                [styles.step_number_active]: mainStep === 4,
              })}
            >
              {details.id - 1}
            </span>
          )}
        </div>
        <p className={styles.title}>{details.title}</p>
      </div>
    </div>
  );
};
