import { useCallback } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { Button, ModalDesktop, ModalMobile } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import s from './modal.module.scss';
import { CompanyCredentialsForm } from '../types';
import { MAX_LENGTH, MIN_LENGTH } from '../company-credentials/const';
import { InnerButton, ModalError, ModalTextarea } from './inner-components';
import { useSetFocus } from '../helpers';

type ChangeNameModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (value: string) => void;
  value: string;
  initialDescription: string;
};

export const ChangeDescriptionModal = ({
  isOpen, onClose, onSave, value, initialDescription,
}: ChangeNameModalProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();
  const { isFocus, setFocus } = useSetFocus();

  const {
    setError, clearErrors, formState: { errors },
  } = useFormContext<CompanyCredentialsForm>();

  const handleSave = useCallback(() => {
    onSave(value);
    onClose();
  }, [value]);

  const handleClose = useCallback(() => {
    clearErrors('description');
    onSave(initialDescription);
    onClose();
  }, [value]);

  const handleBlur = useCallback(() => {
    if (!value) {
      setError('description', {
        type: 'required',
        message: t('forms:validationErrors.required'),
      });
      return;
    }
    if (value.length < MIN_LENGTH || value.length > MAX_LENGTH) {
      setError('description', {
        type: 'length',
        message: t('forms:validationErrors.description', {
          min: MIN_LENGTH, max: MAX_LENGTH,
        }),
      });
      return;
    }
    clearErrors('description');
  }, [value, isMobile]);

  return isMobile ? (
    <ModalMobile
      open={isOpen}
      fullHeight
      headerBordered
      showBackButton={false}
      showCloseButton
      onClose={handleClose}
      contentStyles={s.content}
    >
      <h3 className={s.h3}>
        {t('userAccount:become_company.company_info.description')}
      </h3>
      <ModalTextarea
        value={value}
        onBlur={handleBlur}
        onFocus={setFocus}
      />
      <ModalError error={errors.description} />
      <div className={clsx(s.button_wrapper, {
        [s.button_wrapper_up]: isFocus,
      })}
      >
        <InnerButton
          onClick={handleSave}
          disabled={!value || value.trim() === initialDescription || !!errors.description}
          text={t('common:save')}
        />
      </div>
    </ModalMobile>
  ) : (
    <ModalDesktop
      open={isOpen}
      onClose={handleClose}
      title={t('userAccount:become_company.company_info.description')}
      titleJustifyContent="start"
    >
      <div className={clsx(s.content, s.content_description)}>
        <ModalTextarea
          value={value}
          onBlur={handleBlur}
        />
        <ModalError error={errors.description} />
        <div className={s.buttons}>
          <Button
            appearance="primary"
            buttonColor="grey"
            buttonSize="s"
            onClick={handleClose}
          >
            {t('common:cancel_verb')}
          </Button>
          <InnerButton
            onClick={handleSave}
            disabled={!value || value.trim() === initialDescription || !!errors.description}
            text={t('common:save')}
          />
        </div>
      </div>
    </ModalDesktop>
  );
};
