import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { toast } from 'react-toastify';

import { useMutation } from '@tanstack/react-query';

import { Button, CodeInput } from '@/modules/shared/components';
import { useThrottler } from '../../hook/use-throttler';
import { AuthApi } from '../../../../api';
import { useOpenAuthModal } from '../../../../hooks/use-open-modal.hook';
import styles from '../../auth-modal.module.scss';

export const CompletePhone = () => {
  const {
    watch,
  } = useFormContext<{
    phone: string,
  }>();
  const phoneValue = watch('phone');
  const setModalType = useOpenAuthModal();

  const [throttleSec, startThrottler] = useThrottler();

  useEffect(() => {
    startThrottler();
  }, []);

  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const {
    isPending, error, mutateAsync, reset,
  } = useMutation({
    mutationFn: AuthApi.confirmRegistration,
  });

  const { mutateAsync: resendTotp, isPending: isPendingResendTotp } = useMutation({
    mutationFn: AuthApi.sendTotp,
  });

  const isCodeInvalid = (error as AxiosError<{
    validation_errors: { code: string[] }
  }>)?.response
    ?.data
    ?.validation_errors
    ?.code
    ?.includes?.('INVALID_PHONE_NUMBER_OR_TOTP_CODE');

  useEffect(() => {
    reset();
  }, [code, reset]);

  const handleResendCode = async () => {
    try {
      const data = await resendTotp({
        phone_number: phoneValue,
        operation_type: 'register',
      });

      if (data.status !== 'success') throw new Error();

      startThrottler();
      toast(t('auth:phoneConfirmation.resendCodeDone'), { type: 'success' });
    } catch (e) {
      if (e.code === 'THROTTLED') {
        toast(t('auth:phoneConfirmation.throttled'), { type: 'error' });
      } else {
        toast(t('auth:phoneConfirmation.error'), { type: 'error' });
      }
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await mutateAsync({
        totp: code,
        phone_number: phoneValue,
        registration_type: 'phone_number',
      });
      setModalType('completeRegistration', { phone: response.phone_number, totp: response.totp, 'registration-type': 'phone_number' });
    } catch (e) { /* error was handled outside */ }
  };

  return (
    <>
      <div className={styles.title}>
        <h2>{t('auth:phoneConfirmation.title')}</h2>
        <div className={styles.subTitle}>
          {t('auth:phoneConfirmation.description', {
            phone: phoneValue,
          })}
        </div>
      </div>
      <div className={clsx(styles.centered, styles.centered_column)}>
        <CodeInput error={isCodeInvalid} onChange={setCode} value={code} />
        <div className={clsx(styles.error, {
          [styles.error_invisible]: !isCodeInvalid,
        })}
        >
          {t('auth:phoneConfirmation.incorrectCode')}
        </div>
      </div>
      <div>
        <Button
          disabled={isPending || code.length !== 6}
          onClick={handleSubmit}
          type="button"
          fullWidth
          appearance="primary"
          buttonColor="green"
          buttonSize="s"
        >
          {t('auth:register.continue')}
        </Button>
      </div>
      <div>
        <Button
          fullWidth
          onClick={handleResendCode}
          type="button"
          disabled={isPendingResendTotp || !!throttleSec}
          appearance="secondary"
          buttonColor="green"
          buttonSize="s"
        >
          {throttleSec
            ? t('auth:phoneConfirmation.resendCodeIn', { time: throttleSec })
            : t('auth:phoneConfirmation.resendCode')}
        </Button>
      </div>
    </>
  );
};
