import React from 'react';
import { SvgIcon } from '@/modules/shared/components';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useGlobalData } from '@/context/GlobalContext';
import styles from './posters-button.module.scss';

export const PostersButton = () => {
  const { lang } = useTranslation();
  const { homeCity } = useGlobalData();

  return (
    <Link
      href={`/${homeCity}/posters`}
      className={styles.posters_button}
      data-testid="events_page_button"
    >
      <SvgIcon
        name={lang === 'ru' ? 'logo-poster-1' : 'logo-poster-1-en'}
        className={styles.svg}
      />
    </Link>
  );
};
