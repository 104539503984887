import React from 'react';
import { SvgIcon } from '@/modules/shared/components';
import { useFormatDataPrice } from '@/modules/shared/hooks';
import { AnnouncementReviewData } from '../../../types';
import styles from '../../leave-feedback-modal.module.scss';

interface Props {
  adDetail: AnnouncementReviewData | undefined;
}

export const AdDescription = ({ adDetail }: Props) => {
  const priceDisplayFormat = useFormatDataPrice();

  if (!adDetail) {
    return null;
  }

  return (
    <div className={styles.ad_description}>
      <img
        src={adDetail.main_picture?.thumbnail || ''}
        alt="announcement"
        className={styles.image}
      />
      <div className={styles.ad_description_info}>
        <p className={styles.ad_description_title}>{adDetail.full_name}</p>
        <p className={styles.ad_description_description}>
          {adDetail.title}
          {' '}
          |
          {' '}
          {priceDisplayFormat({ priceType: adDetail.price_type, price: adDetail.price })}
          {adDetail.price_type === 'for_money' && <SvgIcon name="THB" />}
        </p>
      </div>
    </div>
  );
};
