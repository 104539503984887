import { useCallback, useState, useMemo } from 'react';
import { useSwipeable } from 'react-swipeable';
import { EntityImage } from '@/modules/shared/types';
import { SliderDots } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import s from './ad-card.module.scss';

type SliderProps = {
  images: EntityImage[];
  maxDots?: number;
};

export const Slider = ({ images, maxDots = 5 }: SliderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { isMobile } = useMatchMedia();

  const slicedImages = useMemo(
    () => (isMobile ? images.slice(0, maxDots) : images),
    [isMobile, images, maxDots],
  );

  const handlePrev = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? slicedImages.length - 1 : prevIndex - 1));
  }, [slicedImages]);

  const handleNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex === slicedImages.length - 1 ? 0 : prevIndex + 1));
  }, [slicedImages]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
  });

  const handleChange = useCallback((index: number) => {
    setCurrentIndex(index);
  }, []);

  return (
    <div
      className={s.slider_container}
    >
      <div className={s.slider_container__images} {...swipeHandlers}>
        <img
          src={slicedImages[currentIndex].image}
          loading="lazy"
          alt={`image ${currentIndex}`}
        />
      </div>
      <div className={s.slider_container__dots}>
        {slicedImages.map((el, i) => (
          <SliderDots
            key={el.id}
            value={i}
            onChange={handleChange}
            current={currentIndex}
            type="ad_card"
          />
        ))}
      </div>
    </div>
  );
};
