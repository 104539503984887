import React, { useCallback } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useMatchMedia } from '@/modules/shared/hooks';
import { Button } from '@/modules/shared/components';
import ErrorPage from './error-page.component';
import styles from './server-error-page.module.scss';

export const ServerErrorPage = () => {
  const { t } = useTranslation('');
  const { isMobile } = useMatchMedia();

  const handleReload = useCallback(() => {
    window.location.reload();
  }, []);

  const ActionButton = (
    <Button
      appearance="primary"
      buttonColor="blue"
      buttonSize={isMobile ? 's' : 'm'}
      className={isMobile ? styles.buttonS : styles.buttonM}
      onClick={handleReload}
    >
      {t('notFound:pageServerError.repeat')}
    </Button>
  );

  const Content = (
    <>
      <h3>{t('notFound:pageServerError.header')}</h3>
      <p>{t('notFound:pageServerError.description')}</p>
    </>
  );

  return (
    <ErrorPage button={ActionButton} content={Content}>
      <div className={styles.mascot}>
        <img src="/assets/mascots/rabbit-crying.svg" alt="Error" />
      </div>
    </ErrorPage>
  );
};
