import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import {
  UserAvatar,
  Tag,
  UserRating,
  UserCompanyRedirect,
} from '@/modules/shared/components';
import { UserData } from '@/modules/ads/types';
import { UserTypeEnum } from '@/modules/users';
import styles from './ad-profile-block.module.scss';

export const AdProfileBlock = ({ data }: { data: UserData }) => {
  const { t } = useTranslation('');
  let { avatar, full_name } = data;
  const {
    id,
    average_rating,
    created_at,
    user_type,
    ratings_count,
    company_id,
    company,
  } = data;

  const formatDate = (dateString) => {
    const [, month, year] = dateString.split('.');
    return `${t(`common:months.${parseInt(month, 10) - 1}`)} ${year}${t('common:year')}`;
  };

  const getReviewsTranslationKey = (count) => {
    if (count === 1) {
      return 'one';
    }
    if (count >= 2 && count <= 4) {
      return 'few';
    }
    return 'many';
  };

  if (UserTypeEnum.Company === user_type && company) {
    avatar = company.logo ? company.logo.thumbnail : '';
    full_name = company.name;
  }

  return (
    <UserCompanyRedirect data={{ user: id, company: company_id }}>
      <div className={styles.container}>
        <UserAvatar srcAvatar={avatar} userName={full_name} diameter="44px" />

        <div className={styles.contact}>
          <p>{full_name}</p>
          <Tag label={t(`common:${user_type}`)} type="statusS" />
        </div>

        <div className={styles.information}>
          <div className={styles.user}>
            <div className={styles.user_block}>
              <span className={styles.user_rating}>{average_rating}</span>
              <UserRating rating={average_rating} sizeStar="16px" />
            </div>
            <p className={styles.user_reviews}>
              {`${t(`common:reviews.${getReviewsTranslationKey(ratings_count)}`, { count: ratings_count })}`}
            </p>
          </div>

          <p className={styles.created}>
            {t('common:onBazaar')}
            {' '}
            <span>{formatDate(created_at)}</span>
          </p>
        </div>
      </div>
    </UserCompanyRedirect>
  );
};
