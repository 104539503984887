import useTranslation from 'next-translate/useTranslation';
import { Button, ModalDesktop, ModalMobile } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import s from './modal.module.scss';

type ConfirmEmailModalProps = {
  isOpen: boolean;
  onClose: () => void;
  email: string;
  onClick: () => void;
  handleChangeEmail: () => void;
};

export const ConfirmEmailModal = ({
  isOpen, onClose, email, onClick, handleChangeEmail,
}: ConfirmEmailModalProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();

  return (
    <>
      {isMobile ? (
        <ModalMobile
          open={isOpen}
          fullHeight
          headerBordered
          showCloseButton
          onClose={onClose}
          contentStyles={s.content}
        >
          <h3 className={s.h3}>{t('userAccount:confirm_email')}</h3>
          <p className={s.email_sent}>{t('userAccount:email_sent', { email })}</p>
          <Button
            appearance="secondary"
            buttonColor="green"
            buttonSize="xs"
            onClick={onClick}
            className={s.email_resend_button}
          >
            {t('userAccount:email_resend')}
          </Button>
        </ModalMobile>
      ) : (
        <ModalDesktop
          open={isOpen}
          onClose={onClose}
          title={t('userAccount:confirm_email')}
          titleJustifyContent="start"
        >
          <div className={s.content}>
            <p className={s.email_sent}>{t('userAccount:email_sent', { email })}</p>
            <Button
              appearance="secondary"
              buttonColor="green"
              buttonSize="xs"
              onClick={onClick}
              className={s.email_resend_button}
            >
              {t('userAccount:email_resend')}
            </Button>
            <div className={s.buttons}>
              <Button
                type="submit"
                appearance="primary"
                buttonColor="grey"
                buttonSize="s"
                onClick={handleChangeEmail}
              >
                {t('userAccount:email_change')}
              </Button>
            </div>
          </div>
        </ModalDesktop>
      )}
    </>
  );
};
