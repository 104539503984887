import { useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { ModalMobile } from '@/modules/shared/components';
import { Category, CategoryBase, SubCategory } from '@/modules/categories/types';
import { useCategoryName } from '@/modules/categories/hooks';
import { CategoryRow } from './category-row.component';
import { AllInThisCategory } from './all-in-this-category.component';
import styles from './switch-category.module.scss';
import { CategoryType } from './types';

interface Props {
  selectCategoryVisible: boolean;
  handleToggleSelectCategory: () => void;
  currentCategory: CategoryType;
  currentSubCategory: CategoryType;
  categories: Category[];
  handleClickBack: () => void;
  handleCategoryClick: (cat: Extract<CategoryType, CategoryBase>) => void;
  handleSubCategoryClick: (cat: Extract<CategoryType, CategoryBase>) => void;
}

export const SelectCategory = (props: Props) => {
  const {
    selectCategoryVisible,
    handleToggleSelectCategory,
    categories,
    currentCategory,
    currentSubCategory,
    handleClickBack,
    handleCategoryClick,
    handleSubCategoryClick,
  } = props;

  const { t } = useTranslation();
  const [getCategoryName] = useCategoryName();

  const modalTitle = useMemo(() => {
    if (!currentCategory) {
      return t('common:allCategories');
    }
    if (!currentSubCategory) {
      return getCategoryName(currentCategory.codename);
    }
    return getCategoryName(currentCategory.codename, currentSubCategory.codename);
  }, [currentCategory, currentSubCategory, getCategoryName, t]);

  return (
    <ModalMobile
      almostFullHeight
      onClose={handleToggleSelectCategory}
      open={selectCategoryVisible}
      title={modalTitle}
      showBackButton={Boolean(currentCategory || currentSubCategory)}
      onClickBack={handleClickBack}
    >
      <div className={styles.category_list}>
        { /* если не выбрана ни одна категория, показываем список всех категорий */ }
        {!currentCategory && !currentSubCategory && categories.map((cat) => (
          <CategoryRow
            key={cat.codename}
            translateKeys={[cat.codename]}
            onClick={() => handleCategoryClick(cat)}
          />
        ))}
        {currentCategory && !currentSubCategory && (
          <>
            <AllInThisCategory
              category={currentCategory!}
              onClick={handleToggleSelectCategory}
            />
            {(currentCategory as Category).sub_categories.map((cat: SubCategory) => {
              if (cat.under_sub_categories.length === 0) { // если нет вложенности, то должна быть ссылка
                return (
                  <CategoryRow
                    key={cat.codename}
                    asLink
                    href={cat.pathname}
                    onClick={handleToggleSelectCategory}
                    translateKeys={[currentCategory.codename, cat.codename]}
                  />
                );
              }
              return (
                <CategoryRow
                  key={cat.codename}
                  translateKeys={[currentCategory.codename, cat.codename]}
                  onClick={() => handleSubCategoryClick(cat)}
                />
              );
            })}
          </>
        )}
        {currentCategory && currentSubCategory && (
          <>
            <AllInThisCategory
              category={currentSubCategory}
              onClick={handleToggleSelectCategory}
            />
            {(currentSubCategory as SubCategory).under_sub_categories.map(
              (cat) => (
                <CategoryRow
                  key={cat.codename}
                  translateKeys={[
                    currentCategory.codename,
                    currentSubCategory.codename,
                    cat.codename,
                  ]}
                  href={cat.pathname}
                  asLink
                  onClick={handleToggleSelectCategory}
                />
              ),
            )}
          </>
        )}
      </div>
    </ModalMobile>
  );
};
