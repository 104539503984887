import React from 'react';

export const DefaultGoogleConsentMode = () => (
  <>
    {/* default setting of consent mode  */}
    {/* использовать именно script, а не NextScript */}
    <script
      id="gtag-init"
      dangerouslySetInnerHTML={{
        __html: ` 
            window.dataLayer = window.dataLayer || []; 
            function gtag() { dataLayer.push(arguments); } 
            const initConsentData = { 
                  ad_storage: 'denied', 
                  ad_user_data: 'denied', 
                  ad_personalization: 'denied', 
                  analytics_storage: 'denied', 
                  functionality_storage: 'denied', 
                  personalization_storage: 'denied', 
                  security_storage: 'granted', 
            }; 
     
            if (localStorage.getItem('consentMode') === null) { 
              gtag('consent', 'default', initConsentData); 
              localStorage.setItem('consentMode', JSON.stringify(initConsentData)); 
            } else { 
              gtag('consent', 'default', JSON.parse(localStorage.getItem('consentMode'))); 
            } 
          `,
      }}
    />
  </>
);
