import { NextPage } from 'next';
import { isServerSide } from '@/modules/shared/helpers';

import { CategoriesApi } from '../api';

export const withPreloadedCategories = (Component: NextPage): NextPage => {
  const Wrapper: NextPage = (props) => <Component {...props} />;
  // https://bazaar.youtrack.cloud/issue/bw-1147/Replace-getInitialProps-with-server-side-data-preloading-in-withPreloadedCategories-HOC
  Wrapper.getInitialProps = async () => {
    if (isServerSide()) {
      try {
        const preloadedCategories = (await CategoriesApi.list()).data;
        return { preloadedCategories };
      } catch {
        console.log('Не удалось подгрузить категории на стороне SSR');
      }
    }

    return {};
  };
  Wrapper.displayName = `withPreloadedCategories(${Component.displayName})`;

  return Wrapper;
};
