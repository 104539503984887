import useTranslation from 'next-translate/useTranslation';
import { useSearchParams } from 'next/navigation';
import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, InputPhone, Modal,
} from '@/modules/shared/components';
import {
  MemoryRouter, NavigateFunction, Route, Routes, useLocation,
} from 'react-router-dom';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { AuthApi } from '@/modules/auth/api';
import { toast } from 'react-toastify';
import { useCloseAuthModal, useOpenAuthModal } from '../../../../hooks';
import styles from '../../auth-modal.module.scss';
import { RouterListener } from '../router-listener.component';

import { Code } from './phone/code.component';

export const ForgotByPhone = () => {
  const { t } = useTranslation();
  const closeModal = useCloseAuthModal();
  const setModalType = useOpenAuthModal();
  const params = useSearchParams();

  const [router, setCurrentRouter] = useState<{
    location: ReturnType<typeof useLocation>,
    nav: NavigateFunction
  }>();

  const initialPhone = params.get('phone') || '';

  const schema = useMemo(() => yup
    .object({
      phone_number: yup.string()
        .required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.phoneNumber') }))
        .test('valid-phone', t('forms:validationErrors.invalidPhone'), (value) => {
          const phoneNumber = parsePhoneNumberFromString(value);
          return phoneNumber ? phoneNumber.isValid() : false;
        }),
    }), [t]);

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phone_number: initialPhone,
    },
  });
  const {
    control, handleSubmit, formState: { isSubmitting, errors },
  } = formMethods;

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    try {
      const response = await AuthApi.resetPasswordRequest({ phone_number: data.phone_number });
      if (response.status !== 'success') {
        throw new Error();
      }
      router?.nav(`/code?phone=${data.phone_number}`, { replace: true });
    } catch (error) {
      if (error.response.data.validation_errors.code.includes('USER_DOESNT_EXIST')) {
        toast(t('forms:validationErrors.phoneNotRegistered'), {
          type: 'error',
        });
      } else {
        toast(t('auth:forgotByPhone.commonError'), {
          type: 'error',
        });
      }
    }
  };

  return (
    <Modal
      open
      showBackButton={router?.location.pathname === '/'}
      onClickBack={() => setModalType('login')}
      onClose={closeModal}
      fullHeight
      title={<img src="/assets/icons/logo-main-medium.svg" alt="Logo Bazaar" />}
    >
      <FormProvider {...formMethods}>
        <MemoryRouter initialEntries={['/']}>
          <RouterListener onRouteChange={setCurrentRouter} />
          <Routes>
            <Route
              path="/"
              element={(
                <form className={styles.content} onSubmit={handleSubmit(onSubmit)}>
                  <div className={styles.title}><h2>{t('auth:forgotByPhone.title')}</h2></div>
                  <p className={styles.centeredText}>
                    {t('auth:forgotByPhone.description')}
                  </p>
                  <div>
                    <Controller
                      key="phoneNumber"
                      control={control}
                      name="phone_number"
                      render={({ field }) => (
                        <InputPhone
                          key="phoneNumberField"
                          error={!!errors.phone_number}
                          {...field}
                          international
                          fullWidth
                        />
                      )}
                    />
                    {errors.phone_number && <div className={styles.error}>{errors.phone_number.message}</div>}
                  </div>
                  <div>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      fullWidth
                      appearance="primary"
                      buttonColor="green"
                      buttonSize="s"
                    >
                      {t('auth:register.continue')}
                    </Button>
                  </div>
                </form>
            )}
            />
            <Route
              path="/code"
              element={<Code />}
            />
          </Routes>
        </MemoryRouter>
      </FormProvider>
    </Modal>
  );
};
