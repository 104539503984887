import React, { useCallback, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Tag } from '../tag/tag.component';
import { SvgIcon } from '../svg-icon/svg-icon.component';
import { InputText } from '../inputs/input-text/input-text.component';
import styles from './keyword-input.module.scss';

export const KeywordInput = () => {
  const [words, setWords] = useState<string[]>([]);
  const [value, setValue] = useState('');
  const { t } = useTranslation();

  const handleSetWord = useCallback(() => {
    setWords((prevWords) => {
      if (value.trim() && !prevWords.includes(value.trim())) {
        return [...prevWords, value.trim()];
      }
      return prevWords;
    });
    setValue('');
  }, [value]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleSetWord();
      }
    },
    [handleSetWord],
  );

  const handleRemoveWord = useCallback((index: number) => {
    setWords((prevWords) => prevWords.filter((_, i) => i !== index));
  }, []);

  return (
    <div className={styles.keyword_wrapper}>
      <label className={styles.label}>{t('common:keywordsTitle')}</label>
      <div className={styles.input_wrapper}>
        <InputText
          fullWidth
          value={value}
          onKeyDown={handleKeyDown}
          placeholder={t('common:keywords')}
          onChange={(e) => setValue(e.target.value)}
          postfix={
            value.length > 0 && (
              <SvgIcon
                name="plus-with-bg"
                className={styles.add}
                onClick={handleSetWord}
              />
            )
          }
        />
        <div className={styles.word_list}>
          {words.map((word, index) => (
            <Tag
              key={index}
              type="tagM"
              label={word}
              color="very-light-grey"
              className={styles.word_tag}
              icon={(
                <SvgIcon
                  name="close"
                  className={styles.remove}
                  onClick={() => handleRemoveWord(index)}
                />
              )}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
