import {
  useMutation,
  useQueryClient,
  QueryKey,
  QueryFilters,
  InvalidateQueryFilters,
  MutationFunction,
} from '@tanstack/react-query';
import { IListData, IContext } from './react-query.types';

export const createEntityMutationFactory = <
  InputType,
  OutputType,
  ListData extends IListData<OutputType>,
>(createApi: MutationFunction<OutputType, InputType>) => {
  const useCreateMutation = (queryKey: QueryKey) => {
    const queryClient = useQueryClient();
    return useMutation<OutputType, Error, InputType, IContext<ListData>>({
      mutationFn: createApi,
      onMutate: async (entity) => {
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryKey as QueryFilters);

        // Snapshot the previous value
        const defaultPrevious = { data: [] } as unknown as ListData;
        const previous = queryClient.getQueryData<ListData>(queryKey) || defaultPrevious;

        // Optimistically update to the new value
        queryClient.setQueryData(queryKey, {
          ...previous,
          data: [...previous.data, entity],
        });

        // Return a context object with the snapshotted value
        return { previous };
      },
      onError: (err, _, context) => {
        queryClient.setQueryData(queryKey, context?.previous);
      },
      onSuccess: () => queryClient.invalidateQueries(queryKey as InvalidateQueryFilters),
    });
  };

  return useCreateMutation;
};
