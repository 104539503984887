import React, { useCallback, useEffect, useState } from 'react';
import Link from 'next/link';
import { useMatchMedia } from '@/modules/shared/hooks';
import {
  LangSwitcherDesktop, LangSwitcher, SvgIcon, UserAvatar, CountNotification,
} from '@/modules/shared/components';
import { useLanguageMutation, useMeQuery } from '@/modules/me';
import { AuthProtectedLink, useAuthContext } from '@/modules/auth';
import { useFavoritesContext } from '@/modules/favorites';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import setLanguage from 'next-translate/setLanguage';
import { Locale } from '@/modules/shared/types';
import {
  BUSINESS_LINK, profileBasePath, VERIFIED_COMPANIES_LINK,
} from '@/modules/shared/const';
import { useUserType } from '@/modules/users/hooks';
import { UserTypeEnum } from '@/modules/users';
import { useChatDataContext } from '@/modules/chat/providers';
import { LocationClarification } from '../index-page';
import { HeaderProps, languages } from './types';
import s from './primary-header.module.scss';
import { ADD_EVENT_PATH } from './consts';


const getLanguage = (locale: Locale) => languages
  .find((language) => language.locale === locale) || languages[0];

const getLocaleFromLocalStorage = () => localStorage.getItem('locale') as Locale;

export const PrimaryHeader = ({
  showCityModal, setShowCityModal,
}: HeaderProps) => {
  const [lang, setLang] = useState(languages[0]);
  const [showModal, setShowModal] = useState(false);
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const { t } = useTranslation();
  const { mutate: updateUserLanguage } = useLanguageMutation();
  const { isAuthenticated } = useAuthContext();
  const { data: userData } = useMeQuery(isAuthenticated);
  const router = useRouter();
  const { type, companyData } = useUserType(userData?.user_type, userData?.id);
  const { unreadDialogsCount } = useChatDataContext();

  const { totalCount } = useFavoritesContext();

  const isUser = isAuthenticated ? `/${profileBasePath}/chat/support` : '/support/form';

  useEffect(() => {
    if (userData?.locale) {
      const userLanguage = getLanguage(userData?.locale);
      localStorage.setItem('locale', userLanguage?.locale);
      setLang(userLanguage);
    }

    const languageFromLocalStorage = getLocaleFromLocalStorage();
    if (!languageFromLocalStorage) {
      const browserLanguage = (navigator.language || navigator.userLanguage)
        .split('-')[0] as Locale;
      const userLanguage = getLanguage(browserLanguage);
      localStorage.setItem('locale', userLanguage?.locale || 'en');
      setLang(userLanguage);
    } else {
      const userLanguage = getLanguage(languageFromLocalStorage);
      if (userLanguage) setLang(userLanguage);
    }

    router.push(
      router.asPath,
      undefined,
      { locale: getLocaleFromLocalStorage(), scroll: false },
    );
  }, [userData]);

  const toggleLang = useCallback(async (newLang) => {
    setLang(newLang);
    localStorage.setItem('locale', newLang.locale);
    setShowModal(false);
    await setLanguage(newLang.locale);
    if (isAuthenticated) {
      await updateUserLanguage(newLang.locale);
    }
  }, [isAuthenticated, updateUserLanguage]);

  const isAddEventPath = router.pathname.includes(ADD_EVENT_PATH);

  return (
    <>
      <div className={s.primary_header} style={isAddEventPath && isMobile ? { display: 'none' } : {}}>
        {(isMobile || isTablet) && (
          <div className={s.mobile_header}>
            <div className={s.mobile_header_logo} data-testid="header-logo">
              <Link href="/">
                <SvgIcon name="logo-main-small" />
              </Link>
            </div>
            <div className={s.mobile_header_tools}>
              <LocationClarification isShowModal={showCityModal} onChange={() => setShowCityModal(!showCityModal)} />
              <LangSwitcher currentLang={lang} toggleLang={toggleLang} />
            </div>
          </div>
        )}
        {isDesktop && (
          <div className={s.desktop_header}>
            <div className={s.desktop_header_links}>
              <span className={s.desktop_header_lang}>
                <button type="button" onClick={() => setShowModal(!showModal)}>
                  {lang.label}
                  <SvgIcon name="arrow-down" />
                </button>
                {showModal && (
                  <LangSwitcherDesktop
                    isOpen={showModal}
                    currentLang={lang}
                    onClose={() => setShowModal(!showModal)}
                    toggleLang={toggleLang}
                  />
                )}
              </span>
              <ul>
                <Link href={VERIFIED_COMPANIES_LINK} target="_blank">
                  <li>{t('common:header.partners')}</li>
                </Link>
                <Link href={BUSINESS_LINK} target="_blank">
                  <li>{t('common:header.companies')}</li>
                </Link>
                <Link href={isUser}>
                  <li>{t('common:header.support')}</li>
                </Link>
              </ul>
            </div>
            <div className={s.desktop_header_bar}>
              <div className={s.desktop_header_bar_actions}>
                <AuthProtectedLink href={`/${profileBasePath}/favorites`} className={s.desktop_header_bar_actions_favourite}>
                  <SvgIcon name="header-favorite" />
                  <CountNotification
                    count={totalCount}
                    type="header"
                    className={s.desktop_header_bar_actions_favourite_count}
                    color="grey"
                  />
                </AuthProtectedLink>
                <AuthProtectedLink href={`/${profileBasePath}/chat`} className={s.desktop_header_bar_actions_chat}>
                  <SvgIcon name="header-chat" />
                  <CountNotification
                    count={unreadDialogsCount}
                    type="header"
                    className={s.desktop_header_bar_actions_chat_count}
                    color="red"
                  />
                </AuthProtectedLink>
                {userData ? (
                  <Link href={`/${profileBasePath}/my-ads`}>
                    <UserAvatar
                      userName={
                        type === UserTypeEnum.Company
                          ? (companyData?.name || '')
                          : (userData.full_name || userData.email)
                      }
                      srcAvatar={
                        type === UserTypeEnum.Company
                          ? (companyData?.logo?.thumbnail || '')
                          : userData.avatar
                      }
                      diameter="32px"
                    />
                    <p>{t('common:header.profile')}</p>
                  </Link>
                ) : (
                  <AuthProtectedLink href="/?auth-step=login">
                    <SvgIcon name="header-user" />
                    {t('common:header.login')}
                  </AuthProtectedLink>
                )}
              </div>
              {!isAddEventPath && (
                <AuthProtectedLink
                  customHeader="addAds"
                  href={`/${router.locale}/product/create`}
                >
                  <button className={s.desktop_header_bar_ad} data-testid="create_ad_button">
                    {t('common:header.placeAd')}
                  </button>
                </AuthProtectedLink>
              )}
            </div>
          </div>
        )}
      </div>
      <div id="email-notification-portal" style={{ width: '100vw' }} />
    </>
  );
};
