import { createContext, useContext } from 'react';

export const categoryCtx = createContext<{
  codenames: [
    category: string | undefined,
    subCategory: string | undefined,
    underSubCategory: string | undefined,
  ],
  path: string
}>({
  codenames: [undefined, undefined, undefined],
  path: '',
});


export const useCategoryCodenames = () => useContext(categoryCtx);
