import React from 'react';
import { useCreateAdContext } from '../../context';
import { Service } from './service.component';
import { PersonalItems } from './personal-items.component';
import { Transport } from './transport.component';
import { RealEstate } from './real-estate.component';

const additionalFields = {
  services: <Service />,
  'personal-items': <PersonalItems />,
  transport: <Transport />,
  'real-estate': <RealEstate />,
};

export const Details = () => {
  const { category } = useCreateAdContext();

  return additionalFields[category!.codename];
};

/** (! !) - значит, что поле зависит от adType (продаем или сдаем)
 * Недвижимость:
 *   Жилая:
 *     Апартаменты, Кондоминиум:
 *       состояние, (! срок аренды !), кол-во комнат, жилая площадь, (! право собственности, статус завершения !), дополнительные
 *     Дом, Вилла:
 *       состояние, (! срок аренды !), кол-во комнат, жилая площадь, площадь земельного участка, (! право собственности, статус завершения !), дополнительные
 *   Коммерческая:
 *     Состояние, коммерческая площадь, площадь земельного участка, (! право собственности, статус завершения !), дополнительные (разные для сдать/продать)
 *   Земельный участок:
 *     площадь земельного участка, (! право собственности !), разрешенное строительство
 *
 * ТРАНСПОРТ:
 *   Автомобили:
 *     состояние, (! срок аренды !), марка, год выпуска, пробег, тип двигателя, коробка передач, объем двигателя, цвет
 *   Мотоциклы:
 *     состояние, (! срок аренды !), марка, год выпуска, пробег, тип двигателя, коробка передач, объем двигателя, цвет
 *   Скутеры:
 *     состояние, (! срок аренды !), марка, год выпуска, пробег, тип двигателя, коробка передач, объем двигателя, цвет
 *   Воздушный транспорт:
 *     состояние, (! срок аренды !), цвет
 *   Водный транспорт:
 *     состояние, (! срок аренды !), цвет
 *   Грузовики и спецтехника:
 *     состояние, (! срок аренды !)
 *
 * ЛИЧНЫЕ ВЕЩИ:
 *   Женская одежда:
 *     состояние, размер, бренд, цвет
 *   Мужская одежда:
 *     состояние, размер, бренд, цвет
 *   Женская обувь:
 *     состояние, размер, бренд, цвет
 *   Мужская обувь:
 *     состояние, размер, бренд, цвет
 *   Детская одежда и обувь:
 *     состояние, размер, бренд, цвет
 *   Товары для детей и игрушки:
 *     состояние, цвет
 *   Красота и здоровье:
 *     состояние, цвет
 *   Аксессуары:
 *     состояние, пол, бренд, цвет
 *
 * УСЛУГИ:
 *   Грузоперевозки:
 *     грузчики
 */
