import React, {
  FC, PropsWithChildren, createContext, useContext, useMemo,
} from 'react';
import { noop } from 'lodash';
import { useMyAds } from '../../hooks';

interface IMyInactiveAdsPageContext {
  activateAd: (id: number) => void;
}
export const MyInactiveAdsPageContext = createContext<IMyInactiveAdsPageContext>({
  activateAd: noop,
});
export const useMyInactiveAdsPageContext = () => useContext(MyInactiveAdsPageContext);

export const MyInactiveAdsPageProvider: FC<PropsWithChildren> = ({ children }) => {
  const { activateMyAd } = useMyAds();

  const value = useMemo<IMyInactiveAdsPageContext>(() => ({
    activateAd: (id: number) => activateMyAd(id),
  }), [activateMyAd]);

  return (
    <MyInactiveAdsPageContext.Provider value={value}>
      {children}
    </MyInactiveAdsPageContext.Provider>
  );
};
