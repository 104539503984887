import {
  ChangeEvent, Dispatch, SetStateAction, useCallback,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import { InputText } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import s from '../modal.module.scss';

type InputEmailProps = {
  value: string;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  setValue: Dispatch<SetStateAction<string>>;
  onBlur?: () => void;
  onFocus?: () => void;
};

export const InputEmail = ({
  value, setValue, error, setError, onBlur, onFocus,
}: InputEmailProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();

  const handleBlur = useCallback(() => {
    if (isMobile && onBlur) onBlur();
    setError('');
    if (!value.trim().match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setError(t('forms:validationErrors.email_format'));
    }
  }, [value, isMobile, onBlur]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setError('');
    setValue(e.target.value);
  };

  return (
    <div className={s.input_wrapper}>
      <InputText
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={() => onFocus?.()}
        placeholder={t('userAccount:enter_email')}
        style={error ? { borderColor: 'var(--bzr-secondary-error)' } : {}}
      />
      {error && <p className={s.error}>{error}</p>}
    </div>
  );
};
