import { SetStateAction } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { InputText } from '@/modules/shared/components';
import s from '../modal.module.scss';

type PasswordInputProps = {
  value: string;
  onChange: (value: SetStateAction<string>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

export const PasswordInput = ({
  value, onChange, onFocus, onBlur,
}: PasswordInputProps) => {
  const { t } = useTranslation();

  return (
    <InputText
      fullWidth
      type="password"
      autoComplete="off"
      placeholder={t('auth:label.password')}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={s.password_input}
      eyeVisible
      onFocus={() => onFocus?.()}
      onBlur={() => onBlur?.()}
    />
  );
};
