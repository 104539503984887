import React, { useState } from 'react';
import { NextSeo } from 'next-seo';
import SearchTable from '@/components/shared/layout/Inputs/SearchTable';
import useTranslation from 'next-translate/useTranslation';
import s from '@/styles/pages/UserAdvs.module.scss';
import { UserAdsTable } from '../user-ads-table';
import { ActionColumn } from './action-column.component';
import { MyInactiveAdsPageProvider } from './my-inactive-ads-page.provider';

export const MyInactiveAds = () => {
  const [search, setSearch] = useState('');
  const { t } = useTranslation('adv');

  return (
    <MyInactiveAdsPageProvider>
      <div className={s.block_personal_advs}>
        <NextSeo title={t('personalAds.hiddenAdsTitle')} />
        <div className={s.personal_advs_title}>
          <h2>{t('personalAds.hiddenAdsTitle')}</h2>
          <SearchTable value={search} onSearch={setSearch} />
        </div>
        <UserAdsTable
          search={search}
          mode="inactive"
          ActionColumnComponent={ActionColumn}
        />
      </div>
    </MyInactiveAdsPageProvider>
  );
};
