import { MeApi } from '@/modules/me/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUpdatePhoneNumber = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: MeApi.updatePhoneNumber,
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: ['me'],
      });
    },
    onError: () => {},
  });
};
