import { api } from '@/modules/shared/api';
import { endpoints } from './endpoints';

export const eventFavoritesApi = {
  getFavoriteEvents: async () => {
    const { data } = await api.get(endpoints.getFavoriteEvents());
    return data;
  },

  addFavoriteEvent: async (id: number) => {
    const { data } = await api.post(endpoints.addFavoriteEvent(id));
    return data;
  },

  deleteFavoriteEvent: async (id: number) => {
    const { data } = await api.delete(endpoints.deleteFavoriteEvent(id));
    return data;
  },
};
