import {
  useMutation,
  useQueryClient,
  QueryKey,
  QueryFilters,
  InvalidateQueryFilters,
  MutationFunction,
} from '@tanstack/react-query';
import { IListData, IContext, EntityIdGetter } from './react-query.types';

const defaultGetEntityId: EntityIdGetter<unknown> = (entity) => (entity as { id: string }).id;

/**
 *
 * @param {*} destroyApi
 * @param {(any) => string} getEntityId
 */
export const destroyMutationFactory = <
  Entity,
  ListData extends IListData<Entity>,
>(
    destroyApi: MutationFunction<Entity, Entity>,
    getEntityId: EntityIdGetter<Entity> = defaultGetEntityId,
  ) => {
  const useDestroyMutation = (queryKey: QueryKey) => {
    const queryClient = useQueryClient();
    return useMutation<Entity, Error, Entity, IContext<ListData>>({
      mutationFn: destroyApi,
      onMutate: async (entity) => {
        await queryClient.cancelQueries(queryKey as QueryFilters);

        const id = getEntityId(entity);
        const defaultPrevious = { data: [] } as unknown as ListData;
        const previous = queryClient.getQueryData<ListData>(queryKey) || defaultPrevious;

        queryClient.setQueryData(queryKey, {
          ...previous,
          data: previous.data.filter((item) => id !== getEntityId(item)),
        });

        return { previous };
      },
      onError: (err, _, context) => {
        queryClient.setQueryData(queryKey, context?.previous);
      },
      onSuccess: () => queryClient.invalidateQueries(queryKey as InvalidateQueryFilters),
    });
  };

  return useDestroyMutation;
};
