import { useMemo } from 'react';
import { useSearchParams } from 'next/navigation';
import { camelCase } from 'lodash';
import { modalTypeParam } from '../../consts';


export const useModalType = () => {
  const searchParams = useSearchParams();
  const modalType = searchParams.get(modalTypeParam);
  return useMemo(() => camelCase(modalType || ''), [modalType]);
};
