import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';
import { AuthProtectedLink } from '@/modules/auth';
import { useMatchMedia } from '@/modules/shared/hooks';
import { IEvent } from '@/modules/events';
import { useUpdateEffect } from '@react-hookz/web';
import { useCityPrefix } from '@/context/CityPrefixContext';
import { EventsApi } from '@/modules/events/api';
import { PostersButton } from './posters-button.component';
import styles from './event-block.module.scss';

const EventBanner = dynamic(
  () => import('./event-banner.component').then((mod) => mod.default),
  { ssr: true },
);

const imagePath = {
  en: {
    mobile: '/assets/images/no_events_en_mobile.jpg',
    desktop: '/assets/images/no_events_en_desktop.jpg',
  },
  ru: {
    mobile: '/assets/images/no_events_ru_mobile.jpg',
    desktop: '/assets/images/no_events_ru_desktop.jpg',
  },
};

export const EventBlock = ({ events }: { events: IEvent[] | [] }) => {
  const [eventList, setEventList] = useState<IEvent[] | []>(events);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { cityId } = useCityPrefix();
  const { isDesktop } = useMatchMedia();
  const { lang } = useTranslation();
  const [backgroundImage, setBackgroundImage] = useState('');

  useUpdateEffect(() => {
    const refetchEvents = async () => {
      setIsFetching(true);
      const filters = cityId ? { city: cityId } : '';

      const { results: updatedEvents } = await EventsApi.list({
        page: 1,
        pageSize: 5,
        order: ['event_date', 'event_time'],
        filters,
      });

      return updatedEvents;
    };

    refetchEvents()
      .then((data) => setEventList(data))
      .catch(() => setEventList(events))
      .finally(() => setIsFetching(false));
  }, [cityId]);

  useEffect(() => {
    if ((!isFetching && eventList && !!eventList.length) || !lang) return;

    setBackgroundImage(
      isDesktop ? imagePath[lang]?.desktop : imagePath[lang]?.mobile,
    );
  }, [isFetching, eventList, isDesktop, lang]);

  return (
    <section className={styles.event_block}>
      <PostersButton />
      {!isFetching && eventList && eventList.length > 0
        ? <EventBanner eventList={eventList} />
        : (
          <AuthProtectedLink
            href="/events/addevents"
            className={styles.no_events}
            style={{ backgroundImage: `url(${backgroundImage})` }}
          />
        )}
    </section>
  );
};
