import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  FC,
  ReactNode,
  useCallback,
} from 'react';
import Cookie from 'js-cookie';
import { toast } from 'react-toastify';
import useTranslation from 'next-translate/useTranslation';
import { useAuthContext } from '@/modules/auth';
import type { ChatList, ChatSupportList } from '../types';


interface ChatDataContextProps {
  chatList: ChatList[];
  unreadDialogsCount: number;
  isLoading: boolean;
  hasMore: boolean;
  chatSupportList: ChatSupportList[];
  fetchMore: () => void;
  sendChatList: (queryParams?: Record<string, string | number>, page?: number) => void;
  deleteChat: (deleteIds: number[]) => void;
}

const ChatDataContext = createContext<ChatDataContextProps>({
  chatList: [],
  unreadDialogsCount: 0,
  isLoading: false,
  hasMore: false,
  chatSupportList: [],
  fetchMore: () => {},
  sendChatList: () => {},
  deleteChat: () => {},
});

export const ChatDataProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuthContext();
  const { t } = useTranslation('');

  const [chatList, setChatList] = useState<ChatList[]>([]);
  const [unreadDialogsCount, setUnreadDialogsCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [chatSupportList, setChatSupportList] = useState<ChatSupportList[]>([]);
  const [currentQueryParams, setCurrentQueryParams] = useState<Record<string, string | number>>({});
  const socketRef = useRef<WebSocket | null>(null);
  const isFetchingRef = useRef(false);

  const sendChatList = useCallback((queryParams = {}, page = 1) => {
    if (socketRef.current?.readyState === WebSocket.OPEN) {
      setIsLoading(true);
      setCurrentQueryParams(queryParams);
      isFetchingRef.current = true;

      socketRef.current.send(
        JSON.stringify({
          source: 'chat.list',
          query_params: queryParams,
          page_size: 20,
          page,
        }),
      );
    }
  }, []);

  const deleteChat = useCallback((deleteIds: number[]) => {
    if (socketRef.current?.readyState === WebSocket.OPEN) {
      socketRef.current.send(
        JSON.stringify({
          source: 'chat.delete',
          delete_ids: deleteIds,
        }),
      );
    }
  }, []);

  const fetchMore = useCallback(() => {
    if (!hasMore || isLoading || isFetchingRef.current) return;

    const nextPage = currentPage + 1;
    sendChatList(currentQueryParams, nextPage);
  }, [hasMore, isLoading, currentPage, currentQueryParams, sendChatList]);

  useEffect(() => {
    if (!isLoading) {
      isFetchingRef.current = false;
    }
  }, [isLoading]);

  const initializeWebSocket = useCallback(() => {
    if (!isAuthenticated) return;

    const accessToken = Cookie.get('access_token') || '';
    const socket = new WebSocket(
      `${process.env.NEXT_PUBLIC_WS_API_URL}/chat/?token=${accessToken}`,
    );

    socket.onopen = () => {
      // Отправляем запрос на получение количества непрочитанных диалогов
      socket.send(JSON.stringify({ source: 'unread_dialogs.count' }));

      // Отправляем запрос на получение данных чата с техподдержкой
      socket.send(JSON.stringify({ source: 'chat.support.list' }));
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.source === 'unread_dialogs.count') {
        setUnreadDialogsCount(data.data.unread_chats_count);
      } else if (data.source === 'chat.support.list') {
        if (data.data.results?.[0]?.unread_count) {
          setUnreadDialogsCount((prev) => prev + 1);
        }
        setChatSupportList(data.data.results);
      } else if (data.source === 'chat.list') {
        const newChats = data.data.results;
        setChatList((prevChatList) => {
          if (data.data.page === 1) return newChats;

          // Для остальных страниц, обновляем список с новыми сообщениями
          const updatedChatList = [...prevChatList];
          newChats.forEach((newChat) => {
            const existingChatIndex = updatedChatList.findIndex((chat) => chat.id === newChat.id);
            if (existingChatIndex !== -1) {
              // Если чат уже существует, обновляем его
              updatedChatList[existingChatIndex] = newChat;
            } else {
              // Если чат новый, добавляем его в список
              updatedChatList.push(newChat);
            }
          });

          return updatedChatList;
        });


        setHasMore(data.data.page < data.data.total_pages);
        setCurrentPage(data.data.page);
        setIsLoading(false);
      } else if (data.source === 'chat.delete') {
        const statusDeletedIds = data.data.status;

        if (statusDeletedIds === 'success') {
          sendChatList(currentQueryParams, currentPage);
        } else {
          toast.error(t('common:validationErrors.unknownError'));
        }
      }
    };

    socket.onerror = (error) => {
      setIsLoading(false);
      console.error('Tech. webSocket connection error', error);
    };
    socket.onclose = (err) => {
      console.log('Tech. websocket close', err);
      setIsLoading(false);
    };

    socketRef.current = socket;
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      initializeWebSocket();
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [isAuthenticated, initializeWebSocket]);

  return (
    <ChatDataContext.Provider value={{
      chatList,
      unreadDialogsCount,
      isLoading,
      hasMore,
      chatSupportList,
      sendChatList,
      fetchMore,
      deleteChat,
    }}
    >
      {children}
    </ChatDataContext.Provider>
  );
};

export const useChatDataContext = () => useContext(ChatDataContext);
