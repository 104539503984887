import { getActivationEmail, getCurrentTimestamp, getNextEmailResendTimestamp } from '@/modules/shared/helpers';

export const useActivationTimer = () => {
  const nextEmailResendTimestamp = getNextEmailResendTimestamp(getActivationEmail());
  const currentTimestamp = getCurrentTimestamp();

  const activationTimer = Math.max(nextEmailResendTimestamp - currentTimestamp, 0);
  const isButtonDisabled = currentTimestamp < nextEmailResendTimestamp;


  return { activationTimer, isButtonDisabled };
};
