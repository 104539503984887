// event_date: '26.05.2024'; event_time: '23:20:00'

interface EventDateTimeProps {
  event_date: string;
  event_time: string | null;
}

export const showActivateButton = ({ event_date, event_time }: EventDateTimeProps): boolean => {
  const now = Date.now();

  const [day, month, year] = event_date.split('.').map(Number);
  const [hours, minutes, seconds] = event_time ? event_time.split(':').map(Number) : [0, 0, 0];


  const eventTimestamp = new Date(year, month - 1, day, hours, minutes, seconds).getTime();

  return eventTimestamp > now;
};
