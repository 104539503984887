import useTranslation from 'next-translate/useTranslation';
import { Button } from '@/modules/shared/components';
import { BUSINESS_LINK } from '@/modules/shared/const';
import s from './become-company.module.scss';

export const Verification = () => {
  const { t } = useTranslation('userAccount');

  return (
    <section className={s.verification}>
      <h2>{t('become_company.verification_header')}</h2>
      <p>{t('become_company.verification_info')}</p>
      <Button
        appearance="primary"
        buttonColor="green"
        buttonSize="s"
        as="link"
        href={BUSINESS_LINK}
        target="_blank"
        className={s.verification_button}
      >
        {t('become_company.find_out_more')}
      </Button>
    </section>
  );
};
