import React from 'react';
import { useSearchParams } from 'next/navigation';

import { ForgotByEmail } from './components/forgot/by-email.component';
import { ForgotByPhone } from './components/forgot/by-phone.component';

export const ForgotModal = () => {
  const params = useSearchParams();

  return params.get('email') ? <ForgotByEmail /> : <ForgotByPhone />;
};
