import React from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { Button } from '@/modules/shared/components';
import styles from '../../leave-feedback-modal.module.scss';

interface Props {
  setStep: React.Dispatch<React.SetStateAction<1 | 2 | 3>>;
  isConverting: boolean;
}

export const Buttons = ({ setStep, isConverting }: Props) => {
  const { t } = useTranslation('common');
  const { errors, isSubmitting } = useFormState();
  const rating = useWatch({ name: 'rating' });

  return (
    <div className={styles.buttons}>
      <Button
        appearance="primary"
        buttonColor="grey"
        buttonSize="s"
        onClick={() => setStep(1)}
        disabled={isSubmitting}
      >
        {t('review:back')}
      </Button>
      <Button
        appearance="primary"
        buttonColor="blue"
        buttonSize="s"
        type="submit"
        disabled={!rating || isSubmitting || isConverting || Boolean(errors.photos)}
      >
        {isSubmitting ? t('common:submitting') : t('common:submit')}
      </Button>
    </div>
  );
};
