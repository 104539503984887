import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReviewFormTypes } from '../types';
import { FEEDBACK, GIVEN_REVIEW_LIST } from '../consts';
import { ReviewApi } from '../api';

export const useCreateReviewMutation = () => {
  const queryClient = useQueryClient();

  const { isPending, mutateAsync, isSuccess } = useMutation({
    mutationKey: [FEEDBACK],
    mutationFn: (reviewData: ReviewFormTypes) => ReviewApi.createReview(reviewData),
    onSuccess: async () => queryClient.invalidateQueries({
      queryKey: [GIVEN_REVIEW_LIST],
    }),
  });

  return {
    mutateAsync,
    isPending,
    isSuccess,
  };
};
