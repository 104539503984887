import useTranslation from 'next-translate/useTranslation';
import { useFormContext } from 'react-hook-form';
import { useMatchMedia } from '@/modules/shared/hooks';
import s from './add-event-page.module.scss';
import { AddEventType } from '../../types';
import { TYPES } from '../../consts';

export const EventType = () => {
  const { register, watch, formState: { errors } } = useFormContext<AddEventType>();
  const { t } = useTranslation('events');
  const radio = watch('event_type');
  const { isMobile } = useMatchMedia();

  return (
    <div className={s.field}>
      {!isMobile && <h2>{t('category._')}</h2>}
      <div className={s.field_inner}>
        <p className={s.type_header}>
          {t(`category.${isMobile ? 'select_type' : '_'}`)}
          <span> *</span>
        </p>
        <div className={s.values}>
          {TYPES.map((el) => (
            <label key={el} style={radio === el ? { backgroundColor: '#EFEFEF' } : {}}>
              <input
                type="radio"
                value={el}
                {...register('event_type', { required: true })}
              />
              <span>{t(`category.${el}`)}</span>
            </label>
          ))}
        </div>
        {errors.event_type && (
          <p className={s.error}>{errors.event_type['0'] || errors.event_type.message as string}</p>
        )}
      </div>
    </div>
  );
};
