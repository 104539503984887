import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Notification } from '@/modules/shared/components';
import { profileBasePath } from '@/modules/shared/const';
import { useMatchMedia } from '@/modules/shared/hooks';
import { Me } from '@/modules/me/types';
import { ConfirmEmailNotification } from './confirm-email-notification';

interface Props {
  userProfile: Me | undefined;
}

/**
 * Компонент, который будет отображать уведомление о необходимости
 * подтвердить/привязать почту в̲ ̲л̲и̲ч̲н̲о̲м̲ ̲к̲а̲б̲и̲н̲е̲т̲е̲,̲ ̲к̲р̲о̲м̲е̲ ̲ч̲а̲т̲а̲
 * */

export const EmailNotification = ({ userProfile }: Props) => {
  const { t, lang } = useTranslation('common');
  const { isDesktop } = useMatchMedia();

  if (!userProfile) return null;

  const { email, is_email_verified } = userProfile;

  // текст на мобильном и компьютерах будет отличаться, но ссылка одна и та же
  const mainText = isDesktop
    ? t('emailNotification.accessToPlatformText')
    : t('emailNotification.bindEmail');

  const link = isDesktop
    ? t('emailNotification.accessToPlatformLink')
    : t('emailNotification.bindEmailLink');

  return (
    <>
      {email ? (
        // если почта указана, но не подтверждена, показываем просьбу подтвердить почту
        !is_email_verified && <ConfirmEmailNotification email={email} />
      ) : (
        <Notification
          mainText={mainText}
          link={link}
          href={`/${lang}/${profileBasePath}/account?email=add`}
        /> // если почта не указана, просим указать
      )}
    </>
  );
};
