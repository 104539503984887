import React from 'react';
import { SvgIcon } from '@/modules/shared/components';
import { useAuthContext } from '@/modules/auth';
import { useOpenAuthModal } from '@/modules/auth/hooks';

export type ContactsPosterProps = {
  phone_number?: string,
  telegram?: string,
  instagram?: string,
  whatsapp?: string,
};

export const useContactsPoster = ({
  phone_number, telegram, instagram, whatsapp,
}: ContactsPosterProps) => {
  const { isAuthenticated } = useAuthContext();
  const openAuthModal = useOpenAuthModal();

  const handleClick = (event) => {
    if (!isAuthenticated) {
      event.preventDefault();
      openAuthModal('login');
    }
  };

  const contacts: JSX.Element[] = [];

  if (phone_number) {
    contacts.push(
      <a
        href={isAuthenticated ? `tel:${phone_number}` : undefined}
        key="phone"
        onClick={handleClick}
      >
        <SvgIcon name="phone-bg" />
      </a>,
    );
  }

  if (telegram) {
    const telegramUrl = telegram.includes('https://t.me')
      ? telegram
      : `https://t.me/${telegram}`;

    contacts.push(
      <a
        href={isAuthenticated ? telegramUrl : undefined}
        target="_blank"
        rel="noreferrer"
        key="telegram"
        onClick={handleClick}
      >
        <SvgIcon name="telegram" />
      </a>,
    );
  }

  if (instagram) {
    const instagramUrl = instagram.includes('https://www.instagram')
      ? instagram
      : `https://www.instagram.com/${instagram}`;

    contacts.push(
      <a
        href={isAuthenticated ? instagramUrl : undefined}
        target="_blank"
        rel="noreferrer"
        key="instagram"
        onClick={handleClick}
      >
        <SvgIcon name="instagram" />
      </a>,
    );
  }

  if (whatsapp) {
    const whatsAppUrl = whatsapp.includes('https://wa.me')
      ? whatsapp
      : `https://wa.me/${whatsapp}`;

    contacts.push(
      <a
        href={isAuthenticated ? whatsAppUrl : undefined}
        target="_blank"
        rel="noreferrer"
        key="whats-app"
        onClick={handleClick}
      >
        <SvgIcon name="whats-app" />
      </a>,
    );
  }

  return contacts.length > 0 ? contacts : null;
};
