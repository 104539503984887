import React, { SetStateAction } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Scrollbar } from '@/modules/shared/components';
import { Category } from '@/modules/categories';
import { CategoryLabel } from '../../category-label';
// import { CategoryTypes } from '../../types';
import styles from '../category-list.module.scss';

interface Props {
  categories: Category[];
  setStep: React.Dispatch<SetStateAction<number>>;
  setActiveCategory: React.Dispatch<React.SetStateAction<Category | null>>
}

export const CategoryListStepOne = ({ categories, setStep, setActiveCategory }: Props) => {
  const { t } = useTranslation('');

  const handleClick = (category: Category) => {
    setActiveCategory(category);
    setStep(2);
  };

  return (
    <div className={styles.list}>
      <Scrollbar>
        <h4>{t('common:categories')}</h4>
        <div className={styles.content}>
          {categories.map((value, index) => (
            <div
              key={index}
              data-testid={`section_${value.codename}`}
              onClick={() => handleClick(value)}
            >
              <CategoryLabel
                codename={value.codename}
                icon={value.icon}
              />
            </div>
          ))}
        </div>
      </Scrollbar>
    </div>
  );
};
