import useTranslation from 'next-translate/useTranslation';
import { useFormContext } from 'react-hook-form';
import { InputText, SvgIcon } from '@/modules/shared/components';
import s from './add-event-page.module.scss';
import { AddEventType } from '../../types';

export const TicketLink = () => {
  const { register, formState: { errors } } = useFormContext<AddEventType>();
  const { t } = useTranslation('events');

  return (
    <div className={s.field_inner}>
      <p>{t('createEvents.tickets.title')}</p>
      <div className={s.values}>
        <InputText
          fullWidth
          placeholder={t('createEvents.tickets.placeholder')}
          {...register('ticket_purchase_link')}
        />
        <SvgIcon name="link" />
        {errors.ticket_purchase_link && (
          <p className={s.error}>
            {errors.ticket_purchase_link['0'] || errors.ticket_purchase_link.message as string}
          </p>
        )}
      </div>
    </div>
  );
};
