import React from 'react';
import Image from 'next/image';
import clsx from 'clsx';
import { IAd } from '@/modules/ads/types';
import { SvgIcon, AppTooltip } from '@/modules/shared/components';
import { FavoriteButton } from '@/modules/favorites';
// FIXME: надо пофиксить циклические зависимости :((( сделаю в следующем PR
// задача https://bazaar.youtrack.cloud/issue/bw-1938/FE-Resolve-cyclic-dependencies
import { AdLink } from '@/modules/ads/components/ad-link';
import { useFormatDataPrice, useMatchMedia } from '@/modules/shared/hooks';
import { useTranslateLocation } from '@/modules/locations';
import { Labels } from './labels.component';
import { Slider } from './slider.component';
import styles from './ad-card.module.scss';

export type AdCardProps = {
  ad: IAd;
  extendedCard?: boolean;
  isSellerPage?: boolean;
};

export const AdCard = ({ extendedCard = false, ad, isSellerPage = false }: AdCardProps) => {
  const { isMobile } = useMatchMedia();
  const {
    id, title, price, city, district, images, description, created_at, price_type, user_data,
  } = ad;

  const priceDisplayFormat = useFormatDataPrice();
  const translatedLocation = useTranslateLocation(
    city?.codename,
    district?.codename,
  );

  const getImageSrc = (() => {
    if (!images && typeof ad.main_picture === 'object' && ad.main_picture !== null) {
      return ad.main_picture.image;
    }
    return images?.[0]?.image;
  })();

  return (
    <>
      {!extendedCard && (
      <div className={clsx(styles.ad_card, {
        [styles.ad_card__seller]: isSellerPage,
      })}
      >
        <AdLink ad={ad}>
          {(images && images.length > 1) ? (
            <Slider images={images} />
          ) : (
            <img
              className={styles.image}
              src={getImageSrc}
              alt="announcement image"
              loading="lazy"
            />
          )}
        </AdLink>
        <div className={styles.description}>
          <p className={styles.price}>
            {priceDisplayFormat({ priceType: price_type, price })}
            {price_type === 'for_money' && <SvgIcon name="THB-20-semibold" />}
          </p>
          <AdLink ad={ad} className={styles.title}>
            <AppTooltip title={title}>
              <h4>{title}</h4>
            </AppTooltip>
          </AdLink>
          <p className={styles.location}>
            {translatedLocation}
          </p>
        </div>
        {!isSellerPage && <Labels userData={user_data} />}
        <div className={styles.svg_wrapper}>
          <FavoriteButton id={id} type="ads" variant="modern" />
        </div>
      </div>
      )}

      {extendedCard && (
        <div className={styles.extendedCard}>
          <div className={styles.extendedCard_img}>
            <AdLink ad={ad}>
              <Image
                src={getImageSrc}
                alt="announcement image"
                width={227}
                height={141}
                className={styles.image}
              />
            </AdLink>
          </div>

          <div className={styles.extendedCard_info}>
            <div className={styles.extendedCard_info_header}>
              <AdLink ad={ad} className={styles.title}>
                <h3>{title}</h3>
              </AdLink>
              <p className={styles.extendedCard_info_header_price}>
                {priceDisplayFormat({ priceType: price_type, price })}
                {price_type === 'for_money' && <SvgIcon name="THB" />}
              </p>
            </div>

            <p className={styles.extendedCard_info_description}>
              {description}
            </p>

            <div className={styles.extendedCard_info_footer}>
              <div className={styles.extendedCard_info_footer_location}>
                <SvgIcon name="geo-point" />
                <p>
                  {translatedLocation}
                </p>
              </div>

              <p className={styles.extendedCard_info_footer_date}>
                {created_at}
              </p>
            </div>

          </div>

          <div className={clsx(styles.svg_wrapper, {
            [styles.svg_wrapper_static]: !isMobile,
          })}
          >
            <FavoriteButton id={id} type="ads" />
          </div>
        </div>
      )}
    </>
  );
};
