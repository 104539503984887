import { useState, useCallback, useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useCreateAdActionsContext, useCreateAdContext } from '../context';
import { ReturnedType, Progressbar, PossibleKeys } from '../types';

export const useProgressbar = (): ReturnedType => {
  const { t } = useTranslation('createAd');
  const { isAdditionalSpecifications } = useCreateAdContext();
  const { setMainStep } = useCreateAdActionsContext();

  const [progressbar, setProgressbar] = useState<ReturnedType>({
    2: {
      id: 2, // контактная информация
      title: t('progressbar.contactInformation'),
      goBack: () => {},
      goForward: () => {},
    },
    3: {
      id: 3, // Если нет Дополнительных характеристик - пустой объект-заглушка. Либо Описание и фотографии
      title: isAdditionalSpecifications ? t('progressbar.description') : '',
      goBack: () => {},
      goForward: () => {},
    },
    4: {
      id: 4, // Дополнительные характеристики либо Описание и фотографии
      title: isAdditionalSpecifications ? t('progressbar.details') : t('progressbar.description'),
      goBack: () => {},
      goForward: () => {},
    },
  });

  const updateProgressbar = useCallback((key: PossibleKeys, newValues: Partial<Progressbar>) => {
    setProgressbar((prev) => ({
      ...prev,
      [key]: { ...prev[key], ...newValues },
    }));
  }, []);

  useEffect(() => {
    if (isAdditionalSpecifications) {
      // mainStep === 1 => выбор категории
      // mainStep === 2 => контактная информация
      // mainStep === 3 => описание и фотографии
      // mainStep === 4 => дополнительные характеристики
      updateProgressbar(2, { // обновляем контактную информацию
        goBack: () => setMainStep(1),
        goForward: () => setMainStep(3),
      });

      updateProgressbar(3, { // обновляем описание и фотографии
        goBack: () => setMainStep(2),
        goForward: () => setMainStep(4),
      });

      updateProgressbar(4, { // обновляем дополнительные характеристики
        goBack: () => setMainStep(3),
      });
    } else {
      // mainStep === 1 => выбор категории
      // mainStep === 2 => контактная информация
      // mainStep === 3 => пустой, пропускается, если нет дополнительных характеристик
      // mainStep === 4 => описание и фотографии
      updateProgressbar(2, { // обновляем контактную информацию
        goBack: () => setMainStep(1),
        goForward: () => setMainStep(4),
      });

      updateProgressbar(4, { // обновляем описание и фотографии
        id: 4,
        goBack: () => setMainStep(2),
        goForward: () => {},
      });
    }
  }, [isAdditionalSpecifications, updateProgressbar]);

  return progressbar;
};
