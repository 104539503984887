import { ChangeEvent, useCallback, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  FORMATS, MAX_SIZE_BYTES, MAX_SIZE_PARAM, MIN_SIZE_BYTES, MIN_SIZE_PARAM,
} from '@/modules/shared/const';
import { ItemLoader, SvgIcon } from '@/modules/shared/components';
import { useImageUploader } from '@/modules/shared/hooks';
import { EntityImage } from '@/modules/shared/types';
import s from '../credentials.module.scss';
import styles from './company-credentials.module.scss';
import { CompanyCredentialsForm } from '../types';

type ChosenBanners = {
  file: File;
  url: string;
};

type BannersProps = {
  companyBanners: EntityImage[] | null;
};

export const Banners = ({ companyBanners }: BannersProps) => {
  const { t } = useTranslation();
  const {
    setValue, setError, clearErrors, formState: { errors },
  } = useFormContext<CompanyCredentialsForm>();
  const [chosenBanners, setChosenBanners] = useState<Array<ChosenBanners>>([]);
  const { handleImageUpload, loading } = useImageUploader();
  const bannersToRemove = useWatch({ name: 'banners_to_remove' });
  const [initialBanners, setInitialBanners] = useState(companyBanners);

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !event.target.files.length) return;

    const valueArray = Array.from(event.target.files);
    if (valueArray.some((el) => {
      const splitted = el.name.split('.');
      return !FORMATS.includes(splitted[splitted.length - 1].toLowerCase());
    })) {
      setError('banners', {
        type: 'format',
        message: t('forms:validationErrors.file_format'),
      });
      return;
    }
    if (valueArray.some((el) => el.size > MAX_SIZE_BYTES)) {
      setError('banners', {
        type: 'size',
        message: t('forms:validationErrors.photoExceedsMaxSize', MAX_SIZE_PARAM),
      });
      return;
    }
    if (valueArray.some((el) => el.size < MIN_SIZE_BYTES)) {
      setError('banners', {
        type: 'size',
        message: t('forms:validationErrors.photoBelowMinSize', MIN_SIZE_PARAM),
      });
      return;
    }
    clearErrors('banners');

    const newBanners: ChosenBanners[] = [];
    const uploadedImages = await handleImageUpload(event);

    for (const file of uploadedImages as File[]) {
      newBanners.push({ file, url: URL.createObjectURL(file) });
    }

    const updatedPhotos = [...chosenBanners, ...newBanners];
    setChosenBanners((prev) => [...prev, ...newBanners]);
    const files = updatedPhotos.map((photo) => photo.file);
    setValue('banners', files);

    if (((initialBanners?.length || 0) + updatedPhotos.length) > 3) {
      setError('banners', {
        type: 'max_banners',
        message: t('forms:validationErrors.max_banners'),
      });
    }

    const input = event.target as HTMLInputElement;
    input.value = '';
  };

  const removeBanner = useCallback((id: number) => {
    const bannerToRemove = chosenBanners[id];
    const updatedBanners = chosenBanners.filter((_, i) => i !== id);

    URL.revokeObjectURL(bannerToRemove.url);

    setChosenBanners(updatedBanners);
    setValue('banners', updatedBanners.map((el) => el.file));

    if ((initialBanners?.length || 0) + updatedBanners.length <= 3) {
      clearErrors('banners');
    }
  }, [initialBanners, chosenBanners, setValue]);

  const removeInitialBanner = useCallback((id: number) => {
    if (!initialBanners) return;
    const banner = initialBanners.find((el) => el.id === id) as EntityImage;
    setValue('banners_to_remove', [...(bannersToRemove || []), banner]);
    setInitialBanners((prev) => prev && prev.filter((el) => el.id !== id));

    if (initialBanners.length - 1 + chosenBanners.length <= 3) {
      clearErrors('banners');
    }
  }, [initialBanners, chosenBanners, setValue]);

  return (
    <section className={s.section}>
      <h2>{t('userAccount:company.banners')}</h2>
      <div className={s.field}>
        <p className={s.field_header}>{t('userAccount:company.photos')}</p>
        <div className={clsx(s.wrapper)}>
          <p>{t('userAccount:company.banners_text')}</p>
          <p className={s.change_tip}>
            {t('userAccount:company.banners_tip', { ...MIN_SIZE_PARAM, ...MAX_SIZE_PARAM })}
          </p>
          <div className={styles.images}>
            <div className={clsx(styles.input_wrapper, styles.input_wrapper_images)}>
              <label>
                <input
                  type="file"
                  multiple
                  accept=".jpg, .jpeg, .png, .heic, .webp"
                  maxLength={3}
                  onChange={handleChange}
                  style={errors.banners ? { border: '1px solid var(--bzr-secondary-error)' } : {}}
                />
                {!loading && <SvgIcon name="add-photo-right-plus" />}
                <ItemLoader isLoading={loading} size={65} />
              </label>
            </div>
            {initialBanners?.map((el) => (
              <div className={styles.chosen_banner} key={el.id}>
                <img
                  src={el.thumbnail}
                  alt={`banner ${el.id}`}
                />
                <span onClick={() => removeInitialBanner(el.id)}>
                  <SvgIcon name="close" />
                </span>
              </div>
            ))}
            {chosenBanners.map((el, i) => (
              <div className={styles.chosen_banner} key={el.url}>
                <img
                  src={el.url}
                  alt={`banner ${i}`}
                />
                <span onClick={() => removeBanner(i)}>
                  <SvgIcon name="close" />
                </span>
              </div>
            ))}
          </div>
          {errors.banners && (
            <p className={s.error}>
              {errors.banners['0'] as unknown as string || errors.banners.message as string}
            </p>
          )}
        </div>
      </div>
    </section>
  );
};
