import React, { ComponentType } from 'react';
import { NotFoundPage } from './not-found-page.component';
import { ServerErrorPage } from './server-error-page.component';


type BaseProps = {
  serverError?: boolean,
  notFound?: boolean,
};
export const pageFactory = <P extends object>(Page: ComponentType<P>) => ({
  notFound,
  serverError,
  ...props
}: P & BaseProps) => {
  if (notFound) {
    return <NotFoundPage />;
  }
  if (serverError) {
    return <ServerErrorPage />;
  }
  return <Page {...(props as P)} />;
};
