import React from 'react';
import clsx from 'clsx';
import { formatMessageCount } from '../../helpers';
import styles from './count-notification.module.scss';

export type CountNotificationProps = {
  count: number;
  limit?: number | null;
  className?: string;
  type?: 'sidebar' | 'chat' | 'header' | 'mobile_nav_bar';
  color: 'red' | 'grey' | 'green';
};

export const CountNotification = ({
  count,
  className,
  type,
  limit = 100,
  color = 'red',
}: CountNotificationProps) => (
  count > 0 && (
  <span
    className={clsx(
      {
        [styles.sidebar]: type === 'sidebar',
        [styles.chat]: type === 'chat',
        [styles.header]: type === 'header',
        [styles.mobile_nav_bar]: type === 'mobile_nav_bar',
        [styles.red]: color === 'red',
        [styles.grey]: color === 'grey',
        [styles.green]: color === 'green',
      },
      className,
    )}
  >
    {formatMessageCount(count, limit)}
  </span>
  )
);
