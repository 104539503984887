import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { useSwipeable } from 'react-swipeable';
import { useMatchMedia } from '@/modules/shared/hooks';
import { ButtonIcon } from '@/modules/shared/components';
import { calculateDays, calculateEndDate, subtractDaysFromDate } from '../../helpers';
import { useCalendarNames } from '../../hooks';
import s from './calendar.module.scss';

export const Calendar = (props) => {
  const { lang } = useTranslation();
  const { isMobile, isDesktop } = useMatchMedia();
  const { monthNames } = useCalendarNames();

  const initialToday = new Date();
  const [days, setDays] = useState(() => calculateDays());
  const [periodState, setPeriodState] = useState({
    today: initialToday,
    currentDay: initialToday.getDate(),
    currentMonth: initialToday.getMonth(),
    currentYear: initialToday.getFullYear(),
    selectedDay: null as number | null,
    selectedMonth: null as number | null,
    selectedYear: null as number | null,
    startDate: initialToday,
    endDate: calculateEndDate(initialToday, days),
  });

  useEffect(() => {
    const handleResize = () => {
      setDays(calculateDays());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setPeriodState((prevState) => ({
      ...prevState,
      endDate: calculateEndDate(prevState.startDate, days),
    }));
  }, [days]);

  const prevPeriod = useCallback(() => {
    setPeriodState((prevState) => {
      const { startDate, today } = prevState;

      const newStartDate = subtractDaysFromDate(startDate, days);
      const newEndDate = calculateEndDate(newStartDate, days);

      if (newStartDate < today) {
        return prevState;
      }

      const newCurrentMonth = newStartDate.getMonth();
      const newCurrentYear = newStartDate.getFullYear();
      return {
        ...prevState,
        startDate: newStartDate,
        endDate: newEndDate,
        currentMonth: newCurrentMonth,
        currentYear: newCurrentYear,
      };
    });
  }, [days]);

  const nextPeriod = useCallback(() => {
    setPeriodState((prevState) => {
      const newStartDate = new Date(prevState.endDate);
      newStartDate.setDate(prevState.endDate.getDate() + 1);
      const newEndDate = calculateEndDate(newStartDate, days);
      const newCurrentMonth = newStartDate.getMonth();
      const newCurrentYear = newStartDate.getFullYear();

      return {
        ...prevState,
        startDate: newStartDate,
        endDate: newEndDate,
        currentMonth: newCurrentMonth,
        currentYear: newCurrentYear,
      };
    });
  }, [days]);

  const handleDayClick = (day: number, month: number, year: number) => {
    const isSelectedDate = periodState.selectedDay === day
    && periodState.selectedMonth === month
    && periodState.selectedYear === year;

    if (isSelectedDate) {
      setPeriodState({
        ...periodState,
        selectedDay: null,
        selectedMonth: null,
        selectedYear: null,
      });

      if (props.onDayClick) {
        props.onDayClick(null);
      }
    } else {
      setPeriodState({
        ...periodState,
        selectedDay: day,
        selectedMonth: month,
        selectedYear: year,
      });

      if (props.onDayClick) {
        const formattedMonth = (month + 1).toString().padStart(2, '0');
        const formattedDay = day.toString().padStart(2, '0');
        const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
        props.onDayClick(formattedDate);
      }
    }
  };

  const renderMonth = (
    month: number,
    year: number,
    startDay: number,
    endDay: number,
    keyPrefix: string,
  ) => {
    const calendarDays: JSX.Element[] = [];
    for (let day = startDay; day <= endDay; day++) {
      const current = new Date(year, month, day);
      const isSelected = periodState.selectedDay === day
        && periodState.selectedMonth === month
        && periodState.selectedYear === year;

      let dayOfWeek = current.toLocaleDateString(lang, { weekday: 'short' });
      dayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);

      calendarDays.push(
        <div
          key={`${keyPrefix}-day-${day}-${isSelected ? 'selected' : 'unselected'}`}
          className={clsx(s.day, { [s.day_pressed]: isSelected })}
          onClick={() => handleDayClick(day, month, year)}
        >
          <div className={s.day_number}>{day}</div>
          <div className={s.day_week}>{dayOfWeek}</div>
        </div>,
      );
    }

    return (
      <div key={keyPrefix} className={s.month}>
        <p className={s.month_header}>
          {`${isMobile ? monthNames[month]?.toUpperCase() : monthNames[month]}`}
        </p>
        <div className={s.month_column}>{calendarDays}</div>
      </div>
    );
  };

  const {
    currentMonth, currentYear, today, startDate, endDate,
  } = periodState;

  const isCurrentMonth = today.getMonth() === currentMonth && today.getFullYear() === currentYear;

  const monthCalculations = useMemo(() => {
    // Вычисляем ЭТОТ месяц
    const daysInCurrentMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const startDayCurrentMonth = startDate.getDate();
    const endDayCurrentMonth = startDate.getMonth() === endDate.getMonth() ? endDate.getDate() : daysInCurrentMonth;

    // Вычисляем следующий месяц и год
    const nextMonthVal = endDate.getMonth();
    const nextYear = endDate.getFullYear();
    // Начальная дата следующего месяца
    const nextMonthStartDate = new Date(nextYear, nextMonthVal, 1).getDate();
    const nextMonthEndDate = endDate.getDate();

    return {
      daysInCurrentMonth,
      startDayCurrentMonth,
      endDayCurrentMonth,
      nextMonthVal,
      nextYear,
      nextMonthStartDate,
      nextMonthEndDate,
    };
  }, [currentMonth, currentYear, startDate, endDate]);

  const calendarSwiped = useSwipeable({
    onSwipedRight: prevPeriod,
    onSwipedLeft: nextPeriod,
  });


  return (
    <div className={s.container}>
      {isDesktop && (
        <ButtonIcon
          nameIcon="banner-arrow-left"
          dataTestid="arrow_prev"
          type="rectangular"
          notActive={isCurrentMonth}
          onClick={prevPeriod}
          disabled={isCurrentMonth}
        />
      )}

      <div className={s.calendar} {...calendarSwiped}>
        {renderMonth(
          currentMonth,
          currentYear,
          monthCalculations.startDayCurrentMonth,
          monthCalculations.endDayCurrentMonth,
          'current',
        )}
        {startDate.getMonth() !== endDate.getMonth() && renderMonth(
          monthCalculations.nextMonthVal,
          monthCalculations.nextYear,
          monthCalculations.nextMonthStartDate,
          monthCalculations.nextMonthEndDate,
          'next',
        )}
      </div>

      {isDesktop && (
        <ButtonIcon
          nameIcon="banner-arrow-right"
          dataTestid="arrow_next"
          type="rectangular"
          onClick={nextPeriod}
        />
      )}
    </div>
  );
};
