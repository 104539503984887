// timestamp: "2024-09-16T12:32:36.104483+00:00"

export const formatTime = (timestamp: string, lang: string) => {
  const date = new Date(timestamp);
  const timeOptions = { hour: '2-digit', minute: '2-digit' };

  return date.toLocaleTimeString(lang, timeOptions as Intl.DateTimeFormatOptions);
};

export const formatDate = (timestamp: string) => {
  const date = new Date(timestamp);
  const dateOptionsYear = { day: 'numeric', month: '2-digit', year: 'numeric' };

  // Принудительное указание локали на 'ru-RU' для формата ДД.ММ.ГГГГ
  return date.toLocaleDateString('ru-RU', dateOptionsYear as Intl.DateTimeFormatOptions);
};
