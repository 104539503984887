import { useQuery } from '@tanstack/react-query';
import { useAuthContext } from '@/modules/auth';
import { SuggestionFeedback } from '../api';
import { SUGGESTION_FEEDBACK_QUERY_KEY } from '../consts';

const queryKey = [SUGGESTION_FEEDBACK_QUERY_KEY];

export const useSuggestionFeedbackList = () => {
  const { isAuthenticated } = useAuthContext();

  return useQuery({
    queryKey,
    queryFn: SuggestionFeedback.suggestionList,
    enabled: isAuthenticated,
  });
};
