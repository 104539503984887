import { Dispatch, SetStateAction, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { SvgIcon, UserAvatar } from '@/modules/shared/components';
import { Me } from '@/modules/me/types';
import { useMatchMedia } from '@/modules/shared/hooks';
import { getDateOfCreation } from '@/modules/shared/helpers';
import { LoadAvatar, LoadingAvatarIndicator } from '@/modules/me';
import { Company } from '@/modules/company/types';
import { type UserType, UserTypeEnum } from '@/modules/users';
import s from './credentials.module.scss';

type UserInfoProps = {
  userData: Me | undefined;
  handleChangeName: () => void;
  refetch: () => void;
  companyData: Company | undefined;
  type: UserType;
  avatar: File | undefined;
  name: string | undefined;
  setAvatar?: Dispatch<SetStateAction<File | undefined>>;
};

export const UserInfo = ({
  userData, handleChangeName, refetch, companyData, type, setAvatar, avatar, name,
}: UserInfoProps) => {
  const { t, lang } = useTranslation();
  const { isMobile } = useMatchMedia();
  const [loadingAvatar, setLoadingAvatar] = useState(false);

  return (
    <section className={s.user_info}>
      <div className={s.user_avatar_wrapper}>
        {loadingAvatar ? (
          <LoadingAvatarIndicator
            loadingAvatar={loadingAvatar}
          />
        ) : (
          <UserAvatar
            srcAvatar={
              (type === UserTypeEnum.Company
                ? ((avatar && URL.createObjectURL(avatar)) || companyData?.logo?.thumbnail)
                : userData?.avatar) || ''
            }
            userName={(type === UserTypeEnum.Company ? (name || companyData?.name) : userData?.full_name) || ''}
            diameter={isMobile ? '100px' : '140px'}
          />
        )}
        <span className={s.add_photo_icon}>
          <LoadAvatar
            setLoading={setLoadingAvatar}
            icon="add-photo"
            refetch={refetch}
            type={type}
            setAvatar={setAvatar}
          />
        </span>
      </div>
      <div className={s.info}>
        <div className={s.user_name}>
          {type === UserTypeEnum.Company ? (name || companyData?.name) : userData?.full_name}
          <SvgIcon name="pencil" onClick={handleChangeName} />
        </div>
        <p className={s.seller}>{t(`common:${type}`)}</p>
        <p className={s.id}>{`ID ${type === UserTypeEnum.Company ? companyData?.id : userData?.id}`}</p>
        <p>
          {t('common:onBazaar')}
          {' '}
          <span>
            {getDateOfCreation({
              date: (userData?.created_at) || '',
              t,
              lang,
              isUserInfo: true,
            })}
          </span>
        </p>
      </div>
    </section>
  );
};
