import { Button } from '@/modules/shared/components';

type InnerButtonProps = {
  onClick: () => void;
  disabled: boolean;
  text: string;
  className?: string;
};

export const InnerButton = ({
  onClick, disabled, text, className = '',
}: InnerButtonProps) => (
  <Button
    appearance="primary"
    buttonColor="blue"
    buttonSize="s"
    disabled={disabled}
    onClick={onClick}
    className={className}
  >
    {text}
  </Button>
);
