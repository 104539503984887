export const networks = [
  {
    name: 'telegram',
    path: 'https://t.me/bazaarorigin/',
  },
  {
    name: 'twitter',
    path: 'https://twitter.com/bazaarorigin/',
  },
  {
    name: 'instagram',
    path: 'https://www.instagram.com/bazaarorigin/',
  },
];
