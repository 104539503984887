import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { useCategoryName, useSortedCategories } from '@/modules/categories/hooks';
import { Category, SubCategory } from '@/modules/categories';
import { CreateAdTypes } from '../../types';
import { useCreateAdActionsContext, useCreateAdContext } from '../../context';
import { SelectCategoryCard } from './select-category-card.component';
import styles from './select-category.module.scss';

const allowTypes = ['buy', 'rent'];

export const SelectCategory = () => {
  const { sortedCategories } = useSortedCategories();
  const [getNameByCode] = useCategoryName();
  const { t } = useTranslation();

  const { resetField } = useFormContext<CreateAdTypes>();

  const {
    category: selectedCategory,
    subCategory: selectedSubCategory,
    isLastLevel,
  } = useCreateAdContext();

  const {
    setCategory,
    setSubCategory,
    setUnderSubCategory,
    setAdType,
    setMainStep,
    setIsLastLevel,
  } = useCreateAdActionsContext();

  const handleCategoryClick = useCallback((category: Category) => {
    setCategory(category);
    setSubCategory(undefined);
    setUnderSubCategory(undefined);
    resetField('sub_category');
    resetField('under_sub_category');
    resetField('operation_type');
    setIsLastLevel(false);
  }, []);

  const handleSubcategoryClick = useCallback((subcategory: SubCategory) => {
    setSubCategory(subcategory);
    resetField('operation_type');
    resetField('under_sub_category');

    if (subcategory.under_sub_categories.length === 0) {
      setIsLastLevel(true);

      if (selectedCategory?.allow_ad_type) {
        return;
      }
      setMainStep(2);
    } else {
      setIsLastLevel(false);
    }
  }, [selectedCategory]);

  const handleUnderSubcategoryClick = useCallback((underSubCategory) => {
    setIsLastLevel(true);
    setUnderSubCategory(underSubCategory);
    if (!selectedCategory?.allow_ad_type) {
      setMainStep(2);
    }
  }, [selectedCategory]);

  const handleTypeClick = useCallback((type) => {
    setAdType(type);
    setMainStep(2);
  }, [setAdType]);

  return (
    <div className={styles.select_category}>
      <div className={styles.category}>
        {sortedCategories?.map((category) => (
          <SelectCategoryCard
            id={category.id}
            key={category.codename}
            icon={category.icon}
            title={getNameByCode(category.codename)}
            name="category"
            onClick={() => handleCategoryClick(category)}
          />
        ))}
      </div>
      <div className={styles.sub_category}>
        {selectedCategory && selectedCategory.sub_categories?.map((subcategory) => (
          <SelectCategoryCard
            id={subcategory.id}
            key={subcategory.codename}
            title={getNameByCode(selectedCategory.codename, subcategory.codename)}
            name="sub_category"
            onClick={() => handleSubcategoryClick(subcategory)}
          />
        ))}
      </div>
      <div className={styles.under_sub_category}>
        {selectedSubCategory && selectedSubCategory.under_sub_categories?.map((underSubCategory) => (
          <SelectCategoryCard
            id={underSubCategory.id}
            key={underSubCategory.codename}
            title={getNameByCode(selectedCategory!.codename, selectedSubCategory.codename, underSubCategory.codename)}
            name="under_sub_category"
            onClick={() => handleUnderSubcategoryClick(underSubCategory)}
          />
        ))}
      </div>
      <div className={styles.allowed_ad_type}>
        {selectedCategory?.allow_ad_type && isLastLevel && (
          allowTypes.map((type) => (
            <SelectCategoryCard
              id={type}
              key={type}
              title={t(`createAd:allowTypes.${type}`)}
              name="operation_type"
              onClick={() => handleTypeClick(type)}
            />
          ))
        )}
      </div>
    </div>
  );
};
