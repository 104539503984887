import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import styles from './page-title.module.scss';
import { getDeclension } from '../../helpers';

type PageTitleProps = {
  title: string;
  count: number;
};

export const PageTitle = ({ title, count }: PageTitleProps) => {
  const { t } = useTranslation();
  const countLabel = getDeclension({
    count,
    t,
    singular: 'common:countAds.singular',
    few: 'common:countAds.few',
    many: 'common:countAds.many',
  });

  return (
    <div className={styles.page_title}>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.count}>{countLabel}</p>
    </div>
  );
};
