import React from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { stopEvent } from '@/modules/shared/helpers';
import { SvgIcon } from '@/modules/shared/components';
import { profileBasePath } from '@/modules/shared/const';
import styles from './button-back.module.scss';

export type ButtonBackProps = {
  setIsInputFocused?: (val: boolean) => void;
  pathRedirect?: () => void;
  className?: string;
};

export const ButtonBack = ({
  setIsInputFocused,
  pathRedirect,
  className,
}: ButtonBackProps) => {
  const router = useRouter();
  const { locale } = useRouter();

  const handleClick = () => {
    if (setIsInputFocused) {
      setIsInputFocused(false);
    }

    if (pathRedirect) {
      pathRedirect();
    } else if (window.history.length > 1) {
      router.back();
    } else {
      router.push(`/${locale}/${profileBasePath}/chat`);
    }
  };

  return (
    <button
      type="button"
      className={clsx(styles.button, className)}
      onMouseDown={stopEvent}
      onClick={handleClick}
    >
      <SvgIcon name="arrow-back-32" />
    </button>
  );
};
