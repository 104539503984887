import { baseEndpointV1 } from '@/modules/shared/api';

export const endpoints = {
  createReview: () => `${baseEndpointV1}/review/create/`,
  reviewAnswer: (id: string) => `${baseEndpointV1}/review/answer/${id}/`,
  givenReviews: () => `${baseEndpointV1}/review/given/`,
  reviewsList: () => `${baseEndpointV1}/review/list`,
  createImage: () => `${baseEndpointV1}/review/images/create/`,
  reviewRequest: (id?: number) => `${baseEndpointV1}/review/requests/${id ? `?reviewer=${id}` : ''}`,
};
