import React from 'react';
import clsx from 'clsx';
import SendingIcon from './message-sending.svg';
import ReadIcon from './message-read.svg';
import UnreadIcon from './message-unread.svg';
import ErrorIcon from './message-error.svg';
import styles from './status-message.module.scss';

const iconMap = {
  sending: SendingIcon,
  read: ReadIcon,
  unread: UnreadIcon,
  error: ErrorIcon,
};

export type StatusMessageProps = {
  messageStatus?: string;
  onClick?: () => void;
  className?: string;
};

export const StatusMessage = ({ messageStatus, onClick, className }: StatusMessageProps) => {
  const IconComponent = iconMap[messageStatus as keyof typeof iconMap] || null;

  const handleClick = () => {
    if (messageStatus === 'error' && onClick) {
      onClick();
    }
  };

  return (
    messageStatus && (
      <div className={clsx(styles.state, className)} onClick={handleClick}>
        {IconComponent && <IconComponent className={styles[`state_${messageStatus}`]} />}
      </div>
    )
  );
};
