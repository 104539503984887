import React, { ComponentProps } from 'react';
import Link from 'next/link';
import { useAuthContext } from '../contexts';
import { useAuthModalUrl } from '../hooks';

export const AuthProtectedLink = ({ customHeader, ...props }: Omit<ComponentProps<typeof Link>, 'shallow'> & { customHeader?: string }) => {
  const { href } = props;
  const authModalLink = useAuthModalUrl(href, customHeader);
  const { isAuthenticated } = useAuthContext();

  const requiresAuth = href !== '/';
  const isAuthModal = !isAuthenticated && requiresAuth;
  const path = isAuthModal ? authModalLink : href;

  return <Link {...props} shallow={isAuthModal} href={path} />;
};
