import React, { FC } from 'react';
import s from './error-message.module.scss';

interface ErrorMessageProps {
  error?: { message: string } | null | undefined;
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ error }) => (
  error ? <p className={s.error}>{error.message}</p> : null
);
