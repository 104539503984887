import React from 'react';
import { useFormContext } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { UniversalSelect } from '@/modules/shared/components/select/select.component';
import { useCreateAdContext } from '../../context';
import { useRealEstate } from './hooks';
import {
  FieldsLayout,
  FieldLayout,
  ConditionField,
  HiddenRadiobutton,
  CreateAdInput,
  Label,
} from './shared';
import { CreateAdTypes } from '../../types';

const ResidentialRealEstate = () => {
  const { setValue, control } = useFormContext<CreateAdTypes>();
  const { underSubCategory, adType } = useCreateAdContext();
  const { t } = useTranslation();
  const realEstate = useRealEstate();

  return (
    <>
      <FieldsLayout title={t('ad-parameters:characteristics')} marginBottom>
        <ConditionField
          name="state"
          options={realEstate.state}
          required={adType !== 'rent'}
        />
        {adType === 'rent' && (
          <FieldLayout
            name="duration"
            title={t('ad-parameters:realEstate.duration')}
          >
            {realEstate.duration.map(({ value, label }) => (
              <HiddenRadiobutton
                key={value}
                name="duration"
                value={value}
                label={label}
                required
              />
            ))}
          </FieldLayout>
        )}
        <FieldLayout
          name="number_of_rooms"
          title={t('ad-parameters:realEstate.number_of_rooms')}
        >
          <UniversalSelect
            name="number_of_rooms"
            setValue={setValue}
            control={control}
            placeholder=""
            options={realEstate.numberOfRooms}
          />
        </FieldLayout>
        <FieldLayout
          name="living_space"
          title={t('ad-parameters:realEstate.living_space')}
          required={adType !== 'rent'}
        >
          <CreateAdInput name="living_space" postfix="m" />
        </FieldLayout>
        {(underSubCategory!.codename === 'house' || underSubCategory!.codename === 'villa') && (
          <FieldLayout
            name="land_area"
            title={t('ad-parameters:realEstate.land_area')}
            required={adType !== 'rent'}
          >
            <CreateAdInput name="land_area" postfix="m" />
          </FieldLayout>
        )}
        {adType === 'buy' && (
          <>
            <FieldLayout
              name="ownership_right"
              title={t('ad-parameters:realEstate.ownership_right')}
              required={false}
            >
              <UniversalSelect
                name="ownership_right"
                setValue={setValue}
                control={control}
                placeholder=""
                options={realEstate.ownershipRight}
                requiredFiled={false}
              />
            </FieldLayout>
            <FieldLayout
              name="completion_status"
              title={t('ad-parameters:realEstate.completion_status')}
            >
              <UniversalSelect
                name="completion_status"
                setValue={setValue}
                control={control}
                placeholder=""
                options={realEstate.completionStatus}
              />
            </FieldLayout>
          </>
        )}
      </FieldsLayout>
      <FieldsLayout title={t('ad-parameters:additionally')}>
        <FieldLayout
          name="allows_pets"
          title={t('ad-parameters:realEstate.allows_pets')}
          required={false}
        >
          {realEstate.allowsPets.map(({ value, label }) => (
            <HiddenRadiobutton
              key={value}
              name="allows_pets"
              value={value}
              label={label}
              required={false}
            />
          ))}
        </FieldLayout>
        <FieldLayout
          name="additional_options"
          title={t('ad-parameters:included')}
          required={false}
          isVertically
        >
          {realEstate.residential.additional_options.map(({ value, label }) => (
            <Label
              key={value}
              name={value as keyof CreateAdTypes}
              label={label}
              required={false}
            />
          ))}
        </FieldLayout>
      </FieldsLayout>
    </>
  );
};

const CommercialRealEstate = () => {
  const { setValue, control } = useFormContext();
  const { adType } = useCreateAdContext();
  const { t } = useTranslation();
  const realEstate = useRealEstate();

  return (
    <>
      <FieldsLayout title={t('ad-parameters:characteristics')} marginBottom>
        <ConditionField
          name="state"
          options={realEstate.state}
          required
        />
        <FieldLayout
          name="commercial_area"
          title={t('ad-parameters:realEstate.commercial_area')}
        >
          <CreateAdInput name="commercial_area" postfix="m" />
        </FieldLayout>
        <FieldLayout
          name="land_area"
          title={t('ad-parameters:realEstate.land_area')}
          required={adType !== 'rent'}
        >
          <CreateAdInput name="land_area" postfix="m" />
        </FieldLayout>
        {adType === 'buy' && (
          <>
            <FieldLayout
              name="ownership_right"
              title={t('ad-parameters:realEstate.ownership_right')}
              required={false}
            >
              <UniversalSelect
                name="ownership_right"
                setValue={setValue}
                control={control}
                placeholder=""
                options={realEstate.ownershipRight}
                requiredFiled={false}
              />
            </FieldLayout>
            <FieldLayout
              name="completion_status"
              title={t('ad-parameters:realEstate.completion_status')}
            >
              <UniversalSelect
                name="completion_status"
                setValue={setValue}
                control={control}
                placeholder=""
                options={realEstate.completionStatus}
              />
            </FieldLayout>
          </>
        )}
      </FieldsLayout>
      <FieldsLayout title={t('ad-parameters:additionally')}>
        <FieldLayout
          name="additional_options"
          title={t('ad-parameters:included')}
          required={false}
          isVertically
        >
          {realEstate.commercial.additional_options[adType as 'buy' | 'rent'].map(({ value, label }) => (
            <Label
              key={value}
              name={value as keyof CreateAdTypes}
              label={label}
            />
          ))}
        </FieldLayout>
      </FieldsLayout>
    </>
  );
};

const LandRealEstate = () => {
  const { setValue, control } = useFormContext();
  const { adType } = useCreateAdContext();
  const { t } = useTranslation();
  const realEstate = useRealEstate();

  return (
    <FieldsLayout title={t('ad-parameters:characteristics')}>
      <FieldLayout
        name="land_area"
        title={t('ad-parameters:realEstate.land_area')}
      >
        <CreateAdInput name="land_area" postfix="m" />
      </FieldLayout>
      {adType === 'buy' && (
        <FieldLayout
          name="ownership_right"
          title={t('ad-parameters:realEstate.ownership_right')}
          required={false}
        >
          <UniversalSelect
            name="ownership_right"
            setValue={setValue}
            control={control}
            placeholder=""
            options={realEstate.ownershipRight}
            requiredFiled={false}
          />
        </FieldLayout>
      )}
      <FieldLayout
        name="permitted_construction"
        title={t('ad-parameters:realEstate.permitted_construction')}
      >
        <UniversalSelect
          name="permitted_construction"
          setValue={setValue}
          control={control}
          placeholder=""
          options={realEstate.land.permittedBuilding}
        />
      </FieldLayout>
    </FieldsLayout>
  );
};

const additionalFields = {
  residential: <ResidentialRealEstate />,
  commercial: <CommercialRealEstate />,
  land: <LandRealEstate />,
};

export const RealEstate = () => {
  const { subCategory } = useCreateAdContext();

  return additionalFields[subCategory!.codename];
};
