import React, { useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { AutoScrollContainer } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { AdDataForFeedback, MessageDetails } from '../../../types';
import { useTimeMessages } from '../../../hooks';
import { groupMessagesByDateAndType, mergeMessages } from '../../../helpers';
import { Message } from '../message';
import s from './messages-container.module.scss';

const isEmptyArray = (arr) => Array.isArray(arr)
  && arr.length === 1
  && arr[0]?.messages?.length === 0
  && arr[0]?.type === 'messages_read';

export type MessagesContainerProps = {
  isLoading: boolean | null;
  messages: MessageDetails[];
  userId: number | null;
  autoScroll: boolean;
  isPreloading: boolean;
  draftMessages: { message: string; timestamp: string }[];
  errorMessages: { message: string; timestamp: string }[];
  adData: AdDataForFeedback;
  onErrorClick?: () => void;
  isSupport?: boolean;
};

const minHeight = 457;
export const MessagesContainer = ({
  isLoading,
  messages,
  userId,
  autoScroll,
  isPreloading,
  draftMessages,
  errorMessages,
  onErrorClick,
  isSupport = false,
  adData,
}: MessagesContainerProps) => {
  const { t, lang } = useTranslation();
  const dateMessages = useTimeMessages;
  const { isDesktop } = useMatchMedia();

  const { groupedByDateAndType } = useMemo(
    () => groupMessagesByDateAndType(mergeMessages(messages || [], userId)),
    [messages],
  );

  return (
    <div className={s.wrapper}>
      <AutoScrollContainer autoScroll={autoScroll} size="large" scrollToBottom>
        <div className={s.container} style={{ minHeight: isDesktop ? `${minHeight}px` : '100%' }}>
          {isLoading && <p className={s.information}>{t('chat:loading')}</p>}
          {!isLoading && isEmptyArray(messages) && (
            <p className={s.information}>{t('chat:chat.messages')}</p>
          )}

          {!isLoading && !isEmptyArray(messages) && (
            <>
              {Array.from(groupedByDateAndType.entries())
                .map(([date, messageGroups]) => (
                  <div key={date} className={s.day}>
                    <p className={s.day_time}>{dateMessages(date, t, lang)}</p>
                    <div className={s.day_messages}>
                      {messageGroups.map((group, i) => (
                        <div className={clsx(
                          s.day_block,
                          {
                            [s.day_block_system_last]: i + 1 === messageGroups.length && group[0].type === 'system',
                            [s.day_block_system_middle]: i + 1 !== messageGroups.length && group[0].type === 'system',
                          },
                        )}
                        >
                          {group.map((el) => (
                            <Message
                              key={el.id}
                              msg={el}
                              senderType={el.sender.id === userId ? 'sender' : 'recipient'}
                              messageStatus={el.is_read ? 'read' : 'unread'}
                              messageType={el.type}
                              isSupport={el.sender.id === userId ? false : isSupport}
                              adData={adData}
                            />
                          ))}
                        </div>
                      ))}

                    </div>
                  </div>
                ))}
            </>
          )}
          {isPreloading && draftMessages.length > 0 && draftMessages.map((el, index) => (
            <Message key={index} msg={el} senderType="sender" messageStatus="sending" />
          ))}

          {errorMessages.length > 0 && errorMessages.map((el, index) => (
            <Message key={index} msg={el} senderType="sender" messageStatus="error" onErrorClick={onErrorClick} />
          ))}
        </div>
      </AutoScrollContainer>
    </div>
  );
};
