import React, {
  ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState,
} from 'react';
import { toast } from 'react-toastify';
import { useConvertHeicToPng } from '@/modules/shared/helpers';
import useTranslation from 'next-translate/useTranslation';
import { ActionButton, SvgIcon } from '@/modules/shared/components';
import { type UserType, UserTypeEnum } from '@/modules/users';
import { IUpdateUserData } from '../../types';
import { MeApi } from '../../api';

interface IProps {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setEditedData?: Dispatch<SetStateAction<IUpdateUserData>>;
  icon?: string;
  refetch?: () => void;
  type?: UserType | undefined;
  setAvatar?: Dispatch<SetStateAction<File | undefined>>;
}

const supportedFormats = ['jpeg', 'png', 'heic', 'jpg'];

export const LoadAvatar: FC<IProps> = ({
  setEditedData, setLoading, icon, refetch, type, setAvatar,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const convertHeicToPng = useConvertHeicToPng();
  const { t } = useTranslation('forms');
  const [isSuccess, setIsSuccess] = useState(false);
  const { updateUserAvatar } = MeApi;

  const handleAvatarChange = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      if (file) {
        setLoading(true);

        const extension = file.name.split('.').pop()?.toLowerCase();
        const isHeic = ['heic', 'heif'].includes(extension!) || file.type === 'image/heic';
        const fileToSet = isHeic ? await convertHeicToPng(file) : file;

        if (!icon) {
          setEditedData?.((current) => ({
            ...current,
            avatar: fileToSet,
          }));
        } else if (fileToSet) {
          const arrTypeFile = fileToSet.name?.split('.');
          const newExtension = arrTypeFile[arrTypeFile.length - 1];
          if (!supportedFormats.includes(newExtension)) {
            toast.error(`${t('forms:validationErrors.unsupportedImageFormat')} ${newExtension}`);
            setLoading(false);
            return;
          }
          if (type === UserTypeEnum.Company) {
            setAvatar?.(file);
            setLoading(false);
            return;
          }
          const data = await updateUserAvatar(fileToSet, newExtension);
          setIsSuccess(!!data);
        }

        setLoading(false);
      }
    }
  }, [type, setAvatar, convertHeicToPng, setEditedData, icon]);

  useEffect(() => {
    if (type === UserTypeEnum.Company) return;
    if (isSuccess && refetch) refetch();
  }, [type, isSuccess, refetch]);

  return (
    <>
      <input
        type="file"
        accept=".jpeg,.png,.heic,.jpg"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleAvatarChange}
      />
      {icon ? (
        <SvgIcon name={icon} onClick={() => fileInputRef.current?.click()} />
      ) : (
        <ActionButton
          onClick={() => fileInputRef.current?.click()}
          type="button"
        >
          {t('images.update')}
        </ActionButton>
      )}
    </>
  );
};
