import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';
import { Translate } from 'next-translate';

import { useCategoryCodenames } from './use-category-codenames';

export const useOverrideTranslation = () => {
  const { t } = useTranslation();
  const { path: categoryTranslatePath } = useCategoryCodenames();

  const tWithOverride: Translate = useCallback((code: string, query, options) => {
    if (!code.startsWith('filters:')) {
      return t(code);
    }

    const newCode = `filters-override:${categoryTranslatePath}.${code.slice(8)}`;

    return t(newCode, null, {
      default: t(code, query, options),
    });
  }, [categoryTranslatePath, t]);

  return {
    t: tWithOverride,
  };
};
