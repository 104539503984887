import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useMeQuery } from '@/modules/me';
import { useAuthContext } from '@/modules/auth';
import useTranslation from 'next-translate/useTranslation';
import { CountNotification, SvgIcon, UserAvatar } from '@/modules/shared/components';
import { profileBasePath } from '@/modules/shared/const';
import { useUserType } from '@/modules/users/hooks';
import { UserTypeEnum } from '@/modules/users';
import { useChatDataContext } from '@/modules/chat';
import { EXCLUDED_ROUTES, UNSHOW_NAV_BAR } from './navigation.const';
import { Route } from './route';
import styles from './mobile-navigation.module.scss';

export const MobileNavigation = () => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { isAuthenticated } = useAuthContext();
  const { data: userData } = useMeQuery(isAuthenticated);
  const { full_name, email, avatar } = userData || {};
  const username = full_name || email;
  const { type, companyData } = useUserType(userData?.user_type, userData?.id);

  const { unreadDialogsCount } = useChatDataContext();

  const navLinks = useMemo(
    () => [
      {
        path: '/',
        icon: <SvgIcon name="home" />,
        isAuthProtected: false,
        label: t('tabBar.main'),
      },
      {
        path: `/${profileBasePath}/chat`,
        icon: (
          <span className={styles.chat}>
            <SvgIcon name="chat" />
            <CountNotification
              count={unreadDialogsCount}
              type="mobile_nav_bar"
              className={styles.chat_count}
              color="red"
            />
          </span>
        ),
        isAuthProtected: true,
        label: t('tabBar.message'),
      },
      {
        path: `/${profileBasePath}/my-ads`,
        icon: <SvgIcon name="ad" />,
        isAuthProtected: true,
        customAuthHeader: 'addAds',
        label: t('tabBar.ads'),
      },
      {
        path: '/posters',
        icon: <SvgIcon name="events" />,
        isAuthProtected: false,
        label: t('tabBar.events'),
      },
      {
        path: `/${profileBasePath}/personal-account`,
        icon: isAuthenticated && username && avatar && type ? (
          <UserAvatar
            userName={type === UserTypeEnum.Company ? (companyData?.name || '') : username}
            srcAvatar={type === UserTypeEnum.Company ? (companyData?.logo?.thumbnail || '') : avatar}
            diameter="24px"
          />
        ) : (
          <SvgIcon name="profile" />
        ),
        isAuthProtected: true,
        label: t('tabBar.profile'),
      },
    ],
    [t, avatar, username, isAuthenticated, type, companyData, unreadDialogsCount],
  );

  const unshowDefaultNav = useMemo(() => {
    const isInUnshowNavBar = UNSHOW_NAV_BAR.some((path) => router.pathname.includes(path));
    const isInExcludedRoutes = EXCLUDED_ROUTES.includes(router.pathname);
    return isInUnshowNavBar && !isInExcludedRoutes;
  }, [router.pathname]);

  if (unshowDefaultNav) {
    return null;
  }

  return (
    <nav className={styles.nav} id="mobile-navigation-bar">
      {navLinks.map((link) => (
        <Route key={link.path} {...link} />
      ))}
    </nav>
  );
};
