import React, {
  createContext, useContext, FC, ReactNode, useMemo,
} from 'react';
import { IEvent } from '@/modules/events/types';
import { IAd } from '@/modules/ads/types';
import { useFavoriteAds, useFavoriteEvent } from '../hooks';

interface FavoriteAd {
  id: number;
  user: number;
  announcements: IAd[];
}

interface FavoritesContextProps {
  favoritesAds: FavoriteAd[];
  favoritesEvents: IEvent[];
  totalCount: number;
}

const FavoritesContext = createContext<FavoritesContextProps>({
  favoritesAds: [],
  favoritesEvents: [],
  totalCount: 0,
});

interface FavoritesProviderProps {
  children: ReactNode;
}

export const FavoritesProvider: FC<FavoritesProviderProps> = ({ children }) => {
  const { favoritesAds } = useFavoriteAds();
  const { favoritesEvents } = useFavoriteEvent();

  const totalCount = useMemo(() => {
    const ads = favoritesAds?.[0]?.announcements?.length ?? 0;
    const events = favoritesEvents?.length ?? 0;
    return ads + events;
  }, [favoritesAds, favoritesEvents]);

  const value = useMemo(() => ({
    favoritesAds,
    favoritesEvents,
    totalCount,
  }), [favoritesAds, favoritesEvents, totalCount]);

  return (
    <FavoritesContext.Provider value={value}>
      {children}
    </FavoritesContext.Provider>
  );
};

export const useFavoritesContext = () => useContext(FavoritesContext);
