import { Filters } from './types';

const PRIORITY_FILTER_SORT_INDEX = [
  'checkbox_list.operation_type',
  'price',
  'location',
  'checkbox_list.number_of_rooms',
].reduce((acc, key, index) => {
  acc[key] = index;
  return acc;
}, {} as Record<string, number>);

export const sortFiltersByPriority = (data: Filters | undefined): Filters | undefined => {
  if (!data) return data;

  const prioritized: Filters = Array(PRIORITY_FILTER_SORT_INDEX.length);
  const rest: Filters = [];

  data.forEach((item) => {
    const filterIndex = `${item.type}${'name' in item && item.name ? `.${item.name}` : ''}`;

    const index = PRIORITY_FILTER_SORT_INDEX[filterIndex];
    if (index !== undefined) {
      prioritized[index] = item;
    } else {
      rest.push(item);
    }
  });

  return prioritized.filter(Boolean).concat(rest);
};
