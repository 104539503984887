import React, { useRef, useEffect } from 'react';
import clsx from 'clsx';

import { useOverlayScrollbars } from 'overlayscrollbars-react';

import styles from './auto-scroll-container.module.scss';

interface AutoScrollContainerProps {
  children: React.ReactNode;
  autoScroll?: boolean;
  size?: 'large';
  scrollBehavior?: 'auto' | 'smooth' | 'instant';
  scrollToBottom?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export const AutoScrollContainer: React.FC<AutoScrollContainerProps> = ({
  size = 'large',
  children,
  autoScroll = false,
  scrollBehavior = 'instant',
  scrollToBottom = false,
  className,
  style,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [initialize, osInstance] = useOverlayScrollbars();

  useEffect(() => {
    initialize(containerRef.current as HTMLElement);
  }, [initialize]);

  // Скролл вниз
  useEffect(() => {
    if (scrollToBottom && (autoScroll || children)) {
      const { scrollOffsetElement } = osInstance()!.elements();
      scrollOffsetElement.scrollTo({
        behavior: scrollBehavior,
        top: scrollOffsetElement.scrollHeight - scrollOffsetElement.clientHeight,
      });
    }
  }, [scrollToBottom, autoScroll, children, scrollBehavior, osInstance]);

  return (
    <div
      ref={containerRef}
      style={{
        height: '100%', width: '100%', overflow: 'hidden', ...style,
      }}
      className={clsx(styles.scroll, className, {
        [styles.scroll_large]: size === 'large',
      })}
    >
      {children}
    </div>
  );
};
