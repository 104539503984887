import React, {
  useEffect, useMemo, useState,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import * as yup from 'yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';


import { Modal } from '@/modules/shared/components/modal';
import { useSearchParams } from 'next/navigation';
import {
  NavigateFunction, Route, useLocation, MemoryRouter, Routes,
} from 'react-router-dom';
import { Button, InputText } from '@/modules/shared/components';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { RouterListener } from './components/router-listener.component';
import { AuthApi } from '../../api';
import { useCloseAuthModal } from '../../hooks';


import styles from './auth-modal.module.scss';
import { useAuthContext } from '../../contexts';


export const CompleteRecovery = () => {
  const closeModal = useCloseAuthModal();
  const { t } = useTranslation();
  const searchParams = useSearchParams();
  const [router, setCurrentRouter] = useState<{
    location: ReturnType<typeof useLocation>,
    nav: NavigateFunction
  }>();

  const { isPending, mutateAsync } = useMutation({
    mutationFn: AuthApi.resetPasswordComplete,
  });

  const code = searchParams.get('code') || undefined;
  const email = searchParams.get('email') || undefined;
  const phone = searchParams.get('phone') || undefined;
  const totp = searchParams.get('totp') || undefined;

  const schema = useMemo(() => yup.object().shape({
    password: yup.string().required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.password') }))
      .min(8, t('forms:validationErrors.passwordTooShort', { length: 8 })),
    password2: yup
      .string()
      .required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.password') }))
      .oneOf([yup.ref('password')], t('forms:validationErrors.passwordsMismatch')),
  })
    .required(), [t]);

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      password2: '',
    },
  });
  const {
    handleSubmit,
    control,
  } = formMethods;
  const {
    isAuthenticated,
  } = useAuthContext();

  useEffect(() => {
    if (isAuthenticated) {
      closeModal();
    }
  }, [closeModal, isAuthenticated]);

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    try {
      if (email && code) {
        await mutateAsync({
          email,
          code,
          password: data.password as string,
          password2: data.password2 as string,
        });
      } else if (totp && phone) {
        await mutateAsync({
          phone_number: phone,
          totp,
          password: data.password as string,
          password2: data.password2 as string,
        });
      } else {
        throw Error();
      }
      router?.nav('/success', { replace: true });
    } catch (e) {
      if (e?.response?.data?.validation_errors?.code?.includes('INVALID_PHONE_NUMBER_OR_TOTP_CODE')) {
        toast(t('auth:completeRecovery.invalidTotp'), { type: 'error' });
      } else {
        toast(t('auth:completeRecovery.commonError'), { type: 'error' });
      }
    }
  };

  if (isAuthenticated) {
    return null;
  }

  return (
    <Modal
      fullHeight
      title={<img src="/assets/icons/logo-main-medium.svg" alt="Logo Bazaar" />}
      open
      onClose={closeModal}
    >
      <FormProvider {...formMethods}>
        <MemoryRouter initialEntries={['/']}>
          <RouterListener onRouteChange={setCurrentRouter} />
          <Routes>
            <Route
              path="/success"
              element={(
                <div className={styles.content} onSubmit={handleSubmit(onSubmit)}>
                  <div className={styles.title}>
                    <h2>{t('auth:completeRecoverySuccess.title')}</h2>
                    <div className={styles.subTitle}>{t('auth:completeRecoverySuccess.description')}</div>
                  </div>
                  <div className={styles.centered}>
                    <img width={165} height={165} src="/assets/images/rabbit-fun.svg" alt="registration-done" />
                  </div>
                </div>
              )}
            />
            <Route
              path="/"
              element={(
                <form className={styles.content} onSubmit={handleSubmit(onSubmit)}>
                  <div className={styles.title}>
                    <h2>{t('auth:completeRecovery.title')}</h2>
                    <div className={styles.subTitle}>{t('auth:register.subTitleStep2')}</div>
                  </div>
                  <div className={styles.password}>
                    <div className={styles.formItem__title}>{t('auth:label.enterPassword')}</div>
                    <Controller
                      control={control}
                      name="password"
                      render={({ field, formState: { errors } }) => (
                        <>
                          <InputText
                            {...field}
                            type="password"
                            error={!!errors.password}
                            fullWidth
                          />
                          {errors.password && <div className={styles.error}>{errors.password.message}</div>}
                        </>
                      )}
                    />
                  </div>
                  <div className={styles.password}>
                    <div className={styles.formItem__title}>{t('auth:label.confirmationPassword')}</div>
                    <Controller
                      control={control}
                      name="password2"
                      render={({ field, formState: { errors } }) => (
                        <>
                          <InputText
                            {...field}
                            type="password"
                            error={!!errors.password2}
                            fullWidth
                          />
                          {errors.password2 && <div className={styles.error}>{errors.password2.message}</div>}
                        </>
                      )}
                    />
                  </div>
                  <div>
                    <Button
                      disabled={isPending}
                      type="submit"
                      fullWidth
                      appearance="primary"
                      buttonColor="green"
                      buttonSize="s"
                    >
                      {t('auth:register.continue')}
                    </Button>
                  </div>
                </form>
              )}
            />
          </Routes>
        </MemoryRouter>
      </FormProvider>
    </Modal>
  );
};
