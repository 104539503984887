import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { ButtonSwitcher } from '@/modules/shared/components';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import styles from '../view-list-categories.module.scss';

interface Props {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  pathname: string;
  codename: string;
  allow_ad_type: boolean;
  setOperationType: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const DesktopHeaderViewList = (props: Props) => {
  const {
    onClose, pathname, codename, allow_ad_type, setOperationType,
  } = props;
  const { t } = useTranslation('');
  const [activeValues, setActiveValues] = useState({});

  const filterOptions = useMemo(
    () => ['all', 'buy', 'rent'].map((el) => ({
      value: el,
      name: t(`common:${el}`),
      dataTestId: `toggle_${el}`,
    })),
    [t],
  );

  const handleSetActiveValue = useCallback(
    (value) => {
      setActiveValues((prevState) => ({
        ...prevState,
        [codename]: value,
      }));

      setOperationType(value === 'all' ? undefined : value);
    },
    [codename, setOperationType],
  );

  useEffect(() => {
    if (allow_ad_type && !activeValues[codename]) {
      setActiveValues((prevState) => ({
        ...prevState,
        [codename]: filterOptions[0].value,
      }));
    }
  }, [codename, filterOptions, activeValues, allow_ad_type]);

  return (
    <>
      <Link href={pathname} onClick={() => onClose(false)}>
        <h2
          className={styles.header_title}
          data-testid={`section_${codename}`}
        >
          {t(`categories:categories.${codename}._`)}
        </h2>
      </Link>
      {allow_ad_type && (
        <ButtonSwitcher
          buttons={filterOptions}
          value={activeValues[codename]}
          onChange={handleSetActiveValue}
        />
      )}
    </>
  );
};
