import { useCallback } from 'react';
import { AxiosError } from 'axios';
import useTranslation from 'next-translate/useTranslation';
import { saveEntityImages } from '@/modules/shared/api';
import { ValidationError } from '@/modules/shared/types';
import { useTrackEvent } from '@/modules/events-tracking';
import { useQueryClient } from '@tanstack/react-query';
import {
  UseFormGetValues,
  UseFormReset,
  UseFormSetError,
} from 'react-hook-form';
import { AdsApi } from '../../../api';
import { ADS_LIST_QUERY_KEY, INFINITE_LIST_QUERY_KEY } from '../../../consts';
import { useCreateAdMutation } from '../../../mutations';
import { useCreateAdActionsContext, useCreateAdContext } from '../context';
import { CreateAdTypes } from '../types';

interface ValidationErrors {
  [key: string]: ValidationError;
}

interface ErrorResponse {
  validation_errors: ValidationErrors;
}

export const useSubmitAd = (
  reset: UseFormReset<CreateAdTypes>,
  setError: UseFormSetError<CreateAdTypes>,
  getValues: UseFormGetValues<CreateAdTypes>,
) => {
  const { t } = useTranslation();
  const { images: selectedImages } = useCreateAdContext();
  const { setMainStep, setSubmitting, resetAlmostAllContext } = useCreateAdActionsContext();
  const create = useCreateAdMutation([INFINITE_LIST_QUERY_KEY, ADS_LIST_QUERY_KEY]);
  const trackEvent = useTrackEvent();
  const queryClient = useQueryClient();

  const createImageApi = useCallback(
    (formats) => AdsApi.createImages({ formats }),
    [],
  );

  const createAd = async (data: CreateAdTypes) => {
    try {
      setSubmitting(true);

      const images = await saveEntityImages(selectedImages, createImageApi);
      data.images = images.sort((a, b) => a.id - b.id);

      create.mutate(data, {
        onSuccess: async () => {
          trackEvent('publishAd');
          setMainStep(5);

          resetAlmostAllContext(); // очищаем контекст, кроме mainStep
          reset();

          await queryClient.invalidateQueries({
            queryKey: [ADS_LIST_QUERY_KEY, INFINITE_LIST_QUERY_KEY, 'me'],
          });
        },
        onError: (error: AxiosError<ErrorResponse>) => {
          console.error('Product creation failed:', error);
          if (error.response?.data) {
            const currentValues = getValues();
            /**
             * FIXME:
             * когда форма отправляется, isSubmitSuccessful становится true и блокирует кнопку отправки формы в
             * компоненте src/modules/ads/components/create-ad/components/buttons.component.tsx даже если форма
             * отправилась с ошибкой. Если сделать state и устанавливать его в true в случае успешной отправки
             * некорректно блокирует кнопку, есть 1-2 секунды когда кнопка активна и можно отправить объявление еще раз
            * */
            reset({ ...currentValues }, { keepErrors: true });

            Object.entries(error.response.data.validation_errors).forEach(
              ([field, errorDetails]) => {
                setError(field as keyof CreateAdTypes, {
                  type: 'manual',
                  message: t(`forms:validationErrors.${errorDetails.code}`),
                });
              },
            );
          }
        },
      });
    } catch (error) {
      console.error('Product creation failed:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return {
    createAd,
  };
};
