import { useMutation } from '@tanstack/react-query';
import { useParams } from 'next/navigation';
import { cleanCookies } from '@/modules/auth/providers';
import { SettingsApi } from '../api';

export const useDeleteAccountMutation = () => {
  const params = useParams();

  return useMutation({
    mutationFn: (deletion_reason: string) => SettingsApi.deleteAccount(deletion_reason),
    onSuccess: () => {
      cleanCookies();
      window.location.href = `/${params?.locale || ''}`;
    },
    onError: (error) => {
      console.error('Error deleting account:', error.message);
    },
  });
};
