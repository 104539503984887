import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { Button } from '../button';
import { Checkbox } from '../inputs';
import { InputText } from '../inputs/input-text/input-text.component';
import styles from './brands-filter.module.scss';

interface BrandsFilterProps {
  options: { label: string; value: string }[];
}

export const BrandsFilter = ({ options }: BrandsFilterProps) => {
  const { t } = useTranslation();
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [selected, setSelected] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    if (selected.length === 0) setIsAllSelected(true);
  }, [selected]);

  const filteredOptions = useMemo(() => {
    const lowerSearchValue = searchValue.toLowerCase();

    const activeBrands = options.filter((brand) => selected.includes(brand.value));
    const inactiveBrands = options.filter(
      (brand) => !selected.includes(brand.value)
        && brand.label.toLowerCase().includes(lowerSearchValue),
    );

    return [...activeBrands, ...inactiveBrands];
  }, [searchValue, options, selected]);

  const displayedOptions = useMemo(() => (
    showAll ? filteredOptions : filteredOptions.slice(0, 5)
  ), [filteredOptions, showAll]);

  const handleSelectBrand = useCallback((value: string) => {
    setIsAllSelected(false);

    setSelected((prevSelected) => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter((item) => item !== value);
      }
      return [...prevSelected, value];
    });
  }, []);

  return (
    <div className={styles.brands_wrapper}>
      <label className={styles.label}>{t('common:brandsTitle')}</label>
      <InputText
        fullWidth
        value={searchValue}
        placeholder={t('common:searchByBrands')}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <div className={styles.brands_list}>
        <div className={styles.list}>
          <OverlayScrollbarsComponent
            className={clsx(styles.scrollbar, { [styles.scrollable]: showAll })}
          >
            {isAllSelected && selected.length === 0 && (
              <label className={styles.item}>
                <Checkbox checkboxSize="24" checked={isAllSelected} />
                {t('common:all')}
              </label>
            )}
            {displayedOptions.map((item) => (
              <label
                key={item.value}
                htmlFor={item.value}
                className={styles.item}
              >
                <Checkbox
                  id={item.value}
                  checkboxSize="24"
                  onChange={() => handleSelectBrand(item.value)}
                  checked={!isAllSelected && selected.includes(item.value)}
                />
                {item.label}
              </label>
            ))}
          </OverlayScrollbarsComponent>
        </div>
        {filteredOptions.length > 5 && (
          <Button
            buttonSize="xs"
            buttonColor="green"
            appearance="secondary"
            className={styles.button}
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? t('common:showLess') : t('common:showMore')}
          </Button>
        )}
      </div>
    </div>
  );
};
