import Link from 'next/link';
import React, { memo, Fragment } from 'react';
import s from '@/styles/shared/btns/MenuPaths.module.scss';
import { useRouter } from 'next/router';
import i18n from '@/lib/i18n';
import { useCategories } from '@/modules/categories';

function MenuPaths({
  subcategory, category, undersubcategory,
}) {
  const { getCategoryByCode } = useCategories();
  const { locale } = useRouter();

  const categoryItem = getCategoryByCode(category);
  const subCategoryItem = getCategoryByCode(category, subcategory);
  const underSubCategoryItem = getCategoryByCode(category, subcategory, undersubcategory);

  const items = [categoryItem, subCategoryItem, underSubCategoryItem].filter((item) => !!item);

  return (
    <div className={s.block_menu_paths}>
      <ul className={s.menu_paths_route}>
        <li><Link href="/">{i18n.navpath.home[locale]}</Link></li>
        {items.map((item, index) => (
          <Fragment key={item.codename}>
            <li>
              <img src="/assets/icons/menupaths.svg" alt="path icon" />
            </li>
            <li>
              {index !== items.length - 1 ? (
                <Link href={item.pathname}>
                  {item.title}
                </Link>
              ) : item.title}
            </li>
          </Fragment>
        ))}
      </ul>
    </div>
  );
}

export default memo(MenuPaths);
