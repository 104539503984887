import React, { FC } from 'react';
import { Tooltip } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import { SvgIcon } from '@/modules/shared/components';
import { IAd } from '../../types';
import { useMyInactiveAdsPageContext } from './my-inactive-ads-page.provider';

type ActionColumnProps = {
  ad: IAd;
  setAds: React.Dispatch<React.SetStateAction<IAd[]>>;
};
export const ActionColumn: FC<ActionColumnProps> = ({ ad, setAds }) => {
  const { activateAd } = useMyInactiveAdsPageContext();
  const { t } = useTranslation('userTable');

  const handleClick = () => {
    activateAd(ad.id);
    setAds((prev) => prev.filter((el) => el.id !== ad.id));
  };

  return (
    <Tooltip title={t('activationAd')}>
      <button onClick={handleClick} type="button">
        <SvgIcon name="eye-opened" />
      </button>
    </Tooltip>
  );
};
