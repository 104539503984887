import React, { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import Link from 'next/link';

import { AuthProtectedLink } from '@/modules/auth';
import { useCityPrefix } from '@/context/CityPrefixContext';
import { Button, SvgIcon } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { useEventContext } from '@/modules/events';
import { EventCategoryFilter } from '@/modules/events/components';
import { LocationClarification } from '../index-page';
import { SearchBar } from '../search-bar';
import { EVENT_SEARCH_KEY } from './consts';
import { HeaderProps } from './types';
import styles from './secondary-event-header.module.scss';

export const SecondaryEventHeader = ({ showCityModal, setShowCityModal }:HeaderProps) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const { t, lang } = useTranslation('');
  const router = useRouter();

  const { cityPrefix } = useCityPrefix();
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const { category, onChangeStatusFilter, setSearchFilter } = useEventContext();


  const onSearchEvent = (value: string) => {
    setSearchFilter(value);
  };

  return (
    <div className={styles.secondary_event_wrapper}>
      <div className={styles.secondary_event}>
        {(!isMobile && !isTablet) && (
        <Button
          as="link"
          href={`/${router.locale || 'en'}`}
          appearance="secondary"
          buttonSize="s"
          buttonColor="blue"
          className={styles.button_returnHome}
        >
          <SvgIcon name="arrow-back-left" />
          {t('common:returnHome')}
        </Button>
        )}

        <div className={styles.section}>
          {(!isMobile && !isTablet) && (
          <>
            <div className={styles.logo}>
              <Link href={`/${router.locale || 'en'}/${cityPrefix}/posters`}>
                <SvgIcon name={lang === 'ru' ? 'logo-poster-1' : 'logo-poster-1-en'} />
              </Link>
            </div>

            <AuthProtectedLink href="/events/addevents">
              <Button
                appearance="primary"
                buttonSize="s"
                buttonColor="blue"
                className={styles.button_add}
              >
                <SvgIcon name="plus" />
                {t('events:addEvent')}
              </Button>
            </AuthProtectedLink>
          </>
          )}
          <div className={styles.wrapper}>
            <div className={styles.search}>
              <SearchBar
                isInputFocused={isInputFocused}
                setIsInputFocused={setIsInputFocused}
                keyHistory={EVENT_SEARCH_KEY}
                onSearchFilter={onSearchEvent}
                text={{ placeholder: t('events:search.placeholder') }}
                iconOnly={!!isMobile}
              />
            </div>

            {((isMobile || isDesktop) && !isTablet) && (
            <LocationClarification
              isShowModal={showCityModal}
              onChange={() => setShowCityModal(!showCityModal)}
              iconOnly={!!isMobile}
              classNameIcon={isMobile ? styles.svgIconHeader : ''}
            />
            )}
          </div>

          {isMobile && (
          <EventCategoryFilter
            onClick={onChangeStatusFilter}
            isActive={category}
          />
          )}
        </div>
      </div>
    </div>
  );
};
