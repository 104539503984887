import React from 'react';
import type { Me } from '@/modules/me/types';
import { ItemLoader } from '@/modules/shared/components';
import { useUserType } from '@/modules/users/hooks';
import { UserInfo, UserSidebarLinks } from './components';
import styles from './side-menu.module.scss';

interface Props {
  userProfile: Me;
}

export const SideMenu = ({ userProfile }: Props) => {
  const { type, companyData } = useUserType(userProfile?.user_type, userProfile?.id, userProfile?.company_id);

  return (
    <section className={styles.side_menu}>
      {!userProfile ? (
        <ItemLoader size="100%" isLoading={!userProfile} />
      ) : (
        <>
          <UserInfo user={userProfile} type={type} companyData={companyData} />
          <UserSidebarLinks user={userProfile} status={companyData?.status} />
        </>
      )}
    </section>
  );
};
