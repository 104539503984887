import { useEffect } from 'react';
import { useCreateAdActionsContext, useCreateAdContext } from '../context';

const additionalSpecCategories = ['personal-items', 'transport', 'real-estate'];
const additionalSpecSubCategories = ['cargo-transportation'];

export const useAdditionalSpecifications = () => {
  const { category, subCategory } = useCreateAdContext();
  const { setIsAdditionalSpecifications } = useCreateAdActionsContext();

  useEffect(() => {
    const isAddSpecInCategory = Boolean(category && additionalSpecCategories.includes(category.codename));
    const isAddSpecInSubCategory = Boolean(subCategory && additionalSpecSubCategories.includes(subCategory.codename));

    if (isAddSpecInCategory || isAddSpecInSubCategory) {
      setIsAdditionalSpecifications(true);
    } else {
      setIsAdditionalSpecifications(false);
    }
  }, [category, subCategory]);
};
