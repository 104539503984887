import { api, fetchList } from '@/modules/shared/api';
import { SuggestionFeedbackTypes } from '../types';
import { endpoints } from './endpoints';

export const SuggestionFeedback = {
  suggestionList: async () => {
    const data = await fetchList<SuggestionFeedbackTypes[]>(endpoints.list, {});
    return data;
  },
  removeSuggestion: async (announcement_id: number) => {
    const data = await api.patch<SuggestionFeedbackTypes[]>(
      endpoints.removeSuggestion(announcement_id),
      { hidden: true },
    );
    return data;
  },
};
