import React, { FC, useMemo } from 'react';
import { Stack, Breadcrumbs } from '@mui/material';
import Link from '@mui/material/Link';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import styles from './breadcrumbs.module.scss';
import { SvgIcon } from '../svg-icon';
import { BreadcrumbType } from './breadcrumbs.type';

export const BreadCrumbs: FC<{ data: BreadcrumbType[] }> = ({ data }) => {
  const { t } = useTranslation();

  const mainBreadcrumb = useMemo(
    () => ({
      label: t('common:tabBar.main'),
      pathname: '/',
      codename: 'main',
    }),
    [t],
  );

  const breadcrumbs = useMemo(() => {
    if (!data) return [];
    return [
      mainBreadcrumb,
      ...data.map((item) => ({
        label: item.title,
        pathname: item.pathname,
        codename: item.codename,
      })),
    ];
  }, [data, mainBreadcrumb]);

  return (
    <Stack>
      <Breadcrumbs
        separator={(
          <SvgIcon
            name="arrow-right"
            style={{ fontSize: '16px', color: '#A7AAAF' }}
          />
        )}
        sx={{ '& .MuiBreadcrumbs-separator': { margin: '0 4px' } }}
      >
        {breadcrumbs.slice(0, -1).map((breadcrumb, index) => (
          <Link
            key={index}
            underline="none"
            href={breadcrumb.pathname}
            className={clsx(styles.link, {
              [styles.activeLink]: index === breadcrumbs.length - 1,
            })}
            data-testid={`breadcrumb_${breadcrumb.codename}`}
          >
            {breadcrumb.label}
          </Link>
        ))}
        <p className={styles.ad_title}>{breadcrumbs[breadcrumbs.length - 1].label}</p>
      </Breadcrumbs>
    </Stack>
  );
};
