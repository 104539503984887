import { Modal } from '@/modules/shared/components';

import { useMeContext } from '@/modules/me';
import useTranslation from 'next-translate/useTranslation';
import { useMatchMedia } from '@/modules/shared/hooks';
import { useRouter } from 'next/router';
import { api } from '@/modules/shared/api';
import { useEffect, useState } from 'react';
import { getCookie } from '@/components/cookie/cookieHelpers';
import styles from './not-authorized-modal.module.scss';

import Logo from './assets/logo.svg';

export const AuthorizedBlackFridayModal = ({ open, onClose }: { open: boolean, onClose: (shallow: boolean) => void }) => {
  const { me } = useMeContext();
  const { isMobile } = useMatchMedia();
  const { t, lang } = useTranslation();
  const router = useRouter();
  const [secretTokenWithData, setSecretTokenWithData] = useState('');

  const allDataVerified = me?.is_email_verified;

  useEffect(() => {
    if (!open || !allDataVerified) return;

    const getToken = async () => {
      try {
        const response = await api.get('/api/contests/black-friday-token');
        setSecretTokenWithData(response.data?.token || '');
      } catch (e) {
        console.error(e);
      }
    };

    getToken();
  }, [open, allDataVerified]);


  const footer = (
    <button
      className={styles.button}
      onClick={() => {
        onClose(true);
        if (!allDataVerified) {
          if (router.asPath.includes('/user/account')) {
            router.replace(`/${lang}/user/account`);
          } else {
            router.push(`/${lang}/user/account`);
          }
        } else {
          const bzrRef = getCookie('bzrRef');
          window.open(
            `https://salebot.site/fa94840089f491_1?skey=${secretTokenWithData}${bzrRef ? `&ref=${bzrRef}` : ''}`,
            '_blank',
          );
        }
      }}
    >
      {allDataVerified ? t('black-friday:join') : t('black-friday:confirmCredentials')}
    </button>
  );

  if (allDataVerified && !secretTokenWithData) return;

  return (
    <Modal
      className={styles.darkTheme}
      fullHeight
      open={open}
      showBackButton={false}
      closeButtonDelay={4000}
      hasHeader={false}
      onClose={onClose}
      footer={isMobile ? (
        footer
      ) : null}
    >
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <div>
            <div style={{ color: '#47c713' }}>{t('black-friday:authorized.title1')}</div>
            {' '}
            {t('black-friday:authorized.title2')}
          </div>
        </div>
        <div
          className={styles.title}
          style={{
            marginTop: 10,
          }}
        >
          {t('black-friday:authorized.at')}
          {' '}
          <Logo />
        </div>
        <div className={styles.subTitle}>
          {t('black-friday:authorized.subTitle')}
        </div>
        <div className={styles.allPrizes} />
        {!isMobile && (
          footer
        )}
      </div>
    </Modal>
  );
};
