import React from 'react';
import clsx from 'clsx';
import { useFormatDataPrice, useMatchMedia } from '@/modules/shared/hooks';
import { SvgIcon } from '@/modules/shared/components';
import { AdLink } from '@/modules/ads/components';
import type { Announcement } from '../../types';
import styles from './ad-name.module.scss';

export type AdNameProps = {
  announcement: Announcement | null;
  oneChat?: boolean;
  link?: boolean;
};

const truncatedTitle = (title: string, num: number) => (title.length > num ? `${title.slice(0, num)}...` : title);

export const AdName = ({
  announcement, oneChat = false, link = false,
}: AdNameProps) => {
  const priceDisplayFormat = useFormatDataPrice();
  const { isTablet, isDesktop } = useMatchMedia();

  if (!announcement) return null;
  const { title, price, price_type } = announcement;

  const desktopLength = (isDesktop || isTablet) ? 32 : 13;
  const lengthStr = (oneChat) ? 32 : desktopLength;

  const content = () => (
    <p
      className={clsx(styles.ad_title, {
        [styles.ad_title__one_chat]: oneChat,
      })}
    >
      {truncatedTitle(title, lengthStr)}
      {' | '}
      <span>
        {priceDisplayFormat({ priceType: price_type, price })}
        {price_type === 'for_money' && (
        <SvgIcon
          name={`THB-${isDesktop ? '14' : oneChat ? '11' : '12'}-medium`}
          className={clsx(styles.icon, {
            [styles.icon__one_chat]: oneChat,
          })}
        />
        )}
      </span>
    </p>
  );


  return link ? (
    <AdLink ad={announcement}>
      {content()}
    </AdLink>
  ) : content();
};
