import React, { useState, useEffect, useRef } from 'react';
import { useActivationTimer } from '@/modules/auth/helpers';


interface ActivationTimerProps {
  handleTimerEnd?: () => void;
}

export const ActivationTimer = ({ handleTimerEnd }: ActivationTimerProps) => {
  const { activationTimer } = useActivationTimer();
  const [countdown, setCountdown] = useState(activationTimer);
  const timerId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const stopTimer = () => {
      if (timerId.current) {
        clearInterval(timerId.current);
        timerId.current = null;
      }
    };

    if (countdown > 0) {
      timerId.current = setTimeout(() => {
        setCountdown((prevCountdown) => {
          const newCountdown = prevCountdown - 1;
          if (newCountdown <= 0) {
            stopTimer();
            handleTimerEnd?.();
          }
          return newCountdown;
        });
      }, 1000);
    }

    return () => stopTimer();
  }, [countdown, handleTimerEnd]);

  return countdown > 0 ? <span>{countdown}</span> : null;
};
