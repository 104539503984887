import React from 'react';
import Link from 'next/link';
import { SvgIcon } from '../svg-icon';
import styles from './notification.module.scss';

interface NotificationProps {
  mainText: string;
  link: string;
  href: string;
}

/**
 * @param {string} mainText - Текст уведомления
 * @param {string} link - Текст ссылки
 * @param {string} link - Адрес ссылки
 * */

export const Notification = (props: NotificationProps) => {
  const { mainText, link, href } = props;

  return (
    <div className={styles.email_notification_wrapper}>
      <div className={styles.email_notification}>
        <div className={styles.svg}>
          <SvgIcon name="alert" />
        </div>
        <div className={styles.text}>
          <p>
            {mainText}
            <Link href={href}>{link}</Link>
          </p>
        </div>
      </div>
    </div>
  );
};
