import React, { useCallback, useMemo, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useMatchMedia } from '@/modules/shared/hooks';
import { MobileReasonModal } from './mobile-reason-modal.component';
import { DesktopReasonModal } from './desktop-reason-modal.component';
import { DesktopDeleteModal } from './desktop-delete-modal.component';
import { MobileDeleteModal } from './mobile-delete-modal.component';
import { DeletionModalProps } from './deletion.types';
import { useDeleteAccountMutation } from '../mutations';

export const DeletionModals = ({ isOpen, onClose }: DeletionModalProps) => {
  const { t } = useTranslation('userSettings');
  const [selectedReason, setSelectedReason] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { isMobile } = useMatchMedia();
  const { mutate: deleteAccount } = useDeleteAccountMutation();

  const reasons = useMemo(() => [
    { value: 'security_concerns', label: t('deleteReason.securityConcern') },
    { value: 'another_account', label: t('deleteReason.anotherAcc') },
    { value: 'service_quality', label: t('deleteReason.dissatisfied') },
    { value: 'no_longer_needed', label: t('deleteReason.noNeed') },
    { value: 'other', label: t('deleteReason.otherReason') },
  ], [t]);

  const onOpenDeleteModal = useCallback(() => {
    setIsConfirmed(true);
  }, []);

  const onCloseDeleteModal = useCallback(() => {
    setIsConfirmed(false);
  }, []);

  const onDeleteAccount = useCallback(async () => {
    await deleteAccount(selectedReason);
    onClose();
  }, [selectedReason, onClose, deleteAccount]);

  return (
    <>
      {isMobile ? (
        !isConfirmed ? (
          <MobileReasonModal
            isOpen={isOpen}
            options={reasons}
            selectedReason={selectedReason}
            onSelectReason={setSelectedReason}
            onClose={onClose}
            onConfirm={onOpenDeleteModal}
          />
        ) : (
          <MobileDeleteModal
            isConfirmed={isConfirmed}
            onDelete={onDeleteAccount}
            onClose={onClose}
            onBack={onCloseDeleteModal}
          />
        )
      ) : !isConfirmed ? (
        <DesktopReasonModal
          isOpen={isOpen}
          options={reasons}
          selectedReason={selectedReason}
          onSelectReason={setSelectedReason}
          onClose={onClose}
          onConfirm={onOpenDeleteModal}
        />
      ) : (
        <DesktopDeleteModal
          isConfirmed={isConfirmed}
          onDelete={onDeleteAccount}
          onClose={onClose}
        />
      )}
    </>
  );
};
