import React, {
  useEffect, useCallback, useMemo, useState,
} from 'react';
import { useRouter } from 'next/router';
import { FormProvider, useForm } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { useToggleMyAdVisibility } from '@/modules/ads/mutations';
import { Button, ButtonSwitcher, SvgIcon } from '@/modules/shared/components';
import Loadingmain from '@/components/shared/skeleton/loadingmain';
import { useInfiniteListRef, useMatchMedia } from '@/modules/shared/hooks';
import { useMyAdsInfiniteListQuery, useMyAdsListQuery } from '@/modules/ads/queries';
import { MyAdsList, NotAnyAds } from './components';
import styles from './my-ads-page.module.scss';

type AdType = 'active' | 'hidden';

const ITEMS_PER_PAGE = 10;

export const MyAdsPage = () => {
  const [currentPages, setCurrentPages] = useState({
    active: 1,
    hidden: 1,
  });
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedAds, setSelectedAds] = useState<number[]>([]);
  const [quantity, setQuantity] = useState({ active: 0, hidden: 0 });
  const { t } = useTranslation();
  const router = useRouter();
  const { isMobile, isDesktop } = useMatchMedia();
  const [adType, setAdType] = useState(router.query.tab as AdType || 'active');
  const { mutate: toggleAdVisibility, isSuccess } = useToggleMyAdVisibility();

  const formMethods = useForm({ defaultValues: { sort: '-created_at' } });

  const { watch, setValue } = formMethods;
  const selectedSort = watch('sort');

  const filters = useMemo(() => ({
    is_active: adType === 'active' ? 'True' : adType === 'hidden' ? 'False' : undefined,
  }), [adType]);

  const {
    data, isLoading: desktopLoading, initialLoading, refetch,
  } = useMyAdsListQuery({
    pageSize: ITEMS_PER_PAGE,
    order: selectedSort,
    page: currentPages[adType],
    filters,
    disabled: !isDesktop,
  });

  const {
    pages, isLoading, hasMore, fetchMore, refetch: refetchList,
  } = useMyAdsInfiniteListQuery({
    pageSize: 10,
    order: selectedSort,
    filters,
    disabled: isDesktop,
  });

  useEffect(() => {
    if (isSuccess) {
      if (isDesktop) {
        refetch();
      } else {
        refetchList();
      }
    }
  }, [isSuccess, isDesktop, refetch, refetchList]);

  useEffect(() => {
    if (isLoading || desktopLoading) return;

    const {
      active = quantity.active, inactive = quantity.hidden,
    } = isDesktop ? data?.counts || {} : pages?.[0]?.counts || {};

    if (active === quantity.active && inactive === quantity.hidden) return;

    setQuantity({ active, hidden: inactive });
  }, [data, pages, isDesktop, desktopLoading, isLoading, quantity]);

  useEffect(() => {
    const savedPages = JSON.parse(sessionStorage.getItem('returning-my-ads-page') || '{}');
    if (savedPages[adType]) {
      setCurrentPages((prevPages) => ({
        ...prevPages,
        [adType]: savedPages[adType],
      }));
    }
  }, [adType]);

  useEffect(() => {
    if (selectedSort !== '-created_at') {
      setCurrentPages((prevPages) => ({
        ...prevPages,
        [adType]: 1,
      }));
    }
  }, [selectedSort]);

  useEffect(() => {
    if (data.counts?.active === 0 && data.counts?.inactive === 0) return;
    if (!router.asPath.includes(adType)) {
      router.replace({ pathname: router.pathname, query: { tab: adType } });
    }
  }, [router, adType, data]);

  const lastListItemRef = useInfiniteListRef(hasMore, fetchMore, isLoading);

  const myAds = useMemo(() => {
    if (isDesktop) return data?.results;
    return pages;
  }, [isDesktop, data, pages, adType]);

  const buttons = useMemo(() => [
    {
      value: 'active',
      name: `${t('userProfile:myAds.active')} ${!isMobile && quantity.active ? `${quantity.active}` : ''}`,
      dataTestId: 'active',
    },
    {
      value: 'hidden',
      name: `${t('userProfile:myAds.hidden')} ${!isMobile && quantity.hidden ? `${quantity.hidden}` : ''}`,
      dataTestId: 'hidden',
    },
  ], [t, quantity, isMobile]);

  const handleSetPage = useCallback((tab: AdType, page: number) => {
    setCurrentPages((prevPages) => {
      const newPages = { ...prevPages, [tab]: page };
      sessionStorage.setItem('returning-my-ads-page', JSON.stringify(newPages));
      return newPages;
    });
  }, []);

  const handleChangeAdType = useCallback((status: AdType) => {
    setAdType(status);
    setSelectedAds([]);
    setIsAllSelected(false);
    if (selectedSort !== '-created_at') setValue('sort', '-created_at');
  }, [setValue, selectedSort]);

  const shouldShowNoAdsMessage = (adTypeCondition, quantityCheck) => ((adTypeCondition || !myAds)
    && quantityCheck === 0 && !(desktopLoading || isLoading));

  if (initialLoading) return <Loadingmain />;
  if (data.counts?.active === 0 && data.counts?.inactive === 0) {
    return <NotAnyAds />;
  }

  return (
    <FormProvider {...formMethods}>
      <div className={styles.wrapper}>
        <div className={styles.ads_status_wrapper}>
          <ButtonSwitcher
            kind="outline"
            buttonSize={isMobile ? undefined : 'l'}
            buttons={buttons}
            onChange={handleChangeAdType}
            value={adType}
          />
          {shouldShowNoAdsMessage(adType === 'active', quantity.active) && (
            <div className={styles.not_active_ads}>
              {t('userProfile:myAds.ads.none')}
              {isDesktop && (
                <Button
                  appearance="primary"
                  buttonColor="blue"
                  buttonSize="s"
                  as="link"
                  href="/product/create"
                >
                  {t('common:header.placeAd')}
                </Button>
              )}
            </div>
          )}
          {shouldShowNoAdsMessage(adType === 'hidden', quantity.hidden) && (
            <div className={styles.not_hidden_ads}>{t('userProfile:myAds.hiddenAds.none')}</div>
          )}
        </div>
        <MyAdsList
          ads={myAds}
          adType={adType}
          hasMore={hasMore}
          quantity={quantity}
          isLoading={desktopLoading || isLoading}
          fetchMore={fetchMore}
          currentPage={currentPages[adType]}
          selectedAds={selectedAds}
          onSelectAd={setSelectedAds}
          isInfinity={lastListItemRef}
          isAllSelected={isAllSelected}
          totalPages={data?.total_pages}
          onIsAllSelected={setIsAllSelected}
          toggleAdVisibility={toggleAdVisibility}
          handleSetPage={(page) => handleSetPage(adType, page)}
          ITEMS_PER_PAGE={ITEMS_PER_PAGE}
          currentPages={currentPages}
          setCurrentPages={setCurrentPages}
        />
        {!isDesktop && (
          <div className={styles.button_add}>
            <Button
              as="link"
              fullWidth
              buttonSize="s"
              buttonColor="green"
              appearance="primary"
              href="/product/create"
            >
              <SvgIcon name="plus" />
              {t('common:header.placeAd')}
            </Button>
          </div>
        )}
      </div>
    </FormProvider>
  );
};
