import {
  apiWithoutToast,
  api,
  fetchList,
  saveEntityImages,
  create,
} from '@/modules/shared/api';
import { IReviews, FormImage, EntityImage } from '@/modules/shared/types';
import { ReviewFormTypes, RequestReviewTypes, RequestReviewResponseTypes } from '../types';
import { endpoints } from './endpoints';

export const ReviewApi = {
  createReview: async (data: ReviewFormTypes) => {
    try {
      if (data.photos) {
        const savedImages: FormImage[] = await saveEntityImages(
          data.photos as FormImage[],
          (formats) => create(endpoints.createImage, { formats }),
        );
        const sortedImages = savedImages.sort((a: EntityImage, b: EntityImage) => a.id - b.id);
        delete data.photos;
        data.images = sortedImages;
      }
      const response = await apiWithoutToast.post(endpoints.createReview(), data);
      return response.data;
    } catch (error) {
      console.log(error);
      return error.response.data.code;
    }
  },
  givenReviews: async (config = {}) => {
    const data = await fetchList<IReviews[]>(
      endpoints.givenReviews,
      config,
    );

    return data;
  },
  reviewsList: async () => {
    const data: IReviews[] = await fetchList(endpoints.reviewsList, {});
    return data;
  },
  reviewAnswer: async (id: string, answerData: Record<string, string>) => {
    const response = await api.patch(endpoints.reviewAnswer(id), answerData);
    return response.data;
  },

  getReviewRequest: async (reviewerId?: number) => {
    const response = await api.get<RequestReviewResponseTypes[]>(endpoints.reviewRequest(reviewerId));
    return response.data;
  },
  createReviewRequest: async (requestData: RequestReviewTypes) => {
    const { data } = await api.post<RequestReviewTypes>(endpoints.reviewRequest(), {
      reviewer: requestData.reviewer,
      announcement: requestData.announcement,
    });
    return data;
  },
};
