import React from 'react';
import { SvgIcon } from '@/modules/shared/components';
import useTranslation from 'next-translate/useTranslation';
import { setInitialCityInfo } from '@/modules/locations/helpers';
import styles from './locaion-clarification.module.scss';

interface IProps {
  city: string;
  modalRef: React.MutableRefObject<HTMLDivElement | null>;
  setShowModal: (value: React.SetStateAction<boolean>) => void;
  onClose: () => void;
}

export const ConfirmCityModal = ({
  city, modalRef, setShowModal, onClose,
}: IProps) => {
  const { t } = useTranslation('common');

  const handleYesButtonClick = () => {
    if (city === 'allcities') {
      setShowModal(true);
    } else {
      onClose();
      setInitialCityInfo({ city, userSet: true });
    }
  };

  const handleNoButtonClick = () => {
    if (city === 'allcities') {
      onClose();
      setInitialCityInfo({ city, userSet: true });
    } else {
      setShowModal(true);
    }
  };

  return (
    <div className={styles.modal} ref={modalRef}>
      <SvgIcon name="shape" style={{ width: '26px', height: '8px' }} />
      <div className={styles.confirm_city}>
        <p>{city === 'allcities' ? t('selectCity') : t('confirmCity')}</p>
        <button
          type="button"
          data-testid="city_selector_change"
          onClick={handleYesButtonClick}
          className={styles.city_selector_change}
        >
          {city === 'allcities' ? t('select') : t('yes')}
        </button>
        <button
          type="button"
          data-testid="city_selector_no"
          onClick={handleNoButtonClick}
          className={styles.city_selector_no}
        >
          {city === 'allcities' ? t('no') : t('change')}
        </button>
      </div>
    </div>
  );
};
