import React, {
  ReactNode, useMemo, useCallback, useRef,
} from 'react';
import clsx from 'clsx';
import { useUpdateEffect } from '@react-hookz/web';
import { useMatchMedia } from '../../hooks/use-match-media.hook';
import { SvgIcon } from '../svg-icon';
import styles from './pagination.module.scss';

export type PaginationProps = {
  children: ReactNode;
  className?: string;
  itemsPerPage?: number;
  position?: 'start' | 'center' | 'end';
  hasMore?: boolean;
  fullList?: boolean;
  paginationList?: boolean;
  totalPages?: number;
  currentPage: number;
  fetchMore?: (() => void) | undefined;
  handleSetPage?: (page: number) => void;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
};

/**
 * @param itemsPerPage - количество карточек на странице
 * @param fullList - если true, то показывать все карточки без пагинации
 * @param paginationList - если false, покажет только первую страницу, без возможности "перелистнуть"
 * */

export const Pagination = ({
  children,
  className,
  itemsPerPage = 11,
  position = 'end',
  hasMore = false,
  fullList: fullListProp,
  paginationList: paginationListProp,
  totalPages: totalPagesProp,
  fetchMore,
  currentPage = 1,
  setCurrentPage,
  handleSetPage,
}: PaginationProps) => {
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const containerRef = useRef<HTMLDivElement>(null);

  const fullList = fullListProp !== undefined ? fullListProp : (isMobile || isTablet);
  const paginationList = paginationListProp !== undefined ? paginationListProp : isDesktop;

  const siblingCount = 2;
  const totalPages = useMemo(() => {
    if (totalPagesProp !== undefined) {
      return totalPagesProp;
    }
    return Math.ceil(React.Children.count(children) / itemsPerPage);
  }, [children, itemsPerPage, totalPagesProp]);

  const displayItems = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    if (handleSetPage) {
      return React.Children.toArray(children);
    }
    return React.Children.toArray(children).slice(startIndex, endIndex) as React.ReactNode[];
  }, [children, currentPage, itemsPerPage]);

  const createPageRange = useMemo(() => {
    const totalPageNumbers = siblingCount * 2 + 5;

    if (totalPageNumbers >= totalPages) {
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPages);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPages;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftRange = Array.from({ length: 5 }, (_, index) => index + 1);
      return [...leftRange, 'right-ellipsis', lastPageIndex];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightRange = Array.from(
        { length: 5 },
        (_, index) => totalPages - 4 + index,
      );
      return [firstPageIndex, 'left-ellipsis', ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = Array.from({ length: 5 }, (_, index) => leftSiblingIndex + index);
      return [firstPageIndex, 'left-ellipsis', ...middleRange, 'right-ellipsis', lastPageIndex];
    }
  }, [currentPage, siblingCount, totalPages]);

  const handlePageClick = useCallback((page: number | string) => {
    if (typeof page === 'number') {
      if (setCurrentPage) setCurrentPage(page);
      if (handleSetPage) handleSetPage(page);
      if (page > currentPage && hasMore && fetchMore) {
        fetchMore();
      }
    } else if (page === 'left-ellipsis') {
      const newPage = Math.max(currentPage - 5, 1);
      if (setCurrentPage) setCurrentPage(newPage);
      if (handleSetPage) handleSetPage(newPage);
    } else if (page === 'right-ellipsis') {
      const newPage = Math.min(currentPage + 5, totalPages);
      if (setCurrentPage) setCurrentPage(newPage);
      if (handleSetPage) handleSetPage(newPage);
      if (newPage > currentPage && hasMore && fetchMore) {
        fetchMore();
      }
    }
  }, [currentPage, totalPages, hasMore, fetchMore, handleSetPage]);

  useUpdateEffect(() => {
    // На первую загрузку страницы скролл не должен срабатывать.
    // // Например, при возврате со страницы объявления назад к списку избранных
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      if (rect.top < 0) {
        containerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } // скроллим к верху списка, только если реф выше верхней границы экрана
  }, [currentPage]);

  return (
    <div className={clsx(styles.wrapper, {
      [styles.end]: position === 'end',
      [styles.center]: position === 'center',
      [styles.start]: position === 'start',
    })}
    >
      <div className={clsx(styles.container, className)} ref={containerRef}>
        {fullList && children}
        {!fullList && displayItems.map((item, index) => (
          <React.Fragment key={index}>{item}</React.Fragment>
        ))}
      </div>

      {(totalPages > 1 && (paginationList && !fullList)) && (
        <div className={styles.pagination}>
          <button
            onClick={() => handlePageClick(currentPage - 1)}
            disabled={currentPage === 1}
            className={styles.arrow}
            type="button"
          >
            <SvgIcon name="arrow-forward-left" />
          </button>

          {createPageRange?.map((page, index) => (
            <button
              key={index}
              type="button"
              onClick={() => handlePageClick(page)}
              className={clsx(styles.numeral, {
                [styles.numeral_active]: currentPage === page,
              })}
            >
              {typeof page === 'string' ? '...' : page}
            </button>
          ))}

          <button
            onClick={() => handlePageClick(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={styles.arrow}
            type="button"
          >
            <SvgIcon name="arrow-forward-right" />
          </button>
        </div>
      )}
    </div>
  );
};
