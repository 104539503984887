import React, { FC } from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { SvgIcon } from '@/modules/shared/components';
import styles from './mobile-header.module.scss';

interface MobileHeaderProps {
  title?: React.ReactNode;
  className?: string;
  showBackButton?: boolean;
  showOnCloseButton?: boolean;
  onClose?: () => void;
  onClickBack?: () => void;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
}

export const MobileHeader: FC<MobileHeaderProps> = ({
  title,
  className,
  showBackButton = true,
  showOnCloseButton = true,
  onClose,
  onClickBack,
  leftContent,
  rightContent,
}) => {
  const { back } = useRouter();

  return (
    <header className={clsx(styles.mobile_header, className)}>
      <div className={styles.left_section}>
        {showBackButton && (
          <button onClick={onClickBack || back} className={styles.icon_button} type="button">
            <SvgIcon name="arrow-back" />
          </button>
        )}
        {leftContent && (
          <div className={styles.left_content}>{leftContent}</div>
        )}
      </div>
      <div className={styles.center_section}>{title}</div>
      <div className={styles.right_section}>
        {rightContent && (
          <div className={styles.right_content}>{rightContent}</div>
        )}
        {showOnCloseButton && (
          <button onClick={onClose} className={styles.icon_button} type="button">
            <SvgIcon name="close-icon" />
          </button>
        )}
      </div>
    </header>
  );
};
