import React, { FC, ReactNode, useMemo } from 'react';
import { useRouter } from 'next/router';
import { AuthProtectedLink } from '@/modules/auth';
import { useCityPrefix } from '@/context/CityPrefixContext';
import Link from 'next/link';
import clsx from 'clsx';
import styles from './mobile-navigation.module.scss';

export interface RouteProps {
  path: string;
  icon: ReactNode;
  isAuthProtected: boolean;
  customAuthHeader?: string;
  label: string;
}

export const Route: FC<RouteProps> = ({
  path, icon, label, isAuthProtected, customAuthHeader,
}) => {
  const router = useRouter();
  const { locale } = router;
  const { cityPrefix } = useCityPrefix();

  const isActiveIcon = useMemo(() => (router.route.replace('/[city]', '') === path ? styles.active : null), [router, path]);

  const routePath = useMemo(() => {
    const isEvent = path.includes('/posters');
    return isEvent ? `/${locale}/${cityPrefix}${path}` : `/${locale}${path}`;
  }, [path, locale, cityPrefix]);

  const content = (
    <button
      className={clsx(styles.route, {
        [styles.active]: isActiveIcon,
      })}
    >
      {icon}
      <p>{label}</p>
    </button>
  );

  return isAuthProtected ? (
    <AuthProtectedLink customHeader={customAuthHeader} href={routePath}>{content}</AuthProtectedLink>
  ) : (
    <Link href={routePath}>{content}</Link>
  );
};
