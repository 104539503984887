import { FormState } from '@/modules/shared/hooks';
import { InputRange } from '@/modules/shared/components';

import { useMemo } from 'react';
import { FiltersMap } from '../types';
import { withErrorBoundary } from '../hoc/withErrorBoundary';
import { withDynamicFieldFormGuard } from '../hoc/withDynamicFieldFormGuard';
import { FilterRow } from '../components/filter-row';

import styles from './range.module.scss';


type RangeFieldProps = {
  filter: FiltersMap['range'],
  form: FormState<FiltersMap['range']['params']['min'] | FiltersMap['range']['params']['max']>
};

export const RangeField = withErrorBoundary(withDynamicFieldFormGuard<RangeFieldProps>(({ t, form, filter: { params, name } }) => {
  if (!t(`filters:range.${name}.title`, null, {
    default: false,
  })) {
    console.log(`Unknown filters:range.${name}.title`);
    return null;
  }

  const postfix = useMemo(() => {
    const unit = t(`filters:range.${name}.unit`, null, {
      default: undefined,
    });

    return unit && <span className={styles.postfix}>{unit}</span>;
  }, [name, t]);

  return (
    <FilterRow title={t(`filters:range.${name}.title`)}>
      <InputRange
        fullWidth
        fromPostfix={postfix}
        toPostfix={postfix}
        valueFrom={form.value[params.min] || ''}
        valueTo={form.value[params.max] || ''}
        onChangeFrom={form.setter[params.min]!}
        onChangeTo={form.setter[params.max]!}
      />
    </FilterRow>
  );
}));
