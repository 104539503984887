import React, {
  FC, useEffect, useState,
} from 'react';
import { SvgIcon } from '@/modules/shared/components';
import { Tooltip } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import { IAd } from '../../types';
import { useMyAdsPageContext } from './my-ads-page.provider';

export type ActionColumnProps = {
  ad: IAd;
  setAds: React.Dispatch<React.SetStateAction<IAd[]>>;
};

export const ActionColumn: FC<ActionColumnProps> = ({ ad, setAds }) => {
  const { adToDeactivate, setAdToDeactivate } = useMyAdsPageContext();
  const [targetId, setTargetId] = useState<number>();
  const { t } = useTranslation('userTable');

  useEffect(() => {
    if (adToDeactivate === null) {
      setAds((prev) => prev.filter((el) => el.id !== targetId));
    }
  }, [adToDeactivate]);

  const handleClick = () => {
    setAdToDeactivate(ad);
    setTargetId(ad.id);
  };

  return (
    <Tooltip title={t('deactivateAd')}>
      <button onClick={handleClick} type="button">
        <SvgIcon name="eye-closed" />
      </button>
    </Tooltip>
  );
};
