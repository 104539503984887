import React, { SetStateAction, useCallback } from 'react';
import Link from 'next/link';
import { Scrollbar, SvgIcon } from '@/modules/shared/components';
import { useCategoryName } from '@/modules/categories/hooks';
import { Category, SubCategory } from '@/modules/categories';
import styles from '../category-list.module.scss';

interface Props {
  activeCategory: Category | null;
  setStep: React.Dispatch<SetStateAction<number>>;
  setActiveSubcategory: React.Dispatch<React.SetStateAction<SubCategory | null>>;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CategoryListStepTwo = (props: Props) => {
  const {
    activeCategory, setStep, setActiveSubcategory, onClose,
  } = props;
  const [getNameByCode] = useCategoryName();

  const handleCategoryClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, subcategory: SubCategory) => {
      if (subcategory.under_sub_categories.length > 0) {
        event.preventDefault();
        setStep(3);
        setActiveSubcategory(subcategory);
      } else {
        onClose(false);
      }
    },
    [],
  );

  return (
    <>
      <Link
        href={activeCategory!.pathname}
        className={styles.subcategory}
        onClick={() => onClose(false)}
      >
        <h4>{getNameByCode(activeCategory!.codename)}</h4>
        <SvgIcon name="arrow-right" />
      </Link>
      <Scrollbar>
        {activeCategory!.sub_categories.map((subcategory, index) => (
          <Link
            key={index}
            href={subcategory.pathname}
            className={styles.subcategory}
            onClick={(event) => handleCategoryClick(event, subcategory)}
            data-testid={`sub_section_${subcategory.codename}`}
          >
            <p>
              {getNameByCode(activeCategory!.codename, subcategory.codename)}
            </p>
            <SvgIcon name="arrow-right" />
          </Link>
        ))}
      </Scrollbar>
    </>
  );
};
