import { toast } from 'react-toastify';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Me } from '@/modules/me/types';
import { api, baseEndpointV1 } from '@/modules/shared/api';
import { IAd } from '../types';


export const useMyAds = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState<Me>();
  const { t } = useTranslation();

  const fetchUserData = useCallback(async () => {
    try {
      const response = await api.get('/api/users/profile/');
      setUserData(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setError, setUserData]);

  const reasons = useMemo(() => [
    'sold_on_bazaar',
    'sold_somewhere_else',
    'other',
    'duplicate_information',
    'inappropriate_content',
  ].map((reason) => ({
    value: reason,
    label: t(`adv:personalAds.reasonsDeactivation.${reason}`),
  })), [t]);

  const updateProfile = useCallback(async (
    activeAds: IAd[] = [],
    inactiveAds: IAd[] = [],
  ) => {
    setUserData((prevData) => {
      if (!prevData) {
        return prevData;
      }
      return {
        ...prevData,
        announcements: activeAds,
        not_active_announcements: inactiveAds,
      };
    });
    await fetchUserData();
  }, [setUserData, fetchUserData]);

  const deactivateMyAd = useCallback(async (
    id: number,
    body: Record<string, string>,
  ) => {
    try {
      const response = await api.put(
        `${baseEndpointV1}/announcement/update/${id}/visibility/`,
        body,
      );
      if (response.status !== 204) {
        throw new Error('Произошла ошибка при деактивации!');
      }
      toast.success(t('userTable:deactivatedAd'));

      const updatedActiveAnnouncements = userData?.announcements.filter(
        (announcement) => announcement.id !== id,
      ) || [];
      const updatedNotActiveAnnouncements = userData?.not_active_announcements
        .concat([response.data]) || [];
      await updateProfile(updatedActiveAnnouncements, updatedNotActiveAnnouncements);
    } catch (err) {
      console.log('Error deactivating announcement:', err);
    }
  }, [t, userData?.announcements, userData?.not_active_announcements, updateProfile]);

  const activateMyAd = useCallback(async (id: number) => {
    try {
      const response = await api.put(
        `${baseEndpointV1}/announcement/update/${id}/visibility/`,
        { action: 'activate' },
      );

      if (response.status !== 200) {
        throw new Error('Произошла ошибка при активации!');
      }

      const updatedNotActiveAnnouncements = userData?.not_active_announcements.filter(
        (announcement: IAd) => announcement.id !== id,
      );
      const updatedActiveAnnouncements = userData?.announcements.concat([
        response.data,
      ]);
      await updateProfile(updatedActiveAnnouncements, updatedNotActiveAnnouncements);
      toast.success(t('userTable:activatedAd'));
    } catch (err) {
      console.log('Error activating announcement:', err);
    }
  }, [t, userData?.announcements, userData?.not_active_announcements, updateProfile]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return {
    reasons,
    deactivateMyAd,
    loading,
    error,
    activateMyAd,
    userData,
  };
};
