import { useQuery } from '@tanstack/react-query';
import { useAuthContext } from '@/modules/auth';
import { eventFavoritesApi } from '../api';
import { EVENT_KEY } from '../types';

export const useFavoriteEvent = () => {
  const { isAuthenticated } = useAuthContext();

  const {
    data: favoritesEvents = [],
    isFetching,
    error,
  } = useQuery({
    queryKey: EVENT_KEY,
    queryFn: async () => {
      const userFavoritesEvents = await eventFavoritesApi.getFavoriteEvents();
      return userFavoritesEvents;
    },
    enabled: isAuthenticated,
  });

  return {
    favoritesEvents,
    loading: isFetching,
    error,
  };
};
