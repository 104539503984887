export async function uploadFileToS3(signedUrl: string, file: File, isAvatar: boolean = false) {
  const extension = file.name.split('.').pop();
  const response = await fetch(signedUrl, {
    method: 'PUT',
    body: file,
    headers: {
      // у файлов с расширением .jpg тип image/jpeg, поэтому при загрузке на s3 только аватара возвращается поломанная картинка
      'Content-Type': (isAvatar && extension === 'jpg') ? 'image/jpg' : file.type,
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to upload file: ${response.statusText}`);
  }
}
