import {
  useCallback, useEffect, useRef, useState,
} from 'react';

export const useThrottler = () => {
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [time, setTime] = useState(0);

  const start = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    setTime(60);

    intervalRef.current = setInterval(() => {
      setTime((v) => (v ? v - 1 : v));
    }, 1000);
  }, []);

  useEffect(() => () => {
    if (!intervalRef.current) return;
    clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (time <= 0 && intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, [time]);

  return [time, start] as const;
};
