import React, {
  ChangeEvent, memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useFormContext, useWatch } from 'react-hook-form';
import clsx from 'clsx';
import Cookie from 'js-cookie';
import { Loader } from '@googlemaps/js-api-loader';
import { Option } from '@/modules/shared/types';
import { Select, SvgIcon } from '@/modules/shared/components';
import { type UserType, UserTypeEnum } from '@/modules/users/types';
import { BUSINESS_LINK } from '@/modules/shared/const';
import { CreateAdTypes } from '@/modules/ads/components/create-ad/types';
import { AddEventType } from '@/modules/events/types/add-event.type';
import s from './creation-location.module.scss';
import { useLocationsListQuery } from '../../queries';
import { MapContainer } from './map-container.component';
import { AutocompletePrediction, AutocompleteSuggestion, Status } from './types';
import { getAutocompleteSuggestions } from './get-autocomplete-suggestions.helper';
import { SearchError } from './search-error.component';
import { SearchList } from './search-list.component';
import { NO_PLACE_STATUSES, THAI_LAT_LNG, ZOOM } from './const';
import { City } from '../../types';

interface CreationLocationProps {
  user_type?: UserType;
  districtRequired?: boolean;
}
// по умолчанию должно быть 'company', чтобы учитывалось только на странице создания объявлений
export const CreationLocation = memo((props: CreationLocationProps) => {
  const {
    user_type = UserTypeEnum.Company,
    districtRequired = true,
  } = props;

  const {
    setValue, control, resetField, setError, formState: { errors }, clearErrors,
  } = useFormContext<AddEventType | CreateAdTypes>();
  const { t } = useTranslation();
  const { data } = useLocationsListQuery();
  const cityId = useWatch({ name: 'city' });
  const address = useWatch({ name: 'address' });
  const [searchValue, setSearchValue] = useState('');
  const [initialLocation, setInitialLocation] = useState<google.maps.LatLngLiteral | undefined>();
  const [isSearchListOpen, setIsSearchListOpen] = useState(false);
  const [searchResult, setSearchResult] = useState<
  { autocompleteSuggestions: AutocompleteSuggestion[], status: Status | '' }
  >({
    autocompleteSuggestions: [],
    status: '',
  });
  const selectedCity = Cookie.get('selectedCity');
  const [zoom, setZoom] = useState<number>();

  const cities = useMemo<Option[]>(
    () => data.data.map(({ id, codename }) => ({
      value: id,
      label: t(`locations:${codename}.${codename}`),
    })),
    [data, t],
  );

  const districts = useMemo<Option[]>(() => {
    const city = data.data.find(({ id }) => id === cityId);
    const result = city?.districts?.map(({ id, codename }) => ({
      value: id,
      label: t(`locations:${city.codename}.districts.${codename}`),
    })) || [];
    return result;
  }, [data, t, cityId]);

  useEffect(() => {
    resetField('district');
  }, [cityId]);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const userPosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setInitialLocation(userPosition);
      }, () => {
        if (selectedCity && Number(selectedCity)) {
          const city: City = data.data.find(({ id }) => id === Number(selectedCity));
          if (city) {
            const selectedCityLatLng = {
              lat: +city.lat,
              lng: +city.lng,
            };
            setInitialLocation(selectedCityLatLng);
            setZoom(ZOOM[city.codename] || ZOOM.default);
          }
        } else {
          setInitialLocation(THAI_LAT_LNG);
          setZoom(ZOOM.th);
        }
      });
    }
  }, [selectedCity, data]);

  useEffect(() => {
    if (!isSearchListOpen) return;

    const close = (e: MouseEvent) => {
      if (Array.from((e.target as HTMLInputElement).classList).includes(s.search_input)) {
        return;
      }
      setIsSearchListOpen(false);
    };

    window.addEventListener('click', close);
    return () => window.removeEventListener('click', close);
  }, [isSearchListOpen]);

  const loader = new Loader({
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY as string,
    version: 'weekly',
    libraries: ['places', 'maps', 'marker'],
  });

  const sessionToken = useMemo(
    () => {
      if (typeof window !== 'undefined' && window.google && window.google.maps) {
        return new window.google.maps.places.AutocompleteSessionToken();
      }
    },
    [],
  );

  const handlePredictions = (predictions: AutocompletePrediction[], status: Status) => {
    if (status === 'OK') {
      const autocompleteSuggestions = getAutocompleteSuggestions(predictions);
      setSearchResult({ autocompleteSuggestions, status: 'OK' });
    } else {
      setSearchResult({ autocompleteSuggestions: [], status });
    }
  };

  const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsSearchListOpen(true);
    const inputValue = e.target.value;
    setSearchValue(inputValue);
    setValue('address', inputValue);
    clearErrors('address');
    if (inputValue === '') {
      setSearchResult({ autocompleteSuggestions: [], status: '' });
      return;
    }
    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions({
      input: inputValue,
      sessionToken,
    }, handlePredictions);
  };

  const handleSelectAddress = useCallback((item: AutocompleteSuggestion) => {
    const newAddress = `${item.name.string},${item.address.string}`;
    setValue('address', newAddress);
    setSearchValue(newAddress);
    setSearchResult({ autocompleteSuggestions: [], status: '' });
    setIsSearchListOpen(false);
    clearErrors('address');
  }, []);

  const getAddress = useCallback((location: string) => {
    setSearchValue(location);
    setValue('address', location);
  }, []);

  const handleSearchBlur = () => {
    if (NO_PLACE_STATUSES.includes(searchResult.status)) {
      setError('address', {
        type: 'no_address',
        message: `${t('events:createEvents.searchErrors.no_place')}${t('events:createEvents.searchErrors.try')}`,
      });
      setSearchResult({ autocompleteSuggestions: [], status: '' });
      setIsSearchListOpen(false);
    }
  };

  const clearSearchValue = () => {
    setSearchValue('');
    setSearchResult({ autocompleteSuggestions: [], status: '' });
    setValue('address', '');
    clearErrors('address');
  };

  return (
    <div className={s.field}>
      <h2>{t('events:createEvents.location._')}</h2>
      <div className={clsx(s.field_inner, s.city)}>
        <p>
          {t('events:createEvents.location.city')}
          <span> *</span>
        </p>
        <div className={s.values}>
          <Select
            setValue={setValue}
            control={control}
            name="city"
            options={cities}
            requiredFiled
            placeholder={t('common:chooseCity')}
            selectSize="s"
          />
        </div>
      </div>

      <div className={clsx(s.field_inner, s.district)}>
        <p>
          {t('events:createEvents.location.district')}
          {districtRequired && <span> *</span>}
        </p>
        <div className={s.values}>
          <Select
            setValue={setValue}
            control={control}
            name="district"
            options={districts}
            requiredFiled={districtRequired}
            placeholder={t('events:createEvents.location.selectDistrict')}
            selectSize="s"
          />
        </div>
      </div>

      <div className={clsx(s.field_inner, s.address)}>
        <p>
          {t('events:createEvents.location.address')}
        </p>
        <div className={s.values}>
          <div className={s.search_wrapper}>
            <input
              type="search"
              placeholder={searchValue || t('events:createEvents.location.enterAddress')}
              onBlur={handleSearchBlur}
              value={searchValue}
              onChange={handleSearchValueChange}
              className={clsx(s.search_input, {
                [s.search_input_error]: errors.address,
              })}
            />
            <SvgIcon
              name="close"
              style={{
                display: searchValue ? 'block' : 'none',
                color: searchValue && !isSearchListOpen
                  ? 'var(--bzr-text-dark-black-primary)'
                  : 'var(--bzr-text-medium-grey)',
              }}
              onClick={clearSearchValue}
            />
            {errors.address && (
              <p className={s.no_address_error}>
                {errors.address['0'] || (errors.address.message as string)}
              </p>
            )}
          </div>
          <SearchList
            isOpen={isSearchListOpen}
            onClick={handleSelectAddress}
            autocompleteSuggestions={searchResult.autocompleteSuggestions}
          />
          <SearchError status={searchResult.status} />
          {user_type === UserTypeEnum.Company ? (
            <div className={s.map_container}>
              <MapContainer
                address={(address as unknown as string) || 'Phuket, Thailand'}
                initialLocation={initialLocation}
                getAddress={getAddress}
                loader={loader}
                zoom={zoom}
              />
            </div>
          ) : (
            <div className={s.map_info}>
              <SvgIcon name="info" />
              <p dangerouslySetInnerHTML={{
                __html: t('createAd:contactInformation.mapInfo', { link: BUSINESS_LINK }),
              }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
