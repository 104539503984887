import {
  createContext, PropsWithChildren, useEffect, useMemo, useState,
} from 'react';
import { useMediaQuery } from '@react-hookz/web';

interface MatchMediaResult {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export const matchMediaContext = createContext<MatchMediaResult>({ isMobile: false, isTablet: false, isDesktop: false });

export const MatchMediaProvider = ({ children }: PropsWithChildren) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => { // отработает после монтирования, когда window существует
    setIsClient(true);
  }, []);

  const isMobile = useMediaQuery('(max-width: 480px)');
  const isTablet = useMediaQuery('(min-width: 481px) and (max-width: 1024px)');
  const isDesktop = useMediaQuery('(min-width: 1025px)');

  const value = useMemo(() => {
    if (!isClient) {
      return { isMobile: false, isTablet: false, isDesktop: false };
    }

    return { isMobile: !!isMobile, isTablet: !!isTablet, isDesktop: !!isDesktop };
  }, [isClient, isDesktop, isMobile, isTablet]);
  return (
    <matchMediaContext.Provider value={value}>
      {children}
    </matchMediaContext.Provider>
  );
};
