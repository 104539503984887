import { useEffect, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useFormContext } from 'react-hook-form';
import s from './add-event-page.module.scss';
import { AddEventType } from '../../types';
import { AGES } from '../../consts';

export const AgeRestriction = () => {
  const {
    register, watch, resetField, formState: { errors },
  } = useFormContext<AddEventType>();
  const { t } = useTranslation();
  const type = watch('event_type');
  const age = watch('age_restriction');
  const values = useMemo(
    () => (type === 'kids' ? AGES.slice(0, -2) : AGES),
    [type],
  );

  useEffect(() => {
    if (type === 'kids' && ['18+', '21+'].includes(age)) {
      resetField('age_restriction');
    }
  }, [type, age]);

  return (
    <div className={s.field_inner}>
      <p>
        {t('events:createEvents.ageTitle')}
        <span> *</span>
      </p>
      <div className={s.values}>
        {values.map((el) => (
          <label
            key={el}
            style={age === el ? { backgroundColor: '#EFEFEF' } : {}}
            className={s.age}
          >
            <input
              type="radio"
              value={el}
              {...register('age_restriction', { required: true })}
            />
            <span>{el}</span>
          </label>
        ))}
      </div>
      {errors.age_restriction && (
        <p className={s.error}>{errors.age_restriction['0'] || errors.age_restriction.message as string}</p>
      )}
    </div>
  );
};
