import {
  Dispatch, SetStateAction, useCallback, useEffect, useState,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { AxiosError } from 'axios';
import { Button, ModalDesktop, ModalMobile } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { formatPhoneNumber } from '@/modules/shared/helpers';
import { useUpdatePhoneNumber } from '@/modules/me';
import { CodeInput } from '@/modules/auth/components/auth-modal/code-input.component';
import s from './modal.module.scss';
import { CodeForm, CodeType } from './types';
import { MobileConfirmedModal } from './mobile-confirmed-modal.component';

type ConfirmNumberModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
  onResend: () => void;
  refetch: () => void;
  number: string;
  minutes: number;
  seconds: number;
  setMinutes: Dispatch<SetStateAction<number>>;
  setSeconds: Dispatch<SetStateAction<number>>;
};

export const ConfirmNumberModal = ({
  isOpen, onClose, onBack, onResend, refetch, number, minutes, seconds, setMinutes, setSeconds,
}: ConfirmNumberModalProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();
  const {
    mutate: update, isSuccess, error, isError,
  } = useUpdatePhoneNumber();
  const [code, setCode] = useState<string[]>([]);
  const [isConfirmedMobileOpen, setIsConfirmedMobileOpen] = useState(false);
  const {
    control, handleSubmit, setValue, formState, setError, clearErrors,
  } = useForm<CodeForm>();
  const { isSubmitting, isValid, errors } = formState;

  useEffect(() => {
    document.getElementById('code-input-0')?.focus();
  }, []);

  const handleCodeInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const index = parseInt(name.split('.')[1], 10);
    const inputChar = value.slice(-1);

    if (inputChar.match(/\d/)) {
      const newCode = [...code];
      newCode[index] = inputChar;
      setCode(newCode);
      setValue(`code.${index}` as CodeType, inputChar, { shouldValidate: true });
      clearErrors('root'); // Clear errors when code changes
      if (index < 5) {
        const nextInput = document.getElementById(`code-input-${index + 1}`);
        if (nextInput) nextInput.focus();
      }
    }
  }, [code]);

  const handleBackspace = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const index = parseInt(name.split('.')[1], 10);

    if (e.key === 'Backspace' || e.code === 'Backspace') {
      e.preventDefault();
      const newCode = [...code];

      if (value !== '') {
        newCode[index] = '';
        setValue(`code.${index}` as CodeType, '', { shouldValidate: true });
      } else if (index > 0) {
        newCode[index - 1] = '';
        const previousInputName = `code.${index - 1}`;
        setValue(previousInputName as CodeType, '', { shouldValidate: true });
        (e.currentTarget.form?.[previousInputName] as HTMLElement).focus();
      }
      setCode(newCode);
      clearErrors('root'); // Clear errors when code changes
    }
  }, [code, setCode, setValue, clearErrors]);

  const handlePaste = useCallback((e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain').slice(0, 6);
    const newCode = [...code];

    pastedData.split('').forEach((char, i) => {
      newCode[i] = char;
    });
    setCode(newCode);

    newCode.forEach((char, i) => {
      setValue(`code.${i}` as CodeType, char, { shouldValidate: true });
    });
    clearErrors('root'); // Clear errors when code changes
  }, [code, setCode, setValue, clearErrors]);

  const onSubmit = useCallback(async (data) => {
    update({ phone_number: number, totp: Object.values(data.code).join('') });
  }, [isMobile, number]);

  useEffect(() => {
    if (isError && error instanceof AxiosError) {
      const responseErrors = error.response?.data?.validation_errors?.code;
      if (responseErrors.includes('INVALID_PHONE_NUMBER_OR_TOTP_CODE')) {
        setError('root', {
          type: 'manual',
          message: t('forms:validationErrors.code_incorrect'),
        });
      }
      if (responseErrors.includes('TOTP_CODE_IS_EXPIRED')) {
        setError('root', {
          type: 'manual',
          message: t('forms:validationErrors.code_expired'),
        });
      }
    }
    if (isSuccess) {
      if (isMobile) {
        setIsConfirmedMobileOpen(true);
        onClose();
      } else {
        onClose();
      }
      setMinutes(0);
      setSeconds(0);
      refetch();
    }
  }, [isSuccess, error, isError, isMobile]);

  const ComponentInput = (() => (
    <div className={s.input_wrapper}>
      <CodeInput
        control={control}
        handleBackspace={handleBackspace}
        handleCodeInput={handleCodeInput}
        handlePaste={handlePaste}
        className={clsx(s.update_code_inputs, { [s.error_code]: errors.root })}
      />
      {errors.root && <p className={s.error}>{errors.root.message}</p>}
    </div>
  ))();

  const ComponentButtonConfirm = (() => (
    <Button
      type="submit"
      appearance="primary"
      buttonColor="blue"
      buttonSize="s"
      disabled={isSubmitting || !isValid}
    >
      {isMobile ? t('userAccount:continue') : t('auth:confirm')}
    </Button>
  ))();

  const ComponentResend = (() => (
    minutes || seconds
      ? (
        <p className={s.code_resend_in}>
          {`${t('userAccount:code_resend')}${t('userAccount:in')}${`0${minutes}:${seconds.toString().padStart(2, '0')}`}`}
        </p>
      ) : (
        <p className={s.code_resend} onClick={() => onResend()}>
          {t('userAccount:code_resend')}
        </p>
      )
  ))();

  return (
    <>
      {isMobile ? (
        <ModalMobile
          open={isOpen}
          fullHeight
          headerBordered
          showBackButton
          onClickBack={onBack}
          showCloseButton
          onClose={onClose}
        >
          <form onSubmit={handleSubmit(onSubmit)} className={s.content}>
            <h3 className={s.h3}>{t('userAccount:confirm_phone')}</h3>
            <p className={s.code_sent}>{t('userAccount:code_sent', { number: formatPhoneNumber(number) })}</p>
            {ComponentInput}
            {ComponentResend}
            <div className={s.button_wrapper}>
              {ComponentButtonConfirm}
            </div>
          </form>
        </ModalMobile>
      ) : (
        <ModalDesktop
          open={isOpen}
          onClose={onClose}
          title={t('userAccount:confirm_number')}
          titleJustifyContent="start"
        >
          <form onSubmit={handleSubmit(onSubmit)} className={s.content}>
            <p className={s.code_sent}>{t('userAccount:code_sent', { number: formatPhoneNumber(number) })}</p>
            {ComponentInput}
            {ComponentResend}
            <div className={s.buttons}>
              <Button
                appearance="primary"
                buttonColor="grey"
                buttonSize="s"
                onClick={onBack}
              >
                {t('userAccount:back')}
              </Button>
              {ComponentButtonConfirm}
            </div>
          </form>
        </ModalDesktop>
      )}
      <MobileConfirmedModal
        isOpen={isConfirmedMobileOpen}
        header={t('userAccount:phone_confirmed')}
        text={t('userAccount:phone_added')}
        onClose={() => setIsConfirmedMobileOpen(false)}
      />
    </>
  );
};
