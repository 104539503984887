import React, { createContext, useContext, useMemo } from 'react';
import { noop } from 'lodash';
import { Me } from '../types';
import { useMeQuery } from './me.query';


interface IMeContext {
  isLoading: boolean;
  me?: Me;
  refetch: () => void;
}
const MeContext = createContext<IMeContext>({
  isLoading: false,
  refetch: noop,
});


export function MeProvider({ children, isAuthenticated }) {
  const {
    data, isLoading, initialLoading, refetch,
  } = useMeQuery(isAuthenticated);

  const value = useMemo<IMeContext>(() => ({
    me: data,
    isLoading: isLoading || initialLoading,
    refetch,
  }), [data, isLoading, initialLoading, refetch]);

  return (
    <MeContext.Provider value={value}>
      {children}
    </MeContext.Provider>
  );
}

export const useMeContext = () => useContext(MeContext);
