import React from 'react';
import { useRouter } from 'next/router';
import { SvgIcon } from '../svg-icon';
import styles from './mobile-layout-icons.module.scss';

export const IconBack = () => {
  const { back } = useRouter();

  return (
    <SvgIcon
      name="arrow-left"
      className={styles.svg_icon}
      onClick={back}
    />
  );
};
