import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { UseFormResetField } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { useCategoryName } from '@/modules/categories/hooks';
import { SvgIcon } from '@/modules/shared/components';
import { useCreateAdContext } from '../context';
import { useHandleBreadcrumbs } from '../hooks';
import { CreateAdTypes } from '../types';
import styles from './create-ad-breadcrumbs.module.scss';

interface CreateAdBreadcrumbsProps {
  resetField: UseFormResetField<CreateAdTypes>;
}
interface Breadcrumbs {
  title: string;
  handler: () => void;
}

export const CreateAdBreadcrumbs = ({ resetField }: CreateAdBreadcrumbsProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    category, subCategory, underSubCategory, adType,
  } = useCreateAdContext();
  const [getNameByCode] = useCategoryName();

  const {
    handlePlaceAdClick,
    handleCategoryClick,
    handleSubCategoryClick,
    handleUnderSubCategoryClick,
  } = useHandleBreadcrumbs(resetField);

  const initialBreadcrumbs: Breadcrumbs[] = useMemo(() => ([
    { title: t('createAd:main'), handler: () => router.push('/') },
    { title: t('createAd:placeAd'), handler: handlePlaceAdClick },
  ]), [t]);
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumbs[]>(initialBreadcrumbs);

  useEffect(() => {
    const newBreadcrumbs = [...initialBreadcrumbs];
    if (category) {
      const categoryTitle = getNameByCode(category.codename);
      newBreadcrumbs.push({ title: categoryTitle, handler: handleCategoryClick });
    }
    if (category && subCategory) {
      const subCategoryTitle = getNameByCode(category.codename, subCategory.codename);
      newBreadcrumbs.push({ title: subCategoryTitle, handler: handleSubCategoryClick });
    }
    if (category && subCategory && underSubCategory) {
      const underSubCategoryTitle = getNameByCode(category.codename, subCategory.codename, underSubCategory.codename);
      newBreadcrumbs.push({ title: underSubCategoryTitle, handler: handleUnderSubCategoryClick });
    }
    if (adType) {
      const adTypeTitle = t(`createAd:allowTypes.${adType}`);
      newBreadcrumbs.push({ title: adTypeTitle, handler: () => {} });
    }
    setBreadcrumbs(newBreadcrumbs);
  }, [t, category, subCategory, underSubCategory, adType]);

  return (
    <div className={styles.breadcrumbs}>
      {breadcrumbs.slice(0, -1).map((breadcrumb, index) => (
        <React.Fragment key={index}>
          <button
            type="button"
            onClick={() => breadcrumb.handler()}
          >
            {breadcrumb.title}
          </button>
          <SvgIcon name="arrow-right" />
        </React.Fragment>
      ))}
      <p className={styles.last_breadcrumb}>{breadcrumbs[breadcrumbs.length - 1].title}</p>
    </div>
  );
};
