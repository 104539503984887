import { useEffect, useCallback } from 'react';
import { useAuthContext } from '@/modules/auth';

const SESSION_STORAGE_VALUE = '1';

export const useFavoriteClickState = (id: number, addFavorite: (id: number) => void) => {
  const getSessionStorageKey = useCallback(() => `notAuthenticatedFavoriteClick_${id}`, [id]);

  const setNotAuthenticatedClicked = useCallback(
    () => sessionStorage.setItem(getSessionStorageKey(), SESSION_STORAGE_VALUE),
    [getSessionStorageKey],
  );

  const deleteNotAuthenticatedClicked = useCallback(
    () => sessionStorage.removeItem(getSessionStorageKey()),
    [getSessionStorageKey],
  );

  const getNotAuthenticatedClicked = useCallback(
    () => sessionStorage.getItem(getSessionStorageKey()) === SESSION_STORAGE_VALUE,
    [getSessionStorageKey],
  );

  const { isAuthenticated, isModalOpen } = useAuthContext();

  useEffect(() => {
    if (isModalOpen) {
      return;
    }
    if (isAuthenticated && getNotAuthenticatedClicked()) {
      addFavorite(id);
    }
    deleteNotAuthenticatedClicked();
  }, [isModalOpen, isAuthenticated, addFavorite, id, getNotAuthenticatedClicked, deleteNotAuthenticatedClicked]);

  return setNotAuthenticatedClicked;
};
