import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { last, omit } from 'lodash';

import clsx from 'clsx';
import { Box } from '@mui/material';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import MenuPaths from '@/components/shared/layout/Buttons/MenuPaths';
import {
  Button, ItemLoader, SvgIcon,
} from '@/modules/shared/components';
import { useCategories } from '@/modules/categories';
import {
  useCustomWindowWidth, useInfiniteListRef, useMatchMedia, useSetQueryParams,
} from '@/modules/shared/hooks';
// FIXME: надо пофиксить циклические зависимости :((( сделаю в следующем PR
// задача https://bazaar.youtrack.cloud/issue/bw-1938/FE-Resolve-cyclic-dependencies
// eslint-disable-next-line import/no-cycle
import { AdCard } from '@/modules/app/components/index-page/ad-card';
import { useGlobalData } from '@/context/GlobalContext';
import { modalTypeParam } from '@/modules/auth/consts';
import { useSearchParams } from 'next/navigation';
import { useAdsInfiniteListQuery, useRecommendedAdsInfinityList } from '../../queries';
import { IAd } from '../../types';
import { Filter } from '../filter';
import { Title } from './title.component';
import { DesktopAdCard } from './desktop-ad-card';
import { SwitchCategory } from './switch-category';
import { AdsSorting } from './ads-sorting.component';
import s from './ads-infinity-page.module.scss';


// TODO: НА БЭКЕ НУЖНО ДОБАВИТЬ В ДАННЫЕ О ПОЛЬЗОВАТЕЛЕ и вернуть отображение карточки пользователя для десктопов

interface Props {
  order: string;
  onOrderChange: (value: string) => void;
  cityId: unknown;
}

export const AdsInfiniteList = ({ order, onOrderChange, cityId }: Props) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { windowWidth } = useCustomWindowWidth();
  const { isDesktop } = useMatchMedia();
  const searchParams = useSearchParams();
  const { getCategoryByCode } = useCategories();

  const { query } = router;
  const {
    category: categorySlug,
    subcategory: subCategorySlug,
    undersubcategory: underSubCategorySlug,
    search,
  } = query;
  const categoryItem = getCategoryByCode((categorySlug || '') as string);
  const subCategoryItem = getCategoryByCode(
    (categorySlug || '') as string,
    (subCategorySlug || '') as string,
  );
  const underSubCategoryItem = getCategoryByCode(
    (categorySlug || '') as string,
    (subCategorySlug || '') as string,
    (underSubCategorySlug || '') as string,
  );

  const { setQuantityAd } = useGlobalData();

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const filters = useMemo(() => ({
    ...omit(query, ['category', 'subcategory', 'undersubcategory', 'order', modalTypeParam]),
    city: searchParams.getAll('city') || cityId || undefined,
    category: categoryItem?.id,
    sub_category: subCategoryItem?.id,
    under_sub_category: underSubCategoryItem?.id,
    search,
  }), [cityId, query, categoryItem, subCategoryItem, underSubCategoryItem, searchParams, search]);

  const handleToggleFilter = useCallback(() => {
    setIsFilterVisible((current) => !current);
  }, []);

  const setQueryParams = useSetQueryParams();
  const onFilterChange = useCallback(
    (data) => {
      setIsFilterVisible(false);
      if (data.city && data.city === cityId) {
        setQueryParams({
          ...data,
          city: undefined,
        });
      } else {
        setQueryParams(data);
      }
    },
    [setQueryParams, cityId],
  );

  const {
    hasMore,
    fetchMore,
    pages,
    isLoading,
  } = useAdsInfiniteListQuery({
    order,
    filters,
  });
  const totalCount: number | undefined = last(pages || [])?.count;
  const lastListItemRef = useInfiniteListRef(hasMore, fetchMore, isLoading);
  const adsLength = pages?.[0]?.data?.length;

  const { pages: recommendedAds } = useRecommendedAdsInfinityList({
    pageSize: 10,
    filters: { city: cityId },
  });

  useEffect(() => {
    setQuantityAd(pages);
  }, [pages, setQuantityAd]);

  return (
    <section className={s.ads_list_page}>
      {isDesktop ? (
        <>
          <MenuPaths
            category={categoryItem?.codename}
            subcategory={subCategoryItem?.codename}
            undersubcategory={underSubCategoryItem?.codename}
          />
          <Title
            isLoading={isLoading}
            search={filters?.search}
            totalCount={totalCount}
            categoryItemTitle={categoryItem?.title}
            subCategoryItemTitle={subCategoryItem?.title}
            underSubCategoryItemTitle={underSubCategoryItem?.title}
          />
          {adsLength > 0 && !isLoading && (<AdsSorting order={order} onOrderChange={onOrderChange} />)}
        </>
      ) : (
        <>
          {adsLength > 0 ? (
            <div className={s.fixed_header_mobile}>
              <SwitchCategory
                category={categoryItem}
                subcategory={subCategoryItem}
                undersubcategory={underSubCategoryItem}
              />
              <div className={s.mobile_filters_and_sorting}>
                <AdsSorting order={order} onOrderChange={onOrderChange} />
                <Button
                  buttonSize="xs"
                  buttonColor="grey"
                  appearance="primary"
                  className={s.filter_button}
                  onClick={handleToggleFilter}
                >
                  <SvgIcon name="filter_1" />
                  {t('filters-components:title.title')}
                </Button>
              </div>
            </div>
          ) : (
            <Title
              isLoading={isLoading}
              search={filters?.search}
              totalCount={totalCount}
              categoryItemTitle={categoryItem?.title}
              subCategoryItemTitle={subCategoryItem?.title}
              underSubCategoryItemTitle={underSubCategoryItem?.title}
            />
          )}
        </>
      )}
      {adsLength > 0 && (
        <div
          className={clsx(s.container, {
            [s.visibleFilter]: isFilterVisible,
          })}
        >
          <div className={s.ads_list_wrapper}>
            <div className={s.ads_list}>
              {windowWidth && windowWidth >= 1200
                ? pages.map((page) => page.data.map((product: IAd) => (
                  <DesktopAdCard key={product.id} ad={product} />
                )))
                : pages.map((page) => page.data.map((product: IAd) => (
                  <AdCard key={product.id} ad={product} />
                )))}
              <div ref={lastListItemRef} />
            </div>
            {isLoading && (
              <Box display="flex" justifyContent="center">
                <ItemLoader isLoading size="50px" />
              </Box>
            )}
          </div>
          <div className={s.filterComponentContainer}>
            <Filter
              isVisible={isFilterVisible}
              onChangeVisible={handleToggleFilter}
              category={categoryItem}
              subCategory={subCategoryItem}
              underSubCategory={underSubCategoryItem}
              onChange={onFilterChange}
              defaultValue={filters}
            />
          </div>
        </div>
      )}
      {adsLength === 0 && (
        <div className={s.recommendations}>
          <h2>{t('common:searchBlock.recommendations')}</h2>
          <div className={s.recommendation_list}>
            {recommendedAds?.[0]?.data?.map((ad: IAd) => (
              <AdCard key={ad.id} ad={ad} extendedCard={false} />
            ))}
          </div>
        </div>
      )}
    </section>
  );
};
