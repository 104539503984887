import { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { Category, useCategories } from '@/modules/categories';
import type { City } from '@/modules/locations';
import { useLocationsListQuery } from '@/modules/locations';
import { SvgIcon } from '@/modules/shared/components/svg-icon';
import { useMatchMedia } from '@/modules/shared/hooks';
import s from './footer-mobile.module.scss';
import {
  RIGHTS, companyLinks, media, regions, UNSHOW_DEFAULT_FOOTER,
} from '../footer/footer.const';
import { ContactLinks } from './contact-links';

// убрать FooterMobileProps, data и regions, когда будет не нужен storybook
type FooterMobileProps = {
  data: Category[];
};

export const FooterMobile = ({ data }: FooterMobileProps) => {
  const { t } = useTranslation();
  const { categories } = useCategories();
  const { data: locations } = useLocationsListQuery();
  const { isTablet, isMobile: isMatchMobile } = useMatchMedia();
  const cities: City[] = useMemo(
    () => (categories.length ? locations.data : regions).map(
      (city: City) => (t(`locations:${city.codename}.${city.codename}`)),
    ),
    [locations, t],
  );
  const ACTIVE_COUNTRY = `${t('locations:thailand.thailand')}: ${cities.join(', ')}`;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const router = useRouter();
  const unshowDefaultFooter = UNSHOW_DEFAULT_FOOTER.some((path) => router.pathname.includes(path));

  if (unshowDefaultFooter && (isMatchMobile || isTablet)) { // скрываем дефолтный футер в мобильной (планшетной) версии
    return null;
  }

  return (
    <footer className={s.footer_container}>
      <Link href="/" className={s.logo}>
        <img
          src="/assets/icons/logo-main-medium.svg"
          alt="bazaar"
        />
      </Link>

      <nav className={s.footer_nav}>
        <div className={s.categories}>
          <h2>{t('common:categories')}</h2>
          <ul>
            {(categories.length ? categories : data)?.map((el) => (
              <Link key={el.id} href={el.pathname}>
                <li>{el.title}</li>
              </Link>
            ))}
          </ul>
        </div>
        <div className={s.company}>
          <h2>{t('common:footer.company')}</h2>
          <ul>
            {companyLinks.map((el) => (
              <Link key={el} href={`/bazaar/${el}`} target="_blank">
                {t(`common:footer.${el}`)}
              </Link>
            ))}
          </ul>
        </div>
        <div className={s.social_block}>
          <h2>{t('common:footer.media')}</h2>
          <div className={s.social}>
            {media.map((el) => (
              <Link key={el.name} href={el.path} target="_blank">
                <SvgIcon name={el.name} />
              </Link>
            ))}
          </div>
        </div>
        {isTablet && !isMobile && <ContactLinks />}
      </nav>

      {isMobile && <ContactLinks />}

      <section className={s.footer_ribbon}>
        <span>{ACTIVE_COUNTRY}</span>
        <span>{RIGHTS}</span>
      </section>
    </footer>
  );
};
