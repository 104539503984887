import {
  forwardRef, InputHTMLAttributes, ReactNode, useState,
} from 'react';
import clsx from 'clsx';
import { SvgIcon } from '../../svg-icon';
import { stopEvent } from '../../../helpers/stop-event.helper';
import {
  validateInputKeyPress, validateInputKeyPressFractional,
} from '../../../helpers/validate-input-key-press.helper';
import styles from './input-text.module.scss';

export type InputTextProps = {
  fullWidth?: boolean;
  error?: boolean;
  postfix?: ReactNode;
  type?: 'password' | 'text' | 'number';
  placeholder?: string;
  className?: string;
  eyeVisible?: boolean,
  isFractional?: boolean,
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>;

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  ({
    fullWidth,
    type = 'text',
    error,
    disabled,
    value,
    postfix,
    placeholder,
    className = '',
    eyeVisible = false,
    isFractional = false,
    ...props
  }, ref) => {
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisibility = () => {
      setPasswordShown(!passwordShown);
    };

    const handleStopWheel = (e: React.WheelEvent<HTMLInputElement>) => {
      if (e.target instanceof HTMLInputElement) e.target.blur();
    };

    return (
      <div
        className={clsx(styles.wrapper, {
          [styles.wrapper_fullWidth]: fullWidth,
          [styles.wrapper_withPostfix]: !!postfix,
          [styles.wrapper_password]: !placeholder && !passwordShown && type === 'password',
        })}
      >
        <input
          {...props}
          ref={ref}
          value={value}
          className={clsx(styles.input, className, {
            [styles.input_error]: error,
          })}
          disabled={disabled}
          placeholder={placeholder}
          type={type === 'password' && passwordShown ? 'text' : type}
          onKeyDown={
            (type === 'number' && !isFractional)
              ? validateInputKeyPress
              : isFractional ? validateInputKeyPressFractional : undefined
          }
          onWheel={type === 'number' ? handleStopWheel : undefined}
        />
        {type === 'password' && (!placeholder || eyeVisible) ? (
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className={clsx(styles.postfix, styles.password_icon)}
            onMouseDown={stopEvent}
          >
            <SvgIcon name={passwordShown ? 'eye-opened' : 'eye-closed'} />
          </button>
        ) : postfix ? (
          <div className={styles.postfix}>{postfix}</div>
        ) : null}
      </div>
    );
  },
);
