import React, { useState } from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import styles from './sub-header-items.module.scss';

export const SubHeaderItems = ({
  data, onClick, prevCodename, operationType = '',
}) => {
  const [showAll, setShowAll] = useState(false);
  const { t } = useTranslation('');
  const {
    under_sub_categories, codename, pathname,
  } = data;

  const categoriesToShow = showAll
    ? under_sub_categories
    : under_sub_categories.slice(0, 5);
  const remainingCount = under_sub_categories.length - 5;

  const translateString = `categories:categories.${prevCodename}.categories.${codename}`;

  return (
    <div className={styles.container}>
      <Link
        href={{
          pathname,
          ...(operationType ? { query: { operation_type: operationType } } : {}),
        }}
        className={styles.title}
        onClick={onClick}
        data-testid={`sub_section_${codename}`}
      >
        {t(`${translateString}._`)}
        <span className={styles.arrow}> &gt;</span>
      </Link>

      {under_sub_categories.length > 0 && (
        <div className={styles.items}>
          {categoriesToShow.map((item) => (
            <Link
              key={item.codename}
              href={{
                pathname: item.pathname,
                ...(operationType ? { query: { operation_type: operationType } } : {}),
              }}
              className={styles.items_item}
              onClick={onClick}
              data-testid={`category_${item.codename}`}
            >
              {t(`${translateString}.categories.${item.codename}._`)}
            </Link>
          ))}
          {remainingCount > 0 && !showAll && (
            <button
              type="button"
              className={styles.showMore}
              onClick={() => setShowAll(true)}
            >
              {`${t('common:showMore')} ${remainingCount}`}
            </button>
          )}
        </div>
      )}
    </div>
  );
};
