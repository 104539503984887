import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import s from './app-tooltip.module.scss';

type AppTooltipProps = {
  title: string;
  children: ReactElement;
  stylesTooltip?: string;
  placement?: 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';
};

export const AppTooltip = ({
  children,
  stylesTooltip,
  title,
  placement = 'bottom',
}: AppTooltipProps) => (
  <Tooltip
    title={title}
    placement={placement}
    arrow
    slotProps={{
      tooltip: {
        className: clsx(s.tooltip, {
          [s.card]: stylesTooltip === 'card',
          [s.gray]: stylesTooltip === 'gray',
        }),
      },
      arrow: {
        className: clsx(s.arrow, {
          [s.arrow_start]: placement === 'bottom-start' || placement === 'top-start',
          [s.arrow_end]: placement === 'bottom-end' || placement === 'top-end',
          [s.arrow_top]: placement === 'left-start' || placement === 'right-start',
          [s.arrow_bottom]: placement === 'left-end' || placement === 'right-end',
        }),
      },
    }}
  >
    {children}
  </Tooltip>
);
