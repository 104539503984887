import { useCallback, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { useFormContext, useWatch } from 'react-hook-form';
import { SvgIcon, TextByLines } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import s from '../credentials.module.scss';
import { CompanyCredentialsForm } from '../types';
import { ChangeDescriptionModal } from '../modals';

type DescriptionProps = {
  description: string;
};

export const Description = ({ description }: DescriptionProps) => {
  const { t } = useTranslation();
  const { setValue, formState: { errors } } = useFormContext<CompanyCredentialsForm>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const value = useWatch({ name: 'description' });
  const { isDesktop } = useMatchMedia();

  const handleClick = useCallback(() => setIsModalOpen(true), []);
  const onClose = useCallback(() => setIsModalOpen(false), []);

  return (
    <section className={clsx(s.description, s.section)}>
      <h2>
        {
        isDesktop
          ? t('specifications:description')
          : t('userAccount:become_company.company_info.description')
        }
      </h2>
      <div className={s.field}>
        <p className={s.field_header}>{t('specifications:description')}</p>
        <div className={clsx(s.description_text, s.input_view)}>
          <p>
            <TextByLines text={(errors.description || !value) ? description : value} />
          </p>
          <SvgIcon name="pencil" onClick={handleClick} />
        </div>
      </div>
      <ChangeDescriptionModal
        isOpen={isModalOpen}
        onClose={onClose}
        onSave={(newValue: string) => setValue('description', newValue)}
        value={!value && value !== '' ? description : value}
        initialDescription={description}
      />
    </section>
  );
};
