import React, { useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useForm, Resolver, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { ModalDesktop, ModalMobile } from '@/modules/shared/components/modal';
import { useMatchMedia } from '@/modules/shared/hooks';
import { Button, Textarea } from '@/modules/shared/components';

import styles from './describe-situation.module.scss';
import stylesMain from './complaint-modal.module.scss';

type FormValues = {
  description: string;
};

export const DescribeSituation = ({
  description,
  setDescription,
  handleSendComplaint,
  isLoading,
  isOpen,
  onClose,
  setStep,
}) => {
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const { t } = useTranslation('');

  const schema = yup.object().shape({
    description: yup.string().required(t('forms:validationErrors.required')),
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema) as Resolver<FormValues>,
    mode: 'all',
    defaultValues: {
      description,
    },
  });

  const textareaValue = watch('description');

  const onSubmit = () => {
    handleSendComplaint();
  };

  useEffect(() => {
    setDescription(textareaValue || '');
  }, [textareaValue]);


  const renderContent = () => (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.textareaWrapper}>
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              name="description"
              hasErrors={!!errors.description}
              heightScroll={isMobile ? 167 : 157}
            />
          )}
        />

        {errors.description && (
          <p className={styles.error}>{errors.description.message}</p>
        )}
      </div>

      <div className={styles.buttonWrapper}>
        <Button
          appearance="primary"
          buttonColor="grey"
          buttonSize="s"
          className={clsx(styles.button, styles.hide)}
          onClick={() => setStep('specify-reason')}
        >
          {t('common:back')}
        </Button>
        <Button
          appearance="primary"
          buttonColor="blue"
          buttonSize="s"
          className={clsx(styles.button, stylesMain.mobile_button)}
          disabled={!description || !!errors.description}
          type="submit"
        >
          {isLoading ? t('common:submitting') : t('common:submit')}
        </Button>
      </div>
    </form>
  );

  return (
    <>
      {(isTablet || isDesktop) && (
        <ModalDesktop
          open={isOpen}
          title={t('common:complaintModal.describeSituation.title')}
          onClose={onClose}
          titleJustifyContent="start"
          showBackButton={false}
        >
          {renderContent()}
        </ModalDesktop>
      )}
      {isMobile && (
        <ModalMobile
          open={isOpen}
          fullHeight
          headerBordered
          onClose={onClose}
          showBackButton
          showCloseButton
          onClickBack={() => setStep('specify-reason')}
          contentStyles={stylesMain.contentModal}
        >
          <h3>{t('common:complaintModal.describeSituation.title')}</h3>
          {renderContent()}
        </ModalMobile>
      )}
    </>
  );
};
