import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Button, SvgIcon } from '@/modules/shared/components';
import { District } from '@/modules/locations';
import clsx from 'clsx';
import styles from './accordion.module.scss';

interface AccordionItemProps {
  city: string;
  isOpen: boolean;
  options: District[];
  cityChecked: boolean;
  onSelectCity: () => void;
  selectedDistricts: District[];
  onSelectDis: (dis: District) => void;
}

export const AccordionItem = ({
  city, isOpen, options, cityChecked, onSelectCity, onSelectDis, selectedDistricts,
}: AccordionItemProps) => {
  const { t } = useTranslation();

  const isSelectedDistrict = selectedDistricts.length > 0;
  const allDistrictsSelected = selectedDistricts.length === 0;

  return (
    <>
      {isOpen && (
        <div className={styles.accordion_content}>
          <p>{t('common:selectCityDistricts', { district: t(`common:selectIn.${city}`) })}</p>
          <div className={styles.accordion_content_list}>
            {isSelectedDistrict ? null : (
              <Button
                appearance="primary"
                buttonColor="grey"
                buttonSize="xs"
                className={clsx(styles.tag, { [styles.selected_tag]: cityChecked })}
                onClick={onSelectCity}
              >
                {t('locations:allareas')}
                {' '}
                {cityChecked && (<SvgIcon name="close-icon" className={styles.remove_tag} />)}
              </Button>
            )}
            {options.map((item) => (
              <Button
                key={item.id}
                appearance="primary"
                buttonColor="grey"
                buttonSize="xs"
                className={clsx(styles.tag, {
                  [styles.selected_tag]: selectedDistricts.some(
                    (selected) => selected.id === item.id,
                  ),
                })}
                onClick={() => onSelectDis(item)}
              >
                {t(`locations:${city}.districts.${item.codename}`)}
                {' '}
                {selectedDistricts.some(
                  (selected) => selected.id === item.id,
                ) && (
                  <SvgIcon name="close-icon" className={styles.remove_tag} />
                )}
              </Button>
            ))}
          </div>
        </div>
      )}
      {!isOpen && (cityChecked || isSelectedDistrict) && (
        <div className={styles.selected_tags}>
          {allDistrictsSelected && (
            <Button
              appearance="primary"
              buttonColor="grey"
              buttonSize="xs"
              className={clsx(styles.tag, { [styles.selected_tag]: allDistrictsSelected })}
              onClick={onSelectCity}
            >
              {t('locations:allareas')}
              {' '}
              {allDistrictsSelected && (
                <SvgIcon name="close-icon" className={styles.remove_tag} />
              )}
            </Button>
          )}
          {selectedDistricts.map((item) => (
            <Button
              key={item.id}
              appearance="primary"
              buttonColor="grey"
              buttonSize="xs"
              className={styles.selected_tag}
              onClick={() => onSelectDis(item)}
            >
              {t(`locations:${city}.districts.${item.codename}`)}
              {' '}
              <SvgIcon name="close-icon" className={styles.remove_tag} />
            </Button>
          ))}
        </div>
      )}
    </>
  );
};
