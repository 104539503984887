import React, { useCallback } from 'react';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import useTranslation from 'next-translate/useTranslation';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

import { SvgIcon } from '@/modules/shared/components';

import { getTokenExpiration } from '../../../../providers/auth.helpers';
import { AuthApi } from '../../../../api';
import { useAuthContext } from '../../../../contexts';

export const GoogleOauth = () => {
  const { t } = useTranslation();
  const { setAccessToken, setRefreshToken } = useAuthContext();
  const handleSuccessLogin = useCallback(async (data: TokenResponse) => {
    try {
      const response = await AuthApi.googleLogin({
        token: data.access_token,
      });

      Cookies.set('access_token', response.access, { expires: getTokenExpiration(response.access) });
      Cookies.set('refresh_token', response.refresh, { expires: getTokenExpiration(response.refresh) });
      Cookies.set('user_id', response.id, { expires: getTokenExpiration(response.access) });

      setRefreshToken(response.refresh);
      setAccessToken(response.access);
      toast.success(t('auth:success'));
    } catch (error) {
      toast.error(t('auth:error'));
    }
  }, [setAccessToken, setRefreshToken, t]);

  const login = useGoogleLogin({
    onSuccess: handleSuccessLogin,
  });

  return (
    <button
      type="button"
      onClick={() => login()}
    >
      <SvgIcon
        style={{
          fontSize: '44px',
        }}
        name="google"
      />
    </button>
  );
};
