import i18n from '../../../lib/i18n';

export const getWatchBrands = (locale: unknown) => {
  const listOfBrands = [
    i18n.createAdvFilds.things.Otherbrand[locale],
    'Rolex',
    'Omega',
    'TAG Heuer',
    'Patek Philippe',
    'Audemars Piguet',
    'Jaeger-LeCoultre',
    'Breitling',
    'Cartier',
    'Hublot',
    'IWC Schaffhausen',
    'A. Lange & Söhne',
    'Chopard',
    'Vacheron Constantin',
    'Panerai',
    'Tissot',
    'Montblanc',
    'Girard-Perregaux',
    'Baume & Mercier',
    'Zenith',
    'Bulgari',
    'Longines',
    'Ulysse Nardin',
    'Rado',
    'Maurice Lacroix',
    'Frederique Constant',
    'Raymond Weil',
    'Victorinox Swiss Army',
    'Oris',
    'Sinn',
    'MeisterSinger',
    'Bell & Ross',
    'Hamilton',
    'NOMOS Glashütte',
    'Bucherer',
    'Ball',
    'Certina',
    'Junghans',
    'Glycine',
    'Michel Herbelin',
    'Mido',
    'Perrelet',
    'Frédérique Constant',
    'Maurice de Mauriac',
    'Union Glashütte',
    'Tutima',
    'Mühle Glashütte',
    'Chronoswiss',
    'Stowa',
    'Fortis',
    'Alpina',
    'Hanhart',
    'Christopher Ward',
    'Pulsar',
    'Sevenfriday',
    'Nixon',
    'Armani',
    'DKNY',
    'Fossil',
    'Skagen',
    'Guess',
    'Diesel',
    'Marc Jacobs',
    'Michael Kors',
    'Tommy Hilfiger',
    'Swarovski',
    'Kate Spade',
    'Coach',
    'Tissot',
    'Calvin Klein',
    'Lacoste',
    'Esprit',
    'GUESS',
    'Guess',
    'D&G',
    'Flik Flak',
    'Pierre Cardin',
    'Daniel Wellington',
    'ASOS',
    'Citizen',
    'Seiko',
    'Casio',
    'Timex',
    'Swatch',
    'Bulova',
    'Orient',
    'Invicta',
    'Nixon',
    'Luminox',
    'Movado',
    'Skagen',
    'Gucci',
    'Versace',
    'Salvatore Ferragamo',
    'Bottega Veneta',
    'Fendi',
    'Balenciaga',
    'Hermes',
    'Louis Vuitton',
    'Chanel',
    'Dior',
  ];

  return listOfBrands;
};
