import { useMemo, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { ModalDesktop, ModalMobile, SvgIcon } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { getDeclension } from '@/modules/shared/helpers';
import s from './modal.module.scss';
import { InnerButton, PasswordInput } from './inner-components';
import { useSetFocus } from '../helpers';

type ConfirmPasswordModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick: (psw: string) => void;
  mode: 'email' | 'phone_add' | 'phone_change';
  days?: number | undefined;
};

export const ConfirmPasswordModal = ({
  isOpen, onClose, onClick, mode, days,
}: ConfirmPasswordModalProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();
  const [password, setPassword] = useState('');
  const { isFocus, setFocus } = useSetFocus();

  const mobileTitle = {
    email: t('userAccount:add_email'),
    phone_add: t('userAccount:add_phone'),
    phone_change: t('userAccount:change_number'),
  };

  const daysLeft = useMemo(() => {
    if (days) {
      return getDeclension({
        count: days,
        t,
        singular: 'userAccount:days.one',
        few: 'userAccount:days.few',
        many: 'userAccount:days.many',
      });
    }
  }, [days, t]);

  return (
    <>
      {isMobile ? (
        <ModalMobile
          open={isOpen}
          fullHeight
          title={mobileTitle[mode]}
          headerBordered
          showCloseButton
          showBackButton={false}
          onClose={onClose}
          contentStyles={s.content}
        >
          {mode === 'phone_change' && daysLeft && (
            <div className={s.next_phone_change}>
              <SvgIcon name="info" />
              <p>{t('userAccount:next_number_change', { daysLeft: `${days} ${daysLeft}` })}</p>
            </div>
          )}
          <p className={s.enter_password}>{t(`userAccount:enter_password.${mode}`)}</p>
          <PasswordInput
            value={password}
            onChange={setPassword}
            onFocus={setFocus}
          />
          <div className={clsx(s.button_wrapper, {
            [s.button_wrapper_up]: isFocus,
          })}
          >
            <InnerButton
              onClick={() => onClick(password)}
              disabled={!password || password.length < 8}
              text={t('common:continue')}
              className={s.password_confirm_button}
            />
          </div>
        </ModalMobile>
      ) : (
        <ModalDesktop
          open={isOpen}
          onClose={onClose}
          hasHeader={false}
        >
          <div className={s.content}>
            {mode === 'phone_change' && daysLeft && (
              <div className={s.next_phone_change}>
                <SvgIcon name="info" />
                <p>{t('userAccount:next_number_change', { daysLeft: `${days} ${daysLeft}` })}</p>
              </div>
            )}
            <h3 className={s.h3}>{t('auth:titlePassword')}</h3>
            <p className={s.enter_password}>{t(`userAccount:enter_password.${mode}`)}</p>
            <PasswordInput
              value={password}
              onChange={setPassword}
            />
            <InnerButton
              onClick={() => onClick(password)}
              disabled={!password || password.length < 8}
              text={t('auth:confirm')}
              className={s.password_confirm_button}
            />
          </div>
        </ModalDesktop>
      )}
    </>
  );
};
