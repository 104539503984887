import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { Button, RadioButton } from '@/modules/shared/components';
import styles from '../leave-feedback-modal.module.scss';

interface Props {
  setStep: React.Dispatch<React.SetStateAction<1 | 2 | 3>>;
}

const values = ['completed', 'cancelled', 'cancelled_by_seller', 'seller_not_answered', 'no_agreement_reached'];

export const StepOne = ({ setStep }: Props) => {
  const { t } = useTranslation('review');
  const { control } = useFormContext();

  const options = useMemo(
    () => values.map((value) => ({ value, label: t(`transactionStatus.${value}`) })),
    [t],
  );

  return (
    <div className={styles.step_one}>
      <h2>{t('transactionTitle')}</h2>
      <Controller
        name="deal_state"
        control={control}
        render={({ field }) => (
          <div className={styles.options}>
            {options.map((option) => (
              <label key={option.value} className={styles.option}>
                <RadioButton
                  radioColor="black"
                  name="deal_state"
                  value={option.value}
                  checked={field.value === option.value}
                  onChange={() => field.onChange(option.value)}
                />
                {option.label}
              </label>
            ))}
          </div>
        )}
      />
      <Button
        className={styles.button_continue}
        onClick={() => setStep(2)}
        appearance="primary"
        buttonColor="blue"
        buttonSize="s"
      >
        {t('continue')}
      </Button>
    </div>
  );
};
