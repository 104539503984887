import { MessageDetails } from '../types';
import { REVIEW_IS_REQUESTED, REVIEW_IS_SUGGESTED, THANK_YOU_FOR_REVIEW } from '../const';

export const mergeMessages = (messages: MessageDetails[], userId: number | null) => {
  const readMessages = messages
    .filter((msg) => msg.type === 'messages_read' && msg.messages.length > 0)
    .flatMap((msg) => msg.messages);

  let unreadMessages = messages
    .filter((msg) => msg.type === 'from_user' || msg.type === 'system');

  for (const readMsg of readMessages) {
    const existingMsgIndex = unreadMessages.findIndex((msg) => msg.id === readMsg.id);
    if (existingMsgIndex > -1) {
      unreadMessages[existingMsgIndex] = { ...unreadMessages[existingMsgIndex], ...readMsg };
    }
  }

  const hasThankYouMessage = unreadMessages.some(
    (msg) => msg.message === THANK_YOU_FOR_REVIEW && msg.recipient.id === userId,
  );

  // Если есть THANK_YOU_FOR_REVIEW для покупателя, убираем REVIEW_IS_REQUESTED и REVIEW_IS_SUGGESTED
  if (hasThankYouMessage) {
    unreadMessages = unreadMessages.filter(
      (msg) => !(
        (msg.message === REVIEW_IS_REQUESTED || msg.message === REVIEW_IS_SUGGESTED)
          && msg.recipient.id === userId
      ),
    );
  }

  return unreadMessages;
};
