import React, { useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useMatchMedia } from '@/modules/shared/hooks';
import { Tag } from '@/modules/shared/components';
import styles from './event-category-filter.module.scss';

export const EventCategoryFilter = ({ onClick, isActive }) => {
  const { t } = useTranslation('');
  const { isMobile } = useMatchMedia();

  const eventCategory = useMemo(
    () => [
      '', 'party', 'concert', 'standup', 'kids', 'sports', 'business',
    ].map((key) => ({ key, label: t(`events:category.${key || 'all'}`) })),
    [t],
  );

  return (
    <div className={styles.container}>
      {eventCategory.map(({ key, label }) => (
        <Tag
          key={key}
          label={label}
          type={
            key === isActive
              ? (isMobile ? 'tagMobile_white' : 'tagM_white')
              : (isMobile ? 'tagMobile' : 'tagM')
          }
          color={key === isActive ? 'green-default' : 'very-light-grey'}
          onClick={() => onClick(key)}
          className={styles.tag}
        />
      ))}
    </div>
  );
};
