import React, { FC } from 'react';
import clsx from 'clsx';
import { SvgIcon } from '../svg-icon';
import { ColorTagProps } from './types';
import styles from './color-tag-mobile.module.scss';
import stylesMain from './color-picker.module.scss';

export const ColorTagMobile: FC<ColorTagProps> = ({ colorOptions, selectedColors, handleColorClick }) => (
  <>
    {colorOptions.map((option) => (
      <div
        key={option.codename}
        className={clsx(styles.container, {
          [styles.container_selected]: selectedColors.includes(option.codename),
        })}
        onClick={() => handleColorClick(option.codename)}
      >
        {option?.codename === 'multicolor'
          ? <SvgIcon name="colors-multicolor" className={styles.colorOption} />
          : (
            <div
              className={clsx(styles.colorOption, {
                [stylesMain.white]: option?.codename === 'white',
                [stylesMain.silver]: option?.codename === 'silver',
                [stylesMain.golden]: option?.codename === 'golden',
              })}
              style={{ backgroundColor: option.value }}
            />
          )}
        <p>{option.label}</p>
      </div>
    ))}
  </>
);
