import { MeApi } from '@/modules/me/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useAddEmail = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: MeApi.addEmail,
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: ['email'],
      });
    },
    onError: (error) => {
      console.error('Error adding email:', error.message);
      throw error;
    },
  });
};
