import React, {
  ReactNode, useCallback, useEffect, useState,
} from 'react';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
} from 'react-hook-form';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { customComponents } from './custom-components';
import styles from './select.module.scss';

interface Props<T extends FieldValues> {
  setValue: UseFormSetValue<T>;
  control: Control<T, string[]>;
  name: Path<T>;
  placeholder?: string;
  closeMenuOnSelect?: boolean;
  options: SelectOption[];
  requiredFiled?: boolean;
  isSearchable?: boolean;
  autoSelectFirst?: boolean;
  isClearable?: boolean;
  defaultValue?: SelectOption;
  isDisabled?: boolean;
  children?: ReactNode;
  selectSize?: 'xs' | 's';
  className?: string;
  minShowItems?: number;
}

interface SelectOption {
  value: string | number | undefined;
  label: string | number | null | ReactNode;
}

export const UniversalSelect = <T extends FieldValues>(props: Props<T>) => {
  const {
    control,
    setValue,
    name,
    placeholder,
    closeMenuOnSelect = true,
    options,
    isSearchable = false,
    isClearable = true,
    autoSelectFirst = false,
    defaultValue,
    isDisabled,
    requiredFiled,
    children,
    selectSize,
    className,
    minShowItems = 5,
  } = props;
  const animated = makeAnimated();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isValueSelected, setIsValueSelected] = useState(false);
  const { t } = useTranslation('');

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue.value as PathValue<T, Path<T>>);
      setIsValueSelected(false);
    }
  }, []);


  const handleChange = (selectedOption: SelectOption | null) => {
    if (selectedOption && selectedOption !== defaultValue) {
      setValue(name, selectedOption.value as PathValue<T, Path<T>>);
      setIsValueSelected(true);
    } else {
      if (autoSelectFirst) {
        setValue(name, (defaultValue?.value || options[0]?.value) as PathValue<T, Path<T>>);
      } else {
        setValue(name, undefined as unknown as PathValue<T, Path<T>>);
      }

      setIsValueSelected(false);
    }
  };

  const selectStyle = clsx(
    styles.select,
    selectSize && styles[`size_${selectSize}`],
    isDropdownOpen && styles.open,
    className,
  );

  const nothingFoundText = useCallback(
    () => {
      const text = t('notFound:secondText');
      const firstLetter = text.charAt(0).toUpperCase();
      return `${firstLetter}${text.slice(1)}`;
    },
    [t],
  );

  return (
    <>
      {options && (
        <Controller
          name={name}
          control={control}
          rules={{
            required: {
              value: requiredFiled || false,
              message: '',
            },
          }}
          render={({ field }) => (
            <Select
              {...field}
              className={selectStyle}
              id={name}
              components={{
                ...animated,
                Control: customComponents.Control,
                Option: customComponents.Option,
                ClearIndicator: isValueSelected ? customComponents.ClearIndicator : undefined,
                IndicatorSeparator: customComponents.IndicatorSeparator,
                DropdownIndicator: !isValueSelected ? customComponents.DropdownIndicator : undefined,
                Menu: customComponents.Menu,
                // передаем свои minShowItems для отображения минимального количества элементов, если текст в элементе слишком длинный
                MenuList: (menuProps) => (
                  <customComponents.MenuList {...menuProps} minShowItems={minShowItems} />
                ),
              }}
              placeholder={children || placeholder} // можно прокидывать или placeholder, или компонент с SVG/ReactNode
              closeMenuOnSelect={closeMenuOnSelect}
              isClearable={isClearable}
              options={options}
              defaultValue={defaultValue}
              isDisabled={isDisabled}
              isSearchable={isSearchable}
              onChange={(selectedOption) => {
                handleChange(selectedOption as SelectOption | null);
              }}
              value={field.value ? options.find((option) => option.value === field.value) : null}
              onMenuOpen={() => setIsDropdownOpen(true)}
              onMenuClose={() => setIsDropdownOpen(false)}
              noOptionsMessage={nothingFoundText}
            />
          )}
        />
      )}
    </>
  );
};
