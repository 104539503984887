export const NO_PLACE_STATUSES = ['ZERO_RESULTS', 'INVALID_REQUEST', 'NOT_FOUND'];

export const PLUS_SVG = `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4.5C11.5398 4.5 11.1667 4.8731 11.1667 5.33333V11.1667H5.33333C4.8731 11.1667 4.5 11.5398 4.5 12C4.5 12.4602 4.8731 12.8333 5.33333 12.8333H11.1667V18.6667C11.1667 19.1269 11.5398 19.5 12 19.5C12.4602 19.5 12.8333 19.1269 12.8333 18.6667V12.8333H18.6667C19.1269 12.8333 19.5 12.4602 19.5 12C19.5 11.5398 19.1269 11.1667 18.6667 11.1667H12.8333V5.33333C12.8333 4.8731 12.4602 4.5 12 4.5Z" fill="currentColor"/>
  </svg>`;

export const MINUS_SVG = `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.21194 11.25H18.7881C18.9769 11.25 19.158 11.329 19.2915 11.4697C19.425 11.6103 19.5 11.8011 19.5 12C19.5 12.1989 19.425 12.3897 19.2915 12.5303C19.158 12.671 18.9769 12.75 18.7881 12.75H5.21194C5.02312 12.75 4.84203 12.671 4.70852 12.5303C4.57501 12.3897 4.5 12.1989 4.5 12C4.5 11.8011 4.57501 11.6103 4.70852 11.4697C4.84203 11.329 5.02312 11.25 5.21194 11.25Z" fill="currentColor"/>
  </svg>`;

export const THAI_LAT_LNG = { lat: 13.7, lng: 100.51 };

export const ZOOM = {
  default: 10,
  th: 4.5,
  krabi: 12,
  pangan: 11,
  pattaya: 10,
  phuket: 11.3,
  samui: 10.5,
};
