import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { FieldLayout } from './field-layout.component';
import { HiddenRadiobutton } from './hidden-radiobutton.component';
import { CreateAdTypes } from '../../../types';

interface ConditionProps {
  options: { value: string; label: string }[];
  name: keyof CreateAdTypes;
  required: boolean;
}

export const ConditionField = ({ options, name, required }: ConditionProps) => {
  const { t } = useTranslation();

  return (
    <FieldLayout title={t('ad-parameters:condition')} name={name} required={required}>
      {options.map(({ value, label }) => (
        <HiddenRadiobutton
          key={value}
          name={name}
          value={value}
          label={label}
          required={required}
        />
      ))}
    </FieldLayout>
  );
};
