import React, { useCallback, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useMatchMedia } from '../../hooks/use-match-media.hook';
import { Tag } from '../tag/tag.component';
import { Button } from '../button/button.component';
import styles from './tag-selector.module.scss';

interface TagSelectorProps {
  label: string;
  options: { value: string; label: string }[];
}

export const TagSelector = ({ label, options }: TagSelectorProps) => {
  const [selected, setSelected] = useState<string[]>([]);
  const [showAll, setShowAll] = useState(false);
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();
  const text = isMobile ? t('common:hide') : t('common:showLess');

  const handleTagClick = useCallback((value: string) => {
    setSelected((prevSelected) => (
      prevSelected.includes(value)
        ? prevSelected.filter((val) => val !== value)
        : [...prevSelected, value]
    ));
  }, []);

  const displayedOptions = showAll ? options : options.slice(0, 10);

  return (
    <div className={styles.wrapper}>
      <label className={styles.label}>{label}</label>
      <div className={styles.tags_list}>
        {displayedOptions.map((item) => (
          <Tag
            key={item.value}
            label={item.label}
            type="tagMobile_white"
            className={styles.tag}
            onClick={() => handleTagClick(item.value)}
            color={selected.includes(item.value) ? 'light-green' : 'white'}
          />
        ))}
        {options.length > 10 && (
          <Button
            buttonSize="xs"
            buttonColor="green"
            appearance="secondary"
            className={styles.button}
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? text : t('common:showMore')}
          </Button>
        )}
      </div>
    </div>
  );
};
