import { useCallback, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  AppTooltip, Button, InputText, SvgIcon, TipModal,
} from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import s from './become-company.module.scss';
import { TAX_ID_LENGTH } from './const';
import { BecomeCompanyFormType } from './types';

export const VerificationSection = () => {
  const { t } = useTranslation();
  const {
    register, setError, formState: { errors }, clearErrors,
  } = useFormContext<BecomeCompanyFormType>();
  const value = useWatch({ name: 'tax_id' });
  const { isMobile } = useMatchMedia();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleBlur = useCallback(() => {
    if (!value) {
      setError('tax_id', {
        type: 'required',
        message: t('forms:validationErrors.required'),
      });
      return;
    }
    if (value.length !== TAX_ID_LENGTH) {
      setError('tax_id', {
        type: 'length',
        message: t('forms:validationErrors.fix_length', { length: TAX_ID_LENGTH, value: value.length }),
      });
      return;
    }
    clearErrors('tax_id');
  }, [value]);

  const toggleModal = useCallback(() => setIsModalOpen((prev) => !prev), []);

  return (
    <section className={s.field}>
      <h2>{t('userAccount:become_company.verification._')}</h2>
      <div className={s.field_inner}>
        <div className={s.title_wrapper}>
          <p>TAX ID</p>
          {isMobile ? (
            <Button
              appearance="secondary"
              buttonColor="blue"
              buttonSize="xs"
              onClick={toggleModal}
            >
              <SvgIcon name="info" />
            </Button>
          ) : (
            <AppTooltip
              title={t('userAccount:become_company.verification.tooltip')}
              placement="bottom-start"
              stylesTooltip="card"
            >
              <span>
                <SvgIcon name="info" />
              </span>
            </AppTooltip>
          )}
        </div>
        <div className={s.values}>
          <div className={s.input_wrapper}>
            <InputText
              type="number"
              minLength={TAX_ID_LENGTH}
              maxLength={TAX_ID_LENGTH}
              {...register('tax_id', {
                required: true,
                onBlur: handleBlur,
              })}
              className={s.tax_id__input}
            />
            {errors.tax_id && (
              <p className={s.error}>{errors.tax_id.message as string}</p>
            )}
            {!value && (
              <p className={s.placeholder}>{t('userAccount:become_company.verification.length')}</p>
            )}
          </div>
          <p className={s.tip}>{t('userAccount:become_company.verification.tip')}</p>
        </div>
      </div>

      <TipModal
        isOpen={isModalOpen}
        content={t('userAccount:become_company.verification.tooltip')}
        onClose={toggleModal}
      />
    </section>
  );
};
