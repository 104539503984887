import { fetchList } from '@/modules/shared/api';
import { endpoints } from './endpoints';
import { City } from '../types';


export class LocationsApi {
  static list = async (config = {}) => {
    const data = await fetchList<City[]>(
      endpoints.list,
      config,
    );

    return { data };
  };
}
