import { Autocomplete, TextField } from '@mui/material';
import React, { ComponentProps, FC } from 'react';
import styles from './dropdown.module.scss';


export const Dropdown: FC<
{ placeholder?: string, disabled?: boolean } & Omit<ComponentProps<typeof Autocomplete>, 'renderInput'>
> = ({
  className = '', placeholder, disabled = false, ...props
}) => (
  <Autocomplete
    className={`${className} ${styles.container}`}
    renderInput={(params) => (
      <TextField
        {...params}
        label={placeholder}
        variant="standard"
        InputProps={{
          ...params.InputProps,
          disableUnderline: true, // убираем underline/border-bottom
          disabled,
        }}
      />
    )}
    {...props}
  />
);
