import {
  createRef, useRef, ChangeEvent, KeyboardEvent,
} from 'react';
import clsx from 'clsx';
import styles from './code-input.module.scss';


export type CodeInputProps = {
  value?: string;
  error?: boolean;
  onChange: (value: string) => void;
};

export const CodeInput = ({ value, error, onChange }: CodeInputProps) => {
  const inputsRef = useRef<HTMLInputElement[]>(Array(6).fill(createRef()));
  const handleOnChange = (index: number, e: ChangeEvent) => {
    const { value: eventValue } = (e.target as HTMLInputElement);

    if (inputsRef.current[index + 1] && eventValue) {
      inputsRef.current[index + 1]?.focus();
    } else if (!eventValue && inputsRef.current[index]) {
      inputsRef.current[index]?.focus();
    }

    onChange(inputsRef.current.map((input) => input.value).join(''));
  };

  const handleKeyDown = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
    if (
      (e.key === 'Backspace' || e.key === 'Delete')
      && !(e.target as HTMLInputElement).value
      && inputsRef.current[index - 1]
    ) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  return (
    <div className={clsx(styles.wrapper, {
      [styles.wrapper_error]: error,
    })}
    >
      {inputsRef.current.map((ref, index) => (
        <input
          ref={(element) => {
            inputsRef.current[index] = element as HTMLInputElement;
          }}
          value={value?.charAt(index) || ''}
          key={index}
          onKeyDown={(e) => handleKeyDown(index, e)}
          onChange={(e) => handleOnChange(index, e)}
          autoComplete="off"
          type="number"
          maxLength={1}
        />
      ))}
    </div>
  );
};
