import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { CompleteRegistration } from '../auth-modal-new/complete-registration.component';
import { CompleteRecovery } from '../auth-modal-new/complete-recovery.component';
import { useCloseAuthModal } from '../../hooks';
import { AuthModal as AuthModalNew } from '../auth-modal-new/auth-modal.component';
import { RegistrationModal } from '../auth-modal-new/registration-modal.component';
import CodeModal from './code-modal.component';
import ResetPasswordModal from './reset-password-modal.component';
import styles from './auth-modal.module.scss';
import { RegisterConfirmation } from '../auth-modal-new/register-email-confirmation.component';
import { ForgotModal } from '../auth-modal-new/forgot-modal.component';
import { PasswordChangeSuccessModal } from './password-change-success-modal.component';
import { useModalType } from './auth-modal.hooks';
import { ResendActivationLinkModal } from './resend-activation-link-modal.component';

const modals = {
  register: RegistrationModal,
  login: AuthModalNew,
  registerConfirmation: RegisterConfirmation,
  completeRegistration: CompleteRegistration,
  completeRecovery: CompleteRecovery,

  /**
   * handles the account activation link from the email
   */
  resendActivationLink: ResendActivationLinkModal,

  /**
   * handles when the user forgot the password
   */
  forgot: ForgotModal,
  reset: ResetPasswordModal,
  code: CodeModal,
  passwordSuccess: PasswordChangeSuccessModal,
};

const newDesignModals = ['login', 'register', 'registerConfirmation', 'completeRecovery', 'completeRegistration', 'forgot'];

export function AuthModal() {
  const modalType = useModalType();
  const Modal = modals[modalType];
  const closeModal = useCloseAuthModal();

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    };

    if (Modal) {
      document.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      if (Modal) {
        document.removeEventListener('keydown', handleKeyPress);
      }
    };
  }, [Modal, closeModal]);

  if (!Modal) {
    return null;
  }
  return (
    newDesignModals.includes(modalType) ? <Modal /> : (
      <div className={styles.container}>
        <div className={styles.modal_background} onClick={closeModal} data-close-id="modal-auth" />
        <Modal />
      </div>
    )
  );
}
