import React, { ReactNode } from 'react';
import clsx from 'clsx';
import styles from './button-switcher.module.scss';


type Value = string;

export type ButtonSwitcherProps = {
  value?: Value,
  buttonSize?: 'l',
  kind?: 'outline',
  fullWidth?: boolean
  buttons: {
    value: Value,
    name: ReactNode,
    dataTestId?: string
  }[],
  onChange?: (value: Value) => void;
};

export const ButtonSwitcher = ({
  buttonSize, buttons, value, onChange, kind, fullWidth,
}: ButtonSwitcherProps) => (
  <div className={clsx(styles.container, {
    [styles.container_fullWidth]: fullWidth,
    [styles.buttonSizeL]: buttonSize === 'l',
    [styles.kindOutline]: kind === 'outline',
  })}
  >
    {buttons.map((item) => (
      <button
        key={item.value}
        className={clsx(styles.item, {
          [styles.pressed]: item.value === value,
        })}
        onClick={() => onChange?.(item.value)}
        type="button"
        data-testid={item.dataTestId}
      >
        {item.name}
      </button>
    ))}
  </div>
);
