import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useFormatPrice } from '@/modules/shared/hooks';

interface PosterPriceDisplayProps {
  price: number;
  max_price: number | null;
}

export const usePosterPriceDisplay = ({ price, max_price }: PosterPriceDisplayProps) => {
  const eventMinPrice = useFormatPrice({ price });
  const eventMaxPrice = useFormatPrice({ price: max_price });

  const { t } = useTranslation('');

  if (price === 0) {
    return <p>{t('common:forFree')}</p>;
  }

  if (!max_price) {
    return (
      <p style={{ display: 'flex', alignItems: 'center' }}>
        {`${t('common:from')} `}
        {eventMinPrice}
      </p>
    );
  }

  return (
    <p style={{ display: 'flex', alignItems: 'center' }}>
      {
        `${t('common:from')} ${price} ${t('common:to')} `
      }
      {eventMaxPrice}
    </p>
  );
};
