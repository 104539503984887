import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Button, ModalDesktop } from '@/modules/shared/components';
import { DeleteModalProps } from './deletion.types';
import styles from './desktop-delete-modal.module.scss';

export const DesktopDeleteModal = ({
  isConfirmed, onDelete, onClose,
}: DeleteModalProps) => {
  const { t } = useTranslation('userSettings');

  return (
    <ModalDesktop
      title={(
        <p>
          {t('deleteReason.deleteConsequences')}
        </p>
      )}
      open={isConfirmed}
      onClose={onClose}
      className={styles.confirm_modal}
    >
      <div className={styles.confirm_deletion}>
        <p>{t('deleteReason.permanentDeletion')}</p>
        <div className={styles.buttons}>
          <Button
            as="button"
            buttonSize="s"
            buttonColor="grey"
            appearance="primary"
            onClick={onClose}
            fullWidth
          >
            {t('common:cancel')}
          </Button>
          <Button
            as="button"
            buttonSize="s"
            buttonColor="blue"
            appearance="primary"
            onClick={onDelete}
            fullWidth
          >
            {t('deleteReason.confirmDelete')}
          </Button>
        </div>
      </div>
    </ModalDesktop>
  );
};
