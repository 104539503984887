import React from 'react';
import { Select } from '@/modules/shared/components/select/';
import {
  Control, FieldValues, Path, UseFormSetValue,
} from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { SvgIcon } from '@/modules/shared/components';
import styles from './sorting-desktop.module.scss';

interface SortingDesktopProps<T extends FieldValues> {
  name: Path<T>;
  options: Array<{ value: string; label: string }>;
  control: Control<T>;
  setValue: UseFormSetValue<T>;
}

export const SortingDesktop = <T extends FieldValues>({
  name, control, setValue, options,
}: SortingDesktopProps<T>) => {
  const { t } = useTranslation('');

  return (
    <Select
      setValue={setValue}
      control={control}
      name={name}
      options={options}
      selectSize="xs"
      data-testid="sort_btn"
    >
      <div className={styles.select_placeholder}>
        <SvgIcon name="sorting" className={styles.sorting_svg} />
        <p>{t('common:sorting.title')}</p>
      </div>
    </Select>
  );
};
