const QUALITY = 0.7;
const MIME_TYPE = 'image/jpeg';
const MAX_WIDTH = 480;
const MAX_HEIGHT = 306;

export const generateThumbnail = async (file: File): Promise<File> => {
  const dataUrl = await new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = function () {
        const { width, height } = img;
        let newWidth = width;
        let newHeight = height;

        if (width > MAX_WIDTH || height > MAX_HEIGHT) {
          const scaleFactor = MAX_WIDTH / width;
          newWidth = MAX_WIDTH;
          newHeight *= scaleFactor;
        }

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx?.drawImage(img, 0, 0, newWidth, newHeight);

        resolve(canvas.toDataURL(MIME_TYPE, QUALITY));
      };
      img.src = event.target?.result as string;
    };
    reader.readAsDataURL(file);
  });
  const response = await fetch(dataUrl);
  const blob = await response.blob();
  return new File([blob], 'thumbnail.jpeg', { type: MIME_TYPE });
};
