import React, { useMemo } from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { BaseUser, type UserType, UserTypeEnum } from '@/modules/users';
import { getDeclension } from '@/modules/shared/helpers';
import { SvgIcon, UserAvatar, UserRating } from '@/modules/shared/components';
import { profileBasePath } from '@/modules/shared/const';
import { Company } from '@/modules/company/types';
import styles from '../personal-account-mobile.module.scss';

interface Props {
  user: BaseUser;
  type: UserType | undefined;
  companyData: Company | undefined;
}

export const UserInfoMobile = ({ user, type, companyData }: Props) => {
  const { t } = useTranslation('common');

  const reviewsCount = useMemo(() => (
    getDeclension({
      count: user?.reviews?.length,
      t,
      singular: 'common:reviews.one',
      few: 'common:reviews.few',
      many: 'common:reviews.many',
    })
  ), [t, user?.reviews?.length]);

  const rating = (() => {
    if (user.average_rating === 0) {
      return '0.0';
    }

    return user.average_rating;
  })();

  return (
    <div className={styles.user_info}>
      <UserAvatar
        srcAvatar={type === UserTypeEnum.Company ? (companyData?.logo?.thumbnail || '') : user.avatar}
        userName={type === UserTypeEnum.Company ? (companyData?.name || '') : user.full_name}
        diameter="100px"
      />
      <div className={styles.user_name}>
        <div className={styles.name}>
          <span>{type === UserTypeEnum.Company ? (companyData?.name || '') : user.full_name}</span>
          <Link href={`/${profileBasePath}/account`}>
            <SvgIcon name="pencil" className={styles.pencil} />
          </Link>
        </div>
        <p className={styles.seller_type}>{t(`common:${type}`)}</p>
      </div>
      <div className={styles.rating}>
        <div className={styles.rating_number}>
          <p>{rating}</p>
          <UserRating rating={user.average_rating} sizeStar="16px" />
        </div>
        <Link
          className={styles.reviews}
          href={`/${profileBasePath}/rating`}
        >
          {reviewsCount}
        </Link>
      </div>
    </div>
  );
};
