import {
  forwardRef, PropsWithChildren, RefObject,
} from 'react';

import clsx from 'clsx';
import styles from './modal-root.module.scss';

export const ModalRoot = forwardRef(({
  ownerState: {
    open,
    exited,
  },
  children,
  ownerState,
  ...props
}: PropsWithChildren<{ ownerState: { open: boolean, exited: boolean } }>, ref: RefObject<HTMLDivElement>) => (
  <div
    ref={ref}
    {...props}
    className={clsx(styles.root, {
      [styles.root_hidden]: !open && exited,
    })}
  >
    {children}
  </div>
));
