import useTranslation from 'next-translate/useTranslation';
import { Button } from '../button';
import { ModalDesktop } from '../modal';
import s from './tip-modal.module.scss';

type TipModalProps = {
  isOpen: boolean;
  content: string;
  onClose: () => void;
};

export const TipModal = ({ isOpen, content, onClose }: TipModalProps) => {
  const { t } = useTranslation('common');

  return (
    <ModalDesktop
      open={isOpen}
      onClose={onClose}
      showCloseButton={false}
      hasHeader={false}
      className={s.modal}
      isMobile
    >
      <div className={s.modal_content}>
        <p>{content}</p>
        <Button
          appearance="primary"
          buttonColor="grey"
          buttonSize="s"
          onClick={onClose}
          fullWidth
          className={s.modal_content__button}
        >
          {t('ok')}
        </Button>
      </div>
    </ModalDesktop>
  );
};
