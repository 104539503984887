import { useCallback } from 'react';
import { NextRouter } from 'next/router';

export const useLogoClick = (router: NextRouter) => useCallback((event: React.MouseEvent) => {
  event.preventDefault();
  const { locale } = router;
  const { pathname } = window.location;
  if (pathname !== `/${locale}` && pathname !== '/') {
    router.push(`/${locale}`);
  } else {
    window.location.reload();
  }
}, [router]);
