import useTranslation from 'next-translate/useTranslation';
import { CreationDescription, CreationTitle } from '@/modules/shared/components';
import s from './become-company.module.scss';
import { CompanyLogo } from './company-logo.component';

export const CompanyInfo = () => {
  const { t } = useTranslation('userAccount');

  return (
    <section className={s.field}>
      <h2>{t('become_company.company_info._')}</h2>
      <CompanyLogo />
      <CreationTitle
        title={t('become_company.company_info.name')}
        placeholder={t('become_company.company_info.name_placeholder')}
        required={false}
        tip={t('become_company.company_info.name_tip')}
      />
      <CreationDescription
        max_length={150}
        title={t('become_company.company_info.description')}
        placeholder={t('become_company.company_info.description_placeholder')}
        required={false}
        heightScrollDesktop={150}
        heightScrollMobile={125}
      />
    </section>
  );
};
