import React, { forwardRef, ReactNode } from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { ItemLoader } from '../item-loader.component';
import styles from './infinite-list-of-ads-layout.module.scss';

interface ListOfAdsLayoutProps {
  title: string;
  children: ReactNode;
  infiniteListRef?: (node: unknown) => void;
  isResent?: boolean;
  isLoading?: boolean;
}

export const InfiniteListOfAdsLayout = forwardRef<HTMLDivElement, ListOfAdsLayoutProps>(
  (props, ref?) => {
    const {
      title, children, infiniteListRef, isResent, isLoading = false,
    } = props;

    return (
      <section className={clsx(styles.ads_block, { [styles.resent]: isResent })}>
        <h3>{title}</h3>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <ItemLoader isLoading size={65} />
          </Box>
        ) : (
          <div className={styles.ads_container} ref={ref}>
            {children}
          </div>
        )}
        <div ref={infiniteListRef} />
      </section>
    );
  },
);
