import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useTranslation from 'next-translate/useTranslation';
import * as yup from 'yup';
import s from '@/styles/main/AuthModal.module.scss';
import useForgotPassword from '@/hooks/auth/useForgotPassword';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import i18n from '@/lib/i18n';
import { InputPassword } from '@/modules/forms/components';
import { useOpenAuthModal, useValidationSchema } from '@/modules/auth/hooks';

export default function ResetPasswordModal() {
  const { locale } = useRouter();
  const { t } = useTranslation('');
  const { validationPassword, validationPassword2 } = useValidationSchema();

  const schema = yup.object().shape({
    ...validationPassword.fields,
    ...validationPassword2.fields,
  }).required();

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const { setPassword, setPassword2 } = useForgotPassword();
  const setModalType = useOpenAuthModal();

  const onSubmit = async (data) => {
    setPassword(data.password);
    setPassword2(data.password2);
    Cookies.set('resetPassword', data.password);
    Cookies.set('resetPassword2', data.password2);
    setModalType('code');
  };

  return (
    <div className={s.auth_modal}>
      <div className={s.reset_password}>
        <h2>{i18n.auth.resetPassword.title[locale]}</h2>
        <form className={s.password_form} onSubmit={handleSubmit(onSubmit)}>
          <input
            type="email"
            name="email"
            value={Cookies.get('forgotPasswordEmail')}
            hidden
            readOnly
            autoComplete="email"
          />
          <div>
            <InputPassword
              control={control}
              placeholder={t('auth:label.password')}
              name="password"
            />
            {errors.password && <p className={s.error}>{errors.password.message}</p>}
          </div>

          <div>
            <InputPassword
              control={control}
              placeholder={t('auth:label.confirmationPassword')}
              name="password2"
            />
            {errors.password2 && <p className={s.error}>{errors.password2.message}</p>}
          </div>

          <button
            type="submit"
            className={s.reset_button}
          >
            {i18n.auth.resetPassword.reset[locale]}
          </button>
        </form>
      </div>
    </div>
  );
}
