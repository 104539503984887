import React from 'react';
import { UserRating } from '@/modules/shared/components';
import s from './star-line.module.scss';

type StarLineProps = {
  rating: number;
  quantity: number;
  totalQuantity: number;
};

export const StarLine = ({ rating, quantity, totalQuantity }: StarLineProps) => {
  const innerLineWidth = totalQuantity === 0 ? 0 : ((quantity / totalQuantity) * 100).toFixed(2);

  return (
    <div className={s.star_line}>
      <UserRating rating={rating} />
      <span className={s.line}>
        <span
          className={s.inner_line}
          style={{ width: `${innerLineWidth}%` }}
        />
      </span>
      <span className={s.quantity}>{quantity}</span>
    </div>
  );
};
