import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import Link from 'next/link';
import { useMeContext } from '@/modules/me';
import { profileBasePath } from '@/modules/shared/const';
import { Checkbox, CountNotification } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { ChatImage } from '../chat-image';
import { ChatName } from '../chat-name';
import { AdName } from '../ad-name';
import { StatusMessage } from '../status-message';
import { useTimeLastMessage } from '../../hooks';
import type { ChatList } from '../../types';
import { chat_param_ad_id } from '../../const';

import styles from './chat-message.module.scss';


export type ChatMessageProps = {
  data: ChatList;
  onChoose: (id: number) => void;
  isSelected: boolean;
  isCheckbox: boolean;
};

export const ChatMessage = ({
  data, onChoose, isSelected, isCheckbox,
}: ChatMessageProps) => {
  const { t, lang } = useTranslation('');
  const { me } = useMeContext();
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const meID = me?.id;

  const pathChat = data?.announcement
    ? `/${profileBasePath}/chat/user/${data?.recipient?.id}?${chat_param_ad_id}=${data?.announcement?.id}`
    : `/${profileBasePath}/chat/user/${data?.recipient?.id}`;

  const shouldShowCheckbox = isDesktop || ((isTablet || isMobile) && isCheckbox);


  return (
    <div className={styles.container}>
      {shouldShowCheckbox && (
        <Checkbox
          checkboxSize="24"
          checked={isSelected}
          onChange={() => onChoose(data.id)}
          className={styles.container_checkbox}
        />
      )}
      <Link href={pathChat} className={styles.container_message}>
        <ChatImage
          announcement={data?.announcement}
          recipient={data?.recipient}
        />

        <div className={styles.section}>
          <div className={styles.section_info}>
            <div className={styles.name_ad}>
              <ChatName
                recipient={data?.recipient}
                me={me ?? null}
                announcement={data?.announcement ?? null}
              />
              {data?.announcement && (
                <AdName announcement={data.announcement} />
              )}
            </div>
            <p
              className={clsx(styles.section_info_message, {
                [styles.section_info_message_new]: data?.unread_count !== 0,
              })}
            >
              {data?.last_message?.sender === meID ? `${t('chat:you')}: ` : ''}
              <span>{data?.last_message?.message || t('chat:image')}</span>
            </p>
          </div>
          <div className={styles.section_state}>
            {data?.last_message?.sender === meID && (
              <StatusMessage
                messageStatus={data?.last_message?.is_read ? 'read' : 'unread'}
                className={styles.section_state_message}
              />
            )}
            <div className={styles.section_state_box}>
              <p
                className={clsx(styles.section_state_time, {
                  [styles.section_state_time_read]: data?.unread_count === 0,
                })}
              >
                {useTimeLastMessage(data?.last_message?.timestamp, t, lang)}
              </p>
              {data?.last_message?.sender !== meID
                && data?.unread_count !== 0 && (
                  <CountNotification
                    count={data?.unread_count}
                    type="chat"
                    limit={null}
                    color="green"
                  />
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
