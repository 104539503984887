import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useFormContext } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { MobileHeader } from '@/modules/app/components/header/mobile-header.component';
import { useCategoryName, useSortedCategories } from '@/modules/categories/hooks';
import { CreateAdTypes } from '../../types';
import { useSelectCategoryMobileHandlers } from '../../hooks';
import { useCreateAdActionsContext, useCreateAdContext } from '../../context';
import { SelectCategoryCard } from './select-category-card.component';
import styles from './select-category.module.scss';

const allowTypes = ['buy', 'rent'];

export const SelectCategoryMobile = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { sortedCategories } = useSortedCategories();
  const [getNameByCode] = useCategoryName();

  const { resetField } = useFormContext<CreateAdTypes>();

  const {
    category: selectedCategory,
    subCategory: selectedSubCategory,
    underSubCategory: selectedUnderSubCategory,
    isLastLevel,
    mobileStep,
  } = useCreateAdContext();
  const {
    handleClickBack,
    handleCategoryClick,
    handleSubcategoryClick,
    handleUnderSubcategoryClick,
    handleTypeClick,
  } = useSelectCategoryMobileHandlers(resetField);
  const {
    setSubCategory,
    setUnderSubCategory,
    setAdType,
    setMobileStep,
  } = useCreateAdActionsContext();

  useEffect(() => {
    // может быть шаг 2, выбор подкатегории без выбора типа (сдать/продать)
    // может быть шаг 3, выбор типа после подкатегории
    // может быть шаг 3, выбор подподкатегории без выбора типа
    // может быть шаг 4, выбор типа после подподкатегории
    if (isLastLevel) {
      if (!selectedCategory?.allow_ad_type && !selectedUnderSubCategory) {
        setMobileStep(2);
        resetField('sub_category');
        setSubCategory(undefined);
        return;
      }
      if (selectedCategory?.allow_ad_type && !selectedUnderSubCategory) {
        setMobileStep(3);
        resetField('operation_type');
        setAdType(undefined);
        return;
      }
      if (!selectedCategory?.allow_ad_type && selectedUnderSubCategory) {
        setMobileStep(3);
        resetField('under_sub_category');
        setUnderSubCategory(undefined);
        return;
      }
      if (selectedCategory?.allow_ad_type && selectedUnderSubCategory) {
        setMobileStep(4);
        resetField('operation_type');
        setAdType(undefined);
      }
    }
  }, []);

  return (
    <>
      <MobileHeader
        title={t('createAd:placeAd')}
        onClickBack={handleClickBack}
        showBackButton={mobileStep > 1}
        onClose={() => router.push('/user/my-ads')}
      />
      {mobileStep === 1 && sortedCategories && (
        <div className={styles.category}>
          <h4>{t('createAd:category.selectCategory')}</h4>
          {sortedCategories.map((category) => (
            <SelectCategoryCard
              id={category.id}
              key={category.codename}
              icon={category.icon}
              title={getNameByCode(category.codename)}
              name="category"
              onClick={() => handleCategoryClick(category)}
            />
          ))}
        </div>
      )}
      {mobileStep === 2 && selectedCategory && (
        <div className={styles.sub_category}>
          <h4>{getNameByCode(selectedCategory.codename)}</h4>
          {selectedCategory.sub_categories?.map((subcategory) => (
            <SelectCategoryCard
              id={subcategory.id}
              key={subcategory.codename}
              title={getNameByCode(selectedCategory.codename, subcategory.codename)}
              name="sub_category"
              onClick={() => handleSubcategoryClick(subcategory)}
            />
          ))}
        </div>
      )}
      {mobileStep === 3 && selectedSubCategory && (
        <div className={styles.under_sub_category}>
          <h4>
            {selectedSubCategory.under_sub_categories.length !== 0 && getNameByCode(selectedCategory!.codename, selectedSubCategory.codename)}
          </h4>
          {selectedSubCategory.under_sub_categories?.map((underSubCategory) => (
            <SelectCategoryCard
              id={underSubCategory.id}
              key={underSubCategory.codename}
              title={getNameByCode(selectedCategory!.codename, selectedSubCategory.codename, underSubCategory.codename)}
              name="under_sub_category"
              onClick={() => handleUnderSubcategoryClick(underSubCategory)}
            />
          ))}
        </div>
      )}
      {(mobileStep === 3 || mobileStep === 4) && selectedCategory?.allow_ad_type && isLastLevel && (
        <div className={styles.allowed_ad_type}>
          <h4>
            {selectedUnderSubCategory
              ? getNameByCode(selectedCategory.codename, selectedSubCategory!.codename, selectedUnderSubCategory.codename)
              : getNameByCode(selectedCategory.codename, selectedSubCategory!.codename)}
          </h4>
          {allowTypes.map((type) => (
            <SelectCategoryCard
              id={type}
              key={type}
              title={t(`createAd:allowTypes.${type}`)}
              name="operation_type"
              onClick={() => handleTypeClick(type)}
            />
          ))}
        </div>
      )}
    </>
  );
};
