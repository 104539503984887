import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { getDateOfCreation } from '@/modules/shared/helpers';
import { useMatchMedia } from '@/modules/shared/hooks';
import { UserAvatar } from '@/modules/shared/components';
import { IReviews } from '@/modules/shared/types';
import s from './answer-item.module.scss';

// ответ на отзыв, используется на страницах users/id, user/rating, user/my-feedback

export const AnswerItem = ({ item }: { item: IReviews }) => {
  const { t, lang } = useTranslation();
  const { isMobile } = useMatchMedia();


  return (
    <div className={s.answer}>
      <div className={s.answer_recipient}>
        <UserAvatar
          srcAvatar={item.recipient?.company?.logo?.thumbnail ?? item.recipient.avatar}
          userName={item.recipient?.company?.name ?? item.recipient.full_name}
          diameter="32px"
        />
        <p>
          {item.recipient?.company?.name ?? item.recipient.full_name}
          {isMobile && (
            <span className={s.date}>
              {/* на backend не сохранялся дата ответа, поэтому только у новых отзывов будет дата ответа */}
              {item.answered_at && getDateOfCreation({ date: item.answered_at, t, lang })}
            </span>
          )}
        </p>
      </div>
      <p className={s.comment}>{item.recipient_answer}</p>
      {!isMobile && (
        <p className={s.date}>
          {item.answered_at && getDateOfCreation({ date: item.answered_at, t, lang })}
        </p>
      )}
    </div>
  );
};
