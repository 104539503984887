import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AdsApi } from '../api';

export const useToggleMyAdVisibility = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: AdsApi.toggleAdVisibility,
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ['my-ads-list'] });
      await queryClient.refetchQueries({ queryKey: ['my-ads-infinity-list'] });
    },
    onError: (e) => console.error('Error:', e),
  });
};
