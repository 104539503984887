import {
  createContext, Dispatch, SetStateAction, useContext,
} from 'react';
import { noop } from 'lodash';
import { AxiosResponse } from 'axios';

type FormValue = string | number;

export interface IAuthContext {
  isAuthenticated: boolean;
  isLoading: boolean;
  loginError?: Error & {
    response?: AxiosResponse
  };
  login: (loginData: { password: string, email?: string, phone_number?: string, rememberMe: boolean }) => void;
  logout: () => void;
  draft: Record<string, FormValue> | null;
  setDraft: (draftData: Record<string, FormValue> | null) => void;
  resetLoginError: () => void;
  setRefreshToken: Dispatch<SetStateAction<string>>
  setAccessToken: Dispatch<SetStateAction<string>>
  isModalOpen: boolean;
}

export const AuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  isLoading: false,
  login: noop,
  logout: noop,
  draft: null,
  setDraft: noop,
  resetLoginError: noop,
  setRefreshToken: noop,
  setAccessToken: noop,
  isModalOpen: false,
});


export const useAuthContext = () => useContext(AuthContext);
