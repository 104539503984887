import React, {
  useCallback, useState,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Button } from '@/modules/shared/components/button';
import { SvgIcon, Tag } from '@/modules/shared/components';
import { ModalMobile } from '@/modules/shared/components/modal';
import { RadioButton } from '@/modules/shared/components/inputs';
import styles from './sort-button-mobile.module.scss';

interface SortButtonMobileProps {
  setValue: (value: string) => void;
  options: Array<{ value: string; label: string }>;
  isOnlyIcon: boolean;
}

export const SortButtonMobile = ({
  setValue, options, isOnlyIcon,
}:SortButtonMobileProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('');
  const [tempValue, setTempValue] = useState('');

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleApply = useCallback(() => {
    setIsOpen(false);
    setValue(tempValue);
  }, [tempValue]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setTempValue(event.target.value);
  }, []);

  return (
    <>
      {isOnlyIcon ? (
        <Tag
          type="statusM"
          color="very-light-grey"
          icon={(<SvgIcon name="sorting" />)}
          onClick={() => setIsOpen(!isOpen)}
        />
      ) : (
        <Button
          appearance="primary"
          buttonColor="grey"
          buttonSize="xs"
          onClick={() => setIsOpen(!isOpen)}
          data-testid="sort_btn"
          className={styles.sorting_button}
        >
          <SvgIcon name="sorting" />
          {t('adv:filters.sorting')}
        </Button>
      )}
      <ModalMobile open={isOpen} title={t('common:sorting.title')} onClose={handleClose}>
        <div className={styles.modal_content}>
          <div className={styles.options}>
            {options.map((option) => (
              <label
                key={option.value}
                data-testid={`sort_option_${option.value}`}
              >
                <RadioButton
                  radioColor="green"
                  name="sorting"
                  value={option.value}
                  checked={tempValue === option.value}
                  onChange={handleChange}
                />
                {option.label}
              </label>
            ))}
          </div>
          <Button
            appearance="primary"
            buttonColor="green"
            buttonSize="s"
            onClick={handleApply}
          >
            {t('common:apply')}
          </Button>
        </div>
      </ModalMobile>
    </>
  );
};
