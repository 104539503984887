import { useCallback } from 'react';
import useTranslation from 'next-translate/useTranslation';

export const useCategoryName = () => {
  const { t } = useTranslation('categories');

  const getByCode = useCallback(
    (...codenames: string[]) => (codenames[codenames.length - 1] === 'other' ? t('other') : t(
      `${codenames.map((codename) => `categories.${codename}`)
        .join('.')}._`,
    )),
    [t],
  );

  return [getByCode] as const;
};
