import useTranslation from 'next-translate/useTranslation';
import { SvgIcon } from '@/modules/shared/components';
import styles from '../leave-feedback-modal.module.scss';

export const StepThree = () => {
  const { t } = useTranslation('review');

  return (
    <div className={styles.step_three}>
      <h3>{t('successSubmitTitle')}</h3>
      <p>{t('successSubmitText')}</p>
      <SvgIcon name="features" />
    </div>
  );
};
