import { api } from '@/modules/shared/api';
import { getCookie } from '@/components/cookie/cookieHelpers';
import { endpoints } from './endpoints';

const getIdFromCookie = () => {
  const userIdCookie = getCookie('user_id');
  return userIdCookie !== null ? parseInt(userIdCookie, 10) : null;
};

export const adComplaintApi = {
  create: async (data) => {
    const userId = getIdFromCookie();

    if (userId === null) {
      throw new Error('User ID is not available in cookies');
    }

    const formData = { ...data, reviewer: userId };
    const response = await api.post(endpoints.createAdComplaint(), formData);
    return response.data;
  },
};
