import { useCallback, useMemo, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { useSwipeable } from 'react-swipeable';
import Link from 'next/link';
import { SubCategory } from '@/modules/categories/types';
import { SvgIcon } from '@/modules/shared/components/svg-icon';
import s from './subsection-selection-mobile.module.scss';

export const SubsectionSelectionMobile = ({ data }) => {
  const { t } = useTranslation();
  const [activeSubCategoryIndex, setActiveSubCategoryIndex] = useState<number>();
  const [subsectionDepth, setSubsectionDepth] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleSubCategoryClick = (id: number) => {
    setSubsectionDepth((prev) => prev + 1);
    setActiveSubCategoryIndex(id);
  };

  const handleUnderSubCategoryClick = () => {
    setSubsectionDepth((prev) => prev + 1);
  };

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleBack = useCallback(() => {
    setSubsectionDepth((prev) => prev - 1);
  }, []);

  const modalHandlers = useSwipeable({ onSwipedDown: handleClose });

  const arrayToMap = useMemo(
    () => (
      subsectionDepth === 1
        ? data[0]?.sub_categories
        : data[0]?.sub_categories[activeSubCategoryIndex!]?.under_sub_categories
    ),
    [subsectionDepth, activeSubCategoryIndex, data],
  );

  return (
    <div className={clsx(s.sub_categories_modal, { [s.hidden]: !isModalOpen })} {...modalHandlers}>
      {data[0] && (
        <div className={s.sub_categories_block}>
          <div className={s.category_title}>
            {subsectionDepth > 1 && <SvgIcon name="arrow-left" onClick={handleBack} />}
            <h2>
              {
                subsectionDepth === 1
                  ? data[0]?.title
                  : data[0]?.sub_categories[activeSubCategoryIndex!]?.title
              }
            </h2>
            <SvgIcon name="close-icon" onClick={handleClose} />
          </div>
          <div
            className={s.sub_categories}
            data-testid="all_in_category"
          >
            <Link
              href={`/allcities/catalog/${data[0].codename}/${subsectionDepth === 2
                ? data[0].sub_categories[activeSubCategoryIndex!]?.codename
                : ''}`}
            >
              {t(`common:allIn${subsectionDepth > 1 ? 'Sub' : ''}Category`)}
            </Link>
            <SvgIcon name="check" className={s.check_icon} />
          </div>
          {arrayToMap.map((el: SubCategory, i: number) => (
            !el.under_sub_categories || el.under_sub_categories.length === 0
              ? (
                <Link
                  className={s.sub_categories}
                  href={el.pathname}
                >
                  <h3>{el.title}</h3>
                </Link>
              )
              : (
                <div
                  key={el.codename}
                  className={s.sub_categories}
                  onClick={() => (subsectionDepth === 1 ? handleSubCategoryClick(i) : handleUnderSubCategoryClick())}
                  data-testid={`subsection_${el.codename}`}
                >
                  <h3>{el.title}</h3>
                  {el.under_sub_categories.length > 0 && (
                    <SvgIcon name="arrow-right" className={s.next_icon} />
                  )}
                </div>
              )))}
        </div>
      )}
    </div>
  );
};
