import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { omit, pick } from 'lodash';
import { buildListQueryKeyFactory } from './helpers';


const fetchListFactory = (listApi) => async ({ queryKey }) => {
  const [filters, order, pageSize, page] = queryKey;
  return listApi({
    order,
    filters,
    pageSize,
    paginationType: 'page_number',
    page,
  });
};


/**
 *
 * @param {*} listApi
 * @param {string[]} queryKey
 */
export const listQueryFactory = (listApi, queryKey) => {
  const fetchData = fetchListFactory(listApi);
  const buildQueryKey = buildListQueryKeyFactory(queryKey);
  const queryOptionFields = ['refetchOnWindowFocus', 'refetchOnMount', 'staleTime'];
  const defaultQueryOptions = {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  };
  const useListQuery = (options = {}, initialData = { data: [] }) => {
    const keyOptions = useMemo(() => omit(options, queryOptionFields), [options]);
    const queryOptions = useMemo(() => ({
      ...defaultQueryOptions,
      ...pick(options, queryOptionFields),
    }), [options]);

    const {
      data,
      isFetching,
      isPending,
      error,
      refetch,
    } = useQuery({
      queryKey: buildQueryKey(keyOptions),
      queryFn: fetchData,
      initialData,
      ...queryOptions,
    });

    return useMemo(() => ({
      initialLoading: isPending,
      isLoading: isFetching,
      error,
      refetch,
      data,
    }), [
      refetch,
      data,
      isPending,
      isFetching,
      error,
    ]);
  };

  return useListQuery;
};
