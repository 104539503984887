import React from 'react';
import { User } from '@/modules/users';
import { ButtonsGroup, AdPageProfileBlock } from './components';
import ad_page_styles from '../ad-page.module.scss';


interface Props {
  allowedComplain: boolean;
  userProfile: User;
  adID: number;
}

export const ContactsBlock = (props: Props) => {
  const { allowedComplain, userProfile, adID } = props;

  return (
    <div className={ad_page_styles.contacts_block}>
      <AdPageProfileBlock
        userProfile={userProfile}
        allowedComplain={allowedComplain}
      />
      <ButtonsGroup
        allowedComplain={allowedComplain}
        userProfile={userProfile}
        adID={adID}
      />
    </div>
  );
};
