import React, { useRef } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { InfiniteListOfAdsLayout } from '@/modules/shared/components';
import { IAd } from '@/modules/ads/types';
import { AdCard } from '../../index-page';

interface AdsToDisplay {
  data: IAd[];
}
interface SimilarAdsProps {
  adId: number;
  adsToDisplay: AdsToDisplay[];
  infiniteListRef: (node: unknown) => void;
}

export const SimilarAds = (props: SimilarAdsProps) => {
  const { adId, adsToDisplay, infiniteListRef } = props;
  const { t } = useTranslation();
  const adsContainerRef = useRef<HTMLDivElement>(null);

  return (
    <InfiniteListOfAdsLayout
      title={t('adv:similarAds')}
      ref={adsContainerRef}
      infiniteListRef={infiniteListRef}
    >
      {adsToDisplay.map((page) => page?.data?.map((ad: IAd) => {
        if (ad.id === adId) return null;
        return (
          <AdCard key={ad.id} ad={ad} extendedCard={false} />
        );
      }))}
    </InfiniteListOfAdsLayout>
  );
};
