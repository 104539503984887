import { useQuery } from '@tanstack/react-query';
import { useAuthContext } from '@/modules/auth';
import { adFavoritesApi } from '../api';
import { AD_KEY } from '../types';

export const useFavoriteAds = () => {
  const { isAuthenticated } = useAuthContext();

  const {
    data: favoritesAds = [],
    isFetching,
    error,
  } = useQuery({
    queryKey: AD_KEY,
    queryFn: async () => {
      const userFavoritesAds = await adFavoritesApi.getFavoriteAd();
      return userFavoritesAds;
    },
    enabled: isAuthenticated,
  });

  return {
    favoritesAds,
    loading: isFetching,
    error,
  };
};
