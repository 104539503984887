import { Category, SubCategory, UnderSubCategory } from '@/modules/categories';
import { useMatchMedia } from '@/modules/shared/hooks';
import { CategorySelection } from '../components/category-selection';
import { withErrorBoundary } from '../hoc/withErrorBoundary';


type CategoryFieldProps = {
  category?: Category
  subCategory?: SubCategory
  underSubCategory?: UnderSubCategory
};

export const CategoryField = withErrorBoundary(({ category, subCategory, underSubCategory }: CategoryFieldProps) => {
  const { isDesktop } = useMatchMedia();

  if (!isDesktop || !category) return null;
  return (
    <CategorySelection
      category={category}
      subCategory={subCategory}
      underSubCategory={underSubCategory}
    />
  );
});
