import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { User } from '@/modules/users';
import { UserApi } from '../api/user-profile.api';

const getUser = async (id: number | string): Promise<User> => {
  const { data } = await UserApi.getUser({ id: Number(id) });

  if (!data) {
    throw new Error('User not found');
  }

  return data;
};

type Options = Partial<UseQueryOptions<User>>;

export const useUserProfile = (id: number | string, options?: Options) => useQuery<User>({
  queryKey: ['user-profile', id],
  queryFn: () => getUser(id),
  enabled: Boolean(id),
  refetchInterval: 1000 * 60 * 5, // 5 минут
  ...options,
});
