import useTranslation from 'next-translate/useTranslation';
import styles from './mobile-layout-icons.module.scss';
import { Button } from '../button';

type IconSaveProps = {
  onSave?: () => void;
};

export const IconSave = ({ onSave }: IconSaveProps) => {
  const { t } = useTranslation('common');

  return (
    <Button
      appearance="secondary"
      buttonColor="blue"
      buttonSize="s"
      className={styles.save}
      onClick={onSave}
    >
      <span>{t('save')}</span>
    </Button>
  );
};
