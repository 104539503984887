import React from 'react';
import { useFormContext } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { UniversalSelect } from '@/modules/shared/components/select/select.component';
import { useCreateAdContext } from '../../context';
import { usePersonalItems } from './hooks';
import {
  FieldsLayout,
  FieldLayout,
  ConditionField,
  SelectColor,
  HiddenRadiobutton,
} from './shared';

const ClothesAndShoes = () => {
  const { setValue, control } = useFormContext();
  const { subCategory } = useCreateAdContext();
  const { t } = useTranslation();
  const personalItems = usePersonalItems();

  return (
    <FieldsLayout title={t('ad-parameters:characteristics')}>
      <ConditionField
        options={personalItems.state}
        name="state"
        required
      />
      <FieldLayout
        name="size"
        title={t('ad-parameters:size')}
      >
        <UniversalSelect
          setValue={setValue}
          control={control}
          name="size"
          options={personalItems[subCategory!.codename].size} // разные размеры для детской/мужской/женской одежды/обуви
          placeholder=""
        />
      </FieldLayout>
      <FieldLayout
        name="brand"
        title={t('ad-parameters:brand')}
        info={t('ad-parameters:otherBrand')}
      >
        <UniversalSelect
          setValue={setValue}
          control={control}
          name="brand"
          options={personalItems.brands}
          placeholder=""
        />
      </FieldLayout>
      <FieldLayout
        name="color"
        title={t('ad-parameters:color')}
        required={false}
      >
        <SelectColor />
      </FieldLayout>
    </FieldsLayout>
  );
};

// для подкатегорий "Товары для детей и игрушки" и "Красота и здоровье"
const GoodsAndBeauty = () => {
  const { t } = useTranslation();
  const personalItems = usePersonalItems();

  return (
    <FieldsLayout title={t('ad-parameters:characteristics')}>
      <ConditionField
        options={personalItems.state}
        name="state"
        required
      />
      <FieldLayout
        name="color"
        title={t('ad-parameters:color')}
        required={false}
      >
        <SelectColor />
      </FieldLayout>
    </FieldsLayout>
  );
};

const Accessories = () => {
  const { setValue, control } = useFormContext();
  const { t } = useTranslation();
  const personalItems = usePersonalItems();

  return (
    <FieldsLayout title={t('ad-parameters:characteristics')}>
      <ConditionField
        options={personalItems.state}
        name="state"
        required
      />
      <FieldLayout
        name="gender"
        title={t('ad-parameters:gender')}
      >
        {personalItems.accessories.gender.map(({ value, label }) => (
          <HiddenRadiobutton
            key={value}
            name="gender"
            value={value}
            label={label}
          />
        ))}
      </FieldLayout>
      <FieldLayout
        name="brand"
        title={t('ad-parameters:brand')}
        info={t('ad-parameters:otherBrand')}
      >
        <UniversalSelect
          setValue={setValue}
          control={control}
          name="brand"
          options={personalItems.brands}
          placeholder=""
        />
      </FieldLayout>
      <FieldLayout
        name="color"
        title={t('ad-parameters:color')}
        required={false}
      >
        <SelectColor />
      </FieldLayout>
    </FieldsLayout>
  );
};

const additionalFields = {
  'women-clothes': <ClothesAndShoes />,
  'men-clothes': <ClothesAndShoes />,
  'women-shoes': <ClothesAndShoes />,
  'men-shoes': <ClothesAndShoes />,
  'children-clothes': <ClothesAndShoes />,
  'children-goods': <GoodsAndBeauty />,
  'beauty-health': <GoodsAndBeauty />,
  accessories: <Accessories />,
};

// компонент с дополнительными полями для всей категории "Личные вещи"
export const PersonalItems = () => {
  const { subCategory } = useCreateAdContext();

  return additionalFields[subCategory!.codename];
};
