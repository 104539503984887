import clsx from 'clsx';
import styles from './radio-button.module.scss';

type RadioButtonProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  radioColor: 'green' | 'black';
};

export const RadioButton = ({ radioColor, className, ...props }: RadioButtonProps) => {
  const style = clsx(
    styles.radiobutton,
    styles[radioColor],
    className,
  );

  return (
    <input
      type="radio"
      className={style}
      {...props}
    />
  );
};

