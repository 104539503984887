import Link from 'next/link';
import React, { ReactNode, FC } from 'react';
import slugify from '@sindresorhus/slugify';
import { EventDetails } from '../../types';


type EventLinkProps = {
  event: EventDetails;
  children: ReactNode;
};

export const EventLink: FC<EventLinkProps> = ({ event, children }) => (
  <Link
    href={`/poster/${event?.title ? slugify(event.title) : null}_${event.id}`}
  >
    {children}
  </Link>
);
