import { Dispatch, memo } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { useFilterFormContext } from '@/modules/shared/hooks';

import { SearchText } from '../components/search-text';
import { FilterRow } from '../components/filter-row';
import { withErrorBoundary } from '../hoc/withErrorBoundary';

export const SearchField = withErrorBoundary(memo(() => {
  const { t } = useTranslation();
  const form = useFilterFormContext<'search'>();

  return (
    <FilterRow title={t('filters:search.title')}>
      <SearchText onChange={form.setter?.search as Dispatch<string[]>} value={form.value?.search as string[]} />
    </FilterRow>
  );
}));
