import React, { useCallback, useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useAddEmail } from '@/modules/me/mutations';
import { profileBasePath } from '@/modules/shared/const';
import { Button, SvgIcon, Toast } from '@/modules/shared/components';
import styles from '@/modules/shared/components/notification/notification.module.scss';

interface Props {
  email: string;
}

export const ConfirmEmailNotification = ({ email }: Props) => {
  const { t, lang } = useTranslation();
  const [isToastOpen, setIsToastOpen] = useState(false);

  const {
    mutate: resendActivationLink, isSuccess, error, isError,
  } = useAddEmail();

  const resendEmailVerification = useCallback(() => {
    if (!email) return;
    resendActivationLink(email);
  }, [email]);

  useEffect(() => {
    if (isError && error instanceof AxiosError) {
      if (error.response?.data?.detail?.includes('Request was throttled.')) {
        toast.error(t('forms:validationErrors.throttled'));
      }
    }

    if (isSuccess) {
      setIsToastOpen(true);
    }
  }, [isSuccess, error, isError]);

  const changeLink = `/${lang}/${profileBasePath}/account?email=add`;

  return (
    <div className={styles.email_notification_wrapper}>
      <div className={styles.email_notification}>
        <div className={styles.svg}>
          <SvgIcon name="alert" />
        </div>
        <div className={styles.text}>
          <span>
            {t('common:emailNotification.confirmEmail.part1', { email })}
            <Button
              appearance="secondary"
              buttonColor="blue"
              buttonSize="m"
              onClick={resendEmailVerification}
            >
              {t('common:emailNotification.confirmEmail.part2')}
            </Button>
            {t('common:emailNotification.confirmEmail.part3') }
            <Link href={changeLink}>
              {t('common:emailNotification.confirmEmail.part4')}
            </Link>
          </span>
        </div>
      </div>
      {isToastOpen && (
        <Toast
          label={t('common:email_confirm_sent')}
          iconName="mail"
          onClose={() => setIsToastOpen(false)}
          className={styles.toast}
        />
      )}
    </div>
  );
};
