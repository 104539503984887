import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Button, ModalMobile, RadioButton } from '@/modules/shared/components';
import { ReasonModalProps } from './deletion.types';
import styles from './mobile-reason-modal.module.scss';

export const MobileReasonModal = ({
  isOpen,
  options,
  selectedReason,
  onSelectReason,
  onClose,
  onConfirm,
}: ReasonModalProps) => {
  const { t } = useTranslation('userSettings');

  return (
    <ModalMobile
      open={isOpen}
      fullHeight
      onClose={onClose}
      showBackButton={false}
      headerBordered
    >
      <div className={styles.reason_modal}>
        <div className={styles.reasons}>
          <h2>{t('deleteReason.title')}</h2>
          <ul>
            {options.map((option) => (
              <li
                key={option.value}
                onClick={() => onSelectReason(option.value)}
              >
                <RadioButton
                  checked={selectedReason === option.value}
                  radioColor="green"
                />
                {option.label}
              </li>
            ))}
          </ul>
        </div>
        <Button
          fullWidth
          as="button"
          buttonSize="s"
          buttonColor="grey"
          appearance="primary"
          disabled={!selectedReason}
          onClick={onConfirm}
        >
          {t('deleteReason.continue')}
        </Button>
      </div>
    </ModalMobile>
  );
};
