import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Controller, useFormContext } from 'react-hook-form';
import { useMatchMedia } from '@/modules/shared/hooks';
import { UserRating } from '@/modules/shared/components';
import { ReviewFormTypes } from '../../../types';
import styles from '../../leave-feedback-modal.module.scss';

export const MakeGrade = () => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();
  const { control, formState: { errors } } = useFormContext<ReviewFormTypes>();

  return (
    <div className={styles.rating}>
      <h5>{!isMobile ? t('review:makeGrade') : t('review:rate')}</h5>
      <Controller
        name="rating"
        control={control}
        rules={{ required: t('forms:validationErrors.isNotEmpty', { nameField: t('review:rating') }) }}
        render={({ field }) => (
          <UserRating
            sizeStar="44px"
            rating={Number(field.value)}
            onRatingChange={field.onChange}
          />
        )}
      />
      {errors.rating && <p className={styles.error}>{errors?.rating.message}</p>}
    </div>
  );
};
