// dateTimeStr: "2024-09-13T12:45:33.033262+00:00"

export const useTimeLastMessage = (dateTimeStr: string, t, lang: string) => {
  const now = new Date();
  const lastMessageDate = new Date(dateTimeStr);

  const dateOptionsCurrentYear = { day: 'numeric', month: 'short' };
  const dateOptionsPreviousYear = { day: 'numeric', month: '2-digit', year: 'numeric' };
  const timeOptions = { hour: '2-digit', minute: '2-digit' };

  const isCurrentYear = lastMessageDate.getFullYear() === now.getFullYear();

  const isToday = now.toDateString() === lastMessageDate.toDateString();

  const isYesterday = now.getDate() - lastMessageDate.getDate() === 1
    && now.getMonth() === lastMessageDate.getMonth()
    && now.getFullYear() === lastMessageDate.getFullYear();


  if (isToday) {
    return lastMessageDate.toLocaleTimeString(lang, timeOptions as Intl.DateTimeFormatOptions);
  } if (isYesterday) {
    return t('common:yesterday');
  } if (isCurrentYear) { // "12 дек"
    return lastMessageDate.toLocaleDateString(lang, dateOptionsCurrentYear as Intl.DateTimeFormatOptions);
  }
  // "12.12.2023"
  return lastMessageDate.toLocaleDateString(lang, dateOptionsPreviousYear as Intl.DateTimeFormatOptions);
};
