import { useQuery } from '@tanstack/react-query';
import type { Company } from '../types';
import { CompanyApi } from '../api';
import { USER_COMPANY_KEY } from '../const';

export const useUserCompanyByUrl = (url: string) => useQuery<Company>({
  queryKey: [USER_COMPANY_KEY, url],
  queryFn: () => CompanyApi.getUserCompanyByUrl({ url }),
  enabled: Boolean(url),
  refetchOnWindowFocus: false,
});
