import React from 'react';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { useCreateAdContext } from '../../context';
import { ReturnedType } from '../../types';
import styles from './progress-bar.module.scss';

export const ProgressBarMobile = ({ progressbar }: { progressbar: ReturnedType }) => {
  const { t } = useTranslation('createAd');
  const { mainStep } = useCreateAdContext();

  return (
    <div className={styles.progress_bar}>
      <div className={styles.step_description}>
        <p className={styles.title}>
          <span className={styles.step_count}>
            {`${t('progressbar.step')} ${mainStep - 1}. `}
          </span>
          <span>{progressbar[mainStep].title}</span>
        </p>
      </div>
      <div className={styles.line_wrapper}>
        <div
          className={clsx(styles.line, {
            [styles.line_active]: mainStep === 2,
            [styles.line_completed]: mainStep > 2,
          })}
        />
        <div
          className={clsx(styles.line, {
            [styles.line_active]: mainStep === 3,
            [styles.line_completed]: mainStep === 4,
          })}
        />
        <div
          className={clsx(styles.line, {
            [styles.line_active]: mainStep === 4,
          })}
        />
      </div>
    </div>
  );
};
