import { useMutation, useQueryClient } from '@tanstack/react-query';
import { REQUEST_REVIEW } from '../consts';
import { ReviewApi } from '../api';

export const useCreateRequestReviewMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [REQUEST_REVIEW],
    mutationFn: ReviewApi.createReviewRequest,
    onSuccess: async () => queryClient.invalidateQueries({
      queryKey: [REQUEST_REVIEW],
    }),
  });
};
