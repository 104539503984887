import React from 'react';
import clsx from 'clsx';
import styles from './tag.module.scss';

interface TagProps {
  label?: string;
  color?: 'light-green' | 'green-default' | 'very-light-grey' | 'white';
  type?:
  | 'statusXS'
  | 'statusS'
  | 'statusM'
  | 'tagMobile'
  | 'tagMobile_white'
  | 'tagM'
  | 'tagM_white';
  onClick?: () => void;
  className?: string;
  icon?: React.ReactNode;
}

export const Tag = ({
  label,
  color = 'light-green',
  type = 'statusXS',
  onClick,
  className,
  icon,
}: TagProps) => {
  const style = clsx(styles.base, styles[color], styles[type], className);

  return (
    <div className={style} onClick={onClick}>
      {label && label}
      {icon && icon}
    </div>
  );
};
