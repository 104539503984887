import { mapValues, get } from 'lodash';
import { useMemo } from 'react';
import { FormState } from 'react-hook-form';

export const useVisibleErrors = <T extends object>(formState: FormState<T>) => {
  const {
    errors, dirtyFields, touchedFields, isSubmitted,
  } = formState;

  return useMemo(
    () => mapValues(
      errors,
      (_, fieldPath) => get(dirtyFields, fieldPath) || get(touchedFields, fieldPath) || isSubmitted,
    ),
    [errors, dirtyFields, touchedFields, isSubmitted],
  );
};

