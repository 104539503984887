import React, { FC, useCallback, useState } from 'react';
import {
  BreadCrumbs,
  Button,
  SvgIcon,
  Toast,
} from '@/modules/shared/components';
import { FavoriteButton } from '@/modules/favorites';
import { isServerSide } from '@/modules/shared/helpers';
import { useFormatDataPrice } from '@/modules/shared/hooks';
import useTranslation from 'next-translate/useTranslation';
import { BreadcrumbType } from '@/modules/shared/components/breadcrumbs';
import { IAd } from '@/modules/ads/types';
import s from './ad-headline.module.scss';

interface AdHeadlineProps {
  data: IAd;
  breadcrumbs: BreadcrumbType[];
}

export const AdHeadline: FC<AdHeadlineProps> = ({ data, breadcrumbs }) => {
  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation();
  const priceDisplayFormat = useFormatDataPrice();

  const handleCopied = useCallback(() => {
    if (!isServerSide() && navigator) {
      const currentUrl = window.location.href;
      navigator.clipboard.writeText(currentUrl)
        .then(() => setIsCopied(true))
        .catch((err) => console.error('Failed to copy: ', err));
    }
  }, []);

  return (
    <>
      <div className={s.ad_headline}>
        <BreadCrumbs data={breadcrumbs} />
        <div className={s.ad_headline_title}>
          <div className={s.text}>
            <h1>{data?.title}</h1>
            <FavoriteButton id={data.id} type="ads" />
          </div>
          <div className={s.price}>
            <p>{priceDisplayFormat({ priceType: data.price_type, price: data.price })}</p>
            {data.price_type === 'for_money' && <SvgIcon name="THB" />}
          </div>
        </div>
        <Button
          className={s.copy_button}
          onClick={handleCopied}
          appearance="primary"
          buttonColor="grey"
          buttonSize="xs"
        >
          <SvgIcon name="link" />
          <p>{t('adv:linkToAd')}</p>
        </Button>
      </div>
      {isCopied && (
        <Toast
          label={t('adv:linkCopied')}
          iconName="share"
          onClose={() => setIsCopied(false)}
        />
      )}
    </>
  );
};
