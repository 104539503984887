import { useQuery } from '@tanstack/react-query';
import type { Company } from '../types';
import { CompanyApi } from '../api';
import { COMPANY_KEY } from '../const';

export const useMyCompany = (enabled: boolean) => useQuery<Company | undefined>({
  queryKey: [COMPANY_KEY],
  queryFn: () => CompanyApi.getMyCompany(),
  enabled,
  refetchOnWindowFocus: false,
});
