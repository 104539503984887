import { formatDate } from './time-messages.helpers';
import { MessageDetails } from '../types';

export const groupMessagesByDateAndType = (messages: MessageDetails[]) => {
  const validDateMessages = messages.filter((msg) => {
    const date = formatDate(msg.timestamp);
    return (date && date !== 'Invalid Date');
  });

  const groupedByDate = validDateMessages.reduce((acc, msg) => {
    const date = formatDate(msg.timestamp);

    if (!acc[date]) {
      acc[date] = [];
    }

    acc[date].push(msg);

    return acc;
  }, {} as Record<string, MessageDetails[]>);

  const groupedByDateAndType: Map<string, MessageDetails[][]> = new Map();
  Object.entries(groupedByDate).forEach(([date, dateMessages]) => {
    const dateGroups: MessageDetails[][] = [[]];
    for (const message of dateMessages) {
      const lastGroup = dateGroups[dateGroups.length - 1];
      if (lastGroup.length === 0 || message.type === lastGroup[0].type) {
        lastGroup.push(message);
      } else {
        dateGroups.push([message]);
      }
    }

    groupedByDateAndType.set(date, dateGroups);
  });

  return { groupedByDateAndType };
};
