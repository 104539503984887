import React, { FC, useMemo } from 'react';
import { Dropdown, useDropdownOnChange, useDropdownValue } from '@/modules/shared/components';
import useTranslation from 'next-translate/useTranslation';
import { Option } from '@/modules/shared/types';
import { useUpdateEffect } from '@react-hookz/web';
import { useLocationsListQuery } from '../queries';


type Props = {
  cityId: string;
  value: string;
  onChange: (value?: string) => void;
};
export const DistrictsDropdown: FC<Props> = ({ value = null, cityId, onChange }) => {
  const { data } = useLocationsListQuery({ refetchOnMount: false });
  const { t } = useTranslation('');

  const options = useMemo<Option[]>(() => {
    const city = data.data.find(({ id }) => id === cityId);
    const districts = city?.districts?.map(({ id, codename }) => ({
      value: id,
      label: t(`locations:${city.codename}.districts.${codename}`),
    })) || [];
    districts.unshift({ value: null, label: t('common:firstOption') });

    return districts;
  }, [data, t, cityId]);

  useUpdateEffect(() => {
    onChange();
  }, [onChange, cityId]);

  return (
    <Dropdown
      options={options}
      value={useDropdownValue(value, options)}
      onChange={useDropdownOnChange(onChange)}
      disabled={!cityId}
    />
  );
};
