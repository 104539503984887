import { useMutation } from '@tanstack/react-query';
import { eventComplaintApi } from '../api';
import { COMPLAINT_EVENT_KEY } from '../consts';
import type { EventComplaint } from '../types';


export const useComplaintEventMutation = () => {
  const {
    mutate, isPending, error,
  } = useMutation<EventComplaint, Error, EventComplaint>({
    mutationKey: COMPLAINT_EVENT_KEY,
    mutationFn: (complaintData) => eventComplaintApi.create(complaintData),
  });

  return {
    mutate,
    isLoading: isPending,
    error,
  };
};
