import React from 'react';
import { useRouter } from 'next/router';
import i18n from '@/lib/i18n';
import styles from './screen-loader.module.scss';

export const ScreenLoader = () => {
  const { locale } = useRouter();
  return (
    <div className={styles.loading}>
      <img src="/assets/icons/hourglass.svg" alt="hourglass" />
      <p>{i18n.sort.loading[locale]}</p>
    </div>
  );
};
