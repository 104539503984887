import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import useTranslation from 'next-translate/useTranslation';
import { SvgIcon } from '@/modules/shared/components';
import styles from './images.module.scss';

interface ImageItemProps {
  index: number;
  moveImage: (fromIndex: number, toIndex: number) => void;
  handleDeleteImage: (index: number) => void;
  previewUrl: string;
}

export const ImageItem = (props: ImageItemProps) => {
  const {
    index, moveImage, handleDeleteImage, previewUrl,
  } = props;
  const ref = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation('createAd');

  const [, drop] = useDrop({
    accept: 'image',
    hover(item: { index: number }) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveImage(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'image',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className={styles.photo}
      style={{
        backgroundImage: `url(${previewUrl})`,
        opacity: isDragging ? 0.75 : 1,
      }}
    >
      {index === 0 && <span>{t('descriptionAndPhotos.photos.mainPhoto')}</span>}
      <button
        type="button"
        className={styles.delete_img}
        onClick={() => handleDeleteImage(index)}
      >
        <SvgIcon name="close" />
      </button>
    </div>
  );
};
