import useTranslation from 'next-translate/useTranslation';
import { Button, ModalDesktop } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import s from './modal.module.scss';

type ConfirmBeforeUnloadModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onReject: () => void;
};

export const ConfirmBeforeUnloadModal = ({
  isOpen, onClose, onConfirm, onReject,
}: ConfirmBeforeUnloadModalProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();

  return (
    <ModalDesktop
      open={isOpen}
      onClose={onClose}
      showCloseButton={!isMobile}
      isMobile={isMobile}
      className={s.confirm_modal}
      hasHeader={false}
    >
      <div className={s.content}>
        <div className={s.unsaved_changes}>
          <h3>{`${t('userAccount:company.save_changes')}?`}</h3>
          <p>{t('userAccount:company.unsaved_changes')}</p>
        </div>
        <div className={s.buttons}>
          <Button
            appearance="primary"
            buttonColor="blue"
            buttonSize="s"
            onClick={onConfirm}
          >
            {t('common:yes')}
          </Button>
          <Button
            appearance="primary"
            buttonColor="grey"
            buttonSize="s"
            onClick={onReject}
          >
            {t('common:no')}
          </Button>
        </div>
      </div>
    </ModalDesktop>
  );
};
