import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import styles from './accordion.module.scss';

export interface AccordionProps {
  question: string;
  answer: string;
  useDangerouslySetInnerHTML?: boolean;
}

/**
 * @interface AccordionProps
 * @property {string} question - Вопрос, который будет отображаться в заголовке аккордеона.
 * @property {string} answer - Ответ, который будет отображаться при раскрытии аккордеона.
 * @property {boolean} [useDangerouslySetInnerHTML=false] - Флаг, указывающий, нужно ли использовать
 *  `dangerouslySetInnerHTML` для рендеринга текста. Использовать только когда есть теги в тексте! По умолчанию - false.
 */

export const Accordion = ({ question, answer, useDangerouslySetInnerHTML = false }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('');

  const toggle = () => setIsOpen((prev) => !prev);

  return (
    <div className={styles.accordion} onClick={toggle}>
      <div className={styles.question}>
        {useDangerouslySetInnerHTML ? (
          <h3
            className={styles.question_title}
            dangerouslySetInnerHTML={{ __html: t(question) }}
          />
        ) : (
          <h3 className={styles.question_title}>{t(question)}</h3>
        )}

        <button
          type="button"
          className={clsx(styles.accordion_button, {
            [styles.accordion_button_open]: isOpen,
          })}
        />
      </div>

      {isOpen && (
        useDangerouslySetInnerHTML ? (
          <p className={styles.answer} dangerouslySetInnerHTML={{ __html: t(answer) }} />
        ) : (
          <p className={styles.answer}>{t(answer)}</p>
        )
      )}
    </div>
  );
};
