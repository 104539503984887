import clsx from 'clsx';
import React, { ChangeEvent } from 'react';
import styles from './checkbox.module.scss';

type CheckboxProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  checkboxSize: '24' | '14';
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const Checkbox = ({
  checked, onChange, checkboxSize, className, ...props
}: CheckboxProps) => {
  const style = clsx(
    styles.checkbox,
    {
      [styles.checkbox_14]: checkboxSize === '14',
      [styles.checkbox_24]: checkboxSize === '24',
    },
    className,
  );

  return (
    <input
      {...props}
      type="checkbox"
      checked={checked}
      onChange={onChange}
      className={style}
    />
  );
};
