import React, { SetStateAction } from 'react';
import {
  Select, FormControl, MenuItem, OutlinedInput, useMediaQuery,
} from '@mui/material';
import clsx from 'clsx';
import styles from './dropdown.module.scss';

const customStyle = {
  borderRadius: '4px',
  '& .MuiSelect-select': {
    backgroundColor: 'white',
    padding: '12px 20px',
    borderRadius: '8px',
    '& .MuiInput-input:focus': {
      backgroundColor: 'white',
    },
  },
  '& .MuiSvgIcon-root': {
    marginRight: '16px',
    width: '18px',
    height: '16px',
    backgroundImage: 'url(/assets/icons/arrow-down.svg)',
    transition: 'transform 0.3s ease',
    '& path': {
      display: 'none',
    },

    '& .MuiSelect-iconOpen': {
      backgroundImage: 'url(/assets/icons/arrow-down.svg)',
      transform: 'rotate(180deg)',
      transition: 'transform 0.3s ease',
    },
  },
};

interface Option<T> {
  value: T | null;
  label: string;
}

interface DropdownProps {
  placeholder?: string;
  options: Option<string>[];
  selectedOption: Option<string>;
  setState: React.Dispatch<SetStateAction<string>>;
  className?: string;
}

/**
 * Дропдаун без возможности поиска на основе Select компонента из MaterialUi
 * @param {string[]} props.options - список опций, первым элементом должен быть {value: null, label: t('common:firstOption')}
 * @param {Option<string>} props.selectedOption - состояние дропдауна
 * @param {Function} props.setState - сеттер из useState, который будет хранить состояние дропдауна
 */
export const DropdownSelect = (props: DropdownProps) => {
  const {
    options, className, setState, placeholder, selectedOption,
  } = props;

  const isMobile = useMediaQuery('(max-width:480px)');
  const topValue = isMobile ? '420px !important' : 'auto';

  return (
    <FormControl className={clsx(className, styles.container)} variant="standard">
      <Select
        value={selectedOption?.value ?? ''}
        onChange={(event) => setState(event.target.value)}
        input={<OutlinedInput />}
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return placeholder;
          }
          return options.find((option) => option.value === selected)?.label;
        }}
        sx={customStyle}
        MenuProps={{
          PaperProps: {
            sx: {
              top: topValue,
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value || undefined}
            style={{ whiteSpace: 'normal' }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
