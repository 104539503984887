import React from 'react';
import { SvgIcon } from '@/modules/shared/components';
import useTranslation from 'next-translate/useTranslation';
import styles from './category-label.module.scss';

interface CategoryLabelProps {
  codename: string;
  icon: string;
}

export const CategoryLabel = ({ icon, codename } : CategoryLabelProps) => {
  const { t } = useTranslation('categories');

  return (
    <div className={styles.container}>
      <img src={icon} alt={codename} className={styles.icon} />
      <div className={styles.text}>
        {t(`categories.${codename}._`)}
        <SvgIcon name="arrow-right" />
      </div>
    </div>
  );
};
