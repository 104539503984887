import React, { useCallback, useMemo, useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { ModalDesktop, ModalMobile } from '@/modules/shared/components/modal';
import { useMatchMedia } from '@/modules/shared/hooks';
import { RadioButton, Button } from '@/modules/shared/components';
import styles from './specify-reason.module.scss';
import stylesMain from './complaint-modal.module.scss';

const adReasons = ['incorrectAd', 'bannedGoods', 'spamBehavior', 'copyright', 'goodsQuality', 'other'];
const eventReasons = ['invalid_information', 'inactive_social_links', 'copyright_violation', 'fraud'];

export const SpecifyReason = ({
  selectedReason,
  setSelectedReason,
  onNext,
  isOpen,
  onClose,
  type,
}) => {
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const { t } = useTranslation('');

  const adOptions = useMemo(() => adReasons.map((reason) => ({
    codename: t(`adv:complaintModal.specifyReason.reasons.${reason}`),
    text: t(`adv:complaintModal.specifyReason.reasons.${reason}`),
  })), [t]);

  const eventOptions = useMemo(() => eventReasons.map((reason) => ({
    codename: reason,
    text: t(`events:complaintModal.specifyReason.reasons.${reason}`),
  })), [t]);

  const currentOptions = type === 'event' ? eventOptions : adOptions;

  useEffect(() => {
    if (!selectedReason && currentOptions.length > 0) {
      setSelectedReason(currentOptions[0].codename);
    }
  }, [currentOptions, selectedReason, setSelectedReason]);

  const handleOptionChange = useCallback((index) => {
    setSelectedReason(currentOptions[index].codename);
  }, [currentOptions, setSelectedReason]);

  const renderContent = () => (
    <div className={styles.container}>
      <div className={styles.optionsWrapper}>
        {currentOptions.map((option, index) => (
          <label key={index} className={styles.optionRow}>
            <RadioButton
              radioColor="black"
              checked={selectedReason === option.codename}
              onChange={() => handleOptionChange(index)}
            />
            {option.text}
          </label>
        ))}
      </div>
      <div className={stylesMain.mobile_container}>
        <Button
          appearance="primary"
          buttonColor="grey"
          buttonSize="s"
          className={clsx(styles.nextButton, stylesMain.mobile_button)}
          disabled={!selectedReason}
          onClick={onNext}
        >
          {isMobile ? t('common:continue') : t('common:next')}
        </Button>
      </div>
    </div>
  );

  return (
    <>
      {(isTablet || isDesktop) && (
        <ModalDesktop
          open={isOpen}
          title={t('common:complaintModal.specifyReason.title')}
          onClose={onClose}
          titleJustifyContent="start"
          showBackButton={false}
        >
          {renderContent()}
        </ModalDesktop>
      )}
      {isMobile && (
        <ModalMobile
          open={isOpen}
          fullHeight
          headerBordered
          showCloseButton
          onClose={onClose}
          contentStyles={stylesMain.contentModal}
          showBackButton={false}
        >
          <h3>{t('common:complaintModal.specifyReason.title')}</h3>
          {renderContent()}
        </ModalMobile>
      )}
    </>
  );
};
