import React from 'react';
import { useFormState } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { useMatchMedia } from '@/modules/shared/hooks';
import { Button } from '@/modules/shared/components';
import { useCreateAdContext } from '../context';
import { useIsStepValid } from '../hooks';
import { CreateAdTypes, ReturnedType } from '../types';
import styles from './buttons.module.scss';

export const Buttons = ({ progressbar }: { progressbar: ReturnedType }) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchMedia();
  const { mainStep, submitting } = useCreateAdContext();
  const { isSubmitting, isSubmitSuccessful } = useFormState<CreateAdTypes>();
  const { isDisabled } = useIsStepValid();

  return (
    <div className={styles.buttons_wrapper}>
      {/* не нужно использовать тернарный оператор, тк в таком случае клик по кнопке
      (при переходе с 3 на 4 шаг) вызовет отправку формы
      */}
      {mainStep === 4 && (
        <Button
          appearance="primary"
          buttonColor="green"
          buttonSize={isDesktop ? 'm' : 's'}
          type="submit"
          disabled={isDisabled || isSubmitting || isSubmitSuccessful}
        >
          {submitting ? t('common:submitting') : t('createAd:buttonsText.place')}
        </Button>
      )}
      {mainStep !== 4 && (
        <Button
          appearance="primary"
          buttonColor="green"
          buttonSize={isDesktop ? 'm' : 's'}
          type="button"
          disabled={isDisabled}
          onClick={progressbar[mainStep]?.goForward}
        >
          {t('createAd:buttonsText.continue')}
        </Button>
      )}
      {/* Пока без черновиков */}
      {/* <Button */}
      {/*  appearance="secondary" */}
      {/*  buttonColor="green" */}
      {/*  buttonSize={isDesktop ? 'm' : 's'} */}
      {/*  type="button" */}
      {/* > */}
      {/*  {t('createAd:buttonsText.saveDraft')} */}
      {/* </Button> */}
    </div>
  );
};
