import { MeApi } from '@/modules/me/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useLanguageMutation = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: MeApi.updateUserLanguage,
    onSuccess: () => client.invalidateQueries({
      queryKey: ['language'],
    }),
    onError: (error) => {
      console.error('Error updating language preference:', error.message);
      throw error;
    },
  });
};

