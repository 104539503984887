import React, { useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Category } from '@/modules/categories';
import { useGlobalData } from '@/context/GlobalContext';
import { MiniCategoryCard } from '../mini-category-card';
import { LINEAR_CATEGORIES } from './const';
import styles from './linear-categories.module.scss';

type LinearCategoriesType = {
  data: Category[];
};

export const LinearCategories = ({ data }: LinearCategoriesType) => {
  const { t } = useTranslation();
  const { isShowCategoriesModal, setIsShowCategoriesModal } = useGlobalData();

  const filteredCategories = useMemo(() => (
    data?.filter((category) => LINEAR_CATEGORIES.includes(category.codename))
      .sort((a, b) => LINEAR_CATEGORIES.indexOf(a.codename) - LINEAR_CATEGORIES.indexOf(b.codename)) || []
  ), [data]);

  return (
    <div className={styles.linear_list}>
      {filteredCategories.map((category) => (
        <MiniCategoryCard key={category.codename} data={category} />
      ))}
      <button
        type="button"
        className={styles.all_categories_button}
        onClick={() => setIsShowCategoriesModal(!isShowCategoriesModal)}
      >
        {t('common:all')}
      </button>
    </div>
  );
};
