import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { useMatchMedia } from '../../hooks/use-match-media.hook';
import { Select } from '../select';
import { Tag } from '../tag/tag.component';
import { SvgIcon } from '../svg-icon/svg-icon.component';
import styles from './range-selector.module.scss';

interface RangeSelectorProps {
  label: string;
  startOptions: { value: string; label: string }[];
  endOptions: { value: string; label: string }[];
}

export const RangeSelector = ({
  label,
  startOptions,
  endOptions,
}: RangeSelectorProps) => {
  const [selected, setSelected] = useState({ from: '', to: '' });
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { isDesktop } = useMatchMedia();
  const { control, setValue } = useForm();

  const handleTagClick = useCallback((type: 'from' | 'to', value: string) => {
    setSelected((prev) => ({
      ...prev,
      [type]: value,
    }));
  }, []);

  return (
    <div
      className={clsx(styles.range_container, { [styles.mobile]: !isDesktop })}
    >
      <div className={styles.headline}>
        <label className={styles.label}>{label}</label>
        {!isDesktop && (
          <SvgIcon
            name="arrow-down"
            className={clsx(styles.arrow, { [styles.arrow_active]: isOpen })}
            onClick={() => setIsOpen(!isOpen)}
          />
        )}
      </div>
      <div className={styles.range_selectors}>
        {isDesktop && (
          <>
            <Select
              name="from"
              control={control}
              setValue={setValue}
              options={startOptions}
              className={styles.select}
              placeholder={t('common:from')}
            />
            <Select
              name="to"
              control={control}
              setValue={setValue}
              options={endOptions}
              className={styles.select}
              placeholder={t('common:to')}
            />
          </>
        )}
        {isOpen && !isDesktop && (
          <div className={styles.mobile_tag_list_wrapper}>
            <div className={styles.mobile_tag_list}>
              <span>{t('common:from')}</span>
              <div className={styles.list}>
                {startOptions.map((item) => (
                  <Tag
                    key={item.value}
                    color={
                      selected.from === item.value ? 'light-green' : 'white'
                    }
                    type="tagMobile"
                    label={item.label}
                    className={styles.tag}
                    onClick={() => handleTagClick('from', item.value)}
                  />
                ))}
              </div>
            </div>
            <div className={styles.mobile_tag_list}>
              <span>{t('common:to')}</span>
              <div className={styles.list}>
                {endOptions.map((item) => (
                  <Tag
                    key={item.value}
                    color={selected.to === item.value ? 'light-green' : 'white'}
                    type="tagMobile"
                    label={item.label}
                    className={styles.tag}
                    onClick={() => handleTagClick('to', item.value)}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
