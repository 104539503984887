import React from 'react';
import { useMatchMedia } from '@/modules/shared/hooks';
import { ReturnedType } from '../../types';
import { ProgressBarDesktop } from './progress-bar-desktop.component';
import { ProgressBarMobile } from './progress-bar-mobile.component';


export const ProgressBar = ({ progressbar }: { progressbar: ReturnedType }) => {
  const { isDesktop } = useMatchMedia();

  return isDesktop
    ? <ProgressBarDesktop progressbar={progressbar} />
    : <ProgressBarMobile progressbar={progressbar} />;
};
