import React, { FC, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import useTranslation from 'next-translate/useTranslation';
import { Box } from '@mui/material';
import { Option } from '../types';
import styles from './deactivation-modal.module.scss';


type Props = {
  onClose: () => void;
  title: string;
  onSubmit: (reason: string) => void;
  reasons: Option[];
};
export const DeactivationModal: FC<Props> = ({
  onClose, title, onSubmit, reasons,
}) => {
  const [selectedReason, setSelectedReason] = useState<string | null>(null);
  const { t } = useTranslation('common');

  return (
    <section className={styles.modal}>
      <div className={styles.modal_content}>
        <button onClick={onClose} className={styles.close_modal} type="button">
          <RxCross2 size={20} />
        </button>
        <h2 style={{ textAlign: 'center' }}>{title}</h2>
        <div className={styles.input_container}>
          {reasons.map(({ value, label }) => (
            <Box
              component="button"
              className={`${styles.input_row} ${
                selectedReason === value ? styles.selected : ''
              }`}
              key={value}
              onClick={() => setSelectedReason(selectedReason === value ? null : value)}
              type="button"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <span>{label}</span>
              <input
                readOnly
                type="checkbox"
                checked={selectedReason === value}
              />
            </Box>
          ))}
        </div>
        <button
          type="button"
          className={styles.apply}
          onClick={() => onSubmit(selectedReason as string)}
          disabled={selectedReason === null}
        >
          {t('apply')}
        </button>
      </div>
    </section>
  );
};
