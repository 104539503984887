import React, { FC } from 'react';
import clsx from 'clsx';
import { SvgIcon } from '../svg-icon';
import { ColorTagProps } from './types';
import { AppTooltip } from '../app-tooltip';
import styles from './color-tag-desktop.module.scss';
import stylesMain from './color-picker.module.scss';

export const ColorTagDesktop: FC<ColorTagProps> = ({ colorOptions, selectedColors, handleColorClick }) => (
  <>
    {colorOptions.map((option) => (
      <AppTooltip title={option.label} placement="top" key={option.codename}>
        <div
          className={styles.container}
          onClick={() => handleColorClick(option.codename)}
        >
          {option?.codename === 'multicolor'
            ? <SvgIcon name="colors-multicolor" className={styles.colorOption} />
            : (
              <div
                className={clsx(styles.colorOption, {
                  [stylesMain.white]: option?.codename === 'white',
                  [stylesMain.silver]: option?.codename === 'silver',
                  [stylesMain.golden]: option?.codename === 'golden',
                })}
                style={{ backgroundColor: option.value }}
              />
            )}
          {selectedColors.includes(option.codename) && (
          <SvgIcon name="colors-check" className={styles.container_selected} />
          )}
        </div>
      </AppTooltip>
    ))}
  </>
);
