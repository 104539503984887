import { QueryKey } from '@tanstack/react-query';

/**
 *
 * @param {string[]} queryKey
 */
type Options = {
  filters?: unknown;
  order?: string;
  pageSize?: number;
  page?: number;
};
export const buildListQueryKeyFactory = <O extends Options>(
  queryKey: QueryKey,
) => (options: O) => {
    const {
      filters, order, pageSize, page,
    } = options;
    return [filters, order, pageSize, page, ...queryKey];
  };
