import { useCallback } from 'react';
import { UseFormResetField } from 'react-hook-form';
import { Category, SubCategory } from '@/modules/categories';
import { useCreateAdActionsContext, useCreateAdContext } from '../context';
import { CreateAdTypes } from '../types';

export const useSelectCategoryMobileHandlers = (resetField: UseFormResetField<CreateAdTypes>) => {
  const {
    category: selectedCategory,
    // underSubCategory: selectedUnderSubCategory,
    isLastLevel,
    mobileStep,
  } = useCreateAdContext();
  const {
    setCategory, setSubCategory, setUnderSubCategory, setMainStep, setAdType, setIsLastLevel, setMobileStep,
  } = useCreateAdActionsContext();

  const handleCategoryClick = useCallback((category: Category) => {
    setCategory(category);
    setSubCategory(undefined);
    resetField('sub_category');
    setUnderSubCategory(undefined);
    resetField('under_sub_category');
    resetField('operation_type');
    setMobileStep(2);
  }, []);

  const handleSubcategoryClick = useCallback((subcategory: SubCategory) => {
    setSubCategory(subcategory);
    resetField('operation_type');
    resetField('under_sub_category');

    if (subcategory.under_sub_categories.length === 0) {
      setIsLastLevel(true);

      if (selectedCategory?.allow_ad_type) {
        setMobileStep(3);
        return;
      }
      setMainStep(2);
    } else {
      setIsLastLevel(false);
      setMobileStep(3);
    }
  }, [selectedCategory]);

  const handleUnderSubcategoryClick = useCallback((underSubCategory) => {
    setIsLastLevel(true);
    setUnderSubCategory(underSubCategory);
    if (!selectedCategory?.allow_ad_type) {
      setMainStep(2);
      return;
    }
    setMobileStep(4);
  }, [selectedCategory]);

  const handleTypeClick = useCallback((type) => {
    setAdType(type);
    setMainStep(2);
  }, [setAdType]);

  const handleClickBack = useCallback(() => {
    switch (mobileStep) {
      case 2: // Выбор подкатегории, нужно очистить выбранную категорию
        // resetField('category');
        // setCategory(undefined);
        break;
      case 3: // Выбор подподкатегории или типа
        // если выбор подподкатегории, то очищаем подкатегорию
        // если выбор типа, то очищаем подкатегорию, lastLevel
        // if (!isLastLevel && !selectedUnderSubCategory) {
        // resetField('sub_category');
        // setSubCategory(undefined);
        // }
        if (isLastLevel) {
          // resetField('sub_category');
          // setSubCategory(undefined);
          setIsLastLevel(false);
        }
        break;
      case 4: // Выбор типа. Очищаем подподкатегорию, lastLevel и adType
        // resetField('under_sub_category');
        // setUnderSubCategory(undefined);
        resetField('operation_type');
        setAdType(undefined);
        setIsLastLevel(false);
        break;
      default:
        break;
    }

    if (mobileStep > 1) {
      setMobileStep((prev) => prev - 1);
    }
  }, [mobileStep]);

  return {
    handleCategoryClick,
    handleSubcategoryClick,
    handleUnderSubcategoryClick,
    handleTypeClick,
    handleClickBack,
  };
};
