import React, {
  ComponentType, FC,
} from 'react';
import {
  FilterQuery, FormState, useFilterFormContext,
} from '@/modules/shared/hooks';
import { Translate } from 'next-translate';

import { useOverrideTranslation } from '../hooks/use-override-translation';
import { FiltersMap } from '../types';

/**
 * Данных HOC защищает фильтры от вывода неопознанного или незарегистрированного кода фильтра
 * который мог прислать бекенд
 * @param Component
 */
export const withDynamicFieldFormGuard = <T extends {
  filter: FiltersMap[keyof FiltersMap],
  form: FormState<FilterQuery>;
}>(Component: ComponentType<T & {
    t: Translate;
  }>) => {
  const Wrapper: FC<Omit<T, 'form'> & {
    isVisible?: (props: { form: T['form'] }) => boolean,
  }> = ({ isVisible, ...props }) => {
    const { t } = useOverrideTranslation();
    const form = useFilterFormContext();
    const { filter } = props;

    const checkingPassed = (
      (filter.type === 'checkbox_list' || filter.type === 'checkbox_list_depended_choices')
        && 'name' in filter
        && filter.name
        && Object.hasOwn(form.value, filter.name)
    )
        /**
         * Params пока не проверяем, там всего 2 компонента на это location и price, они есть всегда
         */
        || (filter.type === 'location' || filter.type === 'price')
        || (
          filter.type === 'range'
            && Object.hasOwn(form.value, filter.params.min)
            && Object.hasOwn(form.value, filter.params.max)
        );

    if (checkingPassed) {
      if (isVisible && !isVisible({
        form,
      })) return null;

      return <Component {...props as T} form={form} t={t} />;
    }

    return null;
  };
  Wrapper.displayName = `withFormFilterStateGuard(${Component.displayName || Component.name})`;

  return Wrapper;
};
