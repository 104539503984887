import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';

import { Checkbox } from '@/modules/shared/components';

import styles from './checkbox-list.module.scss';


export type CheckboxListProps = {
  value: string[];
  name: string,
  minimized: boolean,
  showMoreOnClick: () => void,
  items: { label: string; value: string }[];
  choices: { length: number }
  onChange: (value: string[]) => void;
};

export const CheckboxList = ({
  value, onChange, items, name, minimized, choices, showMoreOnClick,
}: CheckboxListProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={clsx(styles.items, {
        [styles.items_full]: !minimized,
      })}
      >
        {items.map(({ label, value: itemValue }) => (
          <div className={styles.item} key={itemValue}>
            <label>
              <Checkbox
                checked={value.includes(itemValue)}
                onChange={(e) => {
                  if (e.target.checked) {
                    onChange([...value, itemValue]);
                  } else {
                    onChange(value.filter((v) => v !== itemValue));
                  }
                }}
                name={name}
                value={itemValue}
                checkboxSize="24"
              />
              {label}
            </label>
          </div>
        ))}
      </div>
      {choices.length > 5 && (
        <div
          onClick={showMoreOnClick}
          className={styles.showMore}
        >
          {minimized ? t('filters-components:checkbox-list.showMore') : t('filters-components:checkbox-list.collapse') }
        </div>
      )}
    </div>
  );
};
