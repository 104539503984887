import { ComponentType, FC, memo } from 'react';

import { FormState, useFilterFormContext } from '@/modules/shared/hooks';

import useTranslation from 'next-translate/useTranslation';
import { LocationAccordion } from '../components/location-accordion';
import { FilterRow } from '../components/filter-row';
import { withErrorBoundary } from '../hoc/withErrorBoundary';


const withForm = <T extends object>(Component: ComponentType<T>) => {
  const Wrapper:FC<Omit<T, 'form'>> = (props) => {
    const form = useFilterFormContext<'city' | 'district'>();
    return <Component {...props as T} form={form} />;
  };
  return Wrapper;
};


export const LocationField = withErrorBoundary(withForm(memo<{ form: FormState<'city' | 'district'> }>(({ form }) => {
  const { t } = useTranslation();

  return (
    <FilterRow title={t('filters:location.title')}>
      <LocationAccordion
        city={form.value.city!}
        district={form.value.district!}
        onChangeCity={form.setter.city!}
        onChangeDistrict={form.setter.district!}
      />
    </FilterRow>
  );
}, (prev, next) => (
  prev.form.value.city === next.form.value.city
  && prev.form.value.district === next.form.value.district
))));
