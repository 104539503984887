import { baseEndpointV1 } from '@/modules/shared/api';

export const endpoints = {
  login: () => `${baseEndpointV1}/users/login/`,
  register: () => `${baseEndpointV1}/users/register/`,
  refreshAccessToken: () => `${baseEndpointV1}/users/refresh_token/`,
  confirmRegistration: () => `${baseEndpointV1}/users/confirm_registration/`,
  sendTotp: () => `${baseEndpointV1}/users/me/phone_number_totp/`,
  checkTotp: () => `${baseEndpointV1}/users/me/phone_number/`,
  telegramLogin: () => `${baseEndpointV1}/users/login-telegram/`,
  resetPasswordRequest: () => `${baseEndpointV1}/users/password/reset/request/`,
  resetPasswordComplete: () => `${baseEndpointV1}/users/password/reset/complete/`,
  googleLogin: () => `${baseEndpointV1}/users/login-google/`,
  completeRegistration: () => `${baseEndpointV1}/users/complete-registration/`,
  resendActivationLink: () => `${baseEndpointV1}/users/activation_link/`,
};
