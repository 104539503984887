import { useMemo } from 'react';
import { Category } from '../types';
import { useCategories } from './use-categories';

const categoryOrder = [
  'real-estate',
  'transport',
  'personal-items',
  'services',
  'job',
  'electronics',
  'hobbies',
  'home',
  'spare-parts',
  'business',
  'animals',
];

export const useSortedCategories = () => {
  const { categories } = useCategories();

  const sortedCategories = useMemo(() => {
    const dataMap = categories.reduce((acc, card) => {
      acc[card.codename] = card;
      return acc;
    }, {} as Record<string, Category>);

    return categoryOrder.map((category) => dataMap[category]);
  }, [categories]);

  return { sortedCategories };
};
