import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import s from '@/styles/shared/input/SearchTable.module.scss';


export default function SearchTable({ value, onSearch }) {
  const { t } = useTranslation('common');
  return (
    <div className={s.search_input}>
      <button
        onClick={() => onSearch(document.getElementById('searchInput').value)}
      >
        <img src="/assets/icons/search.svg" alt="search" />
      </button>
      <input
        id="searchInput"
        type="text"
        placeholder={t('search')}
        value={value}
        onChange={(e) => onSearch(e.target.value)}
      />
    </div>
  );
}
