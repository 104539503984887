import React, {
  createContext, ReactNode, useMemo, useState,
} from 'react';
import { Category, SubCategory, UnderSubCategory } from '@/modules/categories';
import { FormImage } from '@/modules/shared/types';

type AdType = 'buy' | 'rent' | undefined;

interface CreateAdContextType {
  category: Category | undefined;
  subCategory: SubCategory | undefined;
  underSubCategory?: UnderSubCategory | undefined;
  mainStep: number; /* "Глобальные" шаги, т.е. выбор категории, заполнения контактной информации, описания, деталей и финиш.
  * В мобильной версии при выборе категории используются состояние mobileStep, они не зависят от mainStep.
  * С точки зрения mainStep, выбор категории в мобильной версии всегда будет первым шагом, хоть и на несколько экранов.
  * Всегда будет 5 шагов, но 3 шаг может пропускаться, если нет дополнительных характеристик.
  * */
  adType: AdType;
  isLastLevel: boolean; /* Флаг, который показывает, что выбрана последняя подкатегория (подподкатегория) и больше нет вложенности.
   * Нужен для корректного отображения "Снять" или "Продать" и перехода к следующему шагу
   * */
  mobileStep: number; // Шаги выбора категорий в мобильной версии
  isAdditionalSpecifications: boolean; // Флаг, обозначающий, что дополнительные характеристики существуют в выбранной категории
  images: FormImage[];
  submitting: boolean;
  isPhoneVerified: boolean;
}
interface ActionsType {
  setCategory: React.Dispatch<React.SetStateAction<Category | undefined>>;
  setSubCategory: React.Dispatch<React.SetStateAction<SubCategory | undefined>>;
  setUnderSubCategory: React.Dispatch<React.SetStateAction<UnderSubCategory | undefined>>;
  setMainStep: React.Dispatch<React.SetStateAction<number>>;
  setAdType: React.Dispatch<React.SetStateAction<AdType>>;
  setIsLastLevel: React.Dispatch<React.SetStateAction<boolean>>;
  setMobileStep: React.Dispatch<React.SetStateAction<number>>;
  setIsAdditionalSpecifications: React.Dispatch<React.SetStateAction<boolean>>;
  setImages: React.Dispatch<React.SetStateAction<FormImage[]>>;
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPhoneVerified: React.Dispatch<React.SetStateAction<boolean>>;
  resetAlmostAllContext: () => void;
}

export const CreateAdContext = createContext<CreateAdContextType | null>(null);
export const CreateAdActions = createContext<ActionsType | null>(null);

export const CreateAdContextProvider = ({ children }: { children: ReactNode }) => {
  const [category, setCategory] = useState<Category | undefined>();
  const [subCategory, setSubCategory] = useState<SubCategory | undefined>();
  const [underSubCategory, setUnderSubCategory] = useState<UnderSubCategory | undefined>();
  const [mainStep, setMainStep] = useState<1 | 2 | 3 | 4 | 5>(1);
  const [adType, setAdType] = useState<AdType>();
  const [isLastLevel, setIsLastLevel] = useState(false);
  const [mobileStep, setMobileStep] = useState<1 | 2 | 3 | 4>(1);
  const [isAdditionalSpecifications, setIsAdditionalSpecifications] = useState(false);
  const [images, setImages] = useState<FormImage[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);

  // сбросит весь контекст, кроме mainStep
  const resetAlmostAllContext = () => {
    setCategory(undefined);
    setSubCategory(undefined);
    setUnderSubCategory(undefined);
    setAdType(undefined);
    setIsLastLevel(false);
    setMobileStep(1);
    setIsAdditionalSpecifications(false);
    setImages([]);
    setSubmitting(false);
    setIsPhoneVerified(false);
  };

  const value = useMemo(
    () => ({
      category,
      subCategory,
      underSubCategory,
      mainStep,
      adType,
      isLastLevel,
      mobileStep,
      isAdditionalSpecifications,
      images,
      submitting,
      isPhoneVerified,
    }),
    [
      category,
      mainStep,
      subCategory,
      underSubCategory,
      adType,
      isLastLevel,
      mobileStep,
      isAdditionalSpecifications,
      images,
      submitting,
      isPhoneVerified,
    ],
  );
  const actions = useMemo(
    () => ({
      setCategory,
      setSubCategory,
      setUnderSubCategory,
      setMainStep,
      setAdType,
      setIsLastLevel,
      setMobileStep,
      setIsAdditionalSpecifications,
      setImages,
      setSubmitting,
      setIsPhoneVerified,
      resetAlmostAllContext,
    }),
    [],
  );

  return (
    <CreateAdContext.Provider value={value}>
      <CreateAdActions.Provider value={actions}>
        {children}
      </CreateAdActions.Provider>
    </CreateAdContext.Provider>
  );
};
