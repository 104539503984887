import { usePathname, useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

export const useSetQueryParams = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  return useCallback((params) => {
    const newParams = {};
    for (const [key, value] of searchParams.entries()) {
      newParams[key] = value;
    }
    for (const key of Object.keys(params)) {
      if (params[key] === undefined || params[key] === null || params[key] === '') {
        delete newParams[key];
      } else {
        newParams[key] = params[key];
      }
    }
    router.push({
      pathname,
      query: newParams,
    }, undefined, { shallow: true, scroll: false });
  }, [pathname, searchParams]);
};
