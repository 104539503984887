import { Locale } from '@/modules/shared/types';

export type HeaderProps = {
  showCityModal: boolean;
  setShowCityModal: (value: React.SetStateAction<boolean>) => void;
};

interface Language {
  label: string;
  locale: Locale;
}

export type LangProps = {
  isOpen?: boolean;
  currentLang: Language;
  onClose?: (event: MouseEvent | TouchEvent) => void;
  toggleLang: (value: React.SetStateAction<Language>) => void;
};

export const languages: Language[] = [
  { label: 'RU', locale: 'ru' },
  { label: 'EN', locale: 'en' },
];
