import React, { ReactNode } from 'react';
import Link from 'next/link';

interface UserCompanyProps {
  data: { user: number; company?: number | null };
  children: ReactNode;
  className?: string;
}

export const UserCompanyRedirect = (props: UserCompanyProps) => {
  const { data: { user, company }, children } = props;
  const href = company ? `/companies/${company}` : `/users/${user}`;

  return <Link href={href} className={props.className}>{children}</Link>;
};
