import React, { useState } from 'react';
import { Category } from '@/modules/categories';
import { Scrollbar } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import {
  DesktopViewList,
  TabletViewList,
  DesktopHeaderViewList,
  TabletHeaderViewList,
} from './components';
import styles from './view-list-categories.module.scss';

interface Props {
  categories: Category & { allow_ad_type: boolean };
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ViewListCategories = ({ categories, onClose }: Props) => {
  const {
    codename, pathname, sub_categories, allow_ad_type,
  } = categories;
  const { isDesktop } = useMatchMedia();
  const [operationType, setOperationType] = useState(undefined);

  return (
    <div className={styles.container}>
      <Scrollbar>
        <div className={styles.content_block}>
          <div className={styles.header}>
            {isDesktop ? (
              <DesktopHeaderViewList
                onClose={onClose}
                pathname={pathname}
                codename={codename}
                allow_ad_type={allow_ad_type}
                setOperationType={setOperationType}
              />
            ) : (
              <TabletHeaderViewList
                onClose={onClose}
                codename={codename}
                pathname={pathname}
              />
            )}
          </div>
          <div className={styles.list}>
            {isDesktop ? (
              <DesktopViewList
                sub_categories={sub_categories}
                onClose={onClose}
                prevCodename={codename}
                operationType={operationType}
              />
            ) : (
              <TabletViewList
                sub_categories={sub_categories}
                onClose={onClose}
                prevCodename={codename}
              />
            )}
          </div>
        </div>
      </Scrollbar>
    </div>
  );
};
