import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Button, SvgIcon } from '@/modules/shared/components';
import { MapWrapper } from './map-wrapper.component';
import s from './product-info.module.scss';

type MapLocationProps = {
  address: string;
};

export const MapLocation = ({ address }: MapLocationProps) => {
  const { t } = useTranslation('adv');
  const [isMapOpened, setIsMapOpened] = useState(false);

  const toggleMap = () => setIsMapOpened((prev) => !prev);

  return (
    <div className={s.map_location}>
      <Button
        appearance="secondary"
        buttonColor="blue"
        buttonSize="s"
        onClick={toggleMap}
        className={s.show_map_button}
      >
        {t('showOnMap')}
        <SvgIcon name={isMapOpened ? 'arrow-up' : 'arrow-down'} />
      </Button>
      {isMapOpened && <MapWrapper address={address} />}
    </div>
  );
};
