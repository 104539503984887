import { useMatchMedia } from '../../hooks/use-match-media.hook';
import { ModalDesktop } from './modal-desktop.component';
import { ModalMobile } from './modal-mobile.component';
import { ModalProps } from './types';

export const Modal = (props: ModalProps) => {
  const { isMobile } = useMatchMedia();

  return isMobile ? <ModalMobile {...props} /> : <ModalDesktop {...props} />;
};
