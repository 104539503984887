import { useMutation } from '@tanstack/react-query';
import { AuthApi } from '../api';


export const useResendActivationLinkMutation = () => {
  const {
    mutate, isPending, error,
  } = useMutation({
    mutationFn: AuthApi.resendActivationLink,
  });

  return {
    resendActivationLink: mutate,
    isLoading: isPending,
    error,
  };
};
