import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { SvgIcon } from '@/modules/shared/components';
import styles from './switch-category.module.scss';
import { CategoryType } from './types';

interface Props {
  category: Exclude<CategoryType, undefined>;
  onClick: () => void;
}

export const AllInThisCategory = ({ category, onClick }: Props) => {
  const { t } = useTranslation();
  const { asPath } = useRouter();

  return (
    <Link
      href={category!.pathname}
      className={styles.link}
      onClick={onClick}
    >
      <h4>{t('filters-components:title.everything')}</h4>
      {category.pathname === asPath
        ? <SvgIcon name="check" className={styles.svg_checked} />
        : <SvgIcon name="arrow-forward-right" />}
    </Link>
  );
};
