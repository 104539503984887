import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import s from '@/styles/pages/AddEvents.module.scss';
import { validateInput } from '@/modules/shared/helpers';

const ReactQuill = dynamic(() => import('react-quill'), { ssr: false });

type DescriptionAreaProps = {
  value: string;
  onChange: (content: string, delta) => void;
  onBlur: () => void;
  error: string;
  minLength: number;
  maxLength: number;
};

export const DescriptionArea = ({
  value, onChange, onBlur, error, minLength, maxLength,
}: DescriptionAreaProps) => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  const handleKeyDown = (e) => {
    if (!validateInput(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div className={s.form_description}>
      <h4>{t('events:createEvents.description.label')}</h4>
      <div className="text-editor-container">
        <ReactQuill
          key={locale}
          value={value}
          onChange={onChange}
          placeholder={t('events:createEvents.description.placeholder')}
          onBlur={onBlur}
          onKeyDown={handleKeyDown}
        />
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <span className={s.span}>
        {value.length < minLength && (
          <p>
            {t('events:createEvents.description.minLength', { minLength: minLength - value.length })}
          </p>
        )}
        <p>
          {value.length || 0}
          /
          {maxLength}
        </p>
      </span>
    </div>
  );
};
