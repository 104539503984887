// Вычисляет конечную дату на основе начальной даты и количества дней (вперед).
export const calculateEndDate = (startDate: Date, days: number) => {
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + days - 1);
  return endDate;
};

// Вычитает указанное количество дней из начальной даты и возвращает новую дату (назад).
export const subtractDaysFromDate = (startDate: Date, days: number) => {
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() - days);
  return endDate;
};

export const calculateDays = () => {
  if (window.innerWidth <= 1200 && window.innerWidth > 1175) {
    return 16;
  }

  if (window.innerWidth <= 1175 && window.innerWidth > 1125) {
    return 15;
  }

  if (window.innerWidth <= 1125 && window.innerWidth > 1075) {
    return 14;
  }

  if (window.innerWidth <= 1075 && window.innerWidth > 1024) {
    return 13;
  }

  if (window.innerWidth <= 1024 && window.innerWidth > 975) {
    return 17;
  }

  if (window.innerWidth <= 975 && window.innerWidth > 920) {
    return 16;
  }

  if (window.innerWidth <= 920 && window.innerWidth > 870) {
    return 15;
  }

  if (window.innerWidth <= 870 && window.innerWidth > 820) {
    return 14;
  }

  if (window.innerWidth <= 820 && window.innerWidth > 725) {
    return 13;
  }

  if (window.innerWidth <= 725 && window.innerWidth > 675) {
    return 12;
  }

  if (window.innerWidth <= 675 && window.innerWidth > 625) {
    return 11;
  }

  if (window.innerWidth <= 625 && window.innerWidth > 575) {
    return 10;
  }

  if (window.innerWidth <= 575 && window.innerWidth > 520) {
    return 9;
  }

  if (window.innerWidth <= 520 && window.innerWidth > 480) {
    return 8;
  }


  if (window.innerWidth <= 480) {
    return 9;
  }

  return 21;
};
