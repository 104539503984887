import { createContext } from 'react';

const AuthContext = createContext({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  email: '',
  setEmail: () => {},
  password: '',
  setPassword: () => {},
  password2: '',
  setPassword2: () => {},
  logout: () => {}, // Добавьте этот метод
});

export default AuthContext;
