import React, { useMemo } from 'react';
import {
  UserAvatar,
  Tag,
  FloatNumber,
  UserRating, Button,
  UserCompanyRedirect,
} from '@/modules/shared/components';
import { User, UserTypeEnum } from '@/modules/users';
import useTranslation from 'next-translate/useTranslation';
import { getDeclension } from '@/modules/shared/helpers';
import { useUserCompanyById } from '@/modules/company/queries';
import styles from './ad-page-profile-block.module.scss';

interface Props {
  userProfile: User;
  allowedComplain: boolean;
}
// TODO: дубль компонента ad-profile-block, в будущем можно порефакторить

export const AdPageProfileBlock = ({ userProfile, allowedComplain }: Props) => {
  const { t } = useTranslation('');
  const {
    id,
    avatar,
    full_name,
    average_rating,
    created_at,
    reviews,
    company_id,
  } = userProfile;
  const { data: companyData } = useUserCompanyById(company_id);
  const type = company_id ? UserTypeEnum.Company : UserTypeEnum.Individual;

  const formatDate = (dateString) => {
    const [, month, year] = (dateString || '').split('.');
    return `${t(`common:months.${parseInt(month, 10) - 1}`)} ${year}${t('common:year')}`;
  };

  const reviewsCount = useMemo(() => (
    getDeclension({
      count: reviews?.length,
      t,
      singular: 'common:reviews.one',
      few: 'common:reviews.few',
      many: 'common:reviews.many',
    })
  ), [t, reviews?.length]);

  return (
    <UserCompanyRedirect data={{ user: id, company: company_id }}>
      <div className={styles.container}>
        <UserAvatar
          srcAvatar={type === UserTypeEnum.Company ? (companyData?.logo?.thumbnail || '') : avatar}
          userName={type === UserTypeEnum.Company ? (companyData?.name || '') : full_name}
          diameter="44px"
        />

        <div className={styles.contact}>
          <p>{type === UserTypeEnum.Company ? companyData?.name : full_name}</p>
          <Tag label={t(`common:${type}`)} type="statusS" />
        </div>

        <div className={styles.information}>
          <div className={styles.user}>
            <div className={styles.user_block}>
              <span className={styles.user_rating}>
                <FloatNumber value={average_rating} precision={1} showRatingSuffix={false} />
              </span>
              <UserRating rating={average_rating} sizeStar="16px" />
            </div>
            <p className={styles.user_reviews}>
              {reviewsCount}
            </p>
          </div>

          <p className={styles.created}>
            {t('common:onBazaar')}
            {' '}
            <span>{formatDate(created_at)}</span>
          </p>
        </div>
      </div>
      {userProfile.telegram && (
        <Button
          appearance="primary"
          buttonColor="grey"
          buttonSize="xs"
          fullWidth
          as="link"
          target="_blank"
          className={styles.telegram}
          disabled={allowedComplain}
        >
          {t('adv:contacts.telegram')}
        </Button>
      )}
    </UserCompanyRedirect>
  );
};
