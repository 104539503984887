import { useContext } from 'react';
import { CreateAdActions } from './create-ad.context';

export const useCreateAdActionsContext = () => {
  const context = useContext(CreateAdActions);

  if (!context) {
    throw new Error('useCreateAdActionsContext must be used within a CreateAdContextProvider');
  }

  return context;
};
