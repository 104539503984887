import React, { useMemo } from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import {
  FloatNumber, SvgIcon, UserAvatar, UserRating,
} from '@/modules/shared/components';
import { getDeclension } from '@/modules/shared/helpers';
import { profileBasePath } from '@/modules/shared/const';
import { Me } from '@/modules/me/types';
import { Company } from '@/modules/company/types';
import { type UserType, UserTypeEnum } from '@/modules/users';
import styles from '../side-menu.module.scss';

interface Props {
  user: Me;
  type: UserType;
  companyData: Company | undefined;
}

export const UserInfo = ({ user, type, companyData }: Props) => {
  const { t } = useTranslation('common');

  const reviewsCount = useMemo(() => (
    getDeclension({
      count: user?.reviews?.length,
      t,
      singular: 'common:reviews.one',
      few: 'common:reviews.few',
      many: 'common:reviews.many',
    })
  ), [t, user?.reviews?.length]);

  return (
    <div className={styles.user_info}>
      <UserAvatar
        srcAvatar={type === UserTypeEnum.Company ? (companyData?.logo?.thumbnail || '') : user.avatar}
        userName={type === UserTypeEnum.Company ? (companyData?.name || '') : user.full_name}
        diameter="100px"
      />
      <Link href={`/${profileBasePath}/account`} className={styles.add_photo}>
        <SvgIcon name="add-photo" className={styles.add_photo_icon} />
      </Link>
      <div className={styles.user_name}>
        <p className={styles.name}>
          {type === UserTypeEnum.Company ? companyData?.name : user.full_name}
        </p>
        <p className={styles.seller_type}>{t(`common:${type}`)}</p>
      </div>
      <div className={styles.rating}>
        <div className={styles.rating_number}>
          <p>
            <FloatNumber value={user.average_rating} precision={1} showRatingSuffix={false} />
          </p>
          <UserRating rating={user.average_rating} sizeStar="16px" />
        </div>
        <Link
          className={styles.reviews}
          href={`/${profileBasePath}/rating`}
        >
          {reviewsCount}
        </Link>
      </div>
    </div>
  );
};
