import { useMemo } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import { modalTypeParam } from '@/modules/auth/consts';

export const useCleanUrl = () => {
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const url = useMemo(() => {
    const search = new URLSearchParams(searchParams);
    search.delete(modalTypeParam);

    return {
      pathname,
      search,
    };
  }, [pathname, searchParams]);

  return url;
};
