import LinearProgress, {
  LinearProgressProps, linearProgressClasses,
} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useMatchMedia } from '../../hooks/use-match-media.hook';

type LinearProgressWithLabelProps = LinearProgressProps & {
  value: number;
};

export const LinearProgressWithLabel = (props: LinearProgressWithLabelProps) => {
  const { isMobile } = useMatchMedia();

  const GradientLinearProgress = styled(LinearProgress)(() => ({
    height: 7,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: 'var(--bzr-secondary-very-light-grey)',
    },
    [`& .${linearProgressClasses.bar}`]: {
      width: `${props.value}%`,
      borderRadius: 5,
      background: 'linear-gradient(90deg, #0085C5 0%, #C7EDFF 100%)',
      transform: 'none !important',
    },
  }));

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <Box sx={{ position: 'absolute', top: isMobile ? '-18px' : '-20px', right: '0' }}>
        <Typography
          sx={{
            fontSize: isMobile ? '10px' : '12px',
            fontWeight: '400',
            color: 'var(--bzr-text-medium-grey)',
          }}
          variant="caption"
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
      <Box sx={{ width: '100%' }}>
        <GradientLinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
};
