import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Button, ModalDesktop, RadioButton } from '@/modules/shared/components';
import { ReasonModalProps } from './deletion.types';
import styles from './desktop-reason-modal.module.scss';

export const DesktopReasonModal = ({
  isOpen, options, selectedReason, onSelectReason, onClose, onConfirm,
}: ReasonModalProps) => {
  const { t } = useTranslation('userSettings');

  return (
    <ModalDesktop
      open={isOpen}
      title={t('deleteReason.title')}
      onClose={onClose}
    >
      <div className={styles.reason_modal}>
        <ul className={styles.reason_list}>
          {options.map((option) => (
            <li key={option.value} onClick={() => onSelectReason(option.value)}>
              <RadioButton checked={selectedReason === option.value} radioColor="green" />
              {option.label}
            </li>
          ))}
        </ul>
        <Button
          as="button"
          buttonSize="s"
          buttonColor="grey"
          appearance="primary"
          disabled={!selectedReason}
          onClick={onConfirm}
          className={styles.continue_button}
        >
          {t('deleteReason.continue')}
        </Button>
      </div>
    </ModalDesktop>
  );
};
