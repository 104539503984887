import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { InputText } from '@/modules/shared/components';
import styles from './create-ad-input.module.scss';

interface CreateAdInputProps {
  name: string;
  postfix?: 'm' | 'cc' | 'liters' | 'km';
  disabled?: boolean;
  required?: boolean;
  min?: number;
  max?: number;
}

export const CreateAdInput = (props: CreateAdInputProps) => {
  const {
    name, postfix, disabled = false, required = false, min, max,
  } = props;
  const { t } = useTranslation('createAd');
  const { control } = useFormContext();

  return (
    <div className={styles.input_wrapper}>
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <InputText
            {...field}
            type="number"
            postfix={postfix && t(`measurementUnits.${postfix}`)}
            className={styles.input}
            disabled={disabled}
            isFractional={postfix === 'liters'}
            step={0.1}
            min={min}
            max={max}
          />
        )}
      />
    </div>
  );
};
