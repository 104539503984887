import { baseEndpointV1 } from '@/modules/shared/api';

export const endpoints = {
  getFavoriteAd: () => `${baseEndpointV1}/complaint/list/`,
  addFavoriteAd: (id: number) => `${baseEndpointV1}/complaint/create/${id}/`,
  deleteFavoriteAd: (id: number) => `${baseEndpointV1}/complaint/delete/${id}/`,
  getFavoriteEvents: () => `${baseEndpointV1}/complaint/event_list/`,
  addFavoriteEvent: (id: number) => `${baseEndpointV1}/complaint/event_create/${id}/`,
  deleteFavoriteEvent: (id: number) => `${baseEndpointV1}/complaint/event_delete/${id}/`,
};
