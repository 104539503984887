import { debounce } from 'lodash';
import { useCallback, useState } from 'react';

export const useOnSearchFilterChange = (setFilters, field) => {
  const updateSearchFilter = useCallback(debounce((searchValue) => {
    setFilters((prev) => ({ ...prev, [field]: searchValue }));
  }, 300), [setFilters]);

  const [value, setSearchValue] = useState('');
  const onChange = useCallback((value) => {
    setSearchValue(value);
    updateSearchFilter(value.toLowerCase().trim());
  }, [updateSearchFilter, setSearchValue]);

  return [value, onChange];
};
