import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import Cookies from 'js-cookie';
import { removeLocaleFromUrl } from '@/modules/shared/helpers';
import { redirectAfterLoginParam, modalTypeParam } from '../consts';
import { useAuthContext } from '../contexts';
import { cleanupSearch, buildQueryString } from '../helpers';
import { EXTRA_QUERY_NAMES } from './constants';
import { useCleanUrl } from './use-clean-url.hook';


const cleanupSearchParams = ['token', 'registration-type', ...EXTRA_QUERY_NAMES];

const cleanCookies = () => {
  const cookies = [
    'forgotPasswordEmail',
    'resetPassword',
    'resetPassword2',
  ];
  for (const cookie in cookies) {
    if (Object.prototype.hasOwnProperty.call(cookies, cookie)) {
      Cookies.remove(cookie);
    }
  }
};

export const useCloseAuthModal = () => {
  const router = useRouter();
  const { pathname, search } = useCleanUrl();
  const { isAuthenticated } = useAuthContext();

  useEffect(() => cleanCookies(), []);

  return useCallback(() => {
    const redirectAfterLoginEncodedUrl = search.get(redirectAfterLoginParam);

    cleanupSearch(search, cleanupSearchParams);

    if (redirectAfterLoginEncodedUrl) {
      search.delete(redirectAfterLoginParam);
      EXTRA_QUERY_NAMES.forEach((value) => {
        search.delete(value);
      });

      if (isAuthenticated) {
        const decodedRedirectAfterLoginUrl = removeLocaleFromUrl(decodeURIComponent(redirectAfterLoginEncodedUrl));
        if (!decodedRedirectAfterLoginUrl.includes(modalTypeParam)) {
          router.replace(`${decodedRedirectAfterLoginUrl}${buildQueryString(search)}`, undefined, { shallow: false });
          return;
        }
      }
    }

    router.replace(`${pathname}${buildQueryString(search)}`, undefined, { shallow: true });
  }, [router, pathname, search, isAuthenticated]);
};
