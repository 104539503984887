import { useQuery } from '@tanstack/react-query';
import { useAuthContext } from '@/modules/auth';
import { GIVEN_REVIEW_LIST } from '../consts';
import { ReviewApi } from '../api';

const queryKey = [GIVEN_REVIEW_LIST];

export const useGivenReviewListQuery = () => {
  const { isAuthenticated } = useAuthContext();

  return useQuery({
    queryKey,
    queryFn: ReviewApi.givenReviews,
    enabled: isAuthenticated,
  });
};
