import {
  memo, useEffect, useMemo, useState,
} from 'react';

import { FormState } from '@/modules/shared/hooks';

import { CheckboxList } from '@/modules/ads/components/filter/components/checkbox-list';
import { FiltersMap } from '../types';
import { withDynamicFieldFormGuard } from '../hoc/withDynamicFieldFormGuard';
import { FilterRow } from '../components/filter-row';
import { withErrorBoundary } from '../hoc/withErrorBoundary';


type NativeCheckboxListProps<T extends FiltersMap['checkbox_list'] = FiltersMap['checkbox_list']> = {
  form: FormState<T['name']>;
  filter: T
  resetOnWillUnmount?: boolean;
};

export const CheckboxListField = withErrorBoundary(withDynamicFieldFormGuard<NativeCheckboxListProps>(
  memo(({
    t,
    form,
    resetOnWillUnmount,
    filter: { choices, name },
  }) => {
    const value = form.value[name] || [];
    const onChange = form.setter[name]!;
    const [minimized, setMinimized] = useState(true);

    const items = useMemo(
      () => {
        const valueIndex = {};

        return choices.reduce((memoValue, code) => {
          if ((minimized && memoValue.length >= Math.max(5, value.length)) || valueIndex[code]) return memoValue;
          memoValue.push({
            label: t(`filters:checkbox-list.${name}.options.${code}`, null, {
              fallback: code,
            }),
            value: code,
          });
          valueIndex[code] = true;

          return memoValue;
        }, value.map((code) => {
          valueIndex[code] = true;

          return ({
            label: t(`filters:checkbox-list.${name}.options.${code}`, null, {
              fallback: code,
            }),
            value: code,
          });
        }) as { label: string, value: string }[]);
      },
      [choices, minimized, name, t,
        /**
        * Cпециально не добавляем value, чтобы не пересчитывать значение пока оно не применилось в форме
        */
      ],
    );

    useEffect(() => {
      if (!resetOnWillUnmount) return;
      return () => onChange.reset();
    }, [resetOnWillUnmount]);

    if (!t(`filters:checkbox-list.${name}.title`, null, {
      default: false,
    }) || !items.length) {
      console.log(`Unknown filters:checkbox-list.${name}.title`);
      return null;
    }

    return (
      <FilterRow title={t(`filters:checkbox-list.${name}.title`)}>
        <CheckboxList
          value={value}
          name={name}
          minimized={minimized}
          showMoreOnClick={() => setMinimized((v) => !v)}
          items={items}
          choices={choices}
          onChange={onChange}
        />
      </FilterRow>
    );
  }, (prev, next) => (
    prev.form.value[prev.filter?.name] === next.form.value[next.filter?.name]
      && prev.filter === next.filter
      && prev.t === next.t
  )),
));
