import React, { useState, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { SvgIcon, Button, AppTooltip } from '@/modules/shared/components';
import { useFormatPrice, useMatchMedia } from '@/modules/shared/hooks';
import { formatDateTimeLongFormat } from '@/modules/shared/helpers';
import { ModalDesktop } from '@/modules/shared/components/modal';
import { useTranslateLocation } from '@/modules/locations';
import { FavoriteButton } from '@/modules/favorites';
import { EventDescription } from '../event-description';
import { usePosterPriceDisplay } from '../../hooks';
import { showActivateButton } from '../../helpers';
import { EventLink } from './event-link.component';
import { IEvent } from '../../types';
import s from './event-card.module.scss';

type EventCatdProps = {
  data: IEvent;
  extendedCard?: boolean;
  typeAction?: 'favourite' | 'hide' | 'moderation' | 'rejected' | 'open';
  onClickAction?: (id: number, action: string) => void;
};

export function EventCard({
  data, extendedCard = false, typeAction = 'favourite', onClickAction,
}: EventCatdProps) {
  const {
    price, event_date, event_time, description,
  } = data;
  const { t, lang } = useTranslation('');
  const eventPrice = useFormatPrice({ price });
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const [openInformationModal, setOpenInformationModal] = useState({ state: false, value: '' });

  const formattedDate = formatDateTimeLongFormat(event_date, event_time, lang);
  const pricePoster = usePosterPriceDisplay({
    price,
    max_price: data?.max_price,
  });

  const translatedLocation = useTranslateLocation(
    data?.city?.codename,
    data?.district?.codename,
  );

  const isButtonVisible = showActivateButton({
    event_date,
    event_time,
  });

  const handleClick = (action: string) => {
    if (onClickAction) {
      onClickAction(data.id, action);
    }
  };

  const openModalWithInfo = useCallback((info:string) => {
    setOpenInformationModal({ state: true, value: info });
  }, []);

  const closeModal = useCallback(() => {
    setOpenInformationModal({ state: false, value: '' });
  }, []);

  const isSingleLine = useMemo(() => data?.title && !/\s/.test(data.title), [data?.title]);

  return (
    <>
      {!extendedCard && (
      <div className={s.card_poster}>
        <div className={s.poster_img}>
          <EventLink event={data}>
            <img
              src={`${data?.images?.[0]?.image}`}
              alt={data?.title}
              loading="lazy"
            />
          </EventLink>
          <span className={s.poster_price}>
            {price === 0 ? t('common:forFree') : (
              <>
                {t('common:from')}
                {' '}
                {eventPrice}
              </>
            )}
          </span>
          <div className={s.poster_img_favorite}>
            <FavoriteButton
              id={data.id}
              type="events"
              variant="modern"
              className={s.poster_img_favorite_button}
            />
          </div>
        </div>

        <div className={s.poster_info}>
          <div className={s.poster_info_header}>
            <EventLink event={data}>
              <AppTooltip title={data.title}>
                <h3
                  className={clsx(s.poster_info_header_title, {
                    [s.poster_info_header_title_single]: isSingleLine,
                    [s.poster_info_header_title_multi]: !isSingleLine,
                  })}
                >
                  {data?.title}
                </h3>
              </AppTooltip>
            </EventLink>
          </div>

          <div className={s.poster_info_location}>
            <p>
              {`${formattedDate} | ${translatedLocation}`}
            </p>
          </div>
        </div>
      </div>
      )}

      {extendedCard && (
      <div className={s.extendedCard}>
        <div className={s.extendedCard_poster_img}>
          <EventLink event={data}>
            <img
              src={`${data?.images?.[0]?.image}`}
              alt={data?.title}
              loading="lazy"
            />
          </EventLink>
        </div>

        <div className={s.extendedCard_poster_section}>
          <div className={s.extendedCard_poster_info}>
            <h3 className={s.extendedCard_poster_info_title}>
              <EventLink event={data}>
                {data?.title}
              </EventLink>
            </h3>
            <p className={s.extendedCard_poster_info_price}>
              {!isMobile && (
                price === 0 ? t('common:forFree') : (
                  <>
                    {t('common:from')}
                    {' '}
                    {eventPrice}
                  </>
                )
              )}
              {isMobile && pricePoster}
            </p>

            {!isMobile && (
            <p className={s.extendedCard_poster_info_location}>
              {`${formattedDate} | ${translatedLocation}`}
            </p>
            )}
            {isMobile && (
            <p className={s.extendedCard_poster_info_time}>
              <SvgIcon name="calendar" />
              {`${formattedDate}`}
            </p>
            )}

            {!isMobile && !isTablet && (
              <EventDescription
                description={description}
                classNameWrapper={s.extendedCard_poster_info_description_wrapper}
              />
            )}
          </div>

          {typeAction === 'favourite' && (
            <FavoriteButton id={data.id} type="events" />
          )}


          {typeAction === 'hide' && (
          <Button
            appearance={(isMobile || isTablet) ? 'secondary' : 'primary'}
            buttonSize="xs"
            buttonColor="grey"
            className={s.button_hide}
            onClick={() => handleClick('hide')}
          >
            <SvgIcon name="eye-opened" />
            {(!isMobile && !isTablet) && t('common:hide')}
          </Button>
          )}

          {typeAction === 'open' && isButtonVisible && (
          <Button
            appearance={isMobile || isTablet ? 'secondary' : 'primary'}
            buttonSize="xs"
            buttonColor="grey"
            className={s.button_hide}
            onClick={() => handleClick('open')}
          >
            <SvgIcon name="eye-hidden" />
            {(!isMobile && !isTablet) && t('common:open')}
          </Button>
          )}

          {typeAction === 'moderation' && isDesktop && (
            <AppTooltip title={t('common:moderation_info')} placement="bottom" stylesTooltip="card">
              <div className={clsx(s.button_info, s.button_moderation)}>
                <SvgIcon name="info" />
                {t('common:moderation')}
              </div>
            </AppTooltip>
          )}
          {typeAction === 'moderation' && (isMobile || isTablet) && (
          <button
            className={clsx(s.button_info, s.button_moderation)}
            type="button"
            onClick={() => openModalWithInfo('moderation')}
          >
            <SvgIcon name="info" />
          </button>
          )}

          {typeAction === 'rejected' && isDesktop && (
            <AppTooltip title={t('common:rejected_info')} placement="bottom" stylesTooltip="card">
              <div className={clsx(s.button_info, s.button_rejected)}>
                <SvgIcon name="info" />
                {t('common:rejected')}
              </div>
            </AppTooltip>
          )}
          {typeAction === 'rejected' && (isMobile || isTablet) && (
          <button
            className={clsx(s.button_info, s.button_rejected)}
            type="button"
            onClick={() => openModalWithInfo('rejected')}
          >
            <SvgIcon name="info" />
          </button>
          )}
        </div>
      </div>
      )}

      <ModalDesktop
        open={openInformationModal.state}
        onClose={closeModal}
        showCloseButton={false}
        className={s.modal_container}
      >
        <div className={s.modal_info}>
          <p className={s.modal_info_p}>
            {openInformationModal.value === 'moderation' && t('common:moderation_info')}
            {openInformationModal.value === 'rejected' && t('common:rejected_info')}
          </p>
          <Button
            appearance="primary"
            buttonSize="xs"
            buttonColor="grey"
            onClick={closeModal}
            className={s.modal_info_button}
          >
            {t('common:ok')}
          </Button>
        </div>
      </ModalDesktop>
    </>
  );
}
