import {
  Dispatch, SetStateAction, useCallback, useEffect, useState,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { Value } from 'react-phone-number-input';
import { AxiosError } from 'axios';
import { ModalDesktop, ModalMobile } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { useGetVerificationCode } from '@/modules/me';
import clsx from 'clsx';
import s from './modal.module.scss';
import { InnerButton, PhoneInput } from './inner-components';
import { useSetFocus } from '../helpers';

type AddPhoneModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onContinue: (number: string) => void;
  phoneNumber: string | null | undefined;
  setMinutes: Dispatch<SetStateAction<number>>;
  setSeconds: Dispatch<SetStateAction<number>>;
  phoneVerified: boolean;
};

export const AddPhoneModal = ({
  isOpen, onClose, onContinue, phoneNumber, setMinutes, setSeconds, phoneVerified,
}: AddPhoneModalProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const { isFocus, setFocus } = useSetFocus();
  const {
    mutate: getCode, isSuccess, error: getCodeError, isError,
  } = useGetVerificationCode();

  const isNumberValid = parsePhoneNumberFromString(value)?.isValid();

  const handleChange = useCallback((e: Value | undefined) => {
    setError('');
    setValue(e?.toString() || '');
  }, []);

  const handleBlur = useCallback(() => {
    if (!isNumberValid) {
      setError(t('forms:validationErrors.phone_invalid'));
      return;
    }
    if (value.trim() === phoneNumber?.toString() && phoneVerified) {
      setError(t('forms:validationErrors.phone_your_account'));
      return;
    }
    setError('');
  }, [value, isNumberValid, isMobile]);

  const handleContinue = useCallback(() => {
    getCode({ phone_number: value });
  }, [value]);

  useEffect(() => {
    if (isError && getCodeError instanceof AxiosError) {
      if (getCodeError.response?.data?.validation_errors?.code?.includes('PHONE_NUMBER_ALREADY_IN_USE')) {
        setError(t('forms:validationErrors.phone_already_linked'));
      }
      if (getCodeError.response?.data?.code?.includes('THROTTLED')) {
        setError(t('forms:validationErrors.throttled'));
      }
    }
    if (isSuccess) {
      setMinutes(2);
      setSeconds(0);
      onContinue(value);
    }
  }, [isSuccess, getCodeError, isError]);

  return isMobile ? (
    <ModalMobile
      open={isOpen}
      fullHeight
      headerBordered
      showBackButton={false}
      onClose={onClose}
      contentStyles={s.content}
      title={t('userAccount:add_phone')}
    >
      <PhoneInput
        value={!isSuccess ? value : ''}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={error}
        onFocus={setFocus}
      />
      <div className={clsx(s.button_wrapper, {
        [s.button_wrapper_up]: isFocus,
      })}
      >
        <InnerButton
          text={t('userAccount:continue')}
          disabled={
            !value || !!error || !isNumberValid || (value.trim() === phoneNumber?.toString() && phoneVerified)
          }
          onClick={handleContinue}
          className={s.continue_button}
        />
      </div>
    </ModalMobile>
  ) : (
    <ModalDesktop
      open={isOpen}
      onClose={onClose}
      title={t('userAccount:add_number')}
      titleJustifyContent="start"
    >
      <div className={s.content}>
        <PhoneInput
          value={!isSuccess ? value : ''}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={error}
        />
        <div className={s.buttons}>
          <InnerButton
            text={t('userAccount:continue')}
            disabled={
              !value || !!error || !isNumberValid || (value.trim() === phoneNumber?.toString() && phoneVerified)
            }
            onClick={handleContinue}
            className={s.continue_button}
          />
        </div>
      </div>
    </ModalDesktop>
  );
};
