import React from 'react';
import { SvgIcon } from '@/modules/shared/components';

interface LabelProps {
  icon: string | false;
  label: string;
  onClickLabel?: () => void;
  onClickIcon?: () => void;
  onClickText?: () => void;
  className?: string;
  clickOnLabel?: boolean;
}

export const Label = ({
  icon,
  label,
  onClickLabel,
  onClickIcon,
  onClickText,
  className,
  clickOnLabel = false,
}: LabelProps) => (
  <div className={className} onClick={clickOnLabel ? onClickLabel : undefined}>
    {icon ? (
      <SvgIcon name={icon} onClick={onClickIcon} />
    ) : <div style={{ width: 24, flexShrink: 0 }} />}
    <p onClick={onClickText}>{label}</p>
  </div>
);
