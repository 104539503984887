/*
Компонент используется для ввода сообщения в чате
*/

import React, {
  FC, ChangeEvent, useCallback, useRef,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import type { OverlayScrollbarsComponentRef } from 'overlayscrollbars-react';
import { useAdjustTextareaHeight } from '../../hooks';
import { useMatchMedia } from '../../hooks/use-match-media.hook';
import styles from './textarea-flex.module.scss';

export interface TextareaFlexProps {
  value: string;
  handleChange: (value: string) => void;
  handleKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  classNameWrapper?: string;
  classNameTextarea?: string;
  maxChars?: number;
  placeholder?: string;
  maxHeightDesktop?: number;
  minHeightDesktop?: number;
  maxHeightMobile?: number;
  minHeightMobile?: number;
  size?: 'width_12';
}

export const TextareaFlex: FC<TextareaFlexProps> = ({
  handleChange,
  handleKeyDown,
  value,
  classNameWrapper,
  classNameTextarea,
  maxChars = 10000,
  placeholder = 'common:enter',
  maxHeightDesktop = 300,
  minHeightDesktop = 44,
  maxHeightMobile = 130,
  minHeightMobile = 32,
  size = 'width_12',
  ...props
}) => {
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const { t } = useTranslation('');
  const osRef = useRef<OverlayScrollbarsComponentRef>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const minHeight = isDesktop ? minHeightDesktop : minHeightMobile;
  const adjustTextareaHeight = useAdjustTextareaHeight({ textareaRef, minHeight });

  const handleTextareaChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    handleChange(newValue);
  }, [handleChange]);


  return (
    <>
      {(isMobile || isTablet) && (
        <textarea
          {...props}
          ref={textareaRef}
          onChange={handleTextareaChange}
          onKeyDown={handleKeyDown}
          onInput={adjustTextareaHeight}
          placeholder={t(placeholder)}
          value={value}
          maxLength={maxChars}
          spellCheck
          style={{
            width: '100%',
            boxSizing: 'border-box',
            resize: 'none',
            maxHeight: `${maxHeightMobile}px`,
            minHeight: `${minHeightMobile}px`,
          }}
          className={clsx(styles.textarea_adaptive)}
          wrap="soft"
          rows={1}
        />
      )}


      {isDesktop && (
        <OverlayScrollbarsComponent
          ref={osRef}
          className={clsx(styles.scroll_textarea, {
            [styles.scroll_textarea_width_12]: size === 'width_12',
          })}
          style={{ maxHeight: `${maxHeightDesktop}px`, minHeight: `${minHeightDesktop}px` }}
        >
          <textarea
            {...props}
            ref={textareaRef}
            onChange={handleTextareaChange}
            onKeyDown={handleKeyDown}
            onInput={adjustTextareaHeight}
            placeholder={t(placeholder)}
            value={value}
            maxLength={maxChars}
            spellCheck
            style={{
              width: '100%',
              boxSizing: 'border-box',
              resize: 'none',
              overflow: 'hidden',
            }}
            className={clsx(styles.textarea_desktop, classNameTextarea)}
            wrap="soft"
            rows={1}
          />
        </OverlayScrollbarsComponent>
      )}
    </>
  );
};
