import React from 'react';

import { SvgIcon } from '../components/svg-icon';

export const useFormatPrice = ({ price, name = 'THB' }) => (
  <>
    {price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ', ')}
    {' '}
    <SvgIcon name={name} />
  </>
);
