import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Button, ModalDesktop } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import styles from './modal-delete-chat.module.scss';

export type ModalDeleteChatProps = {
  count: number;
  onCancel: () => void;
  isOpen: boolean;
  onClose: () => void;
  onDeleteChat: () => void;
};

export const ModalDeleteChat = ({
  count, onCancel, isOpen, onClose, onDeleteChat,
}: ModalDeleteChatProps) => {
  const { t } = useTranslation('');
  const { isDesktop } = useMatchMedia();

  const headerModal = count === 1
    ? t('chat:deleteChatConfirmation.deleteChat')
    : t('chat:deleteChatConfirmation.confirmDeleteChats');
  const descriptionModal = count === 1
    ? t('chat:deleteChatConfirmation.restoreChatImpossible')
    : t('chat:deleteChatConfirmation.restoreChatsImpossible');

  return (
    <ModalDesktop
      open={isOpen}
      onClose={onClose}
      title={headerModal}
      titleJustifyContent="start"
      classNameHeaderContainer={styles.header}
      classNameHeader={styles.header_title}
      isClean={!isDesktop}
      showCloseButton={isDesktop}
      className={styles.modalContainer}
    >
      <div className={styles.container}>
        <p className={styles.container_description}>{descriptionModal}</p>
        <div className={styles.buttons}>
          <Button
            appearance="primary"
            buttonSize="s"
            buttonColor="blue"
            className={styles.buttons_confirm}
            onClick={onDeleteChat}
          >
            {isDesktop ? t('chat:confirmDelete') : t('chat:delete')}
          </Button>
          <Button
            appearance="primary"
            buttonSize="s"
            buttonColor="grey"
            className={styles.buttons_keep}
            onClick={onCancel}
          >
            {isDesktop ? t('chat:keepDelete') : t('chat:cancel')}
          </Button>
        </div>
      </div>
    </ModalDesktop>
  );
};
