import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import s from './credentials.module.scss';
import { Email } from './email.component';
import { Phone } from './phone.component';

type ContactsProps = {
  email: string | null | undefined;
  phoneNumber: string | null | undefined;
  refetch: () => void;
  emailVerified: boolean;
  phoneVerified: boolean;
  phoneUpdatedAt: string | null | undefined;
};

export const Contacts = ({
  email, phoneNumber, refetch, emailVerified, phoneVerified, phoneUpdatedAt,
}: ContactsProps) => {
  const { t } = useTranslation();

  return (
    <section className={clsx(s.contacts, s.section)}>
      <h2>{t('common:footer.contact')}</h2>
      <Phone
        phoneNumber={phoneNumber}
        refetch={refetch}
        phoneUpdatedAt={phoneUpdatedAt}
        phoneVerified={phoneVerified}
      />
      <Email
        email={email}
        refetch={refetch}
        emailVerified={emailVerified}
      />
    </section>
  );
};
