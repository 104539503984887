import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { useFormContext, useWatch } from 'react-hook-form';
import { getToday } from '@/modules/shared/helpers/get-today.helper';
import { SvgIcon } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { AddEventType } from '../../types';
import s from './add-event-page.module.scss';

export const DateAndTime = () => {
  const {
    register, setError, formState: { errors }, clearErrors,
  } = useFormContext<AddEventType>();
  const { t } = useTranslation();
  const date = useWatch({ name: 'event_date' });
  const time = useWatch({ name: 'event_time' });
  const today = getToday();
  const [minTime, setMinTime] = useState<number>();
  const { isMobile } = useMatchMedia();

  useEffect(() => {
    if (date) {
      if (date < today) {
        setError('event_date', {
          type: 'min_date',
          message: t('forms:validationErrors.date'),
        });
      } else if (date === today) {
        clearErrors('event_date');
        const hours = (new Date()).getHours();
        setMinTime(hours + 1);
      } else {
        clearErrors('event_date');
        // нужно явно очищать значение minTime, тк в safari при клике на поле даты проставляется сегодняшняя
        setMinTime(undefined);
      }
    }
    if (time) {
      if (minTime && Number(time.slice(0, 2)) < minTime) {
        setError('event_time', {
          type: 'min_time',
          message: `${t('forms:validationErrors.time')}${minTime.toString().padStart(2, '0')}:00`,
        });
      } else {
        clearErrors('event_time');
      }
    }
  }, [clearErrors, date, minTime, setError, t, time, today]);

  return (
    <div className={clsx(s.field, s.field_date)}>
      <h2>{t('forms:labels.dateAndTime')}</h2>
      <div className={clsx(s.field_inner, s.field_inner_date)}>
        <p>
          {t('events:createEvents.date')}
          <span> *</span>
        </p>
        <div className={s.values}>
          <input
            type="date"
            defaultValue=""
            min={today}
            max="9999-12-31"
            className={s.date}
            placeholder="00/00/0000"
            {...register('event_date', {
              required: true,
            })}
          />
          <SvgIcon name="calendar" />
          {isMobile && <SvgIcon name="select" className={s.select_icon} />}
          {errors.event_date && (
            <p className={s.error}>{errors.event_date['0'] || errors.event_date.message as string}</p>
          )}
        </div>
      </div>
      <div className={clsx(s.field_inner, s.field_inner_time)}>
        <p>
          {t('events:createEvents.time')}
          <span> *</span>
        </p>
        <div className={s.values}>
          <input
            type="time"
            className={s.time}
            placeholder="00:00"
            defaultValue=""
            {...register('event_time', {
              required: true,
            })}
            style={errors.event_time ? { borderColor: 'var(--bzr-secondary-error)' } : {}}
          />
          <SvgIcon name="time" />
          {isMobile && <SvgIcon name="select" className={s.select_icon} />}
          {errors.event_time && (
            <p className={s.error}>{errors.event_time['0'] || errors.event_time.message as string}</p>
          )}
        </div>
      </div>
    </div>
  );
};
