import React, {
  useCallback, useEffect, useRef, CSSProperties, useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useRouter } from 'next/router';
import { useHomeCity, useInitialCityInfoHook } from '@/modules/locations/hooks';
import { useGlobalData } from '@/context/GlobalContext';
import { EVENT_DISPLAY_PATH } from '@/modules/app/components/header/consts';
import { GeolocationPointer } from '../../geolocation-pointer';
import { ConfirmCityModal } from './confirm-city-modal.component';

interface LocClarification {
  isShowModal: boolean;
  onChange: () => void;
  iconOnly?: boolean;
  classNameIcon?: string | CSSProperties;
}

export const LocationClarification = ({
  isShowModal, onChange, iconOnly = false, classNameIcon,
}: LocClarification) => {
  const [locationElement, setLocationElement] = useState<HTMLButtonElement | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const { city } = useHomeCity();
  const router = useRouter();
  const { userSet } = useInitialCityInfoHook();
  const { initialShow, setInitialShow } = useGlobalData();

  const handleOutsideClick = useCallback((event: Event) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setInitialShow(false);
    }
  }, [setInitialShow]);

  const handleEscPress = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setInitialShow(false);
    }
  }, [setInitialShow]);

  useEffect(() => {
    if (isShowModal) {
      document.addEventListener('mousedown', handleOutsideClick);
      document.addEventListener('keydown', handleEscPress);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscPress);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscPress);
    };
  }, [isShowModal, handleOutsideClick, handleEscPress]);

  const onChangeHandler = useCallback(() => {
    onChange();
    setInitialShow(false);
  }, []);

  const eventPath = EVENT_DISPLAY_PATH.some((path) => router.pathname.includes(path));

  return (
    <>
      <GeolocationPointer
        city={city}
        onClick={onChangeHandler}
        ref={setLocationElement}
        iconOnly={iconOnly}
        classNameIcon={classNameIcon}
      />
      {!eventPath && !userSet && initialShow && locationElement && !isShowModal && createPortal(
        <ConfirmCityModal city={city} modalRef={modalRef} setShowModal={onChange} onClose={() => setInitialShow(false)} />,
        locationElement,
      )}
    </>
  );
};
