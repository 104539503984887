import React, { useCallback, useMemo, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { FloatNumber, Sorting, UserRating } from '@/modules/shared/components';
import { Button } from '@/modules/shared/components/button';
import { getDeclension } from '@/modules/shared/helpers';
import { useMatchMedia } from '@/modules/shared/hooks';
import { IReviews } from '@/modules/shared/types';
import { ReviewItem } from './review-item.component';
import { StarLine } from './star-line.component';
import { MAX_REVIEWS } from './const.helper';
import s from './review-block.module.scss';

type ReviewBlockProps = {
  data: Array<IReviews>;
};

export const ReviewBlock = ({ data }: ReviewBlockProps) => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(false);
  const { isMobile } = useMatchMedia();
  const [selectedSort, setSelectedSort] = useState('default');

  const reviews = useMemo(() => {
    const dateFormat = data.map((el) => ({ ...el, created_at: el.created_at.split('.').reverse().join('-') }));
    switch (selectedSort) {
      case 'default':
      case 'newest':
        dateFormat.sort((a, b) => +new Date(b.created_at) - +new Date(a.created_at));
        return dateFormat;
      case 'oldest':
        dateFormat.sort((a, b) => +new Date(a.created_at) - +new Date(b.created_at));
        return dateFormat;
      case 'highly_rated':
        return data.sort((a, b) => b.rating - a.rating);
      case 'low_rated':
        return data.sort((a, b) => a.rating - b.rating);
    }
  }, [selectedSort]);

  const options = useMemo(
    () => (['default', 'newest', 'oldest', 'highly_rated', 'low_rated'].map((el) => (
      { value: el, label: t(`adv:filters.sortingModal.${el}`) }
    ))),
    [t],
  );


  const [ratingCount, totalReviews] = ['ratingCount', 'totalReviews'].map((el) => getDeclension({
    count: data.length || 0,
    t,
    singular: `common:${el}.one`,
    few: `common:${el}.few`,
    many: `common:${el}.many`,
  }));

  const toggleShow = useCallback(() => setIsShow((prev) => !prev), []);

  const itemsToShow = isShow || isMobile ? reviews : reviews?.slice(0, MAX_REVIEWS);
  const averageRating = Math.round((data.reduce((acc, el) => acc + el.rating, 0) / data.length) * 10) / 10 || 0;

  return (
    <section className={s.review_block}>
      <section className={s.overview}>
        {!isMobile ? (
          <div className={s.rating}>
            <div className={s.rating_stars}>
              <span>
                <FloatNumber value={averageRating} precision={1} />
              </span>
              <UserRating rating={averageRating} sizeStar="32px" />
            </div>
            <p>{ratingCount}</p>
          </div>
        ) : (
          <div className={s.rating}>
            <span>
              <FloatNumber value={averageRating} precision={1} />
            </span>
            <div className={s.rating_stars}>
              <UserRating rating={averageRating} sizeStar="32px" />
              <p>{ratingCount}</p>
            </div>
          </div>
        )}
        <div className={s.scales}>
          {new Array(5).fill(5).map((el, i) => el - i).map((el) => (
            <StarLine
              key={el}
              rating={el}
              quantity={data.filter((item) => item.rating === el).length}
              totalQuantity={data.length}
            />
          ))}
        </div>
      </section>
      {data.length > 0 && (
        <section className={s.review_list}>
          <header className={s.header}>
            {!isMobile && <h3>{totalReviews}</h3>}
            <Sorting value={selectedSort} setValue={setSelectedSort} options={options} />
          </header>
          <main className={s.main}>
            {itemsToShow?.map((item) => (
              <ReviewItem key={item.id} item={item} />
            ))}
          </main>
          <footer className={s.footer}>
            {!isMobile && data.length > MAX_REVIEWS && (
              <Button
                appearance="primary"
                buttonColor="blue"
                buttonSize="s"
                onClick={toggleShow}
              >
                {t(`common:${isShow ? 'hide' : 'showMore'}`)}
              </Button>
            )}
          </footer>
        </section>
      )}
    </section>
  );
};
