import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { IAd } from '@/modules/ads/types';
import { useInfiniteListRef, useMatchMedia } from '@/modules/shared/hooks';
import { ItemLoader, NotFound, Pagination } from '@/modules/shared/components';
import { AdCard } from '../index-page';
import styles from './detail-ads-list.module.scss';

interface InfinityAds {
  count: number;
  counts: { all: number };
  results: IAd[];
}

interface DetailAdsListProps {
  hasMore?: boolean;
  currentPage: number;
  totalPages?: number;
  isLoading?: boolean;
  pages: IAd[] | InfinityAds[];
  isSearching?: boolean;
  fetchMore?: (() => void) | undefined;
  handleSetPage?: (page: number) => void;
  setCurrentPage?: (page: React.SetStateAction<number>) => void;
}

export const DetailAdsList = ({
  pages,
  hasMore,
  fetchMore,
  isLoading,
  totalPages,
  isSearching,
  handleSetPage,
  currentPage,
  setCurrentPage,
}: DetailAdsListProps) => {
  const { isDesktop } = useMatchMedia();
  const currentAds = useMemo(() => (
    isDesktop
      ? pages
      : pages[0] && 'results' in pages[0]
        ? pages[0].results
        : []
  ), [isDesktop, pages]);

  const lastListItemRef = useInfiniteListRef(
    hasMore && pages?.length < (totalPages || 0) && !isLoading,
    fetchMore,
    isLoading,
  );

  if (currentAds?.length === 0 && !isLoading) {
    const messageType = isSearching ? 'noResults' : 'noData';
    return <NotFound messageType={messageType} />;
  }

  return (
    <Pagination
      itemsPerPage={9}
      hasMore={hasMore}
      fetchMore={fetchMore}
      currentPage={currentPage}
      handleSetPage={handleSetPage}
      setCurrentPage={setCurrentPage}
      className={styles.pagination_list}
      totalPages={isDesktop ? totalPages : undefined}
    >
      {isDesktop ? (
        currentAds?.map((card) => <AdCard key={card.id} ad={card} isSellerPage />)
      ) : (
        <>
          {pages?.map((page, i) => (
            <React.Fragment key={i}>
              {page?.results?.map((ad, j) => (
                <React.Fragment key={ad.id}>
                  <AdCard key={ad.id} ad={ad} isSellerPage />
                  {i + 1 === pages?.length && page.results.length === j + 1 ? (
                    <div ref={lastListItemRef} />
                  ) : null}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </>
      )}
      {!isLoading ? null : (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <ItemLoader isLoading size="50px" />
        </Box>
      )}
    </Pagination>
  );
};
