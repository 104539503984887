import { api, uploadFileToS3 } from '@/modules/shared/api';
import { generateThumbnail } from '@/modules/shared/helpers';
import { endpoints } from '../api/endpoints';
import { CompanyLogo } from '../types';

export const saveCompanyLogo = async (image: File, company?: number) => {
  const logo: CompanyLogo[] = [];
  const extension = image.type.split('/').pop();
  const { data } = await api.post<CompanyLogo>(endpoints.uploadLogo(), {
    formats: [extension], company,
  });
  const thumbnail = await generateThumbnail(image);
  await uploadFileToS3(data.thumbnail_signed_url, thumbnail);
  if (company) await uploadFileToS3(data.image_signed_url, image);
  logo.push(data);
  return logo;
};
