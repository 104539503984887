import { useCallback } from 'react';
import { events, providers } from './providers.const';


export type Options = {
  exclude?: string[]; // list of providers to exclude
  payload?: unknown; // the event payload
};



export const useTrackEvent = () => useCallback((eventName: string, options: Options = {}) => {
  for (const { name, track } of providers) {
    if (!((options.exclude || []).indexOf(name) > -1) && events[eventName][name]) {
      try {
        track(events[eventName][name], options.payload);
      } catch (err) {
        console.error(`Failed to send event ${eventName} for provider ${name}`);
        console.error(err);
      }
    }
  }
}, []);
