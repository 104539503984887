import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useMatchMedia } from '../../hooks/use-match-media.hook';
import { SvgIcon } from '../svg-icon';
import { profileBasePath } from '../../const';
import styles from './mobile-layout-icons.module.scss';

export const IconClose = () => {
  const router = useRouter();
  const { isDesktop } = useMatchMedia();

  const handleCloseClick = useCallback(() => {
    if (!isDesktop) {
      router.push(`/${profileBasePath}/personal-account`);
    }
  }, [isDesktop]);

  return (
    <SvgIcon
      name="close"
      className={styles.svg_icon}
      onClick={handleCloseClick}
    />
  );
};
