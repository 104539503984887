import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCategoryName } from '@/modules/categories/hooks';
import { SvgIcon } from '@/modules/shared/components';
import styles from './switch-category.module.scss';

interface CategoryRowProps {
  translateKeys: string[];
  onClick: () => void;
  asLink?: boolean;
  href?: string;
}

export const CategoryRow = (props: CategoryRowProps) => {
  const {
    translateKeys,
    onClick,
    asLink,
    href,
  } = props;
  const { asPath } = useRouter();
  const [getNameByCode] = useCategoryName();

  if (asLink && href) {
    return (
      <Link
        href={href}
        className={styles.link}
        onClick={onClick}
      >
        <h4>{getNameByCode(...translateKeys)}</h4>
        {href === asPath
          ? <SvgIcon name="check" className={styles.svg_checked} />
          : <SvgIcon name="arrow-forward-right" />}
      </Link>
    );
  }

  return (
    <button
      type="button"
      className={styles.row}
      onClick={onClick}
    >
      <h4>{getNameByCode(...translateKeys)}</h4>
      <SvgIcon name="arrow-forward-right" />
    </button>
  );
};
