import { useCallback, useMemo } from 'react';
import { Option } from '../../types';

export const useDropdownOnChange = <T>(onChange: (value: T | undefined) => void) => useCallback(
  (_, newValue?: Option<T>) => onChange(newValue?.value),
  [onChange],
);

export const useDropdownValue = <T>(value: T, options: Option<T>[]) => useMemo(
  () => options.find((option) => option.value === value),
  [options, value],
);
