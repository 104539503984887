import React, { ReactNode, useEffect } from 'react';
import clsx from 'clsx';
import { useFormContext, useWatch } from 'react-hook-form';
import { CreateAdTypes } from '../../../types';
import styles from '../../part-of-form.module.scss';

interface FieldLayoutProps {
  title: string;
  required?: boolean;
  children: ReactNode;
  info?: string;
  isVertically?: boolean;
  name: keyof CreateAdTypes;
}

// одно поле в форме создания объявлений внутри блока с заголовком, например состояние или наличие грузчиков
export const FieldLayout = (props: FieldLayoutProps) => {
  const {
    children,
    title,
    required = true,
    info,
    isVertically,
    name,
  } = props;
  const {
    formState: { errors },
    clearErrors,
    control,
  } = useFormContext<CreateAdTypes>();
  const fieldValue = useWatch({ control, name });

  useEffect(() => {
    if (errors[name] && fieldValue) {
      clearErrors(name);
    }
  }, [fieldValue, errors, clearErrors, name]);

  return (
    <div className={styles.field}>
      <p className={styles.secondary_title}>
        {title}
        {required && <span> *</span>}
      </p>
      <div className={styles.field_wrapper}>
        <div className={clsx(styles.universal_field, { [styles.vertical_field]: isVertically })}>
          {children}
        </div>
        {info && <p className={styles.info}>{info}</p>}
        {errors[name] && <p className={styles.error}>{errors[name]?.message}</p>}
      </div>
    </div>
  );
};
