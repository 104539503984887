import { useMemo } from 'react';
import * as linkify from 'linkifyjs';
import { useFeatureFlags } from './use-feature-flags.hook';


type TextItem = { type: 'url' | 'text', value: string };


type Result = {
  text: TextItem[],
  strippedUrls: string[],
};


const extractDomain = (url: string) => {
  const urlObj = new URL(url);
  const parts = urlObj.hostname.split('.');
  const domain = parts.slice(-2).join('.');
  return domain;
};


export const useCleanTextWithLinks = (
  text: string,
  whiteListedDomains: string[],
) => {
  const { BLOCK_EXTERNAL_LINKS } = useFeatureFlags();

  return useMemo<Result>(() => {
    const links = linkify.find(text, 'url');
    if (links.length === 0) {
      return { text: [{ type: 'text', value: text }], strippedUrls: [] };
    }

    const blocks: TextItem[] = [];
    let start = 0;
    const strippedUrls: string[] = [];
    for (const link of links) {
      const index = text.indexOf(link.value, start);
      if (index !== -1) {
        if (index > start) {
          blocks.push({ type: 'text', value: text.slice(start, index) });
        }
        if (whiteListedDomains.indexOf(extractDomain(link.href)) === -1 && BLOCK_EXTERNAL_LINKS) {
          strippedUrls.push(link.value);
        } else {
          blocks.push({ type: 'url', value: link.href });
        }
        start = index + link.value.length;
      }
    }

    if (start < text.length) {
      blocks.push({ type: 'text', value: text.slice(start) });
    }

    return { text: blocks, strippedUrls };
  }, [text, whiteListedDomains, BLOCK_EXTERNAL_LINKS]);
};
