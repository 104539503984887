import { useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';

export const useCalendarNames = () => {
  const { t } = useTranslation();

  const monthNames = useMemo(
    () => [
      'january', 'february', 'march', 'april',
      'may', 'june', 'july', 'august',
      'september', 'october', 'november', 'december',
    ].map((el) => t(`common:calendar.months.${el}`)),
    [t],
  );

  const daysOfWeek = useMemo(
    () => [
      'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun',
    ].map((el) => t(`common:calendar.week.${el}`)),
    [t],
  );

  return { monthNames, daysOfWeek };
};
