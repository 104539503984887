import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useCustomWindowWidth } from '@/modules/shared/hooks';
import { SvgIcon } from '@/modules/shared/components';
import { useGlobalData } from '@/context/GlobalContext';
import { useSortedCategories } from '@/modules/categories/hooks';
import { CategoryList, CategoryListMobile } from '../category-list';
import styles from './categories-modal.module.scss';

interface Props {
  modalRootBlock: Element | null
}

// TODO: в будущем было бы неплохо отрефакторить всю модалку, тк на момент создания не было идей лучше
export const CategoriesModal = ({ modalRootBlock }: Props) => {
  const { t } = useTranslation('common');
  const { windowWidth } = useCustomWindowWidth();
  const customMobile = windowWidth !== null && windowWidth < 768;
  const { sortedCategories } = useSortedCategories();
  const { setIsShowCategoriesModal } = useGlobalData();

  return (
    <section className={styles.modal_wrapper} onClick={() => setIsShowCategoriesModal(false)}>
      {customMobile
        ? (
          <CategoryListMobile
            categories={sortedCategories}
            onClose={() => setIsShowCategoriesModal(false)}
          />
        )
        : (
          <>
            <div className={styles.header}>
              {t('categories')}
              <SvgIcon name="close" onClick={() => setIsShowCategoriesModal(false)} />
            </div>
            <CategoryList
              categories={sortedCategories}
              onClose={() => setIsShowCategoriesModal(false)}
            />
            <div className={styles.background} style={{ height: `${modalRootBlock?.clientHeight}px` }} />
          </>
        )}
    </section>
  );
};

