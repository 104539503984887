import React, {
  useState, useEffect, useCallback, useMemo, useLayoutEffect,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Button, OverflowMenu } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { useRequestReviewListQuery } from '@/modules/review/queries';
import { useCreateRequestReviewMutation } from '@/modules/review/mutations';
import type { RequestReviewResponseTypes } from '@/modules/review';
import type { SuggestionFeedbackTypes } from '@/modules/my-feedback';
import { useSuggestionFeedbackList } from '@/modules/my-feedback/queries';
import styles from './button-request-review.module.scss';

export type ButtonRequestReviewProps = {
  reviewerId: number;
  adId: number;
  canRequestReviews: boolean;
};


export const ButtonRequestReview = ({ reviewerId, adId, canRequestReviews }:ButtonRequestReviewProps) => {
  const { t } = useTranslation('');
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const { data: requestReviewList } = useRequestReviewListQuery({ reviewerId });
  const { data: suggestionFeedbackList } = useSuggestionFeedbackList();
  const { mutate } = useCreateRequestReviewMutation();

  const [requestReviewMobile, setRequestReviewMobile] = useState<string | null>(null);
  const [reviewExists, setReviewExists] = useState<boolean>(true);

  const handleClickRequestReview = useCallback(() => {
    mutate({
      reviewer: reviewerId,
      announcement: adId,
    });
  }, [reviewerId, adId]);


  useLayoutEffect(() => {
    if (requestReviewList && suggestionFeedbackList) {
      const reviewMap = new Map(
        requestReviewList.map((review: RequestReviewResponseTypes) => [
          `${review.reviewer}-${review.announcement}`,
          true,
        ]),
      );

      const suggestionMap = new Map(
        suggestionFeedbackList.map((feedback: SuggestionFeedbackTypes) => [
          `${feedback.user}-${feedback.id}`,
          true,
        ]),
      );

      const key = `${reviewerId}-${adId}`;

      const existsInRequestReviewList = reviewMap.get(key);
      const existsInSuggestionFeedbackList = suggestionMap.get(key);

      setReviewExists(!!existsInRequestReviewList || !!existsInSuggestionFeedbackList);
    }
  }, [requestReviewList, suggestionFeedbackList, reviewerId, adId]);


  const listMenu = useMemo(() => [
    {
      icon: 'reviews16',
      title: 'chat:requestReview',
      value: 'requestReview',
      disabled: canRequestReviews || reviewExists,
    },
  ], [reviewExists, canRequestReviews]);

  useEffect(() => {
    if (requestReviewMobile === 'requestReview') {
      handleClickRequestReview();
    }
  }, [requestReviewMobile]);

  return (
    <>
      {isDesktop && (
        <Button
          appearance="primary"
          buttonColor="grey"
          buttonSize="s"
          className={styles.buttonRequest}
          onClick={handleClickRequestReview}
          disabled={canRequestReviews || reviewExists}
        >
          {t('chat:requestReview')}
        </Button>
      )}
      {(isMobile || isTablet) && (
        <OverflowMenu list={listMenu} action={setRequestReviewMobile} />
      )}
    </>
  );
};
