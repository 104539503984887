const flags = [
  { name: 'DISABLE_SEO', defaultValue: false },
  { name: 'BLOCK_EXTERNAL_LINKS', defaultValue: false },
];


const currentFlags = {
  DISABLE_SEO: process.env.NEXT_PUBLIC_DISABLE_SEO === 'true',
  BLOCK_EXTERNAL_LINKS: process.env.NEXT_PUBLIC_BLOCK_EXTERNAL_LINKS === 'true',
};


export const getFeatureFlags = async () => {
  const result: Record<string, unknown> = {};
  for (const { name, defaultValue } of flags) {
    result[name] = currentFlags[name] || defaultValue;
  }

  return result;
};
