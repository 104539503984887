import {
  Dispatch, SetStateAction, useCallback, useEffect, useState,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { Button, ModalDesktop, ModalMobile } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { useUpdateMeMutation } from '@/modules/me';
import { InputText } from '@/modules/shared/components/inputs/input-text';
import { UserTypeEnum, type UserType } from '@/modules/users';
import s from './modal.module.scss';
import { InnerButton } from './inner-components';
import { useSetFocus } from '../helpers';

type ChangeNameModalProps = {
  isOpen: boolean;
  onClose: () => void;
  name: string | undefined;
  refetch: () => void;
  type: UserType;
  setName: Dispatch<SetStateAction<string | undefined>>;
};

export const ChangeNameModal = ({
  isOpen, onClose, name, refetch, type, setName,
}: ChangeNameModalProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();
  const [value, setValue] = useState<string>(name || '');
  const { mutate: update, isSuccess } = useUpdateMeMutation();
  const { isFocus, setFocus } = useSetFocus();

  const handleSave = useCallback(() => {
    if (type === UserTypeEnum.Company) {
      setName(value);
    } else {
      update({ full_name: value });
    }
    onClose();
  }, [type, value]);

  const handleClose = useCallback(() => {
    setName(value);
    onClose();
  }, [type, value]);

  useEffect(() => {
    if (type === UserTypeEnum.Company) return;
    if (isSuccess) refetch();
  }, [type, isSuccess]);

  return isMobile ? (
    <ModalMobile
      open={isOpen}
      fullHeight
      headerBordered
      showBackButton={false}
      onClose={handleClose}
      contentStyles={s.content}
    >
      <h3 className={s.h3}>
        {type === UserTypeEnum.Company
          ? t('userAccount:become_company.company_info.name')
          : t('auth:label.name')}
      </h3>
      <div className={s.input_wrapper}>
        <InputText
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onFocus={setFocus}
        />
      </div>
      <div className={clsx(s.button_wrapper, {
        [s.button_wrapper_up]: isFocus,
      })}
      >
        <InnerButton
          onClick={handleSave}
          disabled={!value || value.trim() === name}
          text={t('common:save')}
        />
      </div>
    </ModalMobile>
  ) : (
    <ModalDesktop
      open={isOpen}
      onClose={handleClose}
      title={type === UserTypeEnum.Company
        ? t('userAccount:become_company.company_info.name')
        : t('auth:label.name')}
      titleJustifyContent="start"
    >
      <div className={s.content}>
        <div className={s.input_wrapper}>
          <InputText
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div className={s.buttons}>
          <Button
            appearance="primary"
            buttonColor="grey"
            buttonSize="s"
            onClick={handleClose}
          >
            {t('common:cancel_verb')}
          </Button>
          <InnerButton
            onClick={handleSave}
            disabled={!value || value.trim() === name}
            text={t('common:save')}
          />
        </div>
      </div>
    </ModalDesktop>
  );
};
