import React, { ChangeEvent, useRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { useImageUploader } from '@/modules/shared/hooks';
import { ItemLoader, SvgIcon } from '@/modules/shared/components';
import styles from '../../leave-feedback-modal.module.scss';

interface UploadedPhoto {
  file: File;
  url: string;
}
interface LoadPhotosProps {
  setIsConverting: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoadPhotos = ({ setIsConverting }:LoadPhotosProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [uploadedPhotos, setUploadedPhotos] = useState<UploadedPhoto[]>([]);
  const {
    setValue, setError, formState: { errors }, clearErrors,
  } = useFormContext();
  const { handleImageUpload, loading } = useImageUploader();

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    setIsConverting(true);
    if (event.target.files.length > 3 || uploadedPhotos.length >= 3) {
      setError(
        'photos',
        { message: t('forms:validationErrors.hasExceededMaxPhotoLimit', { quantity: 3 }) },
      );
    }

    const uploadedImages = await handleImageUpload(event);

    if (uploadedImages.length > 0) {
      const updatedPhotos: UploadedPhoto[] = uploadedImages.map((file: File) => ({
        file,
        url: URL.createObjectURL(file),
      }));

      setValue('photos', [
        ...uploadedPhotos.map((photo) => photo.file),
        ...updatedPhotos.map((photo) => photo.file),
      ]);
      setUploadedPhotos((prevValue) => [...prevValue, ...updatedPhotos]);
    }

    const input = event.target as HTMLInputElement;
    input.value = '';
    setIsConverting(false);
  };

  const handlePhotoClick = (index: number) => {
    const photoToRemove = uploadedPhotos[index];
    const updatedPhotos = uploadedPhotos.filter((_, i) => i !== index);
    URL.revokeObjectURL(photoToRemove.url);

    setUploadedPhotos(updatedPhotos);
    setValue('photos', updatedPhotos.map((photo) => photo.file));

    if (updatedPhotos.length <= 3) {
      clearErrors('photos');
    }
  };

  return (
    <div className={styles.load_photos}>
      <h5>{t('review:photos')}</h5>
      {errors?.photos?.message && typeof errors.photos.message === 'string' && (
        <p className={styles.error}>{errors.photos.message}</p>
      )}
      <div className={clsx(styles.load_photo_wrapper, { [styles.has_photos]: uploadedPhotos.length > 1 })}>
        <div className={styles.input}>
          <input
            multiple
            type="file"
            ref={inputRef}
            maxLength={4}
            accept=".jpeg,.jpg,.png,.heic,.webp"
            onChange={handleFileChange}
          />
          {!loading && <SvgIcon name="photo" />}
          <ItemLoader isLoading={loading} size={65} />
        </div>
        {!uploadedPhotos.length ? (
          <p>{t('review:photoPlaceholder')}</p>
        ) : (
          uploadedPhotos.map((photo, index) => (
            <div
              key={index}
              className={styles.photo}
              style={{ backgroundImage: `url(${photo.url})` }}
            >
              <button
                type="button"
                className={styles.delete_img}
                onClick={() => handlePhotoClick(index)}
              >
                <SvgIcon name="close" />
              </button>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
