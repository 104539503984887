import { useMutation } from '@tanstack/react-query';
import { adComplaintApi } from '../api';
import { COMPLAINT_AD_KEY } from '../consts';
import type { AdComplaint } from '../types';


export const useComplaintAdMutation = () => {
  const {
    mutate, isPending, error,
  } = useMutation<AdComplaint, Error, AdComplaint>({
    mutationKey: COMPLAINT_AD_KEY,
    mutationFn: (complaintData) => adComplaintApi.create(complaintData),
  });

  return {
    mutate,
    isLoading: isPending,
    error,
  };
};
