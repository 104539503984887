import React, { memo } from 'react';
import NextScript from 'next/script';
import { useFeatureFlags } from '@/modules/shared/hooks';
import { YM_TRACKING_ID } from './providers.const';

const ScriptList = memo(() => (
  <>
    <script
      id="microsoft-clarity-script"
      dangerouslySetInnerHTML={{
        __html: ` 
            (function(c,l,a,r,i,t,y){ 
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; 
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; 
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); 
            })(window, document, "clarity", "script", "kaw9sgb0h2"); 
          `,
      }}
    />
    {/* Yandex.Metrika */}
    <NextScript
      id="yandex-metrika-script"
      strategy="beforeInteractive"
      dangerouslySetInnerHTML={{
        __html: ` 
            (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)}; 
            m[i].l=1*new Date(); 
            for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }} 
            k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) 
            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym"); 
            ym(${YM_TRACKING_ID}, "init", { 
                clickmap:true, 
                trackLinks:true, 
                accurateTrackBounce:true, 
                webvisor:true, 
                ecommerce:"dataLayer" 
            }); 
          `,
      }}
    />

    {/* <GoogleTagManager /> */}
    <NextScript
      id="google-tag-manager-script"
      strategy="beforeInteractive"
      dangerouslySetInnerHTML={{
        __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': 
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], 
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); 
            })(window,document,'script','dataLayer','GTM-KZV6P8DR'); 
          `,
      }}
    />
    {/* Google Analytics */}
    <NextScript
      id="google-analytics-script"
      strategy="beforeInteractive"
      dangerouslySetInnerHTML={{
        __html: ` 
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'G-EXTQPGB1X2'); 
          `,
      }}
    />
    {/* Google Tag Manager (noscript) */}
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-KZV6P8DR"
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>

    {/* Meta Pixel Code */}
    <NextScript id="facebook-meta-pixel-script">
      {` 
            !function(f,b,e,v,n,t,s) 
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod? 
            n.callMethod.apply(n,arguments):n.queue.push(arguments)}; 
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; 
            n.queue=[];t=b.createElement(e);t.async=!0; 
            t.src=v;s=b.getElementsByTagName(e)[0]; 
            s.parentNode.insertBefore(t,s)}(window, document,'script', 
            'https://connect.facebook.net/en_US/fbevents.js'); 
            fbq('init', '7007630589316149'); 
            fbq('track', 'PageView'); 
          `}
    </NextScript>
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        src="https://www.facebook.com/tr?id=7007630589316149&ev=PageView&noscript=1"
      />
    </noscript>
  </>
));

/**
 * Even though it's not a classic React provider, still semantically it's a provider
 */
export function EventsTrackingProvider() {
  const disableSeo = useFeatureFlags().DISABLE_SEO;

  if (disableSeo) return;

  return <ScriptList />;
}
