import React, { ButtonHTMLAttributes, FC } from 'react';
import styles from './action-button.module.scss';


export const ActionButton: FC<
{ type: 'button' | 'submit' } & ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  children, className = '', type = 'submit', ...props
}) => (
  <button
    type={type}
    className={`${styles.action_button} ${className}`}
    {...props}
  >
    {children}
  </button>
);
