import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import s from '@/styles/main/AuthModal.module.scss';
import useTranslation from 'next-translate/useTranslation';
import { getCurrentTimestamp, getNextEmailResendTimestamp, setNextEmailResendTimestamp } from '@/modules/shared/helpers';
import { useOpenAuthModal, useValidationSchema } from '@/modules/auth/hooks';
import { useVisibleErrors } from '@/modules/forms';
import { useResendActivationLinkMutation } from '../../mutations';
import { ActivationTimer } from '../activation-timer.component';
import { ActionButton } from './action-button.component';


export const ResendActivationLinkModal = () => {
  const { t } = useTranslation('');
  const { validationEmail } = useValidationSchema();
  const { resendActivationLink, isLoading, error: errorEmail } = useResendActivationLinkMutation();
  const setModalType = useOpenAuthModal();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [resent, setResent] = useState(false);
  const [email, setEmail] = useState(null);

  const schema = yup.object().shape({
    ...validationEmail.fields,
  }).required();

  const {
    register, handleSubmit, setError, formState, setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all', // recalculate errors on change/blur/submit/touch
  });

  const visibleErrors = useVisibleErrors(formState);
  const { isDirty, isValid, errors } = formState;

  useEffect(() => {
    if (!resent || !email || isLoading) {
      return;
    }

    if (!errorEmail) {
      setModalType('registerConfirmation');
      return;
    }

    const userError = errorEmail?.response?.data?.validation_errors?.code;
    if (userError === 'USER_DOESNT_EXIST') {
      setError('email', {
        type: 'manual',
        message: t('forms:validationErrors.emailNotRegistered'),
      });
    }
    if (userError === 'USER_IS_ACTIVE') {
      setError('email', {
        type: 'manual',
        message: t('forms:validationErrors.emailActivated'),
      });
    }
  }, [resent, errorEmail, email, isLoading, t, setModalType, setError]);

  const handleInputChange = (e) => {
    setEmail(e.target.value);
    setValue('email', e.target.value);
    setButtonDisabled(false);
  };

  const onSubmit = (data) => {
    const { email: emailUser } = data;
    const nextEmailResendTimestamp = getNextEmailResendTimestamp(emailUser);
    const currentTimestamp = getCurrentTimestamp();
    if (currentTimestamp < nextEmailResendTimestamp) {
      setButtonDisabled(nextEmailResendTimestamp - currentTimestamp);
      return;
    }

    localStorage.setItem('authModalState', email);
    setNextEmailResendTimestamp(email);
    resendActivationLink(emailUser);
    setEmail(emailUser);
    setResent(true);
  };

  return (
    <div className={s.auth_modal}>
      <div className={s.forgot_password}>
        <h2>{t('auth:label.sendEmailAgain')}</h2>
        <p>{t('auth:label.enterEmail')}</p>
        <form className={s.forgot_form} onSubmit={handleSubmit(onSubmit)}>
          <div>
            <input
              {...register('email')}
              placeholder="Email"
              className={isLoading ? s.disabled : ''}
              value={email}
              onChange={handleInputChange}
            />
            {(visibleErrors.email || errors) && <p className={s.error}>{errors.email?.message}</p>}
          </div>
          <ActionButton
            type="submit"
            disabled={(isLoading || buttonDisabled) || (isDirty && !isValid)}
          >
            {buttonDisabled ? t('auth:label.sendEmailAgain')
              : (isLoading ? t('forms:labels.submitting') : t('forms:labels.send'))}
            {' '}
            {buttonDisabled && <ActivationTimer handleTimerEnd={() => setButtonDisabled(false)} />}
          </ActionButton>
        </form>
      </div>
    </div>
  );
};
