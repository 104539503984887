import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { exampleFormatPhoneNumber } from '../helpers';
import style from './example-format-phone-number.module.scss';

export const ExampleFormatPhoneNumber = ({ phoneNumber }) => {
  const { t } = useTranslation('');
  const formattedExampleNumber = exampleFormatPhoneNumber(phoneNumber);

  return !!formattedExampleNumber && (
    <p className={style.informational}>
      {t('forms:validationErrors.invalidPhoneFormat', { examplePhoneNumber: formattedExampleNumber })}
    </p>
  );
};
