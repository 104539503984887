import React, {
  createContext, useContext, useState, Dispatch, SetStateAction, useMemo,
} from 'react';

interface EventContextType {
  searchFilter: string;
  setSearchFilter: Dispatch<SetStateAction<string>>;
  category: string;
  onChangeStatusFilter: (eventStatus: string) => void;
}

const EventContext = createContext<EventContextType>({
  searchFilter: '',
  setSearchFilter: () => {},
  category: '',
  onChangeStatusFilter: () => {},
});

export const EventProvider = ({ children }) => {
  const [searchFilter, setSearchFilter] = useState('');
  const [category, setCategory] = useState('');

  const onChangeStatusFilter = (eventStatus: string) => {
    if (!eventStatus || eventStatus === category) {
      setCategory('');
    } else {
      setCategory(eventStatus);
    }
  };

  const eventContextValue = useMemo(() => ({
    searchFilter,
    setSearchFilter,
    category,
    onChangeStatusFilter,
  }), [searchFilter, setSearchFilter, category]);

  return (
    <EventContext.Provider value={eventContextValue}>
      {children}
    </EventContext.Provider>
  );
};

export const useEventContext = () => useContext(EventContext);
