import {
  fetchList, destroy, create, saveEntityImages, api, fetchAnnouncement,
} from '@/modules/shared/api';
import { endpoints } from './endpoints';


const fetchListFactory = async (endpoint, config) => {
  const data = await fetchList(endpoint, config);
  return {
    ...data,
    data: data.results,
  };
};


export class EventsApi {
  static list = async (config = {}) => fetchListFactory(endpoints.list, config);

  static destroy = async (config) => destroy(endpoints.destroy, config);

  static create = async ({ images, ...body }) => {
    const savedImages = await saveEntityImages(
      images,
      (formats) => create(endpoints.createImage, { formats }),
    );
    const { data } = await api.post(endpoints.createEvent(), {
      ...body,
      images: savedImages,
      event_time: body.event_time || undefined,
    });
    return data;
  };

  static detail = async (id) => {
    const data = await fetchAnnouncement(endpoints.detail, id);
    return data;
  };
}
