import React, { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { useMatchMedia } from '@/modules/shared/hooks';
import {
  GalleryModal, UserAvatar, UserCompanyRedirect, UserRating,
} from '@/modules/shared/components';
import { getDateOfCreation } from '@/modules/shared/helpers';
import { IReviews } from '@/modules/shared/types';
import { useUserProfile } from '@/modules/user-profile/queries';
import s from './review-item.module.scss';

// практически дубль компонента review-item с небольшими отличиями
// нужен для модального окна в мобильной версии
export const FeedbackTextInsideResponse = ({ item }: { item: IReviews }) => {
  const { t, lang } = useTranslation();
  const { isMobile } = useMatchMedia();
  const [openModal, setOpenModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { data } = useUserProfile(item.reviewer.id);

  const handleClick = (index: number) => {
    setCurrentIndex(index);
    setOpenModal(true);
  };

  return (
    <div className={s.review_item}>
      <UserCompanyRedirect
        data={{ user: item.reviewer.id, company: data?.company_id }}
        className={s.reviewer}
      >
        <UserAvatar
          srcAvatar={item.reviewer.avatar}
          userName={item.reviewer.full_name}
          diameter="32px"
        />
        <p>
          {item.reviewer.full_name}
          <span className={s.date}>
            {getDateOfCreation({ date: item.created_at, t, lang })}
          </span>
        </p>
      </UserCompanyRedirect>
      <div className={s.deal}>
        <UserRating rating={item.rating} sizeStar={!isMobile ? '24px' : '16px'} />
        {item.deal_state && <p>{t(`common:dealStatusMobile.${item.deal_state}`)}</p>}
      </div>
      <p className={s.ad_title}>{item?.announcement?.title}</p>
      {item.comment && (
        <p className={clsx(s.comment)}>
          {item.comment}
        </p>
      )}
      {!!item?.images?.length && (
        <div className={s.images}>
          {item.images.map((el, i) => (
            <img
              key={el.id}
              src={el.image}
              alt={`img-${i}`}
              onClick={() => handleClick(i)}
            />
          ))}
        </div>
      )}
      {!!item?.images?.length && (
        <GalleryModal
          currentImageIndex={currentIndex}
          setCurrentImageIndex={setCurrentIndex}
          open={openModal}
          onClose={() => setOpenModal(false)}
          images={item.images.map((el) => ({ image: el.image, thumbnail: el.thumbnail }))}
        />
      )}
    </div>
  );
};
