import React, { useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { UserAvatar, UserCompanyRedirect } from '@/modules/shared/components';
import { Me } from '@/modules/me/types';
import { useMatchMedia } from '@/modules/shared/hooks';
import { useLastOnline } from '../../hooks';
import type { Announcement, UserContact } from '../../types';
import styles from './chat-name.module.scss';

export type ChatNameProps = {
  me: Me | null;
  recipient: UserContact;
  announcement: Announcement | null;
  oneChat?: boolean;
  classNameWrapper?: string;
  link?: boolean;
};

export const ChatName = ({
  me, recipient, announcement, oneChat = false, classNameWrapper, link = false,
}: ChatNameProps) => {
  const { t, lang } = useTranslation();
  const { isDesktop } = useMatchMedia();
  const meID = me?.id;
  const myAnnouncement = announcement?.user === meID;

  const displayUserName = useMemo(() => {
    if (recipient?.company) {
      return recipient?.company?.name;
    }
    return recipient?.full_name;
  }, [recipient]);

  const displaySrcAvatar = useMemo(() => {
    if (recipient?.company) {
      return recipient?.company?.logo?.thumbnail ?? null;
    }
    return recipient?.thumbnail ?? null;
  }, [recipient]);

  const userName = () => {
    if (link) {
      return (
        <UserCompanyRedirect
          data={{ user: recipient.id, company: recipient?.company_id }}
        >
          <p className={styles.name}>{displayUserName}</p>
        </UserCompanyRedirect>
      );
    }
    return <p className={styles.name}>{displayUserName}</p>;
  };

  const lastOnlineStatus = useLastOnline(recipient?.last_online || '', t, lang);
  const statusOnlineUser = () => {
    if (oneChat && recipient?.is_online) {
      return <p className={styles.online}>{t('common:online')}</p>;
    }

    if (oneChat && !recipient?.is_online && recipient?.last_online) {
      return <p className={styles.offline}>{lastOnlineStatus}</p>;
    }
  };

  if (!announcement) {
    return (
      <div className={styles.only_name}>
        {userName()}
        {statusOnlineUser()}
      </div>
    );
  }

  return (
    <div className={clsx(styles.wrapper, classNameWrapper)}>
      <div className={styles.wrapper_name}>
        {(myAnnouncement) && (
          <UserAvatar
            diameter={(isDesktop || !oneChat) ? '24px' : '16px'}
            userName={displayUserName}
            srcAvatar={displaySrcAvatar}
          />
        )}
        {userName()}
      </div>
      {statusOnlineUser()}
    </div>
  );
};
