import { useMutation, useQueryClient } from '@tanstack/react-query';
import useTranslation from 'next-translate/useTranslation';
import { toast } from 'react-toastify';
import { AD_KEY } from '../types';
import { adFavoritesApi } from '../api';

export const useFavoritesAdMutation = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('');

  const handleError = (errorMessage) => {
    toast.error(errorMessage);
  };

  const addFavoriteAdMutation = useMutation({
    mutationFn: adFavoritesApi.addFavoriteAd,
    onMutate: async (newId) => {
      await queryClient.cancelQueries({ queryKey: AD_KEY });

      const previousAds = queryClient.getQueryData(AD_KEY) || [];

      queryClient.setQueryData(AD_KEY, (prevIds: number[]) => [...(prevIds || []), newId]);

      return { previousAds };
    },
    onError: (error, _, context) => {
      handleError(t('common:validationErrors.likeError'));
      queryClient.setQueryData(AD_KEY, context?.previousAds || []);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: AD_KEY });
    },
  });

  const deleteFavoriteAdMutation = useMutation({
    mutationFn: adFavoritesApi.deleteFavoriteAd,
    onMutate: async (removedId) => {
      await queryClient.cancelQueries({ queryKey: AD_KEY });

      const previousAds = queryClient.getQueryData(AD_KEY);

      queryClient.setQueryData(AD_KEY, (prevIds: number[]) => (prevIds || []).filter((id) => id !== removedId));

      return { previousAds };
    },
    onError: (error, _, context) => {
      handleError(t('common:validationErrors.removeLikeError'));
      queryClient.setQueryData(AD_KEY, context?.previousAds || []);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: AD_KEY });
    },
  });

  return {
    addFavoriteAd: addFavoriteAdMutation.mutate,
    deleteFavoriteAd: deleteFavoriteAdMutation.mutate,
  };
};
