import { LINK_PATTERN } from '../const';

export const validateInput = (input: string) => {
  const regular = /^[\s,.!?'"-:;()@#$%^&*²°№’฿₽[\]a-zA-Zа-яА-Я0-9\p{Emoji}]*$/u;
  const restEmojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;

  return (restEmojiRegex.test(input) || regular.test(input)) === true;
};

export const validateLinksInText = (input: string) => LINK_PATTERN.test(input);
