import React from 'react';
import type { ChatList as IChatList } from '../../types';
import { ChatMessage } from '../chat-message';
import styles from './chat-list.module.scss';

export type ChatListProps = {
  data: IChatList[];
  onChoose: (id: number) => void;
  selectedChats: number[];
  isCheckbox: boolean;
};

export const ChatList = ({
  data, onChoose, selectedChats, isCheckbox,
}: ChatListProps) => (
  <div className={styles.container}>
    {data.map((chat) => (
      <ChatMessage
        key={chat.id}
        data={chat}
        onChoose={onChoose}
        isSelected={selectedChats.includes(chat.id)}
        isCheckbox={isCheckbox}
      />
    ))}
  </div>
);
