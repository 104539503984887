import React from 'react';
import clsx from 'clsx';
import { Checkbox } from '../inputs';
import styles from './label.module.scss';

interface Props {
  label: string;
  checked?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

export const Label = (props: Props) => {
  const {
    label, checked = false, onChange, className,
  } = props;

  return (
    <label className={clsx(styles.label, className)}>
      <Checkbox
        checkboxSize="24"
        checked={checked}
        onChange={onChange}
      />
      <span>{label}</span>
    </label>
  );
};
