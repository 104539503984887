import React from 'react';
import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { useCreateAdContext } from '../../context';
import { FieldsLayout, FieldLayout } from './shared';
import { useTransportation } from './hooks';
import styles from './shared/hidden-radiobutton.module.scss';
import { CreateAdTypes } from '../../types';

interface LoadersRadiobuttonProps {
  name: keyof CreateAdTypes;
  value: boolean;
  label: string;
  required?: boolean;
}

export const LoadersRadiobutton = (props: LoadersRadiobuttonProps) => {
  const {
    name, value, label, required = false,
  } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field }) => (
        <button
          type="button"
          className={clsx(styles.radioContainer, {
            [styles.checked]: field.value === value,
          })}
          onClick={() => field.onChange(value)}
        >
          <input
            type="radio"
            {...field}
            value={value.toString()}
            checked={field.value === value}
          />
          {label}
        </button>
      )}
    />
  );
};

const Loaders = () => {
  const { t } = useTranslation('');
  const transportation = useTransportation();

  return (
    <FieldsLayout title={t('ad-parameters:service.cargo-transportation')}>
      <FieldLayout
        name="availability_of_loaders"
        title={t('ad-parameters:service.availability-of-loaders.label')}
      >
        {transportation.map(({ value, label }, index) => (
          <LoadersRadiobutton
            key={index}
            name="availability_of_loaders"
            value={value}
            label={label}
          />
        ))}
      </FieldLayout>
    </FieldsLayout>
  );
};

const additionalFields = {
  'cargo-transportation': <Loaders />,
};

// компонент с дополнительными полями для всей категории "Услуги"
export const Service = () => {
  const { subCategory } = useCreateAdContext();

  return additionalFields[subCategory!.codename];
};
