import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Button, ModalMobile } from '@/modules/shared/components';
import { DeleteModalProps } from './deletion.types';
import styles from './mobile-delete-modal.module.scss';

export const MobileDeleteModal = ({
  isConfirmed, onClose, onDelete, onBack,
}: DeleteModalProps) => {
  const { t } = useTranslation('userSettings');

  return (
    <ModalMobile
      open={isConfirmed}
      fullHeight
      onClickBack={onBack}
      showBackButton
      showCloseButton
      onClose={onClose}
      headerBordered
    >
      <div className={styles.confirm_deletion}>
        <div className={styles.consequences}>
          <h2>{t('deleteReason.deleteConsequences')}</h2>
          <p>{t('deleteReason.permanentDeletion')}</p>
        </div>
        <Button
          as="button"
          buttonSize="s"
          buttonColor="grey"
          appearance="primary"
          fullWidth
          onClick={onDelete}
        >
          {t('deleteReason.confirmDelete')}
        </Button>
      </div>
    </ModalMobile>
  );
};
