import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import Cookie from 'js-cookie';
import { User } from '@/modules/users';
import { useAuthContext, useOpenAuthModal } from '@/modules/auth';
import { Button } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { profileBasePath } from '@/modules/shared/const';
import { chat_param_ad_id } from '@/modules/chat';
import styles from '../contacts-block.module.scss';

interface Props {
  allowedComplain: boolean;
  userProfile: User;
  adID: number;
}

export const ButtonsGroup = (props: Props) => {
  const { t } = useTranslation('');
  const { allowedComplain, userProfile, adID } = props;
  const { isAuthenticated } = useAuthContext();
  const openAuthModal = useOpenAuthModal();
  const { isDesktop } = useMatchMedia();

  const phoneButtonRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null);

  const [showPhoneNumber, setShowPhoneNumber] = useState(false);

  const isDisabled = useCallback(
    () => !allowedComplain || !isAuthenticated,
    [allowedComplain, isAuthenticated],
  );

  useEffect(() => {
    if (phoneButtonRef.current) {
      if (!isDesktop && phoneButtonRef.current instanceof HTMLAnchorElement) {
        phoneButtonRef.current.setAttribute('href', `tel:${userProfile.phone_number}`);
      } else {
        phoneButtonRef.current.removeAttribute('href');
      } // если не десктоп и не должно быть ссылкой, удаляем атрибут href
    } // на телефонах и планшетах должна быть ссылка, а на десктопах кнопка
  }, [isDesktop, isDisabled, userProfile]);

  const togglePhoneNumber = useCallback(() => {
    if (isAuthenticated) {
      setShowPhoneNumber((prev) => !prev);
    } else {
      openAuthModal('login');
    }
  }, [isAuthenticated]);

  const isMessageToMyself = Cookie.get('user_id') === String(userProfile.id);
  const isHiddenPhoneNumber = userProfile?.phone_number_is_hidden;

  const handleChatButtonClick = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!isAuthenticated) {
      event.preventDefault();
      openAuthModal('login');
      return;
    }
    if (isMessageToMyself) {
      event.preventDefault();
    }
  }, [isAuthenticated, openAuthModal, isMessageToMyself]);

  return (
    <div className={styles.buttons_wrapper}>
      <Button
        className={styles.chat}
        appearance="primary"
        buttonColor={isMessageToMyself ? 'grey' : 'blue'}
        buttonSize="m"
        fullWidth
        href={`/${profileBasePath}/chat/user/${userProfile.id}?${chat_param_ad_id}=${adID}`}
        as="link"
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleChatButtonClick(event)}
      >
        {isDesktop ? t('adv:contacts.writeMessage') : t('adv:contacts.write')}
      </Button>
      <Button
        appearance="primary"
        buttonColor={isMessageToMyself || isHiddenPhoneNumber ? 'grey' : 'green'}
        buttonSize="m"
        fullWidth
        className={styles.phone}
        ref={phoneButtonRef}
        onClick={togglePhoneNumber}
        as={isDesktop ? 'button' : 'link'}
        href={(!isDesktop && isAuthenticated && !isHiddenPhoneNumber && !isMessageToMyself) ? `tel:${userProfile.phone_number}` : undefined}
      >
        {isDesktop
          ? (showPhoneNumber && !isHiddenPhoneNumber)
            ? userProfile.phone_number
            : t('adv:contacts.viewPhone')
          : t('adv:contacts.call')}
      </Button>
      {userProfile.telegram && (
        <Button
          appearance="primary"
          buttonColor="grey"
          buttonSize="m"
          fullWidth
          as="link"
          href={`https://t.me/${userProfile.telegram}`}
          target="_blank"
          className={styles.telegram}
        >
          {t('adv:contacts.telegram')}
        </Button>
      )}
    </div>
  );
};
