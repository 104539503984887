import React, { useCallback } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { ModalDesktop, ModalMobile } from '@/modules/shared/components/modal';
import { useMatchMedia } from '@/modules/shared/hooks';
import { useAuthContext, useOpenAuthModal } from '@/modules/auth';
import { Button } from '@/modules/shared/components';
import styles from './confirmation-modal.module.scss';
import stylesMain from './complaint-modal.module.scss';

export const ConfirmationModal = ({
  isOpen, onClose, onCloseModal,
}) => {
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const { isAuthenticated } = useAuthContext();
  const openAuthModal = useOpenAuthModal();
  const { t } = useTranslation('');

  const title = isAuthenticated
    ? t('common:complaintModal.confirmation.titleSubmit')
    : t('common:complaintModal.confirmation.titleReady');

  const handleAuth = useCallback(() => {
    openAuthModal('login');
    onClose();
  }, [openAuthModal, onClose]);

  const renderContent = () => (
    <div className={styles.container}>
      <p className={styles.description}>
        {isAuthenticated
          ? t('common:complaintModal.confirmation.messageReview')
          : t('common:complaintModal.confirmation.messageLogin')}
      </p>
      {!isAuthenticated && (
        <Button
          appearance="primary"
          buttonColor="blue"
          buttonSize="s"
          className={styles.button}
          onClick={handleAuth}
        >
          {t('common:complaintModal.confirmation.login')}
        </Button>
      )}
    </div>
  );

  return (
    <>
      {(isTablet || isDesktop) && (
        <ModalDesktop
          open={isOpen}
          title={title}
          onClose={onCloseModal}
          titleJustifyContent="start"
          classNameHeaderContainer={styles.modal_header}
          showBackButton={false}
        >
          {renderContent()}
        </ModalDesktop>
      )}
      {isMobile && (
        <ModalMobile
          open={isOpen}
          fullHeight
          headerBordered
          showCloseButton
          onClose={onCloseModal}
          contentStyles={stylesMain.contentModal}
          showBackButton={false}
        >
          <h3>{title}</h3>
          {renderContent()}
        </ModalMobile>
      )}
    </>
  );
};
