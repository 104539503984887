import { Loader } from '@googlemaps/js-api-loader';
import { MapContainer } from '@/modules/locations';
import s from './product-info.module.scss';

type MapWrapperProps = {
  address: string;
};

export const MapWrapper = ({ address }: MapWrapperProps) => {
  const loader = new Loader({
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY as string,
    version: 'weekly',
    libraries: ['places', 'maps', 'marker'],
  });

  return (
    <div className={s.map_container}>
      <MapContainer
        address={address}
        loader={loader}
        isAdPage
      />
    </div>
  );
};
