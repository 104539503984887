import React, { useCallback, useState } from 'react';
import { useMatchMedia } from '@/modules/shared/hooks';
import useTranslation from 'next-translate/useTranslation';
import { Button, Modal } from '@/modules/shared/components';
import { getDateOfCreation } from '@/modules/shared/helpers';
import { IReviews } from '@/modules/shared/types';
import { FeedbackTextarea } from './feedback-textarea.component';
import { FeedbackTextInsideResponse } from './feedback-text-inside-response.component';
import s from './review-item.module.scss';

export const AnswerToReview = ({ item }: { item: IReviews }) => {
  const { t, lang } = useTranslation();
  const { isMobile } = useMatchMedia();
  const [isCommentOpen, setIsCommentOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsCommentOpen(false);
  }, []);

  return (
    <>
      {/* показываем разные кнопки на мобильном и компьютере, если еще нет ответа на отзыв */}
      {!isMobile && !item.recipient_answer && (
        <div className={s.date_and_reply}>
          <p className={s.date}>
            {getDateOfCreation({ date: item.created_at, t, lang })}
          </p>
          <Button
            className={s.reply}
            appearance="secondary"
            buttonColor="blue"
            buttonSize="m"
            onClick={() => setIsCommentOpen(true)}
          >
            {t('rating-page:reply')}
          </Button>
        </div>
      )}
      {isMobile && !item.recipient_answer && (
        <Button
          appearance="primary"
          buttonColor="blue"
          buttonSize="xs"
          className={s.reply}
          onClick={() => setIsCommentOpen((prev) => !prev)}
        >
          {t('rating-page:reply')}
        </Button>
      )}
      {isMobile ? (
        <Modal
          almostFullHeight
          open={isCommentOpen}
          onClose={handleClose}
          showBackButton={false}
        >
          <FeedbackTextInsideResponse item={item} />
          <h4 className={s.your_answer}>{t('rating-page:yourAnswer')}</h4>
          <FeedbackTextarea
            handleClose={handleClose}
            review_id={String(item.id)}
          />
        </Modal>
      ) : (
        isCommentOpen && (
          <FeedbackTextarea
            handleClose={handleClose}
            review_id={String(item.id)}
          />
        )
      )}
    </>
  );
};
