// example time: 2024-09-18T11:14:57.747239+00:00
import { useMemo, useState, useEffect } from 'react';
import { getDeclension } from '@/modules/shared/helpers';

export const useLastOnline = (time:string, t, lang:string) => {
  const lastOnlineDate = new Date(time);
  const [currentTime, setCurrentTime] = useState<Date>(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const isYesterday = (currentTime.getDate() - lastOnlineDate.getDate()) === 1
    && currentTime.getMonth() === lastOnlineDate.getMonth()
    && currentTime.getFullYear() === lastOnlineDate.getFullYear();

  const isToday = currentTime.toDateString() === lastOnlineDate.toDateString();


  const localTime = lastOnlineDate.toLocaleTimeString(lang, {
    hour: '2-digit',
    minute: '2-digit',
  });

  const dayMonthAndYear = lastOnlineDate.toLocaleDateString(undefined, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const diffInMinutes = Math.floor((currentTime.getTime() - lastOnlineDate.getTime()) / (1000 * 60));
  const minutLastOnline = useMemo(() => {
    if (diffInMinutes <= 0) {
      return t('common:lastTimeOnline.lessOneHour.none');
    }

    return (
      getDeclension({
        count: diffInMinutes,
        t,
        singular: 'common:lastTimeOnline.lessOneHour.one',
        few: 'common:lastTimeOnline.lessOneHour.few',
        many: 'common:lastTimeOnline.lessOneHour.many',
      })
    );
  }, [t, diffInMinutes, currentTime]);


  if (isToday) {
    if (diffInMinutes < 60) {
      return minutLastOnline;
    }
    return t('common:lastTimeOnline.todayAt', { localTime });
  }


  if (isYesterday) {
    return t('common:lastTimeOnline.yesterday', { localTime });
  }

  return t('common:lastTimeOnline.moreThanOneDayAgo', { dayMonthAndYear });
};
