import { useQuery } from '@tanstack/react-query';
import { SettingsApi } from '../api';

export const useGetPrivacies = () => {
  const {
    data: privacies,
  } = useQuery({
    queryKey: ['settings-privacies'],
    queryFn: async () => {
      const data = await SettingsApi.getPrivacies();
      return data;
    },
  });

  return { privacies };
};
