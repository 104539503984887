import { listQueryFactory, buildListQueryKeyFactory } from '@/modules/shared/queries';
import { ADS_LIST_QUERY_KEY } from '../consts';
import { AdsApi } from '../api';

const queryMainKeys = [ADS_LIST_QUERY_KEY];
export const buildAdsListQueryKey = buildListQueryKeyFactory(queryMainKeys);
export const useAdsListQuery = listQueryFactory(
  AdsApi.list,
  queryMainKeys,
);
