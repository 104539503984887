import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { kebabCase } from 'lodash';
import { modalTypeParam } from '@/modules/auth/consts';
import { EXTRA_QUERY_NAMES } from '@/modules/auth/hooks/constants';
import { useCleanUrl } from './use-clean-url.hook';


export const useOpenAuthModal = () => {
  const router = useRouter();
  const url = useCleanUrl();

  return useCallback((modalType: string, extraQuery?: Partial<Record<typeof EXTRA_QUERY_NAMES[number], string>>) => {
    const { pathname, search } = url;
    const updatedSearch = new URLSearchParams(search);
    updatedSearch.set(modalTypeParam, kebabCase(modalType));

    EXTRA_QUERY_NAMES.forEach((value) => {
      if (extraQuery?.[value]) {
        updatedSearch.set(value, extraQuery[value]!);
      } else {
        updatedSearch.delete(value);
      }
    });

    router.push(`${pathname}?${updatedSearch.toString()}`, undefined, { shallow: true, scroll: false });
  }, [router, url]);
};
