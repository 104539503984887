import { ComponentType, Component } from 'react';

export const withErrorBoundary = <T extends object>(ChildComponent: ComponentType<T>): ComponentType<T> => {
  class ErrorBoundary extends Component<T, { hasError: boolean }> {
    constructor(props: T) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
      return { hasError: true };
    }

    componentDidCatch(error, info) {
      console.error('Ошибка в фильтре', error, info.componentStack);
    }

    render() {
      if (this.state.hasError) {
        return null;
      }

      return <ChildComponent {...this.props} />;
    }
  }

  return ErrorBoundary;
};
