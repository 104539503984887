import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';

import { ModalMobile } from '@/modules/shared/components';
import { Footer } from '../footer';
import type { LayoutProps } from './types';

import styles from './layout.module.scss';


export const MobileLayout = ({
  hasNotAppliedFilters,
  hasFilters,
  handleBtnFiltering,
  handleReset,
  onChangeVisible,
  isVisible, children,
}: LayoutProps) => {
  const { t } = useTranslation();

  return (
    <ModalMobile
      footer={(
        <Footer
          hasNotAppliedFilters={hasNotAppliedFilters}
          hasFilters={hasFilters}
          onClickApply={handleBtnFiltering}
          onClickReset={handleReset}
        />
          )}
      onClose={onChangeVisible}
      title={t('filters-components:title.title')}
      showBackButton={false}
      open={isVisible}
    >
      <div className={clsx(styles.container, styles.mobile)}>
        <div className={styles.wrapper}>{children}</div>
      </div>
    </ModalMobile>
  );
};
