import { Dispatch, FC } from 'react';

import { useMatchMedia } from '@/modules/shared/hooks/use-match-media.hook';

import { DesktopLayout, MobileLayout } from '../components/layout';


export const withFilterLayout = <T extends object>(Component: FC<T>) => {
  const Wrapper: FC<T & {
    handleApplyFilter: () => void,
    handleReset: () => void,
    isVisible?: boolean,
    onChangeVisible: Dispatch<boolean>,
    hasFilters?: boolean,
    hasNotAppliedFilters?: boolean,
  }> = ({
    onChangeVisible,
    isVisible,
    handleApplyFilter,
    handleReset,
    hasFilters,
    hasNotAppliedFilters,
    ...props
  }) => {
    const { isDesktop } = useMatchMedia();

    if (isDesktop) {
      return (
        <DesktopLayout
          hasFilters={hasFilters}
          hasNotAppliedFilters={hasNotAppliedFilters}
          handleBtnFiltering={handleApplyFilter}
          handleReset={handleReset}
        >
          <Component {...props as T} />
        </DesktopLayout>
      );
    }

    return (
      <MobileLayout
        isVisible={isVisible}
        onChangeVisible={onChangeVisible}
        hasFilters={hasFilters}
        hasNotAppliedFilters={hasNotAppliedFilters}
        handleBtnFiltering={handleApplyFilter}
        handleReset={handleReset}
      >
        <Component {...props as T} />
      </MobileLayout>
    );
  };
  Wrapper.displayName = `withFilterLayout(${Component.displayName || Component.name})`;

  return Wrapper;
};
