import React, { memo, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Sorting } from '@/modules/shared/components';
import styles from './ads-infinity-page.module.scss';

interface AdsSortingProps {
  order: string;
  onOrderChange: (value: string) => void;
}

export const AdsSorting = memo(({ order, onOrderChange }: AdsSortingProps) => {
  const { t } = useTranslation();

  const options = useMemo(() => [
    { value: '-created_at', label: t('adv:filters.sortingModal.newest') },
    { value: 'created_at', label: t('adv:filters.sortingModal.oldest') },
    { value: '-price', label: t('adv:filters.sortingModal.decreasing') },
    { value: 'price', label: t('adv:filters.sortingModal.increasing') },
  ], [t]);

  return (
    <div className={styles.sorting_wrapper}>
      <Sorting
        value={order}
        setValue={onOrderChange}
        options={options}
        showBackButton={false}
        shouldDisabled
      />
    </div>
  );
});
