import { api } from '@/modules/shared/api';
import { endpoints } from './endpoints';
import { Passwords, PrivaciesTypes } from '../types';

export const SettingsApi = {
  changePassword: async (passwords: Passwords) => {
    const { data } = await api.post(endpoints.changePassword(), passwords);
    return data;
  },

  deleteAccount: async (reason: string) => {
    const { data } = await api.delete(endpoints.deleteAccount(), {
      data: { reason },
    });
    return data;
  },

  getPrivacies: async () => {
    const { data } = await api.get(endpoints.privacies(), {});
    return data;
  },

  changePrivacies: async (privacies: PrivaciesTypes) => {
    const { data } = await api.patch(endpoints.privacies(), privacies);
    return data;
  },
};
