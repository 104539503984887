import React, { useCallback, useEffect } from 'react';
import { useSetQueryParams } from '@/modules/shared/hooks';
import { useRouter } from 'next/router';
import { useHomeCity } from '@/modules/locations/hooks';
import { SeoCatalog } from '@/modules/seo';
import { AdsInfiniteList } from './ads-infinity-list.component';


export const AdsInfiniteListPage = () => {
  const setQueryParams = useSetQueryParams();
  const { cityId } = useHomeCity();

  const handleOrderChange = useCallback((value: string) => {
    setQueryParams({ order: value });
  }, [setQueryParams]);
  const { order } = useRouter().query;

  useEffect(() => () => {
    sessionStorage.removeItem('selected_category');
  }, []);

  return (
    <>
      <SeoCatalog />
      <AdsInfiniteList
        cityId={cityId}
        order={order as string || '-created_at'}
        onOrderChange={handleOrderChange}
      />
    </>
  );
};
