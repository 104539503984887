import { MeApi } from '@/modules/me/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import i18n from '@/lib/i18n';

export const useUpdateMeMutation = () => {
  const client = useQueryClient();
  const { locale } = useRouter();

  return useMutation({
    mutationFn: MeApi.updateUserData,
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: ['me'],
      });
      toast.success(i18n.toast.Successfully[locale]);
    },
    onError: () => toast.error('Error fetching user data!'),
  });
};
