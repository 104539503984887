import { useCallback, useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { ModalDesktop, ModalMobile } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { useAddEmail } from '@/modules/me';
import s from './modal.module.scss';
import { InnerButton, InputEmail } from './inner-components';
import { useSetFocus } from '../helpers';

type AddEmailModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onContinue: (email: string) => void;
};

export const AddEmailModal = ({
  isOpen, onClose, onContinue,
}: AddEmailModalProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const { isFocus, setFocus } = useSetFocus();
  const {
    mutate: update, isSuccess, error: apiError, isError,
  } = useAddEmail();

  const handleContinue = useCallback(() => {
    update(value);
  }, [value]);

  useEffect(() => {
    if (isError && apiError instanceof AxiosError) {
      const responseErrors = apiError.response?.data?.code;
      if (responseErrors?.includes('ALREADY_EXISTS')) {
        setError(t('forms:validationErrors.email_linked'));
      }
      if (apiError.response?.data?.detail?.includes('Request was throttled.')) {
        setError(t('forms:validationErrors.throttled'));
      }
    }

    if (isSuccess) {
      onContinue(value);
    }
  }, [isSuccess, apiError, isError]);

  return isMobile ? (
    <ModalMobile
      open={isOpen}
      fullHeight
      headerBordered
      showBackButton={false}
      onClose={onClose}
      contentStyles={s.content}
      title={t('userAccount:add_email')}
    >
      <InputEmail
        value={value}
        setValue={setValue}
        error={error}
        setError={setError}
        onFocus={setFocus}
      />
      <div className={clsx(s.button_wrapper, {
        [s.button_wrapper_up]: isFocus,
      })}
      >
        <InnerButton
          text={t('userAccount:continue')}
          disabled={!value || !!error}
          onClick={handleContinue}
          className={s.continue_button}
        />
      </div>
    </ModalMobile>
  ) : (
    <ModalDesktop
      open={isOpen}
      onClose={onClose}
      title={t('userAccount:add_email')}
      titleJustifyContent="start"
    >
      <div className={s.content}>
        <InputEmail
          value={value}
          setValue={setValue}
          error={error}
          setError={setError}
        />
        <div className={s.buttons}>
          <InnerButton
            text={t('userAccount:continue')}
            disabled={!value || !!error}
            onClick={handleContinue}
            className={s.continue_button}
          />
        </div>
      </div>
    </ModalDesktop>
  );
};
