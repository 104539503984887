import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';


export const useTranslateLocation = (city: string, district?: string) => {
  const { t } = useTranslation('locations');
  return useMemo(() => {
    const locations = [t(`${city}.${city}`)];
    if (district) {
      locations.push(t(`${city}.districts.${district}`));
    }
    return locations.join(', ');
  }, [city, district, t]);
};
