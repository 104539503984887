import React from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Button } from '@/modules/shared/components';
import { BUSINESS_LINK } from '@/modules/shared/const';

import styles from '../../auth-modal.module.scss';

export const CompleteRegistrationDone = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.title}>
        <h2>{t('auth:completeRegistration.done.title')}</h2>
        <div className={styles.subTitle}>{t('auth:completeRegistration.done.description')}</div>
      </div>
      <Button
        href={BUSINESS_LINK}
        target="_blank"
        as="link"
        fullWidth
        appearance="primary"
        buttonColor="green"
        buttonSize="s"
      >
        {t('auth:completeRegistration.done.more')}
      </Button>
      <div className={styles.centered}>
        <img width={165} height={165} src="/assets/images/rabbit-fun.svg" alt="registration-done" />
      </div>
    </>
  );
};
