import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { Status } from './types';
import s from './creation-location.module.scss';
import { NO_PLACE_STATUSES } from './const';

type SearchErrorProps = {
  status: Status | '';
};

const Message = ({ status }: SearchErrorProps) => {
  const { t } = useTranslation('events');

  if (NO_PLACE_STATUSES.includes(status)) {
    return <p>{t('createEvents.searchErrors.no_place')}</p>;
  }

  if (['OVER_QUERY_LIMIT', 'REQUEST_DENIED'].includes(status)) {
    return (
      <p>
        {t('createEvents.searchErrors.app_error')}
        <Link href="/support" target="_blank">
          {t('createEvents.searchErrors.support')}
        </Link>
      </p>
    );
  }

  return (
    <p>
      {t('createEvents.searchErrors.goggle_error.part1')}
      <Link
        href="https://status.cloud.google.com/maps-platform/products/i3CZYPyLB1zevsm2AV6M/history"
        target="_blank"
        rel="noreferrer"
      >
        {t('createEvents.searchErrors.goggle_error.part2')}
      </Link>
      {t('createEvents.searchErrors.goggle_error.part3')}
    </p>
  );
};

export const SearchError = ({ status }: SearchErrorProps) => (
  status === '' || status === 'OK'
    ? null : (
      <div role="alert" className={s.error}>
        <Message status={status} />
      </div>
    )
);
