import React, { useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useMatchMedia } from '@/modules/shared/hooks';
import { useGlobalData } from '@/context/GlobalContext';
import { Category, useCategoryTop, useCategories } from '@/modules/categories';
import { CategoryCard } from '../category-card';
import styles from './category-block-home.module.scss';


const orderedCategories = [
  'transport', 'personal-items', 'real-estate', 'electronics', 'services', 'home', 'animals',
];

export const CategoryBlockHome = () => {
  const { getCategoryByCode } = useCategories();
  const { data } = useCategoryTop(11);
  const { t } = useTranslation('');
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const { setIsShowCategoriesModal } = useGlobalData();

  const sortedDataCards = useMemo(() => {
    if (!data) return [];
    // Создаем объекты для категорий в нужном порядке
    const filteredDataCards = orderedCategories
      .map((category) => data?.find((card) => card.codename === category))
      .filter((card): card is Category => !!card);

    return filteredDataCards
      .map((item) => getCategoryByCode(item.codename))
      .filter((card): card is Category => !!card);
  }, [data, getCategoryByCode]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        {sortedDataCards.slice(0, 4).map((card) => (
          <CategoryCard data={card} key={card.id} />
        ))}
      </div>
      <div className={styles.row}>
        {sortedDataCards.slice(4).map((card) => (
          <CategoryCard data={card} key={card.id} />
        ))}
        <button
          type="button"
          data-testid="title_all"
          className={styles.all}
          onClick={() => setIsShowCategoriesModal(true)}
        >
          {(isMobile || isTablet) && <p>{t('common:showAllCategories')}</p>}
          {isDesktop && <p>{t('common:allCategories')}</p>}
        </button>
      </div>
    </div>
  );
};
