import { useContext } from 'react';

import { CategoriesContext } from '../providers/categories.provider';

export const useCategories = () => {
  const { enhancedCategories, ...ctx } = useContext(CategoriesContext);

  return {
    ...ctx,
    categories: enhancedCategories,
  };
};
