import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Label as BaseLabel } from '@/modules/shared/components';
import { CreateAdTypes } from '../../../types';

interface LabelProps {
  label: string;
  name: keyof CreateAdTypes;
  required?: boolean;
}

export const Label = ({ label, name, required = false }: LabelProps) => {
  const { control } = useFormContext<CreateAdTypes>();
  const value = useWatch<CreateAdTypes>({ name });

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field }) => (
        <BaseLabel
          label={label}
          checked={Boolean(value)}
          onChange={field.onChange}
        />
      )}
    />
  );
};
