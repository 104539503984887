import React, { useMemo } from 'react';
import clsx from 'clsx';
import { UserAvatar, UserCompanyRedirect } from '@/modules/shared/components';
import { AdLink } from '@/modules/ads/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import type { Announcement, UserContact } from '../../types';
import styles from './chat-image.module.scss';

export type ChatImageProps = {
  announcement: Announcement | null;
  recipient: UserContact;
  link?: boolean;
  oneChat?: boolean;
};

export const ChatImage = ({
  announcement, recipient, link = false, oneChat = false,
}: ChatImageProps) => {
  const { isDesktop } = useMatchMedia();

  const displayUserName = useMemo(() => {
    if (recipient?.company) {
      return recipient?.company?.name;
    }
    return recipient?.full_name;
  }, [recipient]);

  const displaySrcAvatar = useMemo(() => {
    if (recipient?.company) {
      return recipient?.company?.logo?.thumbnail ?? null;
    }
    return recipient?.thumbnail ?? null;
  }, [recipient]);


  if (announcement) {
    return (
      <div className={styles.wrapper}>
        {link ? (
          <AdLink
            ad={announcement}
            className={clsx(styles.container, styles.announcement)}
          >
            <img
              src={announcement.main_picture.thumbnail}
              alt={announcement.title}
            />
          </AdLink>
        ) : (
          <div className={clsx(styles.container, styles.announcement, {
            [styles.container_one_chat]: oneChat,
          })}
          >
            <img
              src={announcement.main_picture.thumbnail}
              alt={announcement.title}
            />
          </div>
        )}
        {announcement?.user === recipient.id && (
          <div className={styles.announcement_not_my}>
            <UserAvatar
              diameter={(isDesktop || !oneChat) ? '24px' : '16px'}
              userName={displayUserName}
              srcAvatar={displaySrcAvatar}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={clsx(styles.container, {
      [styles.container_one_chat]: oneChat,
      [styles.container_one_chat_no_ad]: oneChat,
    })}
    >
      {link ? (
        <UserCompanyRedirect
          data={{ user: recipient.id, company: recipient?.company_id }}
        >
          <UserAvatar
            diameter={isDesktop ? '64px' : oneChat ? '32px' : '48px'}
            userName={displayUserName}
            srcAvatar={displaySrcAvatar}
          />
        </UserCompanyRedirect>
      ) : (
        <div>
          <UserAvatar
            diameter={isDesktop ? '64px' : oneChat ? '32px' : '48px'}
            userName={displayUserName}
            srcAvatar={displaySrcAvatar}
          />
        </div>
      )}
    </div>
  );
};
