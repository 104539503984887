import React from 'react';
import Link from 'next/link';
import { Category } from '@/modules/categories';
import styles from './mini-category-card.module.scss';

type CategoryCardProps = {
  data: Category;
};

export const MiniCategoryCard = ({ data }: CategoryCardProps) => {
  const {
    icon, title, pathname, codename,
  } = data;

  return (
    <Link href={pathname} data-testid={`tile_${codename}`} className={styles.link}>
      <div className={styles.mini_category_card}>
        <div className={styles.icon}>
          <img src={icon} alt={title} />
        </div>
        <p className={styles.text}>{title}</p>
      </div>
    </Link>
  );
};
