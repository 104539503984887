import { Value } from 'react-phone-number-input';
import { InputPhone } from '@/modules/shared/components/inputs/input-phone';
import s from '../modal.module.scss';

type PhoneInputProps = {
  value: string
  handleChange: (e: Value | undefined) => void;
  handleBlur: () => void;
  error: string;
  onFocus?: () => void;
};

export const PhoneInput = ({
  value, handleChange, handleBlur, error, onFocus,
}: PhoneInputProps) => (
  <div className={s.input_wrapper}>
    <InputPhone
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={() => onFocus?.()}
      placeholder="+66"
      international
      style={error ? { borderColor: 'var(--bzr-secondary-error)' } : {}}
    />
    {error && <p className={s.error}>{error}</p>}
  </div>
);
