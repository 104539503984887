import React from 'react';
import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import styles from './hidden-radiobutton.module.scss';

interface HiddenRadiobuttonProps {
  name: string;
  value: string;
  label: string;
  required?: boolean;
}

// https://www.figma.com/design/3Sn1LsLpflBNE0ywoEXipV/Bazaar?node-id=664-130054&node-type=FRAME&t=7zw16w7f2gxKRhDq-0

export const HiddenRadiobutton = (props: HiddenRadiobuttonProps) => {
  const {
    name, value, label, required = false,
  } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field }) => (
        <button
          type="button"
          className={clsx(styles.radioContainer, {
            [styles.checked]: field.value === value,
          })}
          onClick={() => field.onChange(value)}
        >
          <input
            type="radio"
            {...field}
            value={value}
            checked={field.value === value}
          />
          {label}
        </button>
      )}
    />
  );
};
