import Link from 'next/link';
import { useRouter } from 'next/router';

import { withWorkOnlyByFeature } from '../hoc/withWorkOnlyByFeature';
import styles from './menu-banner.module.scss';

export const MenuBlackFridayBanner = withWorkOnlyByFeature(() => {
  const router = useRouter();

  return (
    <Link
      shallow
      href={{
        pathname: router.pathname,
        query: {
          ...router.query,
          'black-friday': 1,
        },
      }}
    >
      <div className={styles.banner} />
    </Link>
  );
});
