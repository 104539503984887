import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import useTranslation from 'next-translate/useTranslation';
import { useCustomWindowWidth } from '@/modules/shared/hooks';
import { useUserProfile } from '@/modules/user-profile/queries';
import { useMeContext } from '@/modules/me';
import { useUserCompanyById } from '@/modules/company/queries';
import { SuggestionFeedbackTypes } from '@/modules/my-feedback/types';
import { useSuggestionFeedbackList } from '@/modules/my-feedback/queries';
import { ReviewFormTypes, AnnouncementReviewData } from '../types';
import { useCreateReviewMutation } from '../mutations';
import { CustomModal } from './custom-modal.component';
import { StepOne, StepTwo, StepThree } from './components';
import styles from './leave-feedback-modal.module.scss';


interface Props {
  isOpen: boolean;
  announcementId: number;
  announcementData?: SuggestionFeedbackTypes | Omit<AnnouncementReviewData, 'full_name'>;
  recipient: number;
  onClose: () => void;
  defaultRating?: '1' | '2' | '3' | '4' | '5';
}
/**
 * @param {number} props.announcementId - нужно передать id объявления, на которое оставляем отзыв
 * * {SuggestionFeedbackTypes | Omit<AnnouncementReviewData, 'full_name'>} [announcementData] - Данные объявления,
 которые нужны для отображения информации во 2 шаге в модальном окне.
 * @param {number} props.defaultRating - на странице "мои отзывы" в списке предложений есть звезды,
 * можно вызвать модальное окно кликом по ним и сразу передать оценку
 * */

export const LeaveFeedbackModal = (props: Props) => {
  const {
    isOpen, announcementId, announcementData, recipient, onClose, defaultRating,
  } = props;

  const { t } = useTranslation('review');
  const [step, setStep] = useState<1 | 2 | 3>(1);
  const { mutateAsync: createReview, isSuccess } = useCreateReviewMutation();
  const { refetch } = useSuggestionFeedbackList();

  // вызываем на верхнем уровне, чтобы ко второму экрану данные подгрузились
  const { me } = useMeContext();
  const { data: profileData, isFetching } = useUserProfile(recipient, { refetchOnWindowFocus: false });
  const { data: companyData, isLoading } = useUserCompanyById(profileData?.company_id);


  const adDetail: AnnouncementReviewData | undefined = useMemo(() => {
    const fullName = companyData?.name || profileData?.full_name || '';

    if (announcementData) {
      return {
        full_name: fullName,
        id: announcementData.id,
        title: announcementData.title,
        price_type: announcementData.price_type,
        price: announcementData.price || null,
        main_picture: {
          thumbnail: announcementData.main_picture.thumbnail || '',
        },
      };
    }

    return undefined;
  }, [announcementData, profileData, companyData]);


  const formMethods = useForm<ReviewFormTypes>({
    defaultValues: {
      announcement: announcementId,
      recipient,
      deal_state: 'completed',
      rating: defaultRating ?? undefined,
    },
  });
  const { handleSubmit, setValue, reset } = formMethods;

  useEffect(() => {
    if (me) {
      setValue('reviewer', me.id);
    }
  }, [me]);
  useEffect(() => {
    if (defaultRating) {
      setValue('rating', defaultRating);
    }
  }, [defaultRating]);

  const onsubmit: SubmitHandler<ReviewFormTypes> = async (data) => {
    if (data.comment?.length === 0) {
      delete data.comment;
    }
    const response = await createReview(data);

    if (response.id) { // если создался отзыв, вернется объект с отзывом
      setStep(3);
      return;
    }
    if (response?.includes('ALREADY_REVIEWED')) {
      toast.info(t('review:reviewed'));
    }
  };

  const handleClickBackMobile = useCallback(() => {
    if (step === 2) {
      setStep(1);
    }
  }, [step]);

  const handleClose = useCallback(() => {
    reset();
    onClose();
    setStep(1);

    if (isSuccess) refetch();
  }, [onClose, reset, isSuccess, refetch]);

  const { windowWidth } = useCustomWindowWidth();
  const isShowButton = Boolean(step === 2 && windowWidth && windowWidth < 768);

  return (
    <CustomModal
      open={isOpen}
      className={styles.modal}
      fullHeight
      showBackButton={isShowButton}
      onClickBack={handleClickBackMobile}
      title={isShowButton && t('yourReview')}
      showCloseButton
      onClose={handleClose}
    >
      <FormProvider {...formMethods}>
        <form
          className={styles.leave_feedback_form}
          onSubmit={handleSubmit(onsubmit)}
        >
          {step === 1 && <StepOne setStep={setStep} />}
          {step === 2 && (
            <StepTwo
              isLoading={isFetching || isLoading}
              setStep={setStep}
              adDetail={adDetail}
            />
          )}
          {step === 3 && <StepThree />}
        </form>
      </FormProvider>
    </CustomModal>
  );
};
