import { memo } from 'react';

import { Category, SubCategory, UnderSubCategory } from '@/modules/categories';
import { compose } from '@/modules/shared/helpers';

import { Filters } from './types';
import { withFilterLayout } from './hoc/withFilterLayout';
import { DynamicFieldsMapper } from './components/dynamic-fields-mapper';
import { withDynamicFilters } from './hoc/withDynamicFilters';
import { MAP_TYPE_TO_FILTER } from './mapping';
import { SearchField } from './dynamic-fields/search';
import { CategoryField } from './dynamic-fields/category';

import styles from './filter.module.scss';


type FilterProps = {
  category?: Category
  subCategory?: SubCategory
  underSubCategory?: UnderSubCategory
  dynamicFilters: Filters
};

export const Filter = compose(
  memo,
  withDynamicFilters,
  withFilterLayout,
)(({
  category,
  subCategory,
  dynamicFilters,
  underSubCategory,
}: FilterProps) => (
  <div className={styles.items}>
    <CategoryField
      category={category}
      subCategory={subCategory}
      underSubCategory={underSubCategory}
    />
    {dynamicFilters.map((filterConfig, index) => (
      <DynamicFieldsMapper
        key={`${filterConfig.type}-${index}`}
        components={MAP_TYPE_TO_FILTER}
        name={filterConfig.type}
        filter={filterConfig}
      />
    ))}
    <SearchField />
  </div>
));
