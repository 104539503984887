import React from 'react';
import Link from 'next/link';
import { SvgIcon } from '../svg-icon';
import { profileBasePath } from '../../const';
import styles from './mobile-layout-icons.module.scss';

export const IconSettings = () => (
  <Link href={`/${profileBasePath}/settings`}>
    <SvgIcon
      name="setting"
      className={styles.svg_icon}
    />
  </Link>
);
