import { useCallback, useEffect, useRef } from 'react';

export const useSliderInterval = <T extends object>(
  list: Array<T>,
  setCurrentEventIndex: (value: React.SetStateAction<number>) => void,
  interval: number,
) => {
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  const startEventInterval = useCallback(() => {
    if (list && list.length > 1) {
      intervalIdRef.current = setInterval(() => {
        setCurrentEventIndex((prevIndex) => (prevIndex + 1) % list.length);
      }, interval);
    }
  }, [list, interval]);

  const stopEventInterval = useCallback(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
  }, []);

  useEffect(() => {
    startEventInterval();

    return () => {
      stopEventInterval();
    };
  }, [startEventInterval, stopEventInterval]);

  return { startEventInterval, stopEventInterval };
};
