type DateOfCreationProps = {
  date: string;
  t: (key: string, opts?: Record<string, unknown>) => string;
  lang: string;
  isUserInfo?: boolean;
};

export const getDateOfCreation = ({
  date, t, lang, isUserInfo = false,
}: DateOfCreationProps) => {
  const dateFormat = date.includes('.') ? new Date(date.split('.').reverse().join('-')) : new Date(date);

  const { day, month, year } = {
    day: dateFormat.getDate(),
    month: dateFormat.getMonth(),
    year: dateFormat.getFullYear(),
  };

  const today = new Date();
  const { todayDay, todayMonth, todayYear } = {
    todayDay: today.getDate(),
    todayMonth: today.getMonth(),
    todayYear: today.getFullYear(),
  };

  const formatter = new Intl.DateTimeFormat(lang, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  let dayToDisplay: string = '';

  if (month === todayMonth && year === todayYear) {
    if (day === todayDay) {
      dayToDisplay = t('common:today');
    }
    if (todayDay - day === 1) {
      dayToDisplay = t('common:yesterday');
    }
  }

  if (isUserInfo) return formatter.format(dateFormat);

  return dayToDisplay || formatter.format(dateFormat);
};
