import React, { useEffect } from 'react';
import clsx from 'clsx';
import { ClickAwayListener } from '@mui/base';
import { SvgIcon } from '../svg-icon';
import s from './toast.module.scss';

interface ToastProps {
  label: string;
  iconName: string;
  onClose: () => void;
  iconStyle?: React.CSSProperties;
  className?: string;
}

export const Toast = ({
  label,
  iconName,
  onClose,
  iconStyle = { fontSize: '24px' },
  className = '',
}: ToastProps) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, [onClose]);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={clsx(s.toast, className)}>
        <div className={s.toast_text}>
          <SvgIcon name={iconName} style={iconStyle} />
          <p>{label}</p>
        </div>
        <button type="button" onClick={onClose} className={s.close_button}>
          <SvgIcon name="close-icon" />
        </button>
      </div>
    </ClickAwayListener>
  );
};
