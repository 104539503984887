import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';


export const getTokenExpiration = (token: string) => {
  const decoded = jwt.decode(token);

  if (typeof decoded !== 'string' && typeof decoded?.exp === 'number') {
    return new Date(decoded.exp * 1000);
  }

  return undefined;
};

const cookies = [
  'access_token',
  'refresh_token',
  'user_id',
  'forgotPasswordEmail',
  'resetPassword',
  'resetPassword2',
];

export const cleanCookies = () => {
  cookies.forEach((el) => Cookies.remove(el));
};
