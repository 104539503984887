import { Modal } from '@/modules/shared/components';

import { useMatchMedia } from '@/modules/shared/hooks';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import styles from './not-authorized-modal.module.scss';

import BlackIcon from './assets/black-logo.svg';

export const NotAuthorizedBlackFridayModal = ({ open, onClose }: { open: boolean, onClose: (shallow: boolean) => void }) => {
  const { isMobile } = useMatchMedia();
  const router = useRouter();
  const { t, lang } = useTranslation();

  const footer = (
    <button
      className={styles.button}
      onClick={() => {
        onClose(true);
        router.push(`/?auth-step=login&redirectAfterLogin=/${lang}?black-friday=1`, undefined, { shallow: true });
      }}
    >
      {t('black-friday:notAuthorized.loginToJoin')}
    </button>
  );

  return (
    <Modal
      fullHeight
      open={open}
      onClose={onClose}
      showBackButton={false}
      closeButtonDelay={4000}
      hasHeader={false}
      footer={isMobile ? (
        footer
      ) : null}
    >
      <div className={styles.wrapper}>
        <div className={styles.title}>
          {t('black-friday:notAuthorized.title1')}
          {' '}
          <br />
          {t('black-friday:notAuthorized.title2')}
        </div>
        <div
          className={styles.title}
          style={{
            marginTop: 10,
          }}
        >
          {t('black-friday:notAuthorized.at')}
          {' '}
          <BlackIcon />
        </div>
        <div className={styles.subTitle}>
          {t('black-friday:notAuthorized.subTitle')}
        </div>
        <div className={styles.prizes}>
          <div className={styles.macbook}>
            MacBook Air
          </div>
          <div className={styles.promotion1}>
            <span>{t('black-friday:notAuthorized.blocks.promo1.title')}</span>
            <br />
            <span>{t('black-friday:notAuthorized.blocks.promo1.text')}</span>
          </div>
          <div className={styles.promotion2}>
            <span>
              {t('black-friday:notAuthorized.blocks.promo2.title')}
            </span>
            <br />
            <span>
              {t('black-friday:notAuthorized.blocks.promo2.text')}
            </span>
          </div>
          <div className={styles.dyson}>
            {t('black-friday:notAuthorized.blocks.styler.title')}
            {' '}
            <br />
            Dyson Airwrap
          </div>
          <div className={styles.iphone}>
            IPhone
            {' '}
            <br />
            {' '}
            16 Pro
          </div>
          <div className={styles.super}>
            <span>
              {t('black-friday:notAuthorized.blocks.super.title')}
            </span>
            <br />
            <span>
              {t('black-friday:notAuthorized.blocks.super.text')}
            </span>
          </div>
        </div>
        {!isMobile && footer}
      </div>
    </Modal>
  );
};
