import {
  useCallback, KeyboardEvent, ClipboardEvent,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { useMatchMedia } from '../../hooks/use-match-media.hook';
import { InputText } from '../inputs';
import s from './creation-title.module.scss';
import { MAX_LENGTH, MIN_LENGTH } from './const';
import { validateInput, validateLinksInText } from '../../helpers';

type CreationTitleProps = {
  title: string;
  placeholder: string;
  required?: boolean;
  tip?: string;
};

export const CreationTitle = ({
  title, placeholder, required = true, tip,
}: CreationTitleProps) => {
  const {
    register, setError, formState: { errors }, clearErrors,
  } = useFormContext<{ title: string }>();
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();
  const value = useWatch({ name: 'title' });

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!validateInput(e.key)) {
      e.preventDefault();
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const clipboardData = e.clipboardData.getData('Text');
    if (!validateInput(clipboardData)) {
      e.preventDefault();
    }
  };

  const handleBlur = useCallback(() => {
    if (!value) {
      setError('title', {
        type: 'required',
        message: t('forms:validationErrors.required'),
      });
      return;
    }
    if (value.length < MIN_LENGTH || value.length > MAX_LENGTH) {
      setError('title', {
        type: 'length',
        message: t('forms:validationErrors.title', { min: MIN_LENGTH, max: MAX_LENGTH }),
      });
      return;
    }
    if (validateLinksInText(value)) {
      setError('title', {
        type: 'links',
        message: t('forms:validationErrors.links_title'),
      });
      return;
    }
    clearErrors('title');
  }, [value]);

  return (
    <div className={s.field_inner}>
      <p>
        {title}
        {required && <span> *</span>}
      </p>
      <div className={s.values}>
        {tip && isMobile && <p className={s.tip}>{tip}</p>}
        <div className={s.input_wrapper}>
          <InputText
            fullWidth
            placeholder={placeholder}
            error={!!errors.title}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            maxLength={MAX_LENGTH}
            {...register('title', {
              required: true,
              onBlur: handleBlur,
            })}
          />
          <span className={tip && isMobile ? clsx(s.length, s.length_company) : s.length}>
            {`${value?.length || 0}/${MAX_LENGTH}`}
          </span>
          {errors.title && (
            <p className={s.error}>{errors.title['0'] || errors.title.message as string}</p>
          )}
        </div>
        {tip && !isMobile && <p className={s.tip}>{tip}</p>}
      </div>
    </div>
  );
};
