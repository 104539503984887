import React, { useCallback } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { getDateOfCreation, getDeclension } from '../../helpers';
import { useMatchMedia } from '../../hooks/use-match-media.hook';
import { Button } from '../button';
import s from './card-system-information.module.scss';

export type CardSystemInformationProps = {
  id: number;
  created_at: string;
  views: number;
  allowedComplain?: boolean;
  handleComplaint?: () => void;
};

export const CardSystemInformation = ({
  id, created_at, views, allowedComplain, handleComplaint,
}: CardSystemInformationProps) => {
  const { t, lang } = useTranslation();
  // const [, setIsModalOpen] = useState(false);
  const { isMobile } = useMatchMedia();

  const date = getDateOfCreation({ date: created_at, t, lang });

  const handleClick = useCallback(() => {
    // setIsModalOpen(true);
    handleComplaint?.();
  }, [handleComplaint]);

  const viewsPlural = getDeclension({
    count: views,
    t,
    singular: 'common:viewsPlural.singular',
    few: 'common:viewsPlural.few',
    many: 'common:viewsPlural.many',
  });

  return (
    <section className={s.system_information}>
      <div className={s.data}>
        <p>{`ID: ${id}`}</p>
        {isMobile ? (
          <>
            <p>{viewsPlural}</p>
            <p>{`${t('common:published')} ${date}`}</p>
          </>
        ) : (
          <>
            <p>{`${t('common:published')} ${date}`}</p>
            <p>{`${t('common:views')}: ${views}`}</p>
          </>
        )}
      </div>
      <Button
        appearance="primary"
        buttonColor="grey"
        buttonSize="s"
        className={s.complaint_button}
        onClick={handleClick}
        disabled={!allowedComplain}
      >
        {t('common:complaintTitle')}
      </Button>
    </section>
  );
};
