import useTranslation from 'next-translate/useTranslation';
import { useCityPrefix } from '@/context/CityPrefixContext';
import { BreadCrumbs } from '@/modules/shared/components';

export const AddEventBreadCrumbs = () => {
  const { t } = useTranslation();
  const { cityPrefix } = useCityPrefix();

  const PATHS = [
    {
      title: t('common:tabBar.events'),
      codename: 'events',
      pathname: `/${cityPrefix}/posters`,
    },
    {
      title: t('events:eventCreation'),
      codename: 'event_creation',
      pathname: `/${cityPrefix}/events/addevents`,
    },
  ];

  return <BreadCrumbs data={PATHS} />;
};
