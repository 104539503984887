import useTranslation from 'next-translate/useTranslation';
import { useSearchParams } from 'next/navigation';
import { useMutation } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, InputText, Modal, SvgIcon,
} from '@/modules/shared/components';
import {
  MemoryRouter, NavigateFunction, Route, Routes, useLocation,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { RouterListener } from '../router-listener.component';
import styles from '../../auth-modal.module.scss';
import { useCloseAuthModal, useOpenAuthModal } from '../../../../hooks';
import { AuthApi } from '../../../../api';

export const ForgotByEmail = () => {
  const { t } = useTranslation();
  const closeModal = useCloseAuthModal();
  const setModalType = useOpenAuthModal();
  const params = useSearchParams();

  const [router, setCurrentRouter] = useState<{
    location: ReturnType<typeof useLocation>,
    nav: NavigateFunction
  }>();

  const initialEmail = params.get('email') || '';

  const {
    mutateAsync,
  } = useMutation({
    mutationFn: AuthApi.resetPasswordRequest,
  });

  const schema = useMemo(() => yup
    .object({
      email: yup.string()
        .email(t('forms:validationErrors.invalidEmail'))
        .required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.email') }))
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t('forms:validationErrors.invalidEmail')),
    }), [t]);

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: initialEmail,
    },
  });
  const {
    watch, register, handleSubmit, formState: { isSubmitting, errors },
  } = formMethods;

  const email = watch('email');

  const onSubmit = async (data) => {
    try {
      await mutateAsync(data);
      router?.nav('/email-sent', { replace: true });
    } catch (error) {
      if (error.response.data.validation_errors.code.includes('USER_DOESNT_EXIST')) {
        toast(t('forms:validationErrors.emailNotRegistered'), {
          type: 'error',
        });
      } else {
        toast(t('auth:forgotEmail.commonError'), {
          type: 'error',
        });
      }
    }
  };

  return (
    <Modal
      open
      showBackButton={router?.location.pathname === '/'}
      onClickBack={() => setModalType('login')}
      onClose={closeModal}
      fullHeight
      title={<img src="/assets/icons/logo-main-medium.svg" alt="Logo Bazaar" />}
    >
      <FormProvider {...formMethods}>
        <MemoryRouter initialEntries={['/']}>
          <RouterListener onRouteChange={setCurrentRouter} />
          <Routes>
            <Route
              path="/"
              element={(
                <form className={styles.content} onSubmit={handleSubmit(onSubmit)}>
                  <div className={styles.title}><h2>{t('auth:forgotEmail.title')}</h2></div>
                  <p className={styles.centeredText}>
                    {t('auth:forgotEmail.description')}
                  </p>
                  <div>
                    <InputText
                      {...register('email')}
                      fullWidth
                      placeholder={t('auth:label.email')}
                    />
                    {errors.email && <div className={styles.error}>{errors.email.message}</div>}
                  </div>
                  <div>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      fullWidth
                      appearance="primary"
                      buttonColor="green"
                      buttonSize="s"
                    >
                      {t('auth:register.continue')}
                    </Button>
                  </div>
                </form>
            )}
            />
            <Route
              path="/email-sent"
              element={(
                <div className={styles.content}>
                  <div className={styles.title}><h2>{t('auth:forgotEmailConfirmation.title')}</h2></div>
                  <p className={styles.centeredText}>
                    {t('auth:forgotEmailConfirmation.description', {
                      email,
                    })}
                  </p>
                  <div className={styles.centered}>
                    <SvgIcon
                      className={styles.emailIcon}
                      width="91"
                      height="66"
                      viewBox="0 0 91 66"
                      fill="var(--bzr-secondary-very-light-grey)"
                      name="mail_adaptive"
                    />
                  </div>
                </div>
              )}
            />
          </Routes>
        </MemoryRouter>
      </FormProvider>
    </Modal>
  );
};
