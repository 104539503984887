import { useContext } from 'react';
import { CreateAdContext } from './create-ad.context';

export const useCreateAdContext = () => {
  const context = useContext(CreateAdContext);

  if (!context) {
    throw new Error('useCreateAdContext must be used within a CreateAdContextProvider');
  }

  return context;
};
