import { NextSeo, NextSeoProps } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
import { canonical } from './canonical';

type DefaultNextSeoProps = Omit<NextSeoProps, 'canonical'>;

interface SeoProps extends DefaultNextSeoProps {
  pathToCanonical?: string;
}

export const Seo = (props: SeoProps) => {
  const { t } = useTranslation('seo');
  const { title = t('defaultTitle'), pathToCanonical } = props;
  let canonicalUrl: string | undefined;

  if (pathToCanonical) {
    canonicalUrl = canonical[pathToCanonical];
  }

  return (
    <NextSeo
      title={title}
      canonical={canonicalUrl}
      {...props}
    />
  );
};
