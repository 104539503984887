export const category = {
  'real-estate': {
    title: 'real-estate',
    residential: {
      title: 'residential',
      apartments: { title: 'apartments' },
      villa: { title: 'villa' },
      house: { title: 'house' },
      condominium: { title: 'condominium' },
    },
    commercial: {
      title: 'commercial',
      'hotels-and-resorts': { title: 'hotels-and-resorts' },
      office: { title: 'office' },
      warehouse: { title: 'warehouse' },
      'commercial-premise': { title: 'commercial-premise' },
    },
    land: { title: 'land' },
  },
  'personal-items': {
    title: 'personal-items',
    'beauty-health': {
      title: 'beauty-health',
      other: { title: 'other' },
      'appliances-and-accessories': { title: 'appliances-and-accessories' },
      fragrances: { title: 'fragrances' },
      'hygiene-products': { title: 'hygiene-products' },
      cosmetics: { title: 'cosmetics' },
      'hair-care-products': { title: 'hair-care-products' },
      'medical-products': { title: 'medical-products' },
    },
    'children-clothes': {
      title: 'children-clothes',
      'for-boys': { title: 'for-boys' },
      'for-girls': { title: 'for-girls' },
    },
    'children-goods': {
      title: 'children-goods',
      strollers: { title: 'strollers' },
      'children-furniture': { title: 'children-furniture' },
      'bicycles-and-scooters': { title: 'bicycles-and-scooters' },
      'feeding-goods': { title: 'feeding-goods' },
      'car-seats': { title: 'car-seats' },
      toys: { title: 'toys' },
      'bathing-goods': { title: 'bathing-goods' },
      'school-goods': { title: 'school-goods' },
      other: { title: 'other' },
    },
    'women-shoes': {
      title: 'women-shoes',
      shoes: { title: 'shoes' },
      'moccasins-and-loafers': { title: 'moccasins-and-loafers' },
      sandals: { title: 'sandals' },
      'flip-flops-and-slides': { title: 'flip-flops-and-slides' },
      slippers: { title: 'slippers' },
      'sports-shoes': { title: 'sports-shoes' },
      'slip-ons-and-espadrilles': { title: 'slip-ons-and-espadrilles' },
      'shoe-care': { title: 'shoe-care' },
      other: { title: 'other' },
      'heeled-sandals': { title: 'heeled-sandals' },
      'boots-and-ankle-boots': { title: 'boots-and-ankle-boots' },
      'sneakers-and-canvas-shoes': { title: 'sneakers-and-canvas-shoes' },
      'ballet-flats': { title: 'ballet-flats' },
      'clogs-and-mules': { title: 'clogs-and-mules' },
      'rubber-boots': { title: 'rubber-boots' },
    },
    'women-clothes': {
      title: 'women-clothes',
      trousers: { title: 'trousers' },
      jeans: { title: 'jeans' },
      underwear: { title: 'underwear' },
      other: { title: 'other' },
      dresses: { title: 'dresses' },
      skirts: { title: 'skirts' },
      sweaters: { title: 'sweaters' },
      'suits-and-blazers': { title: 'suits-and-blazers' },
      'wedding-dresses': { title: 'wedding-dresses' },
      'shirts-and-blouses': { title: 'shirts-and-blouses' },
      'tops-and-t-shirts': { title: 'tops-and-t-shirts' },
      swimwear: { title: 'swimwear' },
    },
    'men-clothes': {
      title: 'men-clothes',
      other: { title: 'other' },
      'sweaters-and-t-shirts': { title: 'sweaters-and-t-shirts' },
      'suits-and-blazers': { title: 'suits-and-blazers' },
      jeans: { title: 'jeans' },
      trousers: { title: 'trousers' },
      shirts: { title: 'shirts' },
      tracksuits: { title: 'tracksuits' },
      shorts: { title: 'shorts' },
      underwear: { title: 'underwear' },
    },
    'men-shoes': {
      title: 'men-shoes',
      other: { title: 'other' },
      sneakers: { title: 'sneakers' },
      shoes: { title: 'shoes' },
      'canvas-shoes': { title: 'canvas-shoes' },
      'moccasins-and-loafers': { title: 'moccasins-and-loafers' },
      'sports-shoes': { title: 'sports-shoes' },
      sandals: { title: 'sandals' },
      'flip-flops-and-slides': { title: 'flip-flops-and-slides' },
      slippers: { title: 'slippers' },
      'slip-ons-and-espadrilles': { title: 'slip-ons-and-espadrilles' },
      'shoe-care': { title: 'shoe-care' },
    },
    accessories: {
      title: 'accessories',
      other: { title: 'other' },
      jewelry: { title: 'jewelry' },
      headwear: { title: 'headwear' },
      glasses: { title: 'glasses' },
      'belts-suspenders': { title: 'belts-suspenders' },
      watches: { title: 'watches' },
      'umbrellas-raincoats': { title: 'umbrellas-raincoats' },
      'hair-accessories': { title: 'hair-accessories' },
      'ties-bowties': { title: 'ties-bowties' },
      'socks-stockings-tights': { title: 'socks-stockings-tights' },
    },
  },
  home: {
    title: 'home',
    appliances: {
      title: 'appliances',
      other: { title: 'other' },
      kitchen: { title: 'kitchen' },
      homes: { title: 'homes' },
      'climate-equipment': { title: 'climate-equipment' },
      'personal-care': { title: 'personal-care' },
    },
    furniture: {
      title: 'furniture',
      other: { title: 'other' },
      'beds-sofas-and-armchairs': { title: 'beds-sofas-and-armchairs' },
      'wardrobes-chests': { title: 'wardrobes-chests' },
      'tables-and-chairs': { title: 'tables-and-chairs' },
      'textiles-and-carpets': { title: 'textiles-and-carpets' },
      lighting: { title: 'lighting' },
      'computer-tables-and-chairs': { title: 'computer-tables-and-chairs' },
    },
    food: { title: 'food' },
    plants: { title: 'plants' },
    kitchenware: {
      title: 'kitchenware',
      other: { title: 'other' },
      serving: { title: 'serving' },
      'cooking-food': { title: 'cooking-food' },
      'food-storage': { title: 'food-storage' },
      'preparing-drinks': { title: 'preparing-drinks' },
      'household-goods': { title: 'household-goods' },
      kitchenware: { title: 'kitchenware' },
    },
    repair: {
      title: 'repair',
      construction: { title: 'construction' },
      other: { title: 'other' },
      tools: { title: 'tools' },
      'water-supply': { title: 'water-supply' },
      doors: { title: 'doors' },
      garden: { title: 'garden' },
    },
  },
  'spare-parts': {
    title: 'spare-parts',
    accessories: { title: 'accessories' },
    'spare-parts': { title: 'spare-parts' },
    tools: { title: 'tools' },
    tires: { title: 'tires' },
    trailers: { title: 'trailers' },
    equipment: { title: 'equipment' },
    'auto-chemistry': { title: 'auto-chemistry' },
    tuning: { title: 'tuning' },
    gps: { title: 'gps' },
    'trunk-hitch': { title: 'trunk-hitch' },
    'audio-video': { title: 'audio-video' },
  },
  electronics: {
    title: 'electronics',
    'audio-video': {
      title: 'audio-video',
      other: { title: 'other' },
      projects: { title: 'projects' },
      headphones: { title: 'headphones' },
      acoustics: { title: 'acoustics' },
    },
    phones: {
      title: 'phones',
      cell: { title: 'cell' },
      accessories: { title: 'accessories' },
    },
    photo: {
      title: 'photo',
      other: { title: 'other' },
      equipment: { title: 'equipment' },
      lenses: { title: 'lenses' },
      compact: { title: 'compact' },
      film: { title: 'film' },
      cameras: { title: 'cameras' },
      binoculars: { title: 'binoculars' },
    },
    tablets: {
      title: 'tablets',
      accessories: { title: 'accessories' },
      tablets: { title: 'tablets' },
      'e-books': { title: 'e-books' },
    },
    'consoles-software': {
      title: 'consoles-software',
      other: { title: 'other' },
      'game-consoles': { title: 'game-consoles' },
      'console-games': { title: 'console-games' },
      computer: { title: 'computer' },
    },
    laptops: { title: 'laptops' },
    desktops: {
      title: 'desktops',
      other: { title: 'other' },
      system: { title: 'system' },
      monoblocks: { title: 'monoblocks' },
    },
    'computer-goods': {
      title: 'computer-goods',
      accessories: { title: 'accessories' },
      other: { title: 'other' },
      monitors: { title: 'monitors' },
      keyboards: { title: 'keyboards' },
    },
  },
  hobbies: {
    title: 'hobbies',
    bicycles: { title: 'bicycles' },
    books: { title: 'books' },
    collecting: { title: 'collecting' },
    'musical-instruments': { title: 'musical-instruments' },
    'hunting-fishing': { title: 'hunting-fishing' },
    sport: {
      title: 'sport',
      other: { title: 'other' },
      'billiards-and-bowling': { title: 'billiards-and-bowling' },
      diving: { title: 'diving' },
      unity: { title: 'unity' },
      'ball-games': { title: 'ball-games' },
      board: { title: 'board' },
      rollerblading: { title: 'rollerblading' },
      tennis: { title: 'tennis' },
      fitness: { title: 'fitness' },
      nutrition: { title: 'nutrition' },
      tourism: { title: 'tourism' },
    },
    'tickets-and-travel': {
      title: 'tickets-and-travel',
      other: { title: 'other' },
      concerts: { title: 'concerts' },
      trips: { title: 'trips' },
      excursions: { title: 'excursions' },
      sport: { title: 'sport' },
    },
  },
  animals: {
    title: 'animals',
    dogs: { title: 'dogs' },
    cats: { title: 'cats' },
    birds: { title: 'birds' },
    aquarium: { title: 'aquarium' },
    'pet-supplies': { title: 'pet-supplies' },
    'other-pets': { title: 'other-pets' },
  },
  business: {
    title: 'business',
    'ready-business': { title: 'ready-business' },
    'business-equipment': { title: 'business-equipment' },
  },
  services: {
    title: 'services',
    repair: {
      title: 'repair',
      other: { title: 'other' },
      plumbing: { title: 'plumbing' },
      electrics: { title: 'electrics' },
      furniture: { title: 'furniture' },
      painting: { title: 'painting' },
      coatings: { title: 'coatings' },
      plastering: { title: 'plastering' },
      'high-rise': { title: 'high-rise' },
      forged: { title: 'forged' },
      insulation: { title: 'insulation' },
      office: { title: 'office' },
      ventilation: { title: 'ventilation' },
      renovation: { title: 'renovation' },
    },
    other: { title: 'other' },
    construction: {
      title: 'construction',
      other: { title: 'other' },
      masonrywork: { title: 'masonrywork' },
      roofing: { title: 'roofing' },
      weldingwork: { title: 'weldingwork' },
      concrete: { title: 'concrete' },
      fundamental: { title: 'fundamental' },
      'demolition-and-dismantling': { title: 'demolition-and-dismantling' },
      facade: { title: 'facade' },
      design: { title: 'design' },
      construction: { title: 'construction' },
    },
    education: {
      title: 'education',
      other: { title: 'other' },
      'foreign-languages': { title: 'foreign-languages' },
      'it-business': { title: 'it-business' },
      'design-drawing': { title: 'design-drawing' },
      'beauty-health': { title: 'beauty-health' },
      'sports-dance': { title: 'sports-dance' },
      driving: { title: 'driving' },
      'music-theater': { title: 'music-theater' },
      'child-development-speech': { title: 'child-development-speech' },
    },
    'household-services': {
      title: 'household-services',
      other: { title: 'other' },
      production: { title: 'production' },
      tailoring: { title: 'tailoring' },
      watch: { title: 'watch' },
      'dry-cleaning-washing': { title: 'dry-cleaning-washing' },
      jewelry: { title: 'jewelry' },
    },
    'cargo-transportation': {
      title: 'cargo-transportation',
      aroundtown: { title: 'aroundtown' },
      betweencities: { title: 'betweencities' },
    },
    handyman: { title: 'handyman' },
    'business-services': {
      title: 'business-services',
      other: { title: 'other' },
      finance: { title: 'finance' },
      translation: { title: 'translation' },
      insurance: { title: 'insurance' },
      legal: { title: 'legal' },
    },
    'passenger-transportation': { title: 'passenger-transportation' },
    garden: {
      title: 'garden',
      construction: { title: 'construction' },
      other: { title: 'other' },
      fountains: { title: 'fountains' },
      canopies: { title: 'canopies' },
      excavation: { title: 'excavation' },
      landscaping: { title: 'landscaping' },
      shutters: { title: 'shutters' },
    },
    'it-telecom': {
      title: 'it-telecom',
      other: { title: 'other' },
      'website-creation-and-promotion': { title: 'website-creation-and-promotion' },
      'website-po': { title: 'website-po' },
      'internet-and-network-configuration': { title: 'internet-and-network-configuration' },
      'advertising-marketing-promotion': { title: 'advertising-marketing-promotion' },
      'programming-crm-configuration': { title: 'programming-crm-configuration' },
      'website-and-app-creation': { title: 'website-and-app-creation' },
    },
    'computer-help': {
      title: 'computer-help',
      other: { title: 'other' },
      computers: { title: 'computers' },
      printers: { title: 'printers' },
      programs: { title: 'programs' },
      internet: { title: 'internet' },
    },
    'equipment-production': {
      title: 'equipment-production',
      other: { title: 'other' },
      rental: { title: 'rental' },
      'installation-maintenance': { title: 'installation-maintenance' },
    },
    advertising: {
      title: 'advertising',
      marketing: { title: 'marketing' },
      printing: { title: 'printing' },
      other: { title: 'other' },
    },
    transfer: { title: 'transfer' },
    cleaning: {
      title: 'cleaning',
      renovation: { title: 'renovation' },
      other: { title: 'other' },
      'spring-cleaning': { title: 'spring-cleaning' },
      disinfection: { title: 'disinfection' },
      window: { title: 'window' },
      easy: { title: 'easy' },
      carpet: { title: 'carpet' },
      upholstered: { title: 'upholstered' },
    },
    'holidays-organization': { title: 'holidays-organization' },
    'food-delivery': { title: 'food-delivery' },
    'pet-care': { title: 'pet-care' },
    security: { title: 'security' },
    'movers-warehouse': { title: 'movers-warehouse' },
    'beauty-health': {
      title: 'beauty-health',
      other: { title: 'other' },
      'manicure-pedicure': { title: 'manicure-pedicure' },
      'hairdresser-services': { title: 'hairdresser-services' },
      'eyelashes-eyebrows': { title: 'eyelashes-eyebrows' },
      cosmetology: { title: 'cosmetology' },
      epilation: { title: 'epilation' },
      'spa-massage': { title: 'spa-massage' },
      'tattoo-piercing': { title: 'tattoo-piercing' },
      psychology: { title: 'psychology' },
      dietetics: { title: 'dietetics' },
      'workspace-rental': { title: 'workspace-rental' },
    },
    nannies: { title: 'nannies' },
    towing: { title: 'towing' },
    'repair-tech': {
      title: 'repair-tech',
      other: { title: 'other' },
      tvs: { title: 'tvs' },
      'mobile-devices': { title: 'mobile-devices' },
      'photo-audio-video': { title: 'photo-audio-video' },
      'air-conditioning-ventilation': { title: 'air-conditioning-ventilation' },
      'washing-drying-machines': { title: 'washing-drying-machines' },
      dishwashers: { title: 'dishwashers' },
      'refrigerators-freezers': { title: 'refrigerators-freezers' },
      'cooktops-ovens': { title: 'cooktops-ovens' },
      'water-heaters': { title: 'water-heaters' },
      'coffee-machines': { title: 'coffee-machines' },
      'sewing-machines-overlocks': { title: 'sewing-machines-overlocks' },
    },
    'photo-video': { title: 'photo-video' },
  },
  job: {
    title: 'job',
    resumes: {
      title: 'resumes',
      it: { title: 'it' },
      'car-business': { title: 'car-business' },
      entertainment: { title: 'entertainment' },
      consulting: { title: 'consulting' },
      education: { title: 'education' },
      sales: { title: 'sales' },
      tourism: { title: 'tourism' },
      personnel: { title: 'personnel' },
      jurisprudence: { title: 'jurisprudence' },
      other: { title: 'other' },
      'administrative-work': { title: 'administrative-work' },
      accounting: { title: 'accounting' },
      household: { title: 'household' },
      pr: { title: 'pr' },
      security: { title: 'security' },
      'fitness,beautysalons': { title: 'fitness,beautysalons' },
    },
    vacancies: {
      'administrative-work': { title: 'administrative-work' },
      accounting: { title: 'accounting' },
      household: { title: 'household' },
      pr: { title: 'pr' },
      security: { title: 'security' },
      tourism: { title: 'tourism' },
      'fitness,beautysalons': { title: 'fitness,beautysalons' },
      other: { title: 'other' },
      it: { title: 'it' },
      'car-business': { title: 'car-business' },
      entertainment: { title: 'entertainment' },
      consulting: { title: 'consulting' },
      education: { title: 'education' },
      sales: { title: 'sales' },
      personnel: { title: 'personnel' },
      jurisprudence: { title: 'jurisprudence' },
    },
  },
  transport: {
    title: 'transport',
    cars: { title: 'cars' },
    motorcycles: { title: 'motorcycles' },
    'air-vehicles': { title: 'air-vehicles' },
    trucks: { title: 'trucks' },
    'water-vehicles': { title: 'water-vehicles' },
    motorbikes: { title: 'motorbikes' },
  },
  all: {
    title: 'all',
  },
};
