import { useCallback } from 'react';
import { toast } from 'react-toastify';
import useTranslation from 'next-translate/useTranslation';
import { api, baseEndpointV1 } from '../api';

export const usePasswordChange = () => {
  const { t } = useTranslation();

  const handlePasswordChange = useCallback(async (passwordData) => {
    if (passwordData.newPassword.length < 6) {
      toast.error(t('forms:validationErrors.passwordTooShort', { length: 6 }));
      return;
    }

    if (passwordData.newPassword !== passwordData.confirmPassword) {
      toast.error(t('forms:validationErrors.passwordsMismatch'));
      return;
    }

    const requestData = {
      old_password: passwordData.currentPassword,
      new_password: passwordData.newPassword,
      new_password2: passwordData.confirmPassword,
    };

    try {
      await api.post(`${baseEndpointV1}/users/change_pass/`, requestData);
    } catch (error) {
      console.error(error);
    }
  }, [t]);

  return handlePasswordChange;
};
