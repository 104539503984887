import React from 'react';
import Link from 'next/link';
import styles from '@/modules/app/components/categories/view-list-categories/view-list-categories.module.scss';
import useTranslation from 'next-translate/useTranslation';

interface Props {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  pathname: string;
  codename: string;
}

/**
 * tablet and mobile component
 */
export const TabletHeaderViewList = ({ onClose, codename, pathname }: Props) => {
  const { t } = useTranslation('categories');

  return (
    <Link href={pathname} onClick={() => onClose(false)}>
      <h2
        className={styles.header_title}
        data-testid={`section_${codename}`}
      >
        {t('everything')}
        <span className={styles.arrow}> &gt;</span>
      </h2>
    </Link>
  );
};
