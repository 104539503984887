import React, { ReactNode, CSSProperties } from 'react';
import clsx from 'clsx';
import Scrollbars from 'react-scrollbars-custom';
import styles from './scrollbar.module.scss';

export interface ScrollbarProps {
  children: ReactNode;
  contentStyles?: string; // Название класса CSS для настройки стилей содержимого внутри полосы прокрутки
  trackYStyles?: string | CSSProperties; // Название класса CSS для настройки стилей полосы прокрутки
}

export const Scrollbar = ({ children, contentStyles, trackYStyles }: ScrollbarProps) => (
  <Scrollbars
    trackYProps={{ className: clsx(styles.track, trackYStyles) }} // Стили для полосы прокрутки
    thumbYProps={{ className: styles.thumb }} // Стили для ползунка прокрутки
    contentProps={{ className: contentStyles }}
  >
    {/* Контент, который будет иметь скролл */}
    {children}
  </Scrollbars>
);
