// Формат даты: 12 декабря, 23:05 || December 12, 23:05
export const formatDateTimeLongFormat = (dateString, timeString, locale:string) => {
  // формат в списке избранных объявлений 09.08.2024
  // формат в списке избранных мероприятий 2024-08-28
  // изначально было настроено под 09.08.2024
  let dateParts: string[];

  if (dateString.includes('.')) {
    dateParts = dateString.split('.');
  } else {
    const parts = dateString.split('-');
    const [year, month, day] = parts;
    dateParts = [day, month, year];
  }

  const timeParts = (timeString || '00:00').split(':');

  const date = new Date(
    Number(dateParts[2]),
    Number(dateParts[1]) - 1,
    Number(dateParts[0]),
    timeParts[0],
    timeParts[1],
  );

  const dateFormatter = new Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  const timeFormatter = new Intl.DateTimeFormat(locale, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });

  const formattedDate = dateFormatter.format(date);
  const formattedTime = timeFormatter.format(date);

  return `${formattedDate}, ${formattedTime}`;
};
