import useTranslation from 'next-translate/useTranslation';
import { Button, CodeInput } from '@/modules/shared/components';
import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useSearchParams } from 'next/navigation';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { AuthApi } from '@/modules/auth/api';
import { AxiosError } from 'axios';
import { useThrottler } from '../../../hook/use-throttler';
import styles from '../../../auth-modal.module.scss';
import { useOpenAuthModal } from '../../../../../hooks/use-open-modal.hook';

export const Code = () => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const searchParams = useSearchParams();

  const setAuthType = useOpenAuthModal();
  const [throttleSec, startThrottler] = useThrottler();
  const { mutateAsync: resendTotp, isPending: isPendingResendTotp } = useMutation({
    mutationFn: AuthApi.sendTotp,
  });

  const { mutateAsync, isPending, error } = useMutation({
    mutationFn: AuthApi.checkTotp,
  });

  const phoneValue = searchParams.get('phone') || '';
  const isCodeInvalid = useMemo(() => (error as AxiosError<{
    validation_errors: { code: string[] }
  }>)?.response
    ?.data
    ?.validation_errors
    ?.code
    ?.includes?.('INVALID_PHONE_NUMBER_OR_TOTP_CODE'), [error]);

  useEffect(() => {
    startThrottler();
  }, []);

  const handleResendCode = async () => {
    try {
      const data = await resendTotp({
        phone_number: phoneValue,
        operation_type: 'reset_password',
      });

      if (data.status !== 'success') throw new Error();

      startThrottler();
      setCode('');
      toast(t('auth:phoneConfirmation.resendCodeDone'), { type: 'success' });
    } catch (e) {
      if (e.code === 'THROTTLED') {
        toast(t('auth:phoneConfirmation.throttled'), { type: 'error' });
      } else {
        toast(t('auth:phoneConfirmation.error'), { type: 'error' });
      }
    }
  };

  const handleSubmit = async () => {
    try {
      await mutateAsync({
        phone_number: phoneValue,
        operation_type: 'reset_password',
        totp: code,
      });

      setAuthType('completeRecovery', {
        phone: phoneValue,
        totp: code,
      });
    } catch (e) {
      /* error was handled outside */
      console.error(e);
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.title}>
        <h2>{t('auth:phoneRecovery.title')}</h2>
        <div className={styles.subTitle}>
          {t('auth:phoneRecovery.description', {
            phone: phoneValue,
          })}
        </div>
      </div>
      <div className={clsx(styles.centered, styles.centered_column)}>
        <CodeInput error={isCodeInvalid} onChange={setCode} value={code} />
        <div className={clsx(styles.error, {
          [styles.error_invisible]: !isCodeInvalid,
        })}
        >
          {t('auth:phoneRecovery.incorrectCode')}
        </div>
      </div>
      <div>
        <Button
          disabled={isPending || code.length !== 6}
          onClick={handleSubmit}
          type="button"
          fullWidth
          appearance="primary"
          buttonColor="green"
          buttonSize="s"
        >
          {t('auth:register.continue')}
        </Button>
      </div>
      <div>
        <Button
          fullWidth
          onClick={handleResendCode}
          type="button"
          disabled={isPendingResendTotp || !!throttleSec}
          appearance="secondary"
          buttonColor="green"
          buttonSize="s"
        >
          {throttleSec
            ? t('auth:phoneConfirmation.resendCodeIn', { time: throttleSec })
            : t('auth:phoneConfirmation.resendCode')}
        </Button>
      </div>
    </div>
  );
};
