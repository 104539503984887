import React from 'react';
import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import { SvgIcon } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import styles from './select-category-card.module.scss';

interface Props {
  id: number | string;
  title: string;
  icon?: string;
  name: string;
  onClick: () => void;
}

export const SelectCategoryCard = (props: Props) => {
  const {
    id, icon, title, name, onClick,
  } = props;
  const { isDesktop } = useMatchMedia();
  const { control, getValues } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const handleClick = () => {
          field.onChange(id);
          onClick();
        }; // нужна функция, чтобы повторный клик по отмеченной радиокнопке выполнялся снова

        return (
          <label
            className={clsx(styles.select_category_card, {
              [styles.active]: field.value === id,
            })}
          >
            <input
              type="radio"
              value={getValues(name) || id}
              checked={field.value === id}
              onClick={handleClick}
            />
            <div className={styles.category}>
              {icon && <img src={icon} alt="icon" />}
              <p className={styles.category_title}>{title}</p>
            </div>
            {isDesktop && <SvgIcon name="arrow-right" className={styles.svg} />}
            {!isDesktop && field.value === id && <SvgIcon name="check" className={styles.svg} />}
          </label>
        );
      }}
    />
  );
};
