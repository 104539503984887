import { POSSIBLE_CITIES } from '@/modules/locations/consts';
import { getServerSideCookieValue } from './get-server-side-cookie-value.helper';

export const checkExistedCityAndRedirect = (resolvedUrl: string, cookieString: string) => {
  const pathParts = resolvedUrl.split('/');
  const currentCity = pathParts[1];

  if (!POSSIBLE_CITIES.includes(currentCity)) {
    const cityFromCookies = getServerSideCookieValue(cookieString, 'selectedCityName');

    if (cityFromCookies) {
      const newUrl = resolvedUrl.replace(currentCity, cityFromCookies);
      return {
        redirect: {
          destination: newUrl,
          permanent: false,
        },
      };
    }

    const newUrl = resolvedUrl.replace(currentCity, 'allcities');
    return {
      redirect: {
        destination: newUrl,
        permanent: false,
      },
    };
  }

  return null;
};
