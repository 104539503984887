import React from 'react';
import { useForm } from 'react-hook-form';
import s from '@/styles/main/AuthModal.module.scss';
import { useRouter } from 'next/router';
import i18n from '@/lib/i18n';
import { useOpenAuthModal } from '../../hooks';

export const PasswordChangeSuccessModal = () => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const setModalType = useOpenAuthModal();
  const { locale } = useRouter();

  const onSubmit = async () => {
    setModalType('login');
  };

  return (
    <div className={s.auth_modal}>
      <div className={s.reset_confirmation}>
        <h2>{i18n.auth.passwordChange.title[locale]}</h2>
        <form className={s.confirmation_form} onSubmit={handleSubmit(onSubmit)}>
          <button
            type="submit"
            className={s.confirmation_button}
            disabled={isSubmitting}
          >
            {i18n.auth.passwordChange.signIn[locale]}
          </button>
        </form>
      </div>
    </div>
  );
};
