import React from 'react';
import { SvgIcon } from '@/modules/shared/components';
import { useFormatDataPrice } from '@/modules/shared/hooks';
import styles from '../ad-page.module.scss';

interface AdTitleMobileProps {
  title: string;
  price: number;
  priceType: string;
}

export const AdTitleMobile = ({ title, price, priceType }: AdTitleMobileProps) => {
  const priceDisplayFormat = useFormatDataPrice();

  return (
    <div className={styles.ad_title_mobile}>
      <h1>{title}</h1>
      <div className={styles.mobile_price}>
        {priceDisplayFormat({ priceType, price })}
        {priceType === 'for_money' && <SvgIcon name="THB" />}
      </div>
    </div>
  );
};
