import { useGlobalData } from '@/context/GlobalContext';
import { useMemo } from 'react';
import { useLocationsListQuery } from '../queries';
import { City } from '../types';

export const useHomeCity = () => {
  const { homeCity = 'allcities' } = useGlobalData();
  const { data, isLoading, error } = useLocationsListQuery();

  return useMemo(() => {
    const city = data.data.find((item: City) => item.codename === homeCity);
    const buildUrl = (url: string) => `/${homeCity}/${url}`;

    return {
      cityId: city?.id, isLoading, error, city: homeCity, buildUrl,
    };
  }, [data, homeCity, isLoading, error]);
};
