import clsx from 'clsx';
import { MouseEventHandler } from 'react';

import useTranslation from 'next-translate/useTranslation';
import styles from './footer.module.scss';


type FooterProps = {
  onClickApply: MouseEventHandler<HTMLButtonElement>
  onClickReset: MouseEventHandler<HTMLButtonElement>
  hasNotAppliedFilters?: boolean
  hasFilters?: boolean
};

export const Footer = ({
  hasFilters,
  hasNotAppliedFilters,
  onClickApply,
  onClickReset,
}: FooterProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(styles.footer, {
        [styles.footer_showReset]: hasNotAppliedFilters || hasFilters,
      })}
    >
      <button
        className={clsx(styles.button, {
          [styles.button_animated]: hasNotAppliedFilters,
        })}
        onClick={onClickApply}
      >
        {t('filters-components:footer.applyButton')}
      </button>
      <button
        className={clsx(styles.button, styles.button_reset)}
        onClick={onClickReset}
      >
        {t('filters-components:footer.resetButton')}
      </button>
    </div>
  );
};
