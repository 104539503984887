import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Button, Textarea } from '@/modules/shared/components';
import useTranslation from 'next-translate/useTranslation';
import { useReviewAnswerMutation } from '../../mutations';
import styles from './feedback-textarea.module.scss';

interface AnswerType {
  recipient_answer: string;
}

interface Props {
  review_id: string;
  handleClose: () => void;
  handleFocus?: (value: boolean) => void;
}

export const FeedbackTextarea = (props: Props) => {
  const { handleClose, handleFocus, review_id } = props;
  const { t } = useTranslation('');
  const [feedbackValue, setFeedbackValue] = useState('');
  const { mutate: sendAnswer, isPending } = useReviewAnswerMutation();
  const queryClient = useQueryClient();

  const {
    control, watch, handleSubmit, reset,
  } = useForm({
    mode: 'all',
  });
  const answer = watch('recipient_answer');
  const isDisabled = () => !answer || answer?.length < 2 || answer?.length > 1000;

  const onSubmit: SubmitHandler<AnswerType> = async (formData) => {
    if (!formData.recipient_answer || isDisabled()) {
      return;
    }

    sendAnswer({
      id: review_id,
      answerData: formData,
    }, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['reviews'],
          refetchType: 'all',
          type: 'all',
        });
        handleFocus?.(false);
        handleClose();
        reset();
      },
    });
  };

  useEffect(() => {
    setFeedbackValue(answer || '');
  }, [answer]);

  return (
    <form className={styles.answer_block} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.textarea_wrapper}>
        <Controller
          name="recipient_answer"
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              name="recipient_answer"
              value={feedbackValue || ''}
              placeholder={t('rating-page:feedbackPlaceholder')}
              onFocus={() => handleFocus?.(true)}
              maxChars={1000}
              heightScroll={150}
            />
          )}
        />
      </div>
      <div className={styles.buttons_wrapper}>
        <Button
          appearance="primary"
          buttonColor="blue"
          buttonSize="s"
          type="submit"
          disabled={isDisabled()}
        >
          {isPending
            ? t('forms:labels.submitting')
            : t('forms:labels.send')}
        </Button>
        <Button
          appearance="primary"
          buttonColor="grey"
          buttonSize="s"
          type="reset"
          onClick={handleClose}
        >
          {t('common:cancel')}
        </Button>
      </div>
    </form>
  );
};
