import React, { useCallback } from 'react';
import { SvgIcon } from '../svg-icon';
import styles from './user-rating.module.scss';

interface UserRatingProps {
  rating: number;
  sizeStar?: '16px' | '24px' | '32px' | '44px';
  onRatingChange?: (rating: number) => void;
}

const roundToNearest = (value: number, nearest: number) => Math.round(value / nearest) * nearest;

const stars = [1, 2, 3, 4, 5];

export const UserRating = ({ rating, sizeStar = '16px', onRatingChange } : UserRatingProps) => {
  const determineStarType = (starIndex: number) => {
    const starRating = rating - (starIndex - 1);
    const roundedStarRating = roundToNearest(starRating, 0.1); // Округляем до ближайшей 0.1

    if (roundedStarRating >= 1) {
      return 'star-1';
    }
    if (roundedStarRating >= 0.8) {
      return 'star-0,8';
    }
    if (roundedStarRating >= 0.5) {
      return 'star-0,5';
    }
    if (roundedStarRating >= 0.1) {
      return 'star-0,1';
    }
    return 'star-0';
  };

  const handleStarClick = useCallback((starIndex: number) => {
    if (onRatingChange) {
      onRatingChange(starIndex);
    }
  }, [onRatingChange]);

  return (
    <div className={styles.userRating}>
      {stars.map((starIndex) => (
        <SvgIcon
          key={starIndex}
          name={determineStarType(starIndex)}
          style={{ width: sizeStar, height: sizeStar }}
          onClick={() => handleStarClick(starIndex)}
        />
      ))}
    </div>
  );
};
