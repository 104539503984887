import React from 'react';
import { SubHeaderItems } from '../../sub-header-items';
import { SubCategoryTypes } from '../../types';
import styles from '../view-list-categories.module.scss';

interface Props {
  sub_categories: SubCategoryTypes[];
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  prevCodename: string;
}

export const TabletViewList = ({ sub_categories, onClose, prevCodename }: Props) => (
  <div className={styles.column}>
    {sub_categories.map((value, index) => (
      <SubHeaderItems
        key={index}
        data={value}
        onClick={onClose}
        prevCodename={prevCodename}
      />
    ))}
  </div>
);
