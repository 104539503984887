import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import { Box } from '@mui/material';
import { IAd } from '@/modules/ads/types';
import { useFormContext } from 'react-hook-form';
import { useMatchMedia } from '@/modules/shared/hooks';
import { getDeclension } from '@/modules/shared/helpers';
import useTranslation from 'next-translate/useTranslation';
import {
  Button, Checkbox, ItemLoader, Pagination, Sorting, SvgIcon,
} from '@/modules/shared/components';
import { MyAdCard } from './my-ad-card.component';
import { ModalConfirmAd } from './modal-confirm-ad.component';
import { MobileMyAdCard } from './mobile-my-ad-card.component';
import styles from './my-ads-list.module.scss';

interface CurrentPagesType {
  active: number;
  hidden: number;
}
interface InfinityAds {
  count: number;
  counts: { active: number, inactive: number };
  results: IAd[];
}
interface MyAdsListProps {
  adType: string;
  hasMore?: boolean;
  totalPages: number;
  isLoading: boolean;
  currentPage: number;
  selectedAds: number[];
  isAllSelected: boolean;
  ads: IAd[] | InfinityAds[];
  quantity: { active: number; hidden: number };
  fetchMore?: () => void;
  onSelectAd: (id: React.SetStateAction<number[]>) => void;
  handleSetPage: (value: number) => void;
  isInfinity?: ((node: HTMLElement | null) => void) | undefined;
  onIsAllSelected: (value: React.SetStateAction<boolean>) => void;
  toggleAdVisibility: (params: { id: number[]; action: string }) => void;
  ITEMS_PER_PAGE: number;
  currentPages: CurrentPagesType;
  setCurrentPages: React.Dispatch<React.SetStateAction<CurrentPagesType>>
}

export const MyAdsList = ({
  ads,
  adType,
  hasMore,
  quantity,
  isLoading,
  totalPages,
  currentPage,
  selectedAds,
  isAllSelected,
  fetchMore,
  onSelectAd,
  isInfinity,
  handleSetPage,
  onIsAllSelected,
  toggleAdVisibility,
  ITEMS_PER_PAGE,
  currentPages,
  setCurrentPages,
}: MyAdsListProps) => {
  const { t } = useTranslation('userProfile');
  const [showModal, setShowModal] = useState(false);
  const { isMobile, isDesktop } = useMatchMedia();
  const { watch, setValue } = useFormContext<{ sort: string }>();
  const [toggleAllCheckbox, setToggleAllCheckbox] = useState(false);

  const currentAds = useMemo(() => {
    if (isDesktop) return ads;
    if (ads && Array.isArray(ads) && ads[0] && 'results' in ads[0]) {
      return ads[0].results;
    }
    return ads;
  }, [isDesktop, ads]);

  const selectedSort = watch('sort');

  useEffect(() => {
    if (isAllSelected || toggleAllCheckbox) {
      if (isDesktop) {
        onSelectAd(currentAds?.map((ad) => ad.id));
      } else {
        const infinityAds = ads.flatMap((ad) => ad.results || []);
        onSelectAd(infinityAds.map((ad) => ad.id));
      }
    } else {
      onSelectAd([]);
    }
  }, [isAllSelected, toggleAllCheckbox, currentAds, onSelectAd, ads, isDesktop]);

  const quantityAds = useMemo(() => getDeclension({
    count: quantity?.active,
    t,
    singular: 'myAds.ads.singular',
    few: 'myAds.ads.few',
    many: 'myAds.ads.many',
  }), [quantity, t]);

  const quantityHiddenAds = useMemo(() => getDeclension({
    count: quantity?.hidden,
    t,
    singular: 'myAds.hiddenAds.singular',
    few: 'myAds.hiddenAds.few',
    many: 'myAds.hiddenAds.many',
  }), [quantity, t]);

  const options = useMemo(() => ([
    { value: '-created_at', label: t('common:sorting.sortingModal.newest') },
    { value: 'created_at', label: t('common:sorting.sortingModal.oldest') },
    { value: '-price', label: t('common:sorting.sortingModal.decreasing') },
    { value: 'price', label: t('common:sorting.sortingModal.increasing') },
  ]), [t]);

  const onSelect = useCallback((id: number) => {
    onSelectAd((prevChosen) => {
      if (prevChosen.includes(id)) {
        const newChosen = prevChosen.filter((item) => item !== id);
        if (newChosen.length === 0) {
          onIsAllSelected(false);
        }
        return newChosen;
      }
      const newChosen = [...prevChosen, id];
      if (newChosen.length === currentAds.length) {
        onIsAllSelected(true);
      }
      return newChosen;
    });
  }, [currentAds, onSelectAd, onIsAllSelected]);

  const handleHideAd = useCallback((id: number) => {
    setShowModal(true);
    onSelectAd((prevChosen) => (
      prevChosen.includes(id)
        ? prevChosen.filter((hiddenId) => hiddenId !== id)
        : [...prevChosen, id]
    ));
  }, [onSelectAd]);

  const handleToggleVisibility = useCallback(() => {
    const action = adType === 'active' ? 'hide' : 'activate';
    /*
    * Прежде чем отправлять запрос, нужно обновить номер последней страницы
    * чтобы корректно обновить список объявлений
    * */
    if (currentPage !== 1) {
      const lastPageAdsQuantity = quantity[adType] % ITEMS_PER_PAGE || ITEMS_PER_PAGE;
      const lastPage = Math.ceil((quantity[adType] - selectedAds.length) / ITEMS_PER_PAGE);
      if ((currentPages[adType] > lastPage) && (selectedAds.length === lastPageAdsQuantity)) {
        // если мы на последней странице и выбраны все объявления, тогда обновляем номер последней страницы
        setCurrentPages((prevPages) => {
          const updatedPages = { ...prevPages, [adType]: lastPage };
          sessionStorage.setItem('returning-my-ads-page', JSON.stringify(updatedPages));
          return updatedPages;
        });
      }
    }

    toggleAdVisibility({ id: selectedAds!, action });
    setToggleAllCheckbox(false);
  }, [adType, toggleAdVisibility, selectedAds]);

  const handleCheckbox = useCallback(() => {
    if (isDesktop) {
      setToggleAllCheckbox(!toggleAllCheckbox);
      onIsAllSelected(!isAllSelected);
    } else {
      setToggleAllCheckbox(!toggleAllCheckbox);
    }
  }, [isDesktop, isAllSelected, onIsAllSelected, toggleAllCheckbox]);

  const actionConfig = {
    active: {
      icon: 'eye-opened',
      text: t('myAds.hide'),
    },
    hidden: {
      icon: 'eye-closed',
      text: t('myAds.open'),
    },
    drafts: {
      icon: 'trash-bin',
      text: t('myAds.delete'),
    },
  };

  const displayQuantity = useMemo(() => {
    const count = adType === 'active' ? quantity?.active : quantity?.hidden;
    return count > 0 ? count : null;
  }, [adType, quantity]);

  const config = actionConfig[adType];
  if (currentAds?.length === 0) return null;

  return (
    <section className={styles.my_ads_list}>
      {isDesktop && (
        <div className={styles.my_ads_list_headline}>
          <div className={styles.sorting}>
            <h4>
              {displayQuantity && displayQuantity}
              {' '}
              {adType === 'active' ? quantityAds : quantityHiddenAds}
            </h4>
            <Sorting
              value={selectedSort}
              setValue={(value) => setValue('sort', value)}
              options={options}
            />
          </div>
          {adType === 'active' && (
            <div className={styles.hiding}>
              <Checkbox
                checkboxSize="24"
                checked={toggleAllCheckbox}
                onChange={handleCheckbox}
              />
              <Button
                as="button"
                buttonSize="xs"
                buttonColor="grey"
                appearance="primary"
                className={styles.hide}
                disabled={selectedAds?.length === 0}
                onClick={() => setShowModal(true)}
              >
                <SvgIcon name="eye-opened" />
                {t('myAds.hide')}
              </Button>
            </div>
          )}
        </div>
      )}
      {!isDesktop && (
        <div className={styles.my_ads_list_mobile_headline}>
          <Sorting
            value={selectedSort}
            setValue={(value) => setValue('sort', value)}
            options={options}
          />
          <div className={styles.hiding}>
            <Button
              as="button"
              buttonSize={isMobile ? 's' : 'xs'}
              buttonColor={isMobile ? 'blue' : 'grey'}
              appearance={isMobile ? 'secondary' : 'primary'}
              className={styles.hiding_button}
              disabled={selectedAds.length === 0}
              onClick={() => setShowModal(true)}
            >
              <SvgIcon name={config.icon} />
              {config.text}
            </Button>
            <Checkbox
              checkboxSize="24"
              checked={toggleAllCheckbox}
              onChange={handleCheckbox}
            />
          </div>
        </div>
      )}
      <Pagination
        itemsPerPage={10}
        hasMore={hasMore}
        fetchMore={fetchMore}
        totalPages={totalPages}
        className={styles.list}
        currentPage={currentPage}
        handleSetPage={handleSetPage}
      >
        {isDesktop ? (
          <>
            {currentAds?.map((ad) => (
              <MyAdCard
                key={ad.id}
                ad={ad}
                adType={adType}
                onSelect={onSelect}
                onClick={handleHideAd}
                isSelected={selectedAds}
              />
            ))}
            {!isLoading ? null : (
              <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                <ItemLoader isLoading size="50px" />
              </Box>
            )}
          </>
        ) : (
          <>
            {ads?.map((page, i) => (
              <React.Fragment key={i}>
                {page?.results?.map((ad, j) => (
                  <React.Fragment key={ad.id}>
                    <MobileMyAdCard
                      ad={ad}
                      adType={adType}
                      onSelect={onSelect}
                      onClick={handleHideAd}
                      isChoosing={toggleAllCheckbox}
                      isSelected={selectedAds}
                    />
                    {i + 1 === ads?.length && page.data.length === j + 1 ? (
                      <div ref={isInfinity} />
                    ) : null}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
            {!isLoading ? null : (
              <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                <ItemLoader isLoading size="50px" />
              </Box>
            )}
          </>
        )}
      </Pagination>
      {showModal && (
        <ModalConfirmAd
          adType={adType}
          isOpen={!!showModal}
          onClose={() => setShowModal(false)}
          toggleAdVisibility={handleToggleVisibility}
          isSelected={selectedAds.length > 1 || isAllSelected}
        />
      )}
    </section>
  );
};
