import { entityQueryFactory } from '@/modules/shared/queries';
import { LocationsApi } from '../api/locations.api';


const _useMyLocationQuery = entityQueryFactory(LocationsApi.getLocation, ['my-location']);

export const useMyLocationQuery = (
  location?: { city?: number, longitude: number; latitude: number },
) => _useMyLocationQuery({
  enabled: !!location,
  longitude: NaN,
  latitude: NaN,
  ...(location || {}),
});


