import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import useTranslation from 'next-translate/useTranslation';
import { EventsApi } from '../api';
import { EVENT_KEY } from '../consts';

export const useCreateEventMutation = () => {
  const client = useQueryClient();
  const { t } = useTranslation('events');

  return useMutation({
    mutationFn: EventsApi.create,
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: [EVENT_KEY],
      });
    },
    onError: () => toast.error(t('createEvents.error')),
  });
};
