import useTranslation from 'next-translate/useTranslation';
import s from './star-notification.module.scss';
import { SvgIcon } from '../svg-icon';

export const StarNotification = () => {
  const { t } = useTranslation('common');

  return (
    <div className={s.wrapper}>
      <SvgIcon name="lightning" />
      <p>
        {t('starNotification.part1')}
        <span>*</span>
        {t('starNotification.part2')}
      </p>
    </div>
  );
};
