import React, { ComponentType, memo } from 'react';
import { FiltersMap } from '../types';


type DynamicFieldsMapperProps = {
  components: Record<string, ComponentType<{ filter: FiltersMap[keyof FiltersMap] }>>;
  name: string,
  filter: FiltersMap[keyof FiltersMap]
};

export const DynamicFieldsMapper = memo(({
  components, name, ...props
}: DynamicFieldsMapperProps) => {
  const Component = components[name];
  if (!Component) {
    console.log('unknown filter type', name, props);
    return null;
  }

  return <Component {...props} />;
});
