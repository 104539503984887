import React, {
  createContext, useContext, useMemo, useState, useEffect,
} from 'react';
import { useRouter } from 'next/router';
import { getInitialCityInfo, setInitialCityInfo } from '@/modules/locations/helpers';

const GlobalContext = createContext();

function GlobalProvider({ children }) {
  const { query } = useRouter();

  const [homeCity, setHomeCity] = useState('allcities');
  const [userSet, setUserSet] = useState(false);

  useEffect(() => {
    const { city, userSet } = getInitialCityInfo();
    setHomeCity(city);
    setUserSet(userSet);
    setInitialCityInfo({ city, userSet });
  }, []);

  useEffect(() => {
    if (query?.city && query.city !== '[city]') {
      setHomeCity(query.city);
      setInitialCityInfo({ city: query.city, userSet: getInitialCityInfo().userSet });
    }
  }, [query]);

  const [initialShow, setInitialShow] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [quantityAd, setQuantityAd] = useState([]);
  const [isShowCategoriesModal, setIsShowCategoriesModal] = useState(false);

  const value = useMemo(() => ({
    homeCity,
    setHomeCity,
    searchData,
    setSearchData,
    quantityAd,
    setQuantityAd,
    initialShow,
    setInitialShow,
    isShowCategoriesModal,
    setIsShowCategoriesModal,
  }), [
    homeCity,
    setHomeCity,
    searchData,
    setSearchData,
    quantityAd,
    setQuantityAd,
    initialShow,
    setInitialShow,
    isShowCategoriesModal,
    setIsShowCategoriesModal,
  ]);

  return (
    <GlobalContext.Provider value={value}>
      {children}
    </GlobalContext.Provider>
  );
}

const useGlobalData = () => useContext(GlobalContext);

export { GlobalProvider, useGlobalData };
