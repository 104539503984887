import { useQuery } from '@tanstack/react-query';
import { CategoriesApi } from '../api';

export const categoriesListTopKeys = (size: number) => ['categories-list-top', size];

export const useCategoryTop = (size: number) => {
  const {
    data: topList,
    isFetching,
    error,
  } = useQuery({
    queryKey: categoriesListTopKeys(size),
    queryFn: async () => {
      const data = await CategoriesApi.toplist(size);
      return data;
    },
  });

  return {
    data: topList,
    loading: isFetching,
    error,
  };
};
