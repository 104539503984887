import { useCallback } from 'react';
import { UseFormResetField } from 'react-hook-form';
import { useCreateAdActionsContext, useCreateAdContext } from '../context';
import { CreateAdTypes } from '../types';

export const useHandleBreadcrumbs = (resetField: UseFormResetField<CreateAdTypes>) => {
  const {
    setCategory, setSubCategory, setUnderSubCategory, setAdType, setMainStep, setIsLastLevel,
  } = useCreateAdActionsContext();
  const { subCategory } = useCreateAdContext();

  const handlePlaceAdClick = useCallback(() => {
    setMainStep(1);
    resetField('category');
    setCategory(undefined);
    resetField('sub_category');
    setSubCategory(undefined);
    resetField('under_sub_category');
    setUnderSubCategory(undefined);
    setAdType(undefined);
  }, []);

  const handleCategoryClick = useCallback(() => {
    setMainStep(1);
    resetField('sub_category');
    setSubCategory(undefined);
    resetField('under_sub_category');
    setUnderSubCategory(undefined);
    setAdType(undefined);
  }, []);

  const handleSubCategoryClick = useCallback(() => {
    setMainStep(1);
    resetField('under_sub_category');
    setUnderSubCategory(undefined);
    setAdType(undefined);
    resetField('operation_type');

    if (subCategory && !subCategory.under_sub_categories) {
      setIsLastLevel(true);
    }
  }, []);

  const handleUnderSubCategoryClick = useCallback(() => {
    setMainStep(1);
    setAdType(undefined);
  }, []);

  return {
    handlePlaceAdClick,
    handleCategoryClick,
    handleSubCategoryClick,
    handleUnderSubCategoryClick,
  };
};
