import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import styles from './notFound.module.scss';

interface NotFoundProps {
  messageType?: 'noData' | 'noResults';
}

export const NotFound = ({ messageType = 'noResults' }: NotFoundProps) => {
  const { t } = useTranslation('notFound');

  const content = {
    noData: {
      image: 'rabbit-not-found',
      text: t('noUserData'),
    },
    noResults: {
      image: 'rabbit-crying',
      firstText: t('firstText'),
      text: t('secondText'),
    },
  }[messageType];

  return (
    <div className={styles.found}>
      <img src={`/assets/mascots/${content.image}.svg`} alt={content.image} />
      {content.firstText && (<h2>{t('firstText')}</h2>)}
      <h3>{content.text}</h3>
    </div>
  );
};
