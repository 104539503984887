import React, {
  useCallback, useMemo, useState, useEffect, Fragment,
} from 'react';
import { Box } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { ItemLoader, OverflowMenu, Button } from '@/modules/shared/components';
import { MobileHeader } from '@/modules/app/components/header';
import { useInfiniteListRef, useMatchMedia } from '@/modules/shared/hooks';
import { SupportService } from './support-service';
import { useChatDataContext } from '../providers';
import { ChatList } from './chat-list';
import { ModalDeleteChat } from './modal';
import { ChatListSorting } from './chat-list-sorting';
import styles from './chat-list-page.module.scss';

const messageFilters = ['allMessages', 'unreadMessages'];
const adFilters = ['allAnnouncements', 'myAnnouncements'];

const listMenu = [
  {
    icon: 'check16',
    title: 'chat:selectChats',
    value: 'selectChats',
  },
];

export const ChatListPage = () => {
  const { t } = useTranslation('');
  const { isMobile, isDesktop } = useMatchMedia();
  const { control, setValue, watch } = useForm();
  const [selectedChat, setSelectedChat] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [isOpenModalDeleteChat, setIsOpenModalDeleteChat] = useState<boolean>(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [searchChat, setSearchChat] = useState('');
  const [chooseChatsMobile, setChooseChatsMobile] = useState<string | null>(null);

  const activeMessageFilters = watch('select-filter-message');
  const activeAdFilters = watch('select-filter-ads');

  const mappedMessageFilter = useMemo(
    () => (activeMessageFilters === 'unreadMessages' ? 'unread' : ''),
    [activeMessageFilters],
  );
  const mappedAdFilter = useMemo(
    () => (activeAdFilters === 'myAnnouncements' ? 'mine' : ''),
    [activeAdFilters],
  );

  const onSearchChat = useCallback((value: string) => {
    setSearchChat(value);
  }, [setSearchChat]);

  const {
    chatList: chatListData,
    sendChatList,
    isLoading,
    hasMore,
    chatSupportList,
    fetchMore,
    unreadDialogsCount,
    deleteChat,
  } = useChatDataContext();
  const lastListItemRef = useInfiniteListRef(hasMore, fetchMore, isLoading);

  useEffect(() => {
    sendChatList({
      announcement_filter: mappedAdFilter,
      message_filter: mappedMessageFilter,
      search: searchChat,
    });
  }, [mappedAdFilter, mappedMessageFilter, searchChat, unreadDialogsCount]);

  const messageFilterOptions = useMemo(
    () => messageFilters.map((el) => ({
      value: el,
      label: t(`chat:${el}`),
    })),
    [t],
  );

  const adFilterOptions = useMemo(
    () => adFilters.map((el) => ({
      value: el,
      label: t(`chat:${el}`),
    })),
    [t],
  );

  const handleSelectChat = (id: number) => {
    setSelectedChat((prevSelectedChat) => (prevSelectedChat.includes(id)
      ? prevSelectedChat.filter((chatId) => chatId !== id)
      : [...prevSelectedChat, id]));
  };

  const handleSelectAll = useCallback(() => {
    if (selectAll) {
      setSelectedChat([]);
    } else {
      setSelectedChat(chatListData?.map((chat) => chat.id) || []);
    }
    setSelectAll(!selectAll);
  }, [chatListData, selectAll]);

  const handleCancelDeleteChat = useCallback(() => {
    setIsOpenModalDeleteChat(false);
    setSelectedChat([]);
  }, []);

  const handleDeleteChat = useCallback(() => {
    deleteChat(selectedChat);
    setIsOpenModalDeleteChat(false);
    setSelectedChat([]);
  }, [deleteChat, selectedChat]);

  return (
    <>
      {!isDesktop && (
        <MobileHeader
          title={t('chat:title')}
          showBackButton={false}
          showOnCloseButton={false}
          rightContent={(
            <>
              {chooseChatsMobile === 'selectChats' ? (
                <Button
                  as="button"
                  buttonSize="s"
                  buttonColor="blue"
                  appearance="secondary"
                  className={styles.buttonCancel}
                  onClick={() => setChooseChatsMobile(null)}
                >
                  {t('chat:cancel')}
                </Button>
              ) : (
                <OverflowMenu list={listMenu} action={setChooseChatsMobile} />
              )}
            </>
          )}
        />
      )}

      <div
        className={clsx(styles.container, {
          [styles.container_search_focus]: isInputFocused && isMobile,
        })}
      >
        {!(isMobile && isInputFocused) && (
          <div className={styles.container_support}>
            <SupportService newMessage={!!chatSupportList?.[0]?.unread_count} />
          </div>
        )}

        {((chatListData && chatListData?.length > 0)
          || mappedMessageFilter
          || mappedAdFilter
          || searchChat) && (
          <div className={styles.container_filter}>
            <ChatListSorting
              isInputFocused={isInputFocused}
              setIsInputFocused={setIsInputFocused}
              selectAll={selectAll}
              selectedChat={selectedChat}
              handleSelectAll={handleSelectAll}
              setIsOpenModalDeleteChat={setIsOpenModalDeleteChat}
              messageFilterOptions={messageFilterOptions}
              adFilterOptions={adFilterOptions}
              control={control}
              onSearchChat={onSearchChat}
              setValue={setValue}
              isOptionsMobile={chooseChatsMobile === 'selectChats'}
            />
          </div>
        )}

        <div className={styles.container_list}>
          {isLoading && !chatListData && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <ItemLoader isLoading size="40px" />
            </Box>
          )}

          {!isLoading
            && chatListData
            && chatListData.length === 0
            && (mappedMessageFilter || mappedAdFilter || searchChat) && (
              <p className={styles.nothingFound}>{t('chat:nothingFound')}</p>
          )}

          {chatListData && (
            <>
              <ChatList
                data={chatListData}
                onChoose={handleSelectChat}
                selectedChats={selectedChat}
                isCheckbox={chooseChatsMobile === 'selectChats'}
              />
              {chatListData.map((dialogue, i) => (
                <Fragment key={i}>
                  {!(i + 1 === chatListData.length) ? null : (
                    <div ref={lastListItemRef} />
                  )}
                </Fragment>
              ))}
              {isLoading && (
                <Box display="flex" justifyContent="center" alignItems="center" marginTop="20px">
                  <ItemLoader isLoading size="40px" />
                </Box>
              )}
            </>
          )}
        </div>
      </div>

      {isOpenModalDeleteChat && (
        <ModalDeleteChat
          count={selectedChat.length}
          onCancel={handleCancelDeleteChat}
          onDeleteChat={handleDeleteChat}
          isOpen={isOpenModalDeleteChat}
          onClose={() => setIsOpenModalDeleteChat(false)}
        />
      )}
    </>
  );
};
