import { useCallback } from 'react';
import { FieldError, useFormContext, useWatch } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { InputText } from '@/modules/shared/components/inputs/input-text';
import { useMatchMedia } from '@/modules/shared/hooks';
import s from '../credentials.module.scss';
import styles from './company-credentials.module.scss';
import { CompanyCredentialsForm } from '../types';

type PageAddressProps = {
  path: string | null;
  id: number;
};

export const PageAddress = ({ path, id }: PageAddressProps) => {
  const { t, lang } = useTranslation();
  const {
    register, formState: { errors }, setError, clearErrors,
  } = useFormContext<CompanyCredentialsForm>();
  const value = useWatch({ name: 'custom_page_url' });
  const { isDesktop } = useMatchMedia();

  const handleBlur = useCallback(() => {
    if (value && Number(value) && Number(value) !== id) {
      setError('custom_page_url', {
        type: 'not_number',
        message: t('forms:validationErrors.not_number'),
      });
      return;
    }
    clearErrors('custom_page_url');
  }, [value, id]);

  return (
    <section className={clsx(s.address, s.section)}>
      <h2>{t('userAccount:company.page_address')}</h2>
      <div className={s.field}>
        <p className={s.field_header}>{t('adv:address')}</p>
        <div className={clsx(s.wrapper)}>
          <p>{t('userAccount:company.change')}</p>
          <p className={s.change_tip}>{t('userAccount:company.change_tip')}</p>
          <div className={styles.input_wrapper}>
            <InputText
              fullWidth
              defaultValue={path || id}
              className={styles.path_input}
              {...register('custom_page_url', {
                onBlur: handleBlur,
              })}
            />
            <span className={styles.path}>
              {isDesktop ? `https://bazaarorigin.com/${lang}/companies/` : `bazaarorigin.com/${lang}/companies/`}
            </span>
            {errors.custom_page_url && (
              <p className={s.error}>
                {Object.hasOwn(errors.custom_page_url, 'code')
                  ? t(`forms:validationErrors.${(errors.custom_page_url as FieldError & { code: string }).code}`)
                  : errors.custom_page_url.message as string}
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
