import React, { FC } from 'react';
import { Controller, Control } from 'react-hook-form';
import { useRouter } from 'next/router';
import { Checkbox } from '@/modules/shared/components/checkbox';
import s from './input-checkbox.module.scss';

interface IInputCheckbox {
  control: Control;
  name: string;
  label: string;
}

export const InputCheckbox: FC<IInputCheckbox> = ({ control, name, label }) => {
  const { locale = 'en' } = useRouter();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <span className={s.checkbox_wrapper}>
          <Checkbox defaultValue={field.value} id={field.name} />

          <label htmlFor={field.name} className={s.checkbox_label}>
            {label[locale]}
          </label>
        </span>
      )}
    />
  );
};
