import React, { useCallback, useState } from 'react';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { useQueryClient } from '@tanstack/react-query';
import { Pagination, Box } from '@mui/material';

import s from '@/styles/pages/UserAdvs.module.scss';
import SearchTable from '@/components/shared/layout/Inputs/SearchTable';
import useUserAccount from '@/hooks/users/useUserAccount';
import { ItemLoader, AdLink } from '@/modules/shared/components';
import { useOnSearchFilterChange } from '@/modules/shared/hooks';
import { useAdsListQuery } from '../../queries';
import { buildAdsListQueryKey } from '../../queries/use-ads-list.query';

export function AdsListPage() {
  const [filters, setFilters] = useState({});
  const [searchValue, onSearchChange] = useOnSearchFilterChange(setFilters, 'search');
  const [page, setPage] = useState(1);
  const onPageChange = useCallback((event, value) => setPage(value), [setPage]);

  const queryOptions = { page, filters };
  const queryKey = buildAdsListQueryKey(queryOptions);
  const { data, initialLoading } = useAdsListQuery(queryOptions);
  const { data: ads, total_pages: pages } = data;

  const { deleteAnnouncementAdmin } = useUserAccount();
  const deleteIconTooltip = 'Удалить объявление';
  const queryClient = useQueryClient();
  const handleDeleteAdv = async (id) => {
    if (id) {
      const previous = queryClient.getQueryData(queryKey);
      try {
        await queryClient.cancelQueries({ queryKey });
        queryClient.setQueryData(queryKey, ({
          ...previous,
          data: previous.data.filter((adv) => adv.id !== id),
        }));
        await deleteAnnouncementAdmin(id);
        queryClient.invalidateQueries({ queryKey });
      } catch (error) {
        queryClient.setQueryData(queryKey, previous);
        console.error('Ошибка при удалении объявления:', error);
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column" className={s.block_personal_advs}>
      <div className={s.personal_advs_title}>
        <h2>Объявления</h2>
        <SearchTable value={searchValue} onSearch={onSearchChange} />
      </div>
      <Box display="flex" flexDirection="column" justifyContent="space-between" flex={1}>
        {initialLoading ? <ItemLoader size="50px" /> : (
          <table className={s.personal_advs_table}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Названия</th>
                <th>Стоимость</th>
                <th>Кол-во просмотров</th>
                <th>Действия</th>
              </tr>
            </thead>
            <tbody>
              {ads.map((adv) => (
                <tr key={adv.id}>
                  <td className={s.personal_id}>{adv.id}</td>
                  <td className={s.adv_title}>
                    <AdLink ad={adv}>
                      {adv.title}
                    </AdLink>
                  </td>
                  <td>{adv.price}</td>
                  <td>
                    <div className={s.personal_card_rating}>
                      {Math.round(adv.views / 2)}
                    </div>
                  </td>
                  <td>
                    <div className={s.personal_ads_card_actions}>
                      <button
                        onClick={() => handleDeleteAdv(adv.id)}
                        title={deleteIconTooltip}
                        data-tippy-interactive
                      >
                        <RiDeleteBin6Fill size={21} color="red" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <Box padding="15px">
          <Pagination count={pages} onChange={onPageChange} />
        </Box>
      </Box>
    </Box>
  );
}
