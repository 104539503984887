import { ReactNode } from 'react';

import styles from './filter-row.module.scss';

type FilterRowProps = {
  title?: string
  children: ReactNode;
};

export const FilterRow = ({ children, title }: FilterRowProps) => (
  <div className={styles.wrapper}>
    {title && <div className={styles.title}>{title}</div>}
    <div className={styles.content}>
      {children}
    </div>
  </div>
);
