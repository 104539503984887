import React, { useState, useEffect, useCallback } from 'react';
import { useAuthContext } from '@/modules/auth';
import { useMatchMedia } from '@/modules/shared/hooks';
import type { EventComplaint, AdComplaint } from '../../types';
import { useComplaintAdMutation, useComplaintEventMutation } from '../../mutations';
import { SpecifyReason } from './specify-reason.component';
import { DescribeSituation } from './describe-situation.component';
import { ConfirmationModal } from './confirmation-modal.component';
import { ReportAlreadySubmitted } from './report-already-submitted.component';

export type ComplaintModalProps = {
  isOpen: boolean;
  setModalClosed: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  recipient: number;
  id: number;
  type: 'ad' | 'event';
};

export const ComplaintModal = ({
  isOpen,
  setModalClosed,
  onClose,
  recipient,
  id,
  type = 'ad',
}: ComplaintModalProps) => {
  const { isMobile } = useMatchMedia();
  const { isAuthenticated } = useAuthContext();
  const { mutate: mutateAd, isLoading: isLoadingAd } = useComplaintAdMutation();
  const { mutate: mutateEvent, isLoading: isLoadingEvent } = useComplaintEventMutation();

  const [step, setStep] = useState('specify-reason');

  const [pendingComplaint, setPendingComplaint] = useState<EventComplaint | AdComplaint | null>(null);
  const [complaint, setComplaint] = useState<EventComplaint | AdComplaint>({
    ...(type === 'event' ? { event: id } : { announcement: id }),
    recipient,
    cause: '',
    description: '',
  });

  const handleSubmitComplaint = useCallback(
    (complaintData: EventComplaint | AdComplaint) => {
      const mutationOptions = {
        onSuccess: () => setStep('confirmation'),
        onError: (error) => {
          if (error?.response?.data?.validation_errors?.code === 'ALREADY_EXISTS') {
            setStep('report-already-submitted');
          } else {
            console.error(`Error submitting complaint: ${error}`);
          }
        },
      };

      if (type === 'ad') {
        mutateAd(complaintData as AdComplaint, mutationOptions);
      } else if (type === 'event') {
        mutateEvent(complaintData as EventComplaint, mutationOptions);
      } else {
        console.error(`Invalid complaint data for ${type} complaint`);
      }
    },
    [mutateAd, mutateEvent, type],
  );

  useEffect(() => {
    if (isAuthenticated && pendingComplaint) {
      handleSubmitComplaint(pendingComplaint);
      setModalClosed(!isOpen);
      setStep('confirmation');
      setPendingComplaint(null);
    }
  }, [
    handleSubmitComplaint,
    isAuthenticated,
    isMobile,
    isOpen,
    pendingComplaint,
    setModalClosed,
  ]);

  const handleSendComplaint = () => {
    if (isAuthenticated) {
      handleSubmitComplaint(complaint);
    } else {
      setPendingComplaint(complaint);
      setStep('confirmation');
    }
  };

  const handleModalClose = () => {
    onClose();
    setStep('specify-reason');
    setComplaint({
      ...(type === 'event' ? { event: id } : { announcement: id }),
      recipient,
      cause: '',
      description: '',
    });
  };

  const renderContent = () => {
    switch (step) {
      case 'specify-reason':
        return (
          <SpecifyReason
            selectedReason={complaint.cause}
            setSelectedReason={(cause) => setComplaint({ ...complaint, cause })}
            onNext={() => setStep('describe-situation')}
            isOpen={isOpen}
            onClose={handleModalClose}
            type={type}
          />
        );
      case 'describe-situation':
        return (
          <DescribeSituation
            description={complaint.description}
            setDescription={(description) => setComplaint({ ...complaint, description })}
            handleSendComplaint={handleSendComplaint}
            isLoading={isLoadingAd || isLoadingEvent}
            isOpen={isOpen}
            onClose={handleModalClose}
            setStep={setStep}
          />
        );
      case 'confirmation':
        return (
          <ConfirmationModal
            isOpen={isOpen}
            onClose={onClose}
            onCloseModal={handleModalClose}
          />
        );
      case 'report-already-submitted':
        return (
          <ReportAlreadySubmitted
            type={type}
            isOpen={isOpen}
            onCloseModal={onClose}
          />
        );
      default:
        return (
          <SpecifyReason
            selectedReason={complaint.cause}
            setSelectedReason={(cause) => setComplaint({ ...complaint, cause })}
            onNext={() => setStep('describe-situation')}
            isOpen={isOpen}
            onClose={handleModalClose}
            type={type}
          />
        );
    }
  };

  return (
    <>
      {renderContent()}
    </>
  );
};
