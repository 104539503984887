import { Dispatch, SetStateAction, useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import {
  FormProvider, SubmitHandler, useForm,
} from 'react-hook-form';
import { Button } from '@/modules/shared/components';
import { useCreateCompany } from '@/modules/company/mutations';
import useUserAccount from '@/hooks/users/useUserAccount';
import s from './become-company.module.scss';
import { CompanyInfo } from './company-info.component';
// import { Documents } from './documents.component';
import type { BecomeCompanyFormType } from './types';
import { VerificationSection } from './verification-section.component';

type BecomeCompanyFormProps = {
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
};

export const BecomeCompanyForm = ({ setIsSuccess }: BecomeCompanyFormProps) => {
  const { t } = useTranslation();
  const { mutate, isSuccess, isPending } = useCreateCompany();
  const { userData } = useUserAccount();

  const formMethods = useForm<BecomeCompanyFormType>();
  const {
    handleSubmit,
    formState: { isValid, errors },
  } = formMethods;

  const onsubmit: SubmitHandler<BecomeCompanyFormType> = async (data) => {
    if (userData) {
      mutate({
        user: userData.id,
        name: data.title,
        description: data.description,
        logo: data.logo,
        tax_id: data.tax_id,
        // documents: data.documents,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) setIsSuccess(true);
  }, [isSuccess]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onsubmit)}
        autoComplete="off"
        id="form-become-company"
        className={s.form}
      >
        <CompanyInfo />
        <VerificationSection />
        {/* временно закомментировано
        <Documents /> */}
        <Button
          type="submit"
          appearance="primary"
          buttonColor="blue"
          buttonSize="s"
          disabled={
            !isValid
            || !!Object.keys(errors).length
            // || watch('documents').length < 3
            || isPending
          }
          className={s.submit}
        >
          {isPending ? t('common:submitting') : t('userAccount:become_company.send')}
        </Button>
      </form>
    </FormProvider>
  );
};
