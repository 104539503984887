import React, { FC, useMemo } from 'react';
import { useRouter } from 'next/router';

type Props = {
  value: number;
  precision?: number;
  showRatingSuffix?: boolean;
};

export const FloatNumber: FC<Props> = ({
  value, precision = 2, showRatingSuffix = true,
}) => {
  const { locale } = useRouter();

  const formattedNumber = useMemo(() => {
    const roundedValue = Number(value.toFixed(precision));

    const formattedValue = new Intl.NumberFormat(locale, {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    }).format(roundedValue);

    const suffix = showRatingSuffix ? ' / 5' : '';

    return `${formattedValue}${suffix}`;
  }, [value, precision, locale, showRatingSuffix]);

  return <>{formattedNumber}</>;
};
