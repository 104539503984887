import { useEffect } from 'react';
import { useAuthContext } from '@/modules/auth';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { getCookie, setCookie } from '@/components/cookie/cookieHelpers';
import { withWorkOnlyByFeature } from '../hoc/withWorkOnlyByFeature';
import { NotAuthorizedBlackFridayModal } from './not-authorized-modal';
import { AuthorizedBlackFridayModal } from './authorized-modal';

export const BlackFridayContainer = withWorkOnlyByFeature(() => {
  const router = useRouter();
  const params = useSearchParams();
  const refQuery = params.get('ref');

  useEffect(() => {
    if (!refQuery || getCookie('bzrRef')) return;

    setCookie('bzrRef', refQuery, 3);
  }, [refQuery]);

  const { isAuthenticated, isModalOpen, isLoading } = useAuthContext();
  const open = !isModalOpen
    && params.has('black-friday');
  const lsAuthKey = `black-friday-isAuth-${isAuthenticated}`;

  const handleCloseModal = (shallow: boolean) => {
    localStorage.setItem(lsAuthKey, '1');

    if (shallow) return;

    const { pathname, query } = router;
    const updatedQuery = { ...query };
    delete updatedQuery['black-friday'];

    router.replace({
      pathname,
      query: updatedQuery,
    }, undefined, { shallow: true });
  };

  useEffect(() => {
    if (open || isModalOpen) return;
    if (localStorage.getItem(lsAuthKey)) return;

    const { pathname, query } = router;
    const updatedQuery = { ...query, 'black-friday': 1 };

    router.replace({
      pathname,
      query: updatedQuery,
    }, undefined, { shallow: true });
  }, [isModalOpen, lsAuthKey, open, router]);

  if (isLoading) return null;

  return isAuthenticated
    ? <AuthorizedBlackFridayModal onClose={handleCloseModal} open={open} />
    : <NotAuthorizedBlackFridayModal onClose={handleCloseModal} open={open} />;
});
