import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import dynamic from 'next/dynamic';
import useUserAccount from '@/hooks/users/useUserAccount';
import { useRouter } from 'next/router';
import { GlobalProvider } from '@/context/GlobalContext';
import Head from 'next/head';
import { useAuthContext } from '@/modules/auth';
import { isServerSide } from '@/modules/shared/helpers';
import {
  Footer, FooterMobile, MobileNavigation, Header,
} from '@/modules/app/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import { profileBasePath } from '@/modules/shared/const';
import AdminSidebar from '../admin/AdminSidebar';
import ModeratorAside from './moderator/ModeratorAside';

const DynamicCookieModal = dynamic(
  () => import('@/modules/app/components/cookie-modal'),
  { ssr: false },
);

export default function Layout({ children }) {
  const router = useRouter();
  const isAdminPath = router.pathname.startsWith('/admin');
  const isModeratorPath = router.pathname.startsWith('/moderator');
  const isUserPath = router.pathname.startsWith(`/${profileBasePath}/`);
  const { userData } = useUserAccount();
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const { isAuthenticated } = useAuthContext();

  const updateHeaderHeight = useCallback(() => {
    if (headerRef.current) {
      requestAnimationFrame(() => {
        setHeaderHeight((prevHeight) => {
          const currentElement = headerRef.current;
          if (currentElement) {
            const newHeight = currentElement.offsetHeight;
            return newHeight !== prevHeight ? newHeight : prevHeight;
          }
          return prevHeight;
        });
      });
    }
  }, []);


  useEffect(() => {
    const resizeObserver = new ResizeObserver(updateHeaderHeight);

    if (headerRef.current) {
      resizeObserver.observe(headerRef.current);
    }

    return () => {
      if (headerRef.current) {
        resizeObserver.unobserve(headerRef.current);
      }
      resizeObserver.disconnect();
    };
  }, [updateHeaderHeight, isMobile, isTablet]);

  useEffect(() => {
    const { utm_term, oid, company } = router.query;

    if (utm_term && oid && company && !isAuthenticated) {
      localStorage.setItem('utm_term', utm_term);
      localStorage.setItem('oid', oid);
      localStorage.setItem('company', company);
    }
  }, [router.query, isAuthenticated]);

  const isLocalStorageAvailable = !isServerSide() && window.localStorage;
  const showModalInitially = isLocalStorageAvailable
    ? localStorage.getItem('showModal') !== 'false'
    : true;
  const [showModal] = useState(showModalInitially);

  return (
    <div className="global_container">
      <GlobalProvider>
        <Head>
          <link rel="icon" href="/favicon.png" />
        </Head>
        {isMobile || isTablet ? (
          <div
            ref={headerRef}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              zIndex: 1000,
              backgroundColor: '#fff',
            }}
          >
            <Header />
          </div>
        ) : (
          <Header />
        )}
        {showModal && <DynamicCookieModal />}
        <main
          className="main_sda"
          style={{
            paddingTop: isMobile || isTablet ? `${headerHeight}px` : 0,
          }}
        >
          <main
            className={
              isAdminPath || isUserPath || isModeratorPath
                ? 'layout_container'
                : 'container'
            }
          >
            {userData?.is_admin && isAdminPath && <AdminSidebar />}
            {userData?.is_moderator && isModeratorPath && <ModeratorAside />}
            {children}
          </main>
        </main>
        {isDesktop ? <Footer /> : <FooterMobile />}
        {(isMobile || isTablet) && (
          <div
            style={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              width: '100%',
              zIndex: 1000,
            }}
          >
            <MobileNavigation />
          </div>
        )}
      </GlobalProvider>
    </div>
  );
}
