import React, {
  useCallback, useRef, useState, useEffect,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { SvgIcon } from '../svg-icon';
import styles from './overflow-menu.module.scss';

export type OverflowMenuItem = {
  icon?: string;
  title: string;
  value: string;
  disabled?: boolean;
};

export type OverflowMenuProps = {
  list: OverflowMenuItem[];
  action: (value: string) => void;
};

export const OverflowMenu: React.FC<OverflowMenuProps> = ({ list, action }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const toggleMenu = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current
        && !menuRef.current.contains(event.target as Node)
        && buttonRef.current
        && !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <button
        ref={buttonRef}
        onClick={toggleMenu}
        className={styles.menu_button}
      >
        <SvgIcon name="24_menu-dots-vertical" />
      </button>

      {isOpen && (
        <div className={styles.menu_dropdown} ref={menuRef}>
          <ul>
            {list.map((item, index) => (
              <li
                key={index}
                className={clsx(styles.menu_dropdown_item, {
                  [styles.menu_dropdown_item__disabled]: item.disabled,
                })}
                onClick={() => !item.disabled && action(item.value)}
              >
                {item.icon && <SvgIcon name={item.icon} />}
                {t(item.title)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
