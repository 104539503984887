import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useTranslation from 'next-translate/useTranslation';
import { compressImage, useConvertHeicToPng } from '../helpers';
import { FormImage } from '../types';
import { SIZE_TO_COMPRESS } from '../const';

interface UseImageUploadProps {
  maxSizeInMB?: number;
  minSizeInKB?: number;
  supportedFormats?: string[];
  currentFiles?: (File | FormImage)[];
  compress?: number;
}

export const useImageUploader = ({
  maxSizeInMB = 40,
  minSizeInKB = 40,
  supportedFormats = ['image/jpeg', 'image/png', 'image/heic', 'image/webp'],
  currentFiles = [],
  compress,
}: UseImageUploadProps = {}) => {
  const { t } = useTranslation();
  const convertHeicToPng = useConvertHeicToPng();
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
  const minSizeInBytes = minSizeInKB * 1024;
  const [loading, setLoading] = useState(false);
  const sizeToCompress = compress ? compress * 1024 * 1024 : SIZE_TO_COMPRESS;

  const processFile = async (file: File): Promise<File | FormImage | null> => {
    let convertedFile: File | null = file;
    if (file.type === 'image/heic' || file.name.toLowerCase().endsWith('.heic')) {
      convertedFile = await convertHeicToPng(file);
    }
    if (convertedFile && convertedFile.size > sizeToCompress) {
      convertedFile = await compressImage(convertedFile);
    }
    return convertedFile;
  };

  const handleImageUpload = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>): Promise<(File | FormImage)[]> => {
      setLoading(true);
      if (!e.target.files) {
        setLoading(false);
        return [];
      }

      const filesArray = Array.from(e.target.files);
      const filteredImages: (File | FormImage)[] = [];

      for (const file of filesArray) {
        const extension = file.name.split('.').pop()?.toLowerCase();

        if (file.name.toLowerCase().endsWith('.heic')) {
          const processedFile = await processFile(file);
          if (processedFile) {
            filteredImages.push(processedFile);
          }
        } else if (!supportedFormats.includes(file.type)) {
          toast.error(`${t('forms:validationErrors.unsupportedImageFormat')} ${extension}`);
        } else if (file.size < minSizeInBytes) {
          toast.error(t('forms:validationErrors.photoBelowMinSize', { minSize: minSizeInKB }));
        } else if (file.size > maxSizeInBytes) {
          toast.error(t('forms:validationErrors.photoExceedsMaxSize', { maxSize: maxSizeInMB }));
        } else {
          const processedFile = await processFile(file);
          if (processedFile) {
            filteredImages.push(processedFile);
          }
        }
      }

      const newValue: (File | FormImage)[] = [...filteredImages];

      setLoading(false);
      e.target.value = '';
      return newValue;
    },
    [convertHeicToPng, currentFiles, maxSizeInBytes, minSizeInBytes, supportedFormats, t, maxSizeInMB, minSizeInKB],
  );

  return { handleImageUpload, loading };
};
