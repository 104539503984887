import { profileBasePath } from '@/modules/shared/const';

export const UNSHOW_NAV_BAR = [
  '/support',
  '/support/form',
  '/poster/[...slug]',
  `/${profileBasePath}`,
  '/events/addevents',
  'product/create-new',
];
export const EXCLUDED_ROUTES = [
  `/${profileBasePath}/personal-account`,
  `/${profileBasePath}/my-ads`,
  `/${profileBasePath}/chat`,
];
