import React from 'react';
import { useFormContext } from 'react-hook-form';
import { SvgIcon } from '@/modules/shared/components';
import { UniversalSelect } from '@/modules/shared/components/select/select.component';
import styles from './select-color.module.scss';

const options = [
  { value: 'dark_gray', label: <SvgIcon name="filled-ellipse" fill="#808080" fontSize="24" /> },
  { value: 'blue', label: <SvgIcon name="filled-ellipse" fill="#414BB2" fontSize="24" /> },
  { value: 'beige', label: <SvgIcon name="filled-ellipse" fill="#DBCA95" fontSize="24" /> },
  { value: 'black', label: <SvgIcon name="filled-ellipse" fill="#000000" fontSize="24" /> },
  { value: 'birch', label: <SvgIcon name="filled-ellipse" fill="#10A689" fontSize="24" /> },
  { value: 'red', label: <SvgIcon name="filled-ellipse" fill="#F14725" fontSize="24" /> },
  { value: 'ping', label: <SvgIcon name="filled-ellipse" fill="#F2439B" fontSize="24" /> },
  { value: 'purple', label: <SvgIcon name="filled-ellipse" fill="#652CB3" fontSize="24" /> },
  { value: 'sky', label: <SvgIcon name="filled-ellipse" fill="#6AD9E6" fontSize="24" /> },
  { value: 'orange', label: <SvgIcon name="filled-ellipse" fill="#FAC712" fontSize="24" /> },
  { value: 'yellow', label: <SvgIcon name="filled-ellipse" fill="#FEF445" fontSize="24" /> },
  { value: 'gray', label: <SvgIcon name="filled-ellipse" fill="#D2C7C7" fontSize="24" /> },
  { value: 'gold', label: <SvgIcon name="filled-ellipse" fill="#ECC819" fontSize="24" /> },
  { value: 'bard', label: <SvgIcon name="filled-ellipse" fill="#9E3D3D" fontSize="24" /> },
  { value: 'white', label: <SvgIcon name="filled-ellipse" fill="#FFF" fontSize="24" /> },
  { value: 'light_gray', label: <SvgIcon name="filled-ellipse" fill="#F2F2F2" fontSize="24" /> },
];

interface SelectColorProps {
  required?: boolean;
}

export const SelectColor = ({ required = false }: SelectColorProps) => {
  const { setValue, control } = useFormContext();

  return (
    <UniversalSelect
      name="color"
      options={options}
      control={control}
      setValue={setValue}
      className={styles.select}
      placeholder=""
      requiredFiled={required}
    >
      <SvgIcon name="dashed-ellipse" fill="transparent" fontSize="24" />
    </UniversalSelect>
  );
};
