import React, { ButtonHTMLAttributes, FC } from 'react';
import styles from './action-button.module.scss';

export const ActionButton: FC<
ButtonHTMLAttributes<HTMLButtonElement> & { type: 'button' | 'submit' }
> = ({
  className = '', onClick, type, ...props
}) => (
  <button
    type={type}
    className={`${className} ${styles.button}`}
    onClick={onClick}
    {...props}
  />
);
