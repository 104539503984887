import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { CreationDescription, CreationTitle } from '@/modules/shared/components';
import { Price } from './price.component';
import { Images } from './images.component';
import styles from '../part-of-form.module.scss';

export const DescriptionAndPhotos = () => {
  const { t } = useTranslation('createAd');

  return (
    <div className={styles.description_and_photos}>
      <div className={styles.block}>
        <h3 className={styles.block_title}>{t('descriptionAndPhotos.description')}</h3>
        <CreationTitle
          title={t('descriptionAndPhotos.adTitle')}
          placeholder={t('descriptionAndPhotos.adTitlePlaceholder')}
        />
        <CreationDescription />
      </div>
      <div className={styles.block}>
        <h3 className={styles.block_title}>{t('descriptionAndPhotos.price')}</h3>
        <Price />
      </div>
      <div className={styles.block}>
        <h3 className={styles.block_title}>{t('descriptionAndPhotos.photos.title')}</h3>
        <Images />
      </div>
    </div>
  );
};
