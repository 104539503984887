import { FormState } from '@/modules/shared/hooks';
import { ColorPicker } from '@/modules/shared/components';

import { memo } from 'react';
import { FiltersMap } from '../types';
import { FilterRow } from '../components/filter-row';
import { withDynamicFieldFormGuard } from '../hoc/withDynamicFieldFormGuard';
import { withErrorBoundary } from '../hoc/withErrorBoundary';


type ColorFieldProps = {
  filter: FiltersMap['checkbox_list'],
  form: FormState<'color'>
};

export const ColorField = withErrorBoundary(withDynamicFieldFormGuard<ColorFieldProps>(memo(({ t, filter: { choices }, form }) => (
  <FilterRow title={t('filters:color.title')}>
    <ColorPicker
      colors={choices}
      onChange={form.setter.color}
      value={Array.isArray(form.value.color) ? form.value.color : (form.value.color ? [form.value.color] : [])}
    />
  </FilterRow>
), (prev, next) => (
  prev.form.value.color === next.form.value.color
    && prev.filter === next.filter
    && prev.t === next.t
))));
