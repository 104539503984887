import { useEffect } from 'react';
import Cookie from 'js-cookie';

const CURRENT_VERSION = 'v0.1.7';

export function useCheckVersion() {
  useEffect(() => {
    const userVersion = localStorage.getItem('VERSION');
    if (userVersion !== CURRENT_VERSION) {
      const prevValue = localStorage.getItem('showModal');
      localStorage.clear();
      localStorage.setItem('showModal', prevValue);
      for (const cookie in Cookie.get()) {
        if (Object.prototype.hasOwnProperty.call(Cookie.get(), cookie)) {
          Cookie.remove(cookie);
        }
      }
      localStorage.setItem('VERSION', CURRENT_VERSION);
    }
  }, []);
}
