import React from 'react';
import clsx from 'clsx';

import { CategoryBase } from '@/modules/categories/types';
import { Label } from './label.component';
import styles from './category-item.module.scss';

interface CategoryItemProps {
  isOpen: boolean;
  isActive: boolean;
  title: string;
  activeItemId?: number;
  onClickIcon: () => void;
  onClickItem: (item: CategoryBase) => void;
  onClickCategory: () => void;
  items?: CategoryBase[]
}

export const CategoryItem = ({
  items,
  onClickItem,
  onClickIcon,
  isOpen,
  title,
  onClickCategory,
  activeItemId,
  isActive,
}: CategoryItemProps) => (
  <div className={styles.category}>
    <Label
      label={title}
      icon={items?.length ? (isOpen ? 'arrow-back-left' : 'plus') : false}
      onClickIcon={onClickIcon}
      onClickText={onClickCategory}
      className={clsx(styles.current, { [styles.no_hover]: isOpen, [styles.current_active]: isActive })}
    />
    {isOpen && !!items?.length && (
    <div className={styles.items}>
      {items.map((item) => (
        <p
          key={item.id}
          className={clsx({
            [styles.active]: activeItemId === item.id,
          })}
          onClick={() => onClickItem(item)}
        >
          {item.title}
        </p>
      ))}
    </div>
    )}
  </div>
);
