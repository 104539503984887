import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';


export type RouterListenerProps = {
  onRouteChange: (data: {
    location: ReturnType<typeof useLocation>,
    nav: NavigateFunction,
  }) => void
};

export const RouterListener = ({ onRouteChange }: RouterListenerProps) => {
  const location = useLocation();
  const nav = useNavigate();

  useEffect(() => {
    onRouteChange({
      location, nav,
    });
  }, [nav, location, onRouteChange]);

  return null;
};
