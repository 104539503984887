import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Controller, useFormContext } from 'react-hook-form';
import { Textarea } from '@/modules/shared/components';
import styles from '../../leave-feedback-modal.module.scss';

export const LeaveComment = () => {
  const { t } = useTranslation('review');
  const { control } = useFormContext();

  return (
    <div className={styles.comment}>
      <h5>{t('comment')}</h5>
      <Controller
        name="comment"
        control={control}
        render={({ field }) => (
          <Textarea
            {...field}
            name="comment"
            heightScroll={137}
            placeholder={t('yourCommentPlaceholder')}
            onChange={field.onChange}
          />
        )}
      />
    </div>
  );
};
