import React, { useCallback, useState } from 'react';
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { UniversalSelect } from '@/modules/shared/components/select/select.component';
import { ModalMobile } from '@/modules/shared/components/modal';
import { Button, RadioButton, SvgIcon } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import styles from './filter-select-by-message.module.scss';

export type FilterSelectByMessageProps<T extends FieldValues> = {
  setValue: UseFormSetValue<T>;
  control: Control;
  name: string;
  options: { value: string; label: string }[];
};

export const FilterSelectByMessage: React.FC<FilterSelectByMessageProps<FieldValues>> = ({
  setValue,
  control,
  name,
  options,
}) => {
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('');
  const [tempValue, setTempValue] = useState(options[0]);


  const handleApply = useCallback(() => {
    setIsOpen(false);
    setValue(name, tempValue.value);
  }, [tempValue]);


  return (
    <>
      {(isTablet || isDesktop) && (
        <UniversalSelect
          setValue={setValue}
          control={control}
          name={name}
          options={options}
          selectSize="s"
          defaultValue={options[0]}
          className={styles.select}
          autoSelectFirst
        />
      )}

      {isMobile && (
        <Button
          appearance="primary"
          buttonColor="grey"
          buttonSize="xs"
          onClick={() => setIsOpen(!isOpen)}
          className={styles.button_select}
        >
          <label>
            {tempValue.label}
          </label>
          <SvgIcon name="arrow-bottom" />
        </Button>
      )}

      <ModalMobile
        open={isOpen}
        onClose={() => setIsOpen(false)}
        showBackButton={false}
      >
        <div className={styles.modal_content}>
          <div className={styles.options}>
            {options.map((option) => (
              <label key={option.value} onChange={(() => setTempValue(option))}>
                <RadioButton
                  radioColor="green"
                  name="sorting"
                  value={option.value}
                  checked={tempValue.value === option.value}
                />
                {option.label}
              </label>
            ))}
          </div>
          <Button
            appearance="primary"
            buttonColor="green"
            buttonSize="s"
            onClick={handleApply}
            className={styles.modal_content_button}
          >
            {t('common:apply')}
          </Button>
        </div>
      </ModalMobile>
    </>
  );
};
