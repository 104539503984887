type BoldStringProps = {
  string: string;
  length: number | undefined;
  offset: number | undefined;
};

export const BoldString = ({ length, offset, string }: BoldStringProps) => {
  if ((length === 0 && offset === 0) || length === undefined || offset === undefined) {
    return <span>{string}</span>;
  }

  const userText = string.substring(offset, offset + length);
  const stringBefore = string.substring(0, offset);
  const stringAfter = string.substring(offset + length);

  return (
    <span>
      {stringBefore}
      <b>{userText}</b>
      {stringAfter}
    </span>
  );
};
