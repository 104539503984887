import * as yup from 'yup';
import { useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';


export const useValidationSchema = () => {
  const { t } = useTranslation('');
  const forbiddenNames = ['admin', 'administrator', 'root'];

  // email
  const validationEmail = useMemo(() => yup.object().shape({
    email: yup.string()
      .email(t('forms:validationErrors.invalidEmail'))
      .required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.email') }))
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, t('forms:validationErrors.invalidEmail')),
  }), [t]);

  // password
  const validationPassword = useMemo(() => yup.object().shape({
    password: yup.string()
      .required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.password') }))
      .min(8, t('forms:validationErrors.passwordTooShort', { length: 8 })),
  }), [t]);

  // password2
  const validationPassword2 = useMemo(() => yup.object().shape({
    password2: yup
      .string()
      .required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.password') }))
      .oneOf([yup.ref('password')], t('forms:validationErrors.passwordsMismatch')),
  }), [t]);


  // fullName
  const validationFullName = useMemo(() => yup.object().shape({
    fullName: yup
      .string()
      .required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.name') }))
      .min(2, t('forms:validationErrors.userNameTooShort'))
      .test(
        'is-not-url',
        t('forms:validationErrors.noUrlsUserName'),
        (value) => !/(https?:\/\/|www\.)/.test(value),
      )
      .trim()
      .matches(/^[\w'-\s.А-я]+$/, {
        message: t('forms:validationErrors.invalidCharsUserName'),
        excludeEmptyString: true,
      })
      .matches(/^[^._-].*[^._-]$/, {
        message: t('forms:validationErrors.invalidEdgeCharsUserName'),
        excludeEmptyString: true,
      })
      .notOneOf(forbiddenNames, t('forms:validationErrors.forbiddenName')),
  }), [forbiddenNames, t]);


  return {
    validationEmail,
    validationPassword,
    validationPassword2,
    validationFullName,
  };
};

