import { MINUS_SVG, PLUS_SVG } from './const';

const getStyledButton = (type: '+' | '-') => {
  const button = document.createElement('button');

  button.style.display = 'flex';
  button.style.justifyContent = 'center';
  button.style.alignItems = 'center';
  button.style.width = '32px';
  button.style.height = '32px';
  button.style.backgroundColor = 'white';
  button.style.borderRadius = type === '+'
    ? 'var(--bzr-radius-xs) var(--bzr-radius-xs) 0 0'
    : '0 0 var(--bzr-radius-xs) var(--bzr-radius-xs)';
  button.style.cursor = 'pointer';
  button.type = 'button';

  const svg = document.createElement('svg');
  svg.innerHTML = type === '+' ? PLUS_SVG : MINUS_SVG;
  svg.style.width = '24px';
  svg.style.height = '24px';
  svg.style.color = 'var(--bzr-text-medium-grey)';
  svg.addEventListener('mouseover', () => {
    svg.style.color = 'var(--bzr-text-dark-black-primary)';
  });
  svg.addEventListener('mouseout', () => {
    svg.style.color = 'var(--bzr-text-medium-grey)';
  });

  button.appendChild(svg);

  return button;
};

export const createZoomControl = (map: google.maps.Map) => {
  const wrapper = document.createElement('div');
  wrapper.style.display = 'flex';
  wrapper.style.flexDirection = 'column';
  wrapper.style.alignItems = 'center';
  wrapper.style.boxShadow = '0px 4px 12px 0px #09090926, 0px 0px 1px 0px #2020200D';
  wrapper.style.margin = '0 12px 4px 0';
  wrapper.style.borderRadius = 'var(--bzr-radius-xs)';
  wrapper.style.backgroundColor = 'white';

  const increaseButton = getStyledButton('+');
  const decreaseButton = getStyledButton('-');

  increaseButton.addEventListener('click', () => {
    map.setZoom(map.getZoom()! + 1);
  });
  decreaseButton.addEventListener('click', () => {
    map.setZoom(map.getZoom()! - 1);
  });

  const divider = document.createElement('span');
  divider.style.width = '24px';
  divider.style.height = '0.5px';
  divider.style.backgroundColor = 'var(--bzr-secondary-light-grey)';

  wrapper.appendChild(increaseButton);
  wrapper.appendChild(divider);
  wrapper.appendChild(decreaseButton);

  return wrapper;
};
