import { useQuery } from '@tanstack/react-query';
import type { Company } from '../types';
import { CompanyApi } from '../api';
import { USER_COMPANY_KEY } from '../const';

export const useUserCompanyById = (id: number | string | undefined) => useQuery<Company>({
  queryKey: [USER_COMPANY_KEY, id],
  queryFn: () => CompanyApi.getUserCompanyById({ id }),
  enabled: Boolean(id),
  refetchOnWindowFocus: false,
});
