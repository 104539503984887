// бэк не поддерживает разные размеры одежды и обуви
import useTranslation from 'next-translate/useTranslation';

export const usePersonalItems = () => {
  const { t } = useTranslation('ad-parameters');

  const mapState = (value:string) => ({ value, label: t(`personal-items.conditions.${value}`) });
  const mapBrand = (value:string) => ({ value, label: t(`personal-items.brands.${value}`) });

  const mapWomenClothesSize = (value:string) => ({ value, label: t(`personal-items.women-clothes.size.${value}`) });
  const mapMenClothesSize = (value:string) => ({ value, label: t(`personal-items.men-clothes.size.${value}`) });

  const mapWomenShoesSize = (value:string) => ({ value, label: t(`personal-items.women-shoes.size.${value.replaceAll('.', ',')}`) });
  const mapMenShoesSize = (value:string) => ({ value, label: t(`personal-items.men-shoes.size.${value.replaceAll('.', ',')}`) });

  const mapChildrenClothesSize = (value:string) => ({ value, label: t(`personal-items.children-clothes.size.${value}`) });
  const mapAccessoriesGender = (value:string) => ({ value, label: t(`personal-items.accessories.gender.${value}`) });

  return {
    state: ['new', 'excellent', 'good', 'satisfactory'].map(mapState),
    brands: [
      'Other', 'Adidas', 'Aldo', 'American Eagle Outfitters', 'Armani', 'ASOS',
      'Audemars Piguet', 'Banana Republic', 'Bogner', 'Breguet', 'Brunello Cucinelli',
      'Burberry', 'C&A', 'Calvin Klein', 'Cartier', 'Chanel', 'Chopard',
      'Chow Tai Fook', 'Christian Louboutin', 'Coach', 'Cole Haan', 'Desigual',
      'Diesel', 'Dior', 'Dolce & Gabbana', 'Elie Saab', 'Elie Tahari', 'ESCADA',
      'Fendi', 'Forever 21', 'Foot Locker Inc.', 'Fossil', 'Furla', 'G-star',
      'GAP', 'Gucci', 'H&M', 'Hermès', 'Hugo Boss', 'IWC Schaffhausen',
      'Jaeger-Le Coultre', 'Jimmy Choo', 'Lacoste', 'Levi’s', 'Lululemon',
      'Louis Vuitton', 'Macy’s', 'Manolo Blahnik', 'Max Mara', 'Michael Kors',
      'Moncler', 'Net-a-Porter', 'New Balance', 'New Look', 'Next', 'Nike',
      'Nine West', 'Nordstrom', 'Oakley', 'Off White', 'Old Navy', 'Omega',
      'Patek Philippe', 'Patagonia', 'Primark', 'Prada', 'Polo Ralph Lauren',
      'Puma', 'Ray-Ban', 'Rolex', 'Salvatore Ferragamo', 'Skechers', 'Sisley',
      'Steve Madden', 'Stuart Weitzman', 'Swarovski', 'Swatch', 'Ted Baker',
      'The North Face', 'Tiffany & Co.', 'Tissot', 'TOD’s', 'Tom Ford',
      'Tommy Hilfiger', 'Topshop', 'Tory Burch', 'TJ Maxx', 'Under Armour',
      'UNIQLO', 'Urban Outfitters', 'Valentino', 'Vacheron Constantin',
      'Vera Wang', 'Victoria’s Secret', 'Zalando', 'Zara',
    ].map(mapBrand),
    'women-clothes': {
      size: ['xs', 's', 'm', 'l', 'xl', 'xxl', 'xxxl', 'one_size'].map(mapWomenClothesSize),
    },
    'men-clothes': {
      size: ['s', 'm', 'l', 'xl', 'xxl', 'xxxl', 'one_size'].map(mapMenClothesSize),
    },
    'women-shoes': {
      size: [
        '35 (5)', '35.5 (5.5)', '36 (6)', '36.5 (6.5)', '37 (7)', '37.5 (7.5)', '38 (8)',
        '38.5 (8.5)', '39 (9)', '39.5 (9.5)', '40/41 (10)', '41 (10.5)', '41/42 (11)',
      ].map(mapWomenShoesSize),
    },
    'men-shoes': {
      size: [
        '40 (7)', '40.5 (7.5)', '41 (8)', '41.5 (8.5)', '42 (9)', '42.5 (9.5)', '43 (10)',
        '43.5 (10.5)', '44 (11)', '44.5 (11.5)', '45 (12)', '46 (13)', '47 (14)',
      ].map(mapMenShoesSize),
    },
    'children-clothes': {
      size: [
        '50-56 (3 months)', '62-68 (6 months)', '74-80 (9 months)', '86-92 (1-3 years)',
        '98-104 (4 years)', '110-122 (5-7 years)', '128-152 (8-12 years)', '158-164 (14-17 years)',
      ].map(mapChildrenClothesSize),
    },
    accessories: {
      gender: ['male', 'female', 'unisex'].map(mapAccessoriesGender),
    },
  };
};
