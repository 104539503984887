import { useMutation } from '@tanstack/react-query';
import { SettingsApi } from '../api';
import { PrivaciesTypes } from '../types';

export const useChangePrivacies = () => {
  const {
    mutate,
    data: privacies,
  } = useMutation({
    mutationFn: async (privacySettings: PrivaciesTypes) => {
      const data = await SettingsApi.changePrivacies(privacySettings);
      return data;
    },
  });

  return { changePrivacies: mutate, privacies };
};
