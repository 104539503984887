import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { useAuthContext } from '@/modules/auth';
import { CountNotification, SvgIcon } from '@/modules/shared/components';
import type { Me } from '@/modules/me/types';
import { profileBasePath } from '@/modules/shared/const';
import { UserTypeEnum } from '@/modules/users';
import { CompanyStatusEnum, type CompanyStatus } from '@/modules/company/types';
import { useChatDataContext } from '@/modules/chat';
import { useFavoritesContext } from '@/modules/favorites';
import { MenuBlackFridayBanner } from '@/modules/contests/black-friday/ui/menu-banner';
import styles from '../side-menu.module.scss';

interface Props {
  user: Me;
  status: CompanyStatus | undefined;
}

export const UserSidebarLinks = ({ user, status }: Props) => {
  const { t } = useTranslation('common');
  const { logout } = useAuthContext();
  const { unreadDialogsCount } = useChatDataContext();
  const { totalCount } = useFavoritesContext();

  const router = useRouter();

  const handleLogout = () => {
    sessionStorage.removeItem('returning-my-ads-page');
    logout();
  };

  return (
    <div className={styles.links}>
      <MenuBlackFridayBanner />
      <div className={styles.menu_block}>
        <Link
          href={{
            pathname: `/${profileBasePath}/my-ads`,
            query: router?.query?.tab && { tab: router?.query?.tab },
          }}
          className={router.asPath.includes(`/${profileBasePath}/my-ads`) ? styles.active_link : ''}
        >
          <SvgIcon name="my-ads" />
          {t('userSidebar.myAds')}
        </Link>
        <Link
          href={`/${profileBasePath}/posters`}
          className={router.asPath.includes(`/${profileBasePath}/posters`) ? styles.active_link : ''}
        >
          <SvgIcon name="events" className={styles.custom_svg} />
          {t('userSidebar.myEvents')}
        </Link>
        <Link
          href={`/${profileBasePath}/favorites`}
          className={clsx(styles.favourite, {
            [styles.active_link]: router.asPath.includes(`/${profileBasePath}/favorites`),
          })}
        >
          <span>
            <SvgIcon name="favourite-icon" />
            {t('userSidebar.favs')}
          </span>
          <CountNotification count={totalCount} type="sidebar" color="grey" />
        </Link>
      </div>
      <div className={styles.menu_block}>
        <Link
          href={`/${profileBasePath}/chat`}
          className={clsx(styles.chat, {
            [styles.active_link]: router.asPath.includes(`/${profileBasePath}/chat`),
          })}
        >
          <span>
            <SvgIcon name="chat" />
            {t('userSidebar.messages')}
          </span>
          <CountNotification count={unreadDialogsCount} type="sidebar" color="red" />
        </Link>
        <Link
          href={`/${profileBasePath}/my-feedback`}
          className={router.asPath.includes(`/${profileBasePath}/my-feedback`) ? styles.active_link : ''}
        >
          <SvgIcon name="feedback" className={styles.custom_svg} />
          {t('userSidebar.feedback')}
        </Link>
      </div>
      <div className={styles.menu_block}>
        {(user?.user_type !== UserTypeEnum.Company || status === CompanyStatusEnum.Pending) && (
          <Link
            href={`/${profileBasePath}/become-company`}
            className={router.asPath.includes(`/${profileBasePath}/become-company`) ? styles.active_link : ''}
          >
            <SvgIcon name="company" />
            {t('userSidebar.company')}
          </Link>
        )}
        <Link
          href={`/${profileBasePath}/account`}
          className={router.asPath.includes(`/${profileBasePath}/account`) ? styles.active_link : ''}
        >
          <SvgIcon name="accounting-information" />
          {t('userSidebar.credentials')}
          {(!user.email || !user.is_email_verified) && (
            <SvgIcon name="alert" className={styles.alert_svg} />
          )}
        </Link>
        <Link
          href={`/${profileBasePath}/settings`}
          className={router.asPath.includes(`/${profileBasePath}/settings`) ? styles.active_link : ''}
        >
          <SvgIcon name="setting" />
          {t('userSidebar.settings')}
        </Link>
      </div>
      <div className={styles.menu_block}>
        <Link href="/" onClick={handleLogout}>
          <SvgIcon name="exit" className={styles.custom_svg} />
          {t('userSidebar.logout')}
        </Link>
      </div>
    </div>
  );
};
