const QUALITY = 0.7;
const MIME_TYPE = 'image/jpeg';

export const compressImage = async (file: File): Promise<File | null> => {
  const dataUrl = await new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = function () {
        const { width, height } = img;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;
        ctx?.drawImage(img, 0, 0, width, height);

        resolve(canvas.toDataURL(MIME_TYPE, QUALITY));
      };
      img.src = event.target?.result as string;
    };
    reader.readAsDataURL(file);
  });

  const response = await fetch(dataUrl);
  const blob = await response.blob();
  return new File([blob], 'image.jpeg', { type: MIME_TYPE });
};
