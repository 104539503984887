type FormatDate = {
  date: string;
  t: (key: string, opts?: Record<string, unknown>) => string;
  translateMonth: string;
  translateYear: string;
  withDay?: boolean;
};

// format: марта 2024г.
export const formatDateMonthYear = ({
  date,
  t,
  withDay = false,
  translateMonth,
  translateYear,
}: FormatDate): string => {
  const [day, month, year] = date.split('.');
  const formattedDay = withDay ? parseInt(day, 10) : '';
  return `${formattedDay} ${t(`${translateMonth}.${+month - 1}`)} ${year}${t(translateYear)}`;
};
