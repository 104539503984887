import React from 'react';
import { ItemLoader } from '../../../shared/components';

interface IProps {
  loadingAvatar: boolean;
  previewImage?: string;
}

export const LoadingAvatarIndicator = ({ previewImage, loadingAvatar }: IProps) => (
  loadingAvatar
    ? <ItemLoader isLoading={loadingAvatar} size={65} />
    : <img src={previewImage || ''} alt="new avatar" />
);
