import {
  useCallback, useEffect, useState,
} from 'react';
import { Fade } from '@mui/material';
import { Modal as BaseModal } from '@mui/base/Modal';
import clsx from 'clsx';

import { ModalProps } from './types';
import { ModalRoot } from './root/modal-root';
import { SvgIcon } from '../svg-icon';
import { ModalBackDrop } from './back-drop/modal-back-drop';

import styles from './modal-desktop.module.scss';

export const ModalDesktop = ({
  children,
  title,
  onClose,
  showBackButton,
  showCloseButton = true,
  onClickBack,
  className,
  classNameHeaderContainer,
  classNameHeader,
  titleJustifyContent = 'space-between',
  open = false,
  isClean,
  hasHeader = true,
  isMobile = false,
  closeButtonDelay,
}: ModalProps) => {
  const [isCloseVisible, setIsCloseVisible] = useState(false);

  useEffect(() => {
    if (showCloseButton) {
      if (closeButtonDelay && closeButtonDelay > 0) {
        const timer = setTimeout(() => {
          setIsCloseVisible(true); // показываем кнопку после задержки
        }, closeButtonDelay);

        return () => clearTimeout(timer);
      }
      setIsCloseVisible(true); // показываем кнопку сразу, если таймер не задан
    } else {
      setIsCloseVisible(false); // скрываем кнопку, если showCloseButton === false
    }
  }, [closeButtonDelay, showCloseButton]);
  const handleClose = useCallback(() => {
    // если задан таймер и он еще не прошел, запрещаем закрытие модалки при клике на задний фон
    if (!closeButtonDelay || isCloseVisible) {
      onClose?.(false);
    }
  }, [isCloseVisible, closeButtonDelay, onClose]);

  return (
    <BaseModal
      open={open}
      closeAfterTransition
      onClose={handleClose}
      slots={{
        backdrop: ModalBackDrop,
        root: ModalRoot,
      }}
    >
      <Fade in={open}>
        <div className={clsx(styles.modal, className)}>
          <div className={clsx(
            styles.modal__box,
            { [styles.modal__box_clean]: isClean },
            { [styles.modal__box_mobile]: isMobile },
          )}
          >
            {hasHeader && (
              <div className={clsx(
                styles.modal__header,
                classNameHeaderContainer,
                { [styles.modal__header_contentCenter]: titleJustifyContent === 'center' },
                { [styles.modal__header_titleStart]: titleJustifyContent === 'start' },
              )}
              >
                <div className={styles.modal__action}>
                  {showBackButton && (
                    <button onClick={onClickBack}>
                      <SvgIcon name="arrow-back" />
                    </button>
                  )}
                </div>
                <div className={clsx(styles.modal__title, classNameHeader)}>
                  {title}
                </div>
                <div className={styles.modal__action} />
              </div>
            )}
            <div>
              {children}
            </div>
          </div>
          {showCloseButton && (
            <button
              onClick={handleClose}
              className={clsx(styles.modal__close, {
                [styles.modal__close_visible]: isCloseVisible,
              })}
            >
              <SvgIcon name="close-icon" />
            </button>
          )}
        </div>
      </Fade>
    </BaseModal>
  );
};
