import React, { useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import { Notification, SvgIcon } from '@/modules/shared/components';
import { profileBasePath } from '@/modules/shared/const';
import { useMatchMedia } from '@/modules/shared/hooks';
import { IAd } from '@/modules/ads/types';
import { useMeContext } from '@/modules/me';
import { ConfirmEmailNotification } from '@/modules/app/components/personal-account/confirm-email-notification';
import { Announcement, UserContact } from '../../../types';
import { ChatImage } from '../../chat-image';
import { ButtonBack } from '../../button-back';
import { ChatName } from '../../chat-name';
import { AdName } from '../../ad-name';
import { ButtonRequestReview } from '../button-request-review';
import styles from './chat-top.module.scss';

export type ChatTopProps = {
  recipient: UserContact | null;
  typeChat?: 'support' | 'user';
  ad?: IAd;
  canRequestReviews: boolean;
};

export const ChatTop = ({
  recipient, typeChat, ad, canRequestReviews,
}: ChatTopProps) => {
  const { t } = useTranslation('');
  const { me } = useMeContext();
  const { isDesktop } = useMatchMedia();

  const modifiedAd: Announcement | null = useMemo(() => {
    if (ad) {
      const targetImage = ad.images.find((el) => el.id === ad.main_picture as number);
      return {
        ...ad,
        is_active: ad.is_active as boolean,
        is_banned: ad.is_banned as boolean,
        id: ad.id,
        price_type: ad.price_type as string,
        main_picture: {
          id: ad.main_picture as number,
          image: targetImage?.image || '',
          thumbnail: targetImage?.thumbnail || '',
          user: ad.user,
        },
        category_codename: ad.category.codename as string,
        sub_category_codename: ad?.sub_category?.codename as string,
        under_sub_category_codename: ad?.under_sub_category?.codename as string,
      };
    }
    return null;
  }, [ad]);

  return (
    <>
      <div className={styles.wrapper}>
        <ButtonBack className={styles.wrapper_buttonback} />

        {typeChat === 'user' && recipient && (
          <div className={styles.section}>
            <div className={clsx(styles.user, { [styles.user_ad]: ad })}>
              <ChatImage
                announcement={modifiedAd}
                recipient={recipient}
                oneChat
                link
              />
              <div className={styles.info}>
                <ChatName
                  recipient={recipient}
                  me={me ?? null}
                  announcement={modifiedAd ?? null}
                  oneChat
                  classNameWrapper={styles.info_name}
                  link
                />
                {ad && (
                <AdName
                  announcement={modifiedAd}
                  oneChat
                  link
                />
                )}
              </div>
            </div>
            {ad && ad.user === me?.id && (
            <ButtonRequestReview
              reviewerId={recipient.id as number}
              adId={ad?.id}
              canRequestReviews={canRequestReviews}
            />
            )}
          </div>
        )}

        {typeChat === 'support' && (
          <div className={styles.support}>
            <div className={styles.support_logo}>
              <SvgIcon
                name={`logo-main-${isDesktop ? 'small' : 'xs'}`}
                className={styles.support_logo_svg}
              />
            </div>
            <p className={styles.support_title}>{t('chat:supportTitle')}</p>
          </div>
        )}

      </div>
      {!isDesktop && (
        !me?.email ? (
          <Notification
            mainText={t('common:emailNotification.bindEmail')}
            link={t('common:emailNotification.bindEmailLink')}
            href={`/${profileBasePath}/account?email=add`}
          />
        ) : !me?.is_email_verified && (
          <ConfirmEmailNotification email={me?.email} />
        )
      )}
    </>
  );
};
