import {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useCityPrefix } from '@/context/CityPrefixContext';
import { CategoryBase, SubCategory } from '@/modules/categories/types';
import { SectionSelectionMobile } from '@/modules/app/components/categories/section-selection-mobile';
import { useCategories } from '@/modules/categories';
import { SelectCategory } from './select-category.component';
import styles from './switch-category.module.scss';
import { CategoryType } from './types';

interface SwitchCategoryProps {
  category: CategoryType;
  subcategory: CategoryType;
  undersubcategory: CategoryType;
}

export const SwitchCategory = memo((props: SwitchCategoryProps) => {
  const { category, subcategory, undersubcategory } = props;
  const { t } = useTranslation();

  const [selectCategoryVisible, setSelectCategoryVisible] = useState(false);
  const { categories } = useCategories();
  const [currentCategory, setCurrentCategory] = useState<CategoryType>();
  const [currentSubCategory, setCurrentSubCategory] = useState<CategoryType>();

  const { cityPrefix } = useCityPrefix();
  const initValue = useMemo(() => ({
    codename: 'all',
    title: t('common:allCategories'),
    pathname: `/${cityPrefix}/catalog/all`,
  }), [cityPrefix, t]);

  const buttonTitle = useMemo(() => {
    if (category && !subcategory && !undersubcategory) {
      return category;
    } if (category && subcategory && !undersubcategory) {
      return subcategory;
    } if (category && subcategory && undersubcategory) {
      return undersubcategory;
    }
    return initValue;
  }, [category, subcategory, undersubcategory, initValue]);

  useEffect(() => {
    if (category) setCurrentCategory(category);
    // чтобы корректно отобразить подкатегории у которых нет вложенности,
    // сохраняем категорию в состояние только если она имеет подПодКатегории,
    // даже если в url есть subcategory
    if (subcategory && (subcategory as SubCategory).under_sub_categories.length > 0) {
      setCurrentSubCategory(subcategory);
    }
  }, [category, subcategory, selectCategoryVisible]);
  // selectCategoryVisible здесь нужен для того, чтобы при повторном открытии модального окна
  // снова показывался список с текущей категорией,
  // в случае если пользователь "листал" категории назад

  const handleToggleSelectCategory = useCallback(() => {
    setSelectCategoryVisible((current) => !current);
  }, []);

  const handleClickBack = useCallback(() => {
    if (currentCategory && currentSubCategory) {
      setCurrentSubCategory(undefined);
      return;
    }
    if (currentCategory && !currentSubCategory) {
      setCurrentSubCategory(undefined);
      setCurrentCategory(undefined);
    }
  }, [currentCategory, currentSubCategory]);

  const handleCategoryClick = useCallback((cat: CategoryBase) => {
    setCurrentCategory(cat);
  }, []);
  const handleSubCategoryClick = useCallback((cat: CategoryBase) => {
    setCurrentSubCategory(cat);
  }, []);

  return (
    <div className={styles.switch_category}>
      <SectionSelectionMobile
        data={buttonTitle as CategoryBase}
        onClick={handleToggleSelectCategory}
      />
      <SelectCategory
        selectCategoryVisible={selectCategoryVisible}
        handleToggleSelectCategory={handleToggleSelectCategory}
        categories={categories}
        currentCategory={currentCategory}
        currentSubCategory={currentSubCategory}
        handleClickBack={handleClickBack}
        handleCategoryClick={handleCategoryClick}
        handleSubCategoryClick={handleSubCategoryClick}
      />
    </div>
  );
});
