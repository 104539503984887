import React, { useEffect, useState } from 'react';
import { ModalMobile, ModalDesktop } from '@/modules/shared/components';
import { ModalProps } from '@/modules/shared/components/modal';

export const CustomModal = (props: ModalProps) => {
  const [customMobile, setCustomMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setCustomMobile(window.innerWidth < 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return customMobile ? <ModalMobile {...props} /> : <ModalDesktop {...props} />;
};
