export const MAX_DAYS = process.env.NEXT_PUBLIC_PHONE_NUMBER_CHANGE_LIMIT_DAYS || '30';

export const getDays = (dateStr: string) => {
  const date = new Date(dateStr);
  date.setTime(date.getTime() + +MAX_DAYS * 24 * 60 * 60 * 1000);
  const today = new Date();
  const diffInMs = +date - +today;
  const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
  return diffInDays >= 0 ? diffInDays : undefined;
};
