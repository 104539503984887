import {
  useEffect, useRef, useState,
} from 'react';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';

import { Footer } from '../footer';
import type { LayoutProps } from './types';

import styles from './layout.module.scss';

export const DesktopLayout = ({
  hasNotAppliedFilters,
  hasFilters,
  handleBtnFiltering,
  handleReset,
  children,
}: Omit<LayoutProps, 'isVisible' | 'onChangeVisible'>) => {
  const [isFixed, setIsFixed] = useState(false);
  const [isFooterShowing, setIsFooterShowing] = useState(false);
  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const invisibleTrigger = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!invisibleTrigger.current) return;
    const element = invisibleTrigger.current;
    const footerElement = document.getElementsByTagName('footer')[0];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (element === entry.target) {
            setIsFixed(!entry.isIntersecting);
          } else if (footerElement === entry.target) {
            setIsFooterShowing(entry.isIntersecting);
          }
        });
      },
      { threshold: 0, rootMargin: '-90px 0px 0px 0px' },
    );

    observer.observe(element);
    observer.observe(footerElement);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className={clsx(styles.container, styles.desktop)}>
      <div ref={invisibleTrigger} />
      <div
        ref={wrapperRef}
        className={clsx(styles.wrapper, {
          [styles.wrapper_fixed]: isFixed,
          [styles.wrapper_footerShowing]: isFixed && isFooterShowing,
        })}
      >
        <h2 className={styles.title}>{t('filters-components:title.title')}</h2>
        {children}
        <Footer
          hasNotAppliedFilters={hasNotAppliedFilters}
          hasFilters={hasFilters}
          onClickApply={handleBtnFiltering}
          onClickReset={handleReset}
        />
      </div>
    </div>
  );
};
