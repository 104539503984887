import { useEffect, useState } from 'react';
import { getFeatureFlags } from './use-feature-flags.const';

export const useFeatureFlags = () => {
  const [featureFlags, setFeatureFlags] = useState<Record<string, unknown>>({});

  // it's not necessary to use useEeffect or even useMemo here.
  // but it can be possible that in future we will switch to LaunchDarkly or an alternative
  // and thus will have to use useEffect, so.
  useEffect(() => {
    const setFlags = async () => {
      setFeatureFlags(await getFeatureFlags());
    };
    setFlags();
  }, [setFeatureFlags]);
  return featureFlags;
};
