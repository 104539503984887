import { useMemo } from 'react';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { SvgIcon } from '@/modules/shared/components';
import { getDeclension } from '@/modules/shared/helpers';
import { UserData } from '@/modules/ads/types';
import { UserTypeEnum } from '@/modules/users/types';
import styles from './ad-card.module.scss';

type LabelsProps = {
  userData: UserData;
};

export const Labels = ({ userData }: LabelsProps) => {
  const { t } = useTranslation();
  const {
    user_type, average_rating, ratings_count, company_id,
  } = userData;
  const type = company_id ? user_type : UserTypeEnum.Individual;

  const reviewsCount = useMemo(() => (
    getDeclension({
      count: ratings_count,
      t,
      singular: 'common:reviews.one',
      few: 'common:reviews.few',
      many: 'common:reviews.many',
    })
  ), [t, ratings_count]);

  return (
    <div className={styles.labels}>
      <div
        className={clsx(styles.label, styles.type, {
          [styles.type_individual]: type === UserTypeEnum.Individual,
        })}
      >
        <SvgIcon name={type === UserTypeEnum.Individual ? 'user-16' : 'check-company-16'} />
        <p>{t(`common:${type}`)}</p>
      </div>
      <div className={clsx(styles.label, styles.rating)}>
        <SvgIcon
          name="star-16"
          style={{
            color: average_rating
              ? 'var(--bzr-main-green-default)'
              : 'var(--bzr-secondary-light-grey)',
          }}
        />
        <p>
          <span>{average_rating}</span>
          {' / '}
          {reviewsCount}
        </p>
      </div>
    </div>
  );
};
