import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useCategories } from '@/modules/categories';
import { Tag } from '@/modules/shared/components';
import useTranslation from 'next-translate/useTranslation';
import { useMatchMedia } from '@/modules/shared/hooks';
import { Controller, useFormContext } from 'react-hook-form';
import { SortButtonMobile } from '../categories/sorting/sort-button-mobile.component';
import styles from './filtering-by-category.module.scss';

type Quantity = {
  all: number;
  category: Record<number, number>;
};

interface FilteringProps {
  quantity?: Quantity;
  isLoading?: boolean;
  selectedCategory: number | undefined;
  onSelectAllCategories: () => void;
  onSelectCategory: (category: number) => void;
}

export const FilteringByCategory = ({
  quantity,
  isLoading,
  selectedCategory,
  onSelectCategory,
  onSelectAllCategories,
}: FilteringProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();
  const { categories } = useCategories();
  const { control, setValue } = useFormContext<{ sort: string }>();

  const userCategories = useMemo(() => {
    if (quantity && quantity?.category) {
      return categories.filter((category) => quantity?.category[category.id]);
    }
    return [];
  }, [categories, quantity]);

  const options = useMemo(() => [
    { value: '-created_at', label: t('adv:filters.sortingModal.newest') },
    { value: 'created_at', label: t('adv:filters.sortingModal.oldest') },
    { value: '-price', label: t('adv:filters.sortingModal.decreasing') },
    { value: 'price', label: t('adv:filters.sortingModal.increasing') },
  ], [t]);

  return (
    <div className={styles.tags}>
      {isMobile && (
        <Controller
          control={control}
          name="sort"
          render={({ field: { onChange } }) => (
            <SortButtonMobile
              setValue={(value) => {
                onChange(value);
                setValue('sort', value);
              }}
              options={options}
              isOnlyIcon
            />
          )}
        />
      )}
      <Tag
        label={`${t('common:all')} ${isLoading || !quantity?.all ? '' : `(${quantity?.all})`}`}
        onClick={onSelectAllCategories}
        color={!selectedCategory ? 'green-default' : 'very-light-grey'}
        type="statusM"
        className={clsx(styles.tag, {
          [styles.active_tag]: !selectedCategory,
        })}
      />
      {userCategories.map((category) => (
        <Tag
          key={category.id}
          label={`${category.title} (${quantity?.category[category.id]})`}
          onClick={() => onSelectCategory(category.id)}
          color={
            selectedCategory === category.id
              ? 'green-default'
              : 'very-light-grey'
          }
          className={clsx(styles.tag, {
            [styles.active_tag]: selectedCategory === category.id,
          })}
          type="statusM"
        />
      ))}
    </div>
  );
};
