import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { SvgIcon } from '@/modules/shared/components/svg-icon';
import { Button } from '@/modules/shared/components/button';
import { EMAIL, PHONE_NUMBER } from '../footer/footer.const';
import s from './contact-links.module.scss';

export const ContactLinks = () => {
  const { t } = useTranslation();

  return (
    <section className={s.contact}>
      <Link href={`tel:${PHONE_NUMBER}`} className={s.phone}>
        <Button appearance="primary" buttonSize="s" buttonColor="grey">
          <SvgIcon name="phone" />
          <span>{t('common:footer.contactUs')}</span>
        </Button>
      </Link>
      <Link href={`mailto:${EMAIL}`} className={s.email}>
        <SvgIcon name="mail" />
        <span>{EMAIL}</span>
      </Link>
    </section>
  );
};
