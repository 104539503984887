import React, { FC } from 'react';
import Link from 'next/link';
import { useCleanTextWithLinks } from '../../hooks';
import styles from './text-with-links.module.scss';


type Props = {
  text: string;
  whiteListedDomains: string[];
};
export const TextWithLinks: FC<Props> = ({ text, whiteListedDomains }) => {
  const { text: cleanText } = useCleanTextWithLinks(text, whiteListedDomains);

  return (
    <>
      {cleanText.map((part, index) => (part.type === 'text' ? part.value : (
        <Link className={styles.link} key={index} href={part.value} target="_blank">
          {part.value}
        </Link>
      )))}
    </>
  );
};
