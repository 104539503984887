import { useMemo, useCallback } from 'react';
import { REVIEW_IS_REQUESTED, THANK_YOU_FOR_REVIEW } from '../const';
import { MessageDetails } from '../types';

export const useCanRequestReviews = (messages: MessageDetails[]) => {
  const messageSet = useMemo(
    () => new Set(messages.map((msg) => msg.message)),
    [messages],
  );

  const canRequestReviews = useCallback(
    () => messageSet.has(THANK_YOU_FOR_REVIEW)
      || messageSet.has(REVIEW_IS_REQUESTED),
    [messageSet],
  );

  return canRequestReviews;
};
