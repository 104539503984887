import React from 'react';
import Link from 'next/link';
import { SvgIcon } from '@/modules/shared/components';
import styles from '../personal-account-mobile.module.scss';

interface Props {
  title: string;
  secondaryText: string;
  href: string;
  secondaryActive?: boolean;
  style?: React.CSSProperties;
}

export const UserMenuLink = (props: Props) => {
  const {
    title, secondaryText, href, secondaryActive = false, style,
  } = props;

  return (
    <Link href={href} className={styles.link} style={style}>
      <div className={styles.text_wrapper}>
        <p>{title}</p>
        <span className={secondaryActive ? styles.secondary_active : ''}>
          {secondaryText}
        </span>
      </div>
      <SvgIcon name="arrow-forward-right" />
    </Link>
  );
};
