import { isServerSide } from '@/modules/shared/helpers';

type CityInfo = {
  city?: string;
  userSet: boolean;
};

const cityInfoKey = 'selectedCity';

export const getInitialCityInfo = (): CityInfo => {
  if (isServerSide()) {
    return { city: 'allcities', userSet: false };
  }
  const selectedCityStr = localStorage.getItem(cityInfoKey);
  if (!selectedCityStr) {
    return { city: 'allcities', userSet: false };
  }
  try {
    return JSON.parse(selectedCityStr);
  } catch {
    return { city: selectedCityStr, userSet: true };
  }
};

export const setInitialCityInfo = (cityInfo: CityInfo) => !isServerSide() && localStorage.setItem(cityInfoKey, JSON.stringify(cityInfo));
