import React, { memo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import s from './creation-contacts.module.scss';
// import { ContactType } from './contact-type.component';
import { MediaContacts } from './media-contacts.component';
import { PhoneContact } from './phone-contact.component';

type CreationContactsProps = {
  isEventCreation?: boolean;
  setIsAdPhoneVerified?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreationContacts = memo(({ isEventCreation = false, setIsAdPhoneVerified }: CreationContactsProps) => {
  const { t } = useTranslation();

  return (
    <div className={s.field}>
      <h2>{t('common:footer.contact')}</h2>
      <PhoneContact setIsAdPhoneVerified={setIsAdPhoneVerified} isEventCreation={isEventCreation} />
      {isEventCreation && <MediaContacts />}
      {/* временно закомментировано */}
      {/* {!isEventCreation && <ContactType />} */}
    </div>
  );
});
