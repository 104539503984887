import {
  forwardRef, PropsWithChildren, RefObject,
} from 'react';
import { Fade } from '@mui/material';

import styles from './modal-back-drop.module.scss';

export const ModalBackDrop = forwardRef(({
  ownerState: { open },
  ...props
}: PropsWithChildren<{ ownerState: { open: boolean } }>, ref: RefObject<HTMLDivElement>) => (
  <Fade in={open}>
    <div {...props} ref={ref} className={styles.backdrop} />
  </Fade>
));
