import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Button } from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';
import ErrorPage from './error-page.component';
import styles from './not-found-page.module.scss';

export const NotFoundPage = () => {
  const { t } = useTranslation('');
  const { isMobile, isTablet, isDesktop } = useMatchMedia();


  const ActionButton = (
    <Button
      appearance="primary"
      buttonColor="blue"
      as="link"
      href="/"
      buttonSize={isMobile ? 's' : 'm'}
      className={isMobile ? styles.buttonS : styles.buttonM}
    >
      {t('notFound:pageNotFound.returnHome')}
    </Button>
  );

  const Content = (
    <>
      <h3>{t('notFound:pageNotFound.header')}</h3>
      <p>{t('notFound:pageNotFound.description')}</p>
    </>
  );

  return (
    <ErrorPage button={ActionButton} content={Content}>
      <div className={styles.mascot}>
        {(isTablet || isDesktop) && (
          <div className={styles.mascot_numbers}>
            <p>4</p>
            <p>0</p>
            <p>4</p>
          </div>
        )}
        {isMobile && (
          <p className={styles.mascot_label}>
            {t('notFound:pageNotFound.errorLabel')}
          </p>
        )}
        <div className={styles.mascot_image}>
          <img
            src="/assets/mascots/rabbit-not-found.svg"
            alt="404 - Not found"
          />
        </div>
      </div>
    </ErrorPage>
  );
};
