import React, { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { SvgIcon } from '@/modules/shared/components';
import { Category, SubCategory } from '@/modules/categories';
import {
  CategoryListStepOne,
  CategoryListStepTwo,
  CategoryListStepThree,
} from './components';
import styles from './category-list.module.scss';

interface Props {
  categories: Category[];
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CategoryListMobile = ({ onClose, categories }: Props) => {
  const router = useRouter();
  const [step, setStep] = useState(1);
  const [activeCategory, setActiveCategory] = useState<Category | null>(null);
  const [activeSubcategory, setActiveSubcategory] = useState<SubCategory | null>(null);

  const handleLogoClick = useCallback(() => {
    onClose(false);
    router.push('/');
  }, []);

  const handleClickBack = useCallback(() => {
    if (step > 1) {
      setStep((prev) => prev - 1);
    }
  }, [step]);

  return (
    <>
      <div
        className={styles.header}
        onClick={(event) => event.stopPropagation()}
      >
        {step === 1 ? (
          <SvgIcon
            name="logo-main-small"
            className={styles.logo}
            onClick={handleLogoClick}
          />
        ) : (
          <SvgIcon name="arrow-left" onClick={handleClickBack} />
        )}
        <SvgIcon name="close" onClick={() => onClose(false)} />
      </div>
      <div
        className={styles.container}
        onClick={(event) => event.stopPropagation()}
      >
        {step === 1 && (
          <CategoryListStepOne
            categories={categories}
            setStep={setStep}
            setActiveCategory={setActiveCategory}
          />
        )}
        {step === 2 && activeCategory && (
          <CategoryListStepTwo
            activeCategory={activeCategory}
            setStep={setStep}
            setActiveSubcategory={setActiveSubcategory}
            onClose={onClose}
          />
        )}
        {step === 3 && activeSubcategory && (
          <CategoryListStepThree
            activeCategory={activeCategory}
            activeSubcategory={activeSubcategory}
            onClose={onClose}
          />
        )}
      </div>
    </>
  );
};
