import { CategoryBase } from '@/modules/categories/types';
import { SvgIcon } from '@/modules/shared/components/svg-icon';
import s from './section-selection-mobile.module.scss';

type SectionSelectionMobileProps = {
  data: CategoryBase;
  onClick: () => void;
};

export const SectionSelectionMobile = ({ data, onClick }: SectionSelectionMobileProps) => (
  <button
    type="button"
    data-testid={`section_${data.codename ?? 'all'}`}
    onClick={onClick}
    className={s.section_button}
  >
    <h3>{data.title}</h3>
    <SvgIcon name="arrow-bottom" />
  </button>
);
