import React, {
  useCallback, useMemo, useRef, useState, CSSProperties,
} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ButtonIcon, SliderDots } from '@/modules/shared/components';
import clsx from 'clsx';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { BannerImages } from './banner.type';
import s from './banner.module.scss';

interface ArrowProps {
  onClick?: () => void;
}

const SampleNextArrow = ({ onClick }: ArrowProps) => (
  <div className={clsx(s.arrow, s.next_arrow)}>
    <ButtonIcon
      onClick={onClick}
      nameIcon="banner-arrow-right"
      dataTestid="arrow_next"
    />
  </div>
);

const SamplePrevArrow = ({ onClick }: ArrowProps) => (
  <div className={clsx(s.arrow, s.prev_arrow)}>
    <ButtonIcon
      onClick={onClick}
      nameIcon="banner-arrow-left"
      dataTestid="arrow_prev"
    />
  </div>
);

interface Props {
  isFullWidth: boolean;
  className?: string | CSSProperties;
  bannerImages: BannerImages[];
}

export const Banner = ({ isFullWidth, bannerImages, className }: Props) => {
  const { lang } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<Slider>(null);

  const handleBeforeChange = useCallback((_, newIndex: number) => {
    setCurrentSlide(newIndex);
  }, []);

  const goToSlide = useCallback((index: number) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  }, []);

  const settings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      swipe: true,
      adaptiveHeight: true,
      arrows: true,
      beforeChange: handleBeforeChange,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    }),
    [handleBeforeChange],
  );

  return (
    <section className={clsx(s.slider, className, { [s.slider_full]: isFullWidth })} data-testid="advertising_banner">
      <Slider {...settings} ref={sliderRef}>
        {bannerImages.map((banner: BannerImages) => {
          let link = banner.banner_url;
          if (link && link.includes('/ru/')) {
            link = link.replace('/ru/', `/${lang}/`);
          }
          if (link && link.includes('/en/')) {
            link = link.replace('/en/', `/${lang}/`);
          }
          return link ? (
            <Link href={link} key={banner.id}>
              <img
                src={banner.banner_image}
                alt={`Slide ${banner.id}`}
                className={s.image}
              />
            </Link>
          ) : (
            <div key={banner.id}>
              <img
                src={banner.banner_image}
                alt={`Slide ${banner.id}`}
                className={s.image}
              />
            </div>
          );
        })}
      </Slider>
      {bannerImages.length > 1 && (
        <div className={s.dots}>
          {bannerImages.map((_, index: number) => (
            <SliderDots
              key={index}
              onChange={goToSlide}
              value={index}
              current={currentSlide}
            />
          ))}
        </div>
      )}
    </section>
  );
};
