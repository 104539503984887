import { useParams } from 'next/navigation';
import Cookies from 'js-cookie';
import {
  useCallback,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import { api } from '@/modules/shared/api';
import { AuthApi } from '../api';
import { getTokenExpiration, cleanCookies } from './auth.helpers';

export const useLogout = () => {
  const params = useParams();

  return useCallback(async () => {
    try {
      await api.post('/api/users/logout/');
      cleanCookies();
      window.location.href = `/${params?.locale || ''}`;
    } catch (e) {
      console.error(e);
    }
  }, [params?.locale]);
};


export const useWatchAuthTokens = (
  accessToken: string,
  setAccessToken: Dispatch<SetStateAction<string | undefined>>,
  refreshToken: string,
  setRefreshToken: Dispatch<SetStateAction<string | undefined>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
) => {
  const timeoutRef = useRef<number | undefined>();

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const fetchAccessToken = async () => {
      try {
        setIsLoading(true);
        const token = await AuthApi.refreshAccessToken(refreshToken);
        const exp = getTokenExpiration(token);
        Cookies.set('access_token', token, { expires: exp });
        setAccessToken(token);
      } catch (err) {
        if (err.response?.status === 401) {
          setAccessToken(undefined);
          setRefreshToken(undefined);
          cleanCookies();
          window.location.reload();
        }
        throw err;
      } finally {
        setIsLoading(false);
      }
    };

    if (refreshToken) {
      const tokenExpiresIn = +(getTokenExpiration(accessToken) || new Date()) - Date.now() - 30000;

      timeoutRef.current = setTimeout(
        () => fetchAccessToken(),
        Math.max(tokenExpiresIn, 0),
      ) as unknown as number;
    }

    /**
     * HOTFIX обновления токена если он по какой то причине протухщий или установлен не верно
     * TODO Разобраться почему это происходит
     */
    try {
      if (accessToken) {
        const exp = getTokenExpiration(accessToken);
        if ((exp?.getTime() || 0) <= Date.now()) {
          throw new Error('Was used expired token');
        }
      }
    } catch (e) {
      console.error(e);
      setAccessToken(undefined);
      setRefreshToken(undefined);
      cleanCookies();
    }
  }, [accessToken, refreshToken]);
};
